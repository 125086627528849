import {
  Download,
  FileOpen,
  InsertDriveFileOutlined,
} from "@mui/icons-material";
import {
  alpha,
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Icon,
  IconButton,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/system";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { FilePond, registerPlugin } from "react-filepond";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {
  notifyShowErrorMessage,
  notifyShowSuccessMessage,
} from "../common/CommonActions";
import { ErrorMessageResolver } from "../common/ErrorMessageResolver";
import { LOCALE } from "../properties/Locale";
import { AttachmentRepository } from "../repositories/AttachmentRepository";
import { AuthRepository } from "../repositories/AuthRepository";

registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginFileValidateSize);

export const CsvBookings = ({}) => {
  const [searchParams, setSearchParams] = useState({});
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const globalState = useSelector((state) => state);

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(15);
  const [expanded, setExpanded] = useState(false);
  const theme = useTheme();
  const params = useParams();
  const [currentUser, setCurrentUser] = useState(
    AuthRepository.getUserDetails()
  );
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const [files, setFiles] = useState([]);
  const [csvBookingsFile, setCsvBookingsFile] = useState();
  const [openNotProcessedTicketDialog, setOpenNotProcessedTicketDialog] =
    useState(false);
  const [notProcessedTickets, setNotProcessedTickets] = useState([]);

  const getStatusColor = (status) => {
    if (status === "PENDING") return theme.palette.info.main;
    if (status === "PROCESSING") return theme.palette.secondary.main;
    if (status === "WAITING") return theme.palette.primary.main;
    if (status === "DONE") return theme.palette.success.main;
    if (status === "ERROR") return theme.palette.error.main;
    else return theme.palette.primary.main;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate(
        `/client/uploads/${params?.page}/${params?.size}/${JSON.stringify(
          searchParams
        )}/${params?.orderValue}/${params?.orderDirection}`
      );
    }, 500);
    return () => clearTimeout(timer);
  }, [searchParams]);

  useEffect(() => {
    if (params && params.page && params.size) {
      loadData(
        params?.page,
        params?.size,
        params?.params,
        params?.orderValue,
        params?.orderDirection
      );
    }
    setSearchParams(JSON.parse(params.params));
  }, [
    params?.page,
    params?.size,
    params?.params,
    params?.orderValue,
    params?.orderDirection,
    globalState?.receiveNewNotification,
  ]);

  const loadData = (
    providedPage,
    providedSize,
    filterParams,
    orderValue,
    orderDirection
  ) => {
    setLoading(true);
    let tmp = filterParams ? filterParams : JSON.stringify({ ...searchParams });
    let tmpObj = JSON.parse(tmp);
    tmp = JSON.stringify(tmpObj);
    let tmpPage = providedPage ? providedPage : page;
    let tmpSize = providedSize ? providedSize : size;

    AttachmentRepository.getAllCSVBookings(
      tmpPage,
      tmpSize,
      tmp,
      orderValue,
      orderDirection
    )
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
        setLoading(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    navigate(
      `/client/uploads/${newPage}/${params?.size}/${JSON.stringify(
        JSON.parse(params.params)
      )}/${params?.orderValue}/${params?.orderDirection}`
    );
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(parseInt(event.target.value, 10));
    navigate(
      `/client/uploads/${0}/${event.target.value}/${JSON.stringify(
        JSON.parse(params.params)
      )}/${params?.orderValue}/${params?.orderDirection}`
    );
  };

  const submitUploadedFile = () => {
    setLoading(true);
    AttachmentRepository.uploadCSVBookingsFile(csvBookingsFile)
      .then((res) => {
        dispatch(notifyShowSuccessMessage("File uploaded successfully!"));
        loadData();
        setLoading(false);
        setCsvBookingsFile(null);
        setFiles([]);
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage("Error uploading file!"));
        setLoading(false);
      });
  };

  const handleUploadDocument = (file, setFile) => {
    if (file) {
      setFile(file);
    }
  };

  const handleOnProcessFile = (error, file) => {
    if (error) {
      console.log(error);
    }
  };

  const handleCloseProcessedTicketsDialog = () => {
    setNotProcessedTickets([]);
    setOpenNotProcessedTicketDialog(false);
  };

  const handleDownloadFile = (selectedFileId, initFile) => {
    setLoading(true);
    AttachmentRepository.downloadCSVBookingsFile(selectedFileId, initFile)
      .then((res) => {
        const blob = new Blob([res.data], {
          type: res.headers["content-type"],
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;

        // Extract the filename from the Content-Disposition header
        const contentDisposition = res.headers["content-disposition"];
        const filename = contentDisposition
          ? contentDisposition.split("filename=")[1].replace(/"/g, "")
          : initFile
          ? `Initial Bookings File - ${selectedFileId}.csv`
          : `Processed Bookings File - ${selectedFileId}.xlsx`;

        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={4}>
          <Stack
            direction="row"
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography
              variant="h5"
              key="2"
              color="text.primary"
              sx={{
                fontWeight: "bold",
                typography: { xs: "h4", md: "h5" },
                alignContent: "center",
                marginLeft: { xs: "8px", md: "0px" },
              }}
            >
              Your Uploads
              <IconButton
                onClick={() => loadData()}
                sx={{
                  border: "1px solid lightGray",
                  borderRadius: "5px",
                  padding: "1px",
                  minWidth: "auto",
                  height: "auto",
                  marginLeft: "5px",
                  display: { xs: "none", md: "inline-flex" },
                }}
              >
                <Icon
                  style={{ color: theme.palette.darkContrast.main }}
                  fontSize="20"
                >
                  refresh
                </Icon>
              </IconButton>
            </Typography>
            <Box
              sx={{
                display: { xs: "inline-flex", md: "none", marginRight: "11px" },
              }}
            >
              <IconButton
                onClick={() => loadData()}
                sx={{
                  border: "1px solid lightGray",
                  borderRadius: "5px",
                  padding: "1px",
                  minWidth: 55,
                  height: 55,
                  marginLeft: "5px",
                }}
              >
                <Icon
                  style={{
                    color: theme.palette.darkContrast.main,
                    fontSize: "28px",
                  }}
                >
                  refresh
                </Icon>
              </IconButton>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12} md={4} sx={{ paddingTop: 2 }}>
          <FilePond
            onprocessfile={handleOnProcessFile}
            files={files}
            onupdatefiles={setFiles}
            onremovefile={() => {
              setCsvBookingsFile(null);
            }}
            maxFileSize="5MB"
            allowFileSizeValidation={true}
            allowMultiple={false}
            acceptedFileTypes={[
              "application/vnd.ms-excel",
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              "text/csv",
            ]}
            maxFiles={1}
            server={{
              process: (
                fieldName,
                file,
                metadata,
                load,
                error,
                progress,
                abort
              ) => {
                try {
                  handleUploadDocument(file, setCsvBookingsFile);
                  load(file);
                } catch (err) {
                  error("An error occurred during file processing");
                }
              },
              revert: null,
              restore: null,
              load: null,
            }}
            name="file"
            labelIdle='Drag & Drop your file or <span class="filepond--label-action">Browse</span>'
          />
        </Grid>
        {csvBookingsFile && (
          <Grid item xs={12} md={2} sx={{ marginLeft: 2, marginTop: 5 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => submitUploadedFile()}
            >
              Upload Bookings
            </Button>
          </Grid>
        )}

        <Grid item xs={12} sx={{ paddingTop: 2 }}>
          <Divider />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            display: {
              xs: "block",
              md: "none",
            },
            height: "60vh",
            overflowY: "auto",
            scrollbarWidth: "none", // For Firefox
            "&::-webkit-scrollbar": {
              display: "none", // For Webkit browsers (Chrome, Safari, Edge)
            },
          }}
        >
          <Table
            sx={{
              borderSpacing: "8px",
              borderCollapse: "separate",
              paddingBottom: "10px",
            }}
          >
            <TableBody>
              {data &&
                data?.totalElements > 0 &&
                data?.content?.map((file, index) => (
                  <TableRow key={index}>
                    <TableCell colSpan={12} sx={{ padding: { xs: 1, sm: 2 } }}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Grid
                            container
                            spacing={1}
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <Grid item xs={4}>
                              <Typography
                                variant="h6"
                                sx={{ fontWeight: "light" }}
                              >
                                Date of Upload:
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography
                                variant="h6"
                                sx={{ fontWeight: "light" }}
                              >
                                {file?.dateCreated
                                  ? moment(file?.dateCreated).format(
                                      "DD.MM.YYYY HH:mm:ss"
                                    )
                                  : "N/A"}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid
                            container
                            spacing={1}
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <Grid item xs={4}>
                              <Typography
                                variant="h6"
                                sx={{ fontWeight: "light" }}
                              >
                                Initial File Name:
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography
                                variant="h6"
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  fontWeight: "light",
                                  fontSize: { xs: "14px", sm: "16px" },
                                }}
                              >
                                <InsertDriveFileOutlined
                                  sx={{
                                    marginRight: 1,
                                    fontSize: { xs: "16px", sm: "20px" },
                                  }}
                                />
                                {file?.initExcelExport?.fileName
                                  ? file?.initExcelExport?.fileName
                                      ?.split("\\")
                                      .pop()
                                  : "N/A"}
                                {file?.initExcelExport && (
                                  <Tooltip
                                    arrow
                                    title={
                                      <Typography variant="h6">
                                        Download Initial File
                                      </Typography>
                                    }
                                  >
                                    <IconButton
                                      sx={{
                                        border: `1px solid ${theme.palette.lightGray.main}`,
                                        padding: { xs: 0.5, sm: 1 },
                                        marginLeft: "8px",
                                      }}
                                      onClick={() =>
                                        handleDownloadFile(
                                          file?.initExcelExport?.id,
                                          true
                                        )
                                      }
                                      disabled={loading}
                                    >
                                      <Download
                                        sx={{
                                          fontSize: { xs: "16px", sm: "20px" },
                                        }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid
                            container
                            spacing={1}
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <Grid item xs={4}>
                              <Typography
                                variant="h6"
                                sx={{ fontWeight: "light" }}
                              >
                                Result File Name:
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <Typography
                                variant="h6"
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  fontWeight: "light",
                                  fontSize: { xs: "14px", sm: "16px" },
                                }}
                              >
                                <InsertDriveFileOutlined
                                  sx={{
                                    marginRight: 1,
                                    fontSize: { xs: "16px", sm: "20px" },
                                  }}
                                />
                                {file?.resultExcelExport
                                  ? file?.resultExcelExport?.fileName
                                  : "N/A"}
                                {file?.resultExcelExport &&
                                  file?.csvBookingStatus == "DONE" && (
                                    <Tooltip
                                      arrow
                                      title={
                                        <Typography variant="h6">
                                          Download Result File
                                        </Typography>
                                      }
                                    >
                                      <IconButton
                                        sx={{
                                          border: `1px solid ${theme.palette.lightGray.main}`,
                                          padding: { xs: 0.5, sm: 1 },
                                          marginLeft: "8px",
                                        }}
                                        onClick={() =>
                                          handleDownloadFile(
                                            file?.resultExcelExport?.id,
                                            false
                                          )
                                        }
                                        disabled={loading}
                                      >
                                        <Download
                                          sx={{
                                            fontSize: {
                                              xs: "16px",
                                              sm: "20px",
                                            },
                                          }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        {/* <Grid item xs={12}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: "light",
                              fontSize: { xs: "14px", sm: "16px" },
                            }}
                          >
                            {file?.initExcelExport?.initiatedBy?.displayName
                              ? file?.initExcelExport?.initiatedBy?.displayName
                              : "N/A"}
                          </Typography>
                        </Grid> */}
                        <Grid item xs={12}>
                          <Grid container spacing={1} alignItems="center">
                            <Grid item xs={4}>
                              <Typography
                                variant="h6"
                                sx={{ fontWeight: "light" }}
                              >
                                Processed Tickets:
                              </Typography>
                            </Grid>
                            <Grid item xs={5}>
                              <Typography
                                variant="h5"
                                sx={{
                                  fontWeight: "light",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {
                                  file?.csvBookingItems?.filter(
                                    (item) => item.isCreated
                                  ).length
                                }{" "}
                                out of {file?.csvBookingItems?.length}
                                <IconButton
                                  variant="outlined"
                                  size="small"
                                  onClick={() => {
                                    setOpenNotProcessedTicketDialog(true);
                                    setNotProcessedTickets(
                                      file?.csvBookingItems
                                    );
                                  }}
                                >
                                  <FileOpen
                                    sx={{ color: theme.palette.primary.main }}
                                  />
                                </IconButton>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12}>
                          <Grid
                            container
                            spacing={1}
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <Grid item xs={4}>
                              <Typography
                                variant="h6"
                                sx={{ fontWeight: "light" }}
                              >
                                Status:
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <Chip
                                label={
                                  <Typography
                                    variant="h6"
                                    sx={{
                                      fontWeight: "light",
                                      fontSize: { xs: "12px", sm: "14px" },
                                    }}
                                  >
                                    {file?.csvBookingStatus}
                                  </Typography>
                                }
                                sx={{
                                  backgroundColor: getStatusColor(
                                    file?.csvBookingStatus
                                  ),
                                  color: theme.palette.background.paper,
                                  fontSize: { xs: "12px", sm: "14px" },
                                }}
                                variant="outlined"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter
              style={{
                position: "fixed",
                bottom: 0,
                zIndex: 5,
                backgroundColor: theme.palette.background.paper,
                width: "100%",
                left: 0,
                right: 0,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <TableRow>
                <TableCell colSpan={20} sx={{ borderBottom: "none" }}>
                  <Grid item xs={12} justifyContent="center"></Grid>
                  <Grid
                    container
                    sx={{
                      marginBottom: "10px",
                    }}
                  >
                    <Grid item xs={12}>
                      <Pagination
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          "& .MuiPagination-ul": {
                            display: "flex",
                            justifyContent: "center",
                            flexGrow: 1,
                          },
                          "& .MuiPaginationItem-root": {
                            fontSize: "24px",
                          },
                          "& .MuiPaginationItem-page": {
                            height: 48,
                            width: 48,
                          },
                          "& .MuiPaginationItem-page.Mui-selected": {
                            backgroundColor: alpha(
                              theme.palette.primary.main,
                              0.5
                            ),
                            color: "white",
                            height: 48,
                            width: 48,
                          },
                          "& .MuiPaginationItem-previousNext": {
                            border: "1px solid",
                            borderColor: theme.palette.lightGray.main,
                            height: 48,
                            width: 48,
                            "& svg": {
                              fontSize: "24px",
                            },
                          },
                        }}
                        count={
                          (data?.totalElements % size === 0
                            ? data?.totalElements / size
                            : Math.floor(data?.totalElements / size) + 1) || 0
                        }
                        shape="rounded"
                        size="small"
                        page={data?.number + 1 || 0}
                        onChange={(event, value) => {
                          handleChangePage(event, value - 1);
                        }}
                      />
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </Grid>

        <Grid
          item
          md={12}
          xs={12}
          sx={{
            height: "65vh",
            overflowY: "auto",
            display: { xs: "none", md: "block" },
          }}
        >
          <Table
            sx={{
              borderCollapse: "separate",
              borderSpacing: " 0 8px",
            }}
          >
            <TableHead
              sx={{
                position: "sticky",
                zIndex: 5,
                top: 0,
                backgroundColor: theme.palette.dashboardBackground.main,
                width: "100%",
              }}
            >
              <TableRow>
                <TableCell
                  style={{
                    align: "left",

                    color: "#8f8f8f",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    borderBottom: "none",
                  }}
                >
                  <Typography
                    variant={isMediumScreen ? "subtitle2" : "subtitle1"}
                  >
                    Date of Upload
                  </Typography>
                </TableCell>
                <TableCell
                  style={{
                    align: "left",

                    color: "#8f8f8f",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    borderBottom: "none",
                  }}
                >
                  <Typography
                    variant={isMediumScreen ? "subtitle2" : "subtitle1"}
                  >
                    Initial File
                  </Typography>
                </TableCell>

                <TableCell
                  style={{
                    align: "left",

                    color: "#8f8f8f",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    borderBottom: "none",
                  }}
                >
                  <Typography
                    noWrap
                    variant={isMediumScreen ? "subtitle2" : "subtitle1"}
                  >
                    Result File
                  </Typography>
                </TableCell>

                <TableCell
                  style={{
                    align: "left",

                    color: "#8f8f8f",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    borderBottom: "none",
                  }}
                >
                  <Typography
                    noWrap
                    variant={isMediumScreen ? "subtitle2" : "subtitle1"}
                  >
                    Processed tickets
                  </Typography>
                </TableCell>
                <TableCell
                  style={{
                    align: "center",

                    color: "#8f8f8f",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    borderBottom: "none",
                  }}
                >
                  <Typography
                    noWrap
                    variant={isMediumScreen ? "subtitle2" : "subtitle1"}
                  >
                    Status
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data?.totalElements > 0 &&
                data?.content?.map((file, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <Typography variant="h5" sx={{ fontWeight: "light" }}>
                          {file?.dateCreated
                            ? moment(file?.dateCreated).format(
                                "DD.MM.YYYY HH:mm:ss"
                              )
                            : "N/A"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="h5"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            fontWeight: "light",
                          }}
                        >
                          <InsertDriveFileOutlined
                            sx={{
                              marginRight: 1,
                              fontSize: "20px",
                            }}
                          />
                          {file?.initExcelExport?.fileName
                            ? file?.initExcelExport?.fileName
                            : "N/A"}
                          {file?.initExcelExport && (
                            <Tooltip
                              arrow
                              title={
                                <Typography variant="h6">
                                  Download Initial File
                                </Typography>
                              }
                            >
                              <IconButton
                                sx={{
                                  border: `1px solid ${theme.palette.lightGray.main}`,
                                  marginLeft: "8px",
                                }}
                                onClick={() =>
                                  handleDownloadFile(
                                    file?.initExcelExport?.id,
                                    true
                                  )
                                }
                                disabled={loading}
                              >
                                <Download />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="h5"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            fontWeight: "light",
                          }}
                        >
                          <InsertDriveFileOutlined
                            sx={{
                              marginRight: 1,
                              fontSize: "20px",
                            }}
                          />
                          {file?.resultExcelExport
                            ? file?.resultExcelExport?.fileName
                            : "N/A"}
                          {file?.resultExcelExport &&
                            file?.csvBookingStatus == "DONE" && (
                              <Tooltip
                                arrow
                                title={
                                  <Typography variant="h6">
                                    Download Result File
                                  </Typography>
                                }
                              >
                                <IconButton
                                  sx={{
                                    border: `1px solid ${theme.palette.success.main}`,
                                    marginLeft: "8px",
                                    color: theme.palette.success.main,
                                  }}
                                  onClick={() =>
                                    handleDownloadFile(
                                      file?.resultExcelExport?.id,
                                      false
                                    )
                                  }
                                  disabled={loading}
                                >
                                  <Download />
                                </IconButton>
                              </Tooltip>
                            )}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h5" sx={{ fontWeight: "light" }}>
                          {
                            file?.csvBookingItems?.filter(
                              (item) => item.isCreated
                            ).length
                          }{" "}
                          out of {file?.csvBookingItems?.length}
                          <Tooltip
                            arrow
                            title={
                              <Typography variant="h6">
                                View unprocessed tickets
                              </Typography>
                            }
                          >
                            <IconButton
                              onClick={() => {
                                setOpenNotProcessedTicketDialog(true);
                                setNotProcessedTickets(file?.csvBookingItems);
                              }}
                            >
                              <FileOpen />
                            </IconButton>
                          </Tooltip>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {file?.csvBookingStatus === "ERROR" ? (
                          <Tooltip
                            arrow
                            title={
                              <Typography variant="h6">
                                {file?.errorMessage
                                  ? file?.errorMessage
                                  : "Unexpected error"}
                              </Typography>
                            }
                          >
                            <Chip
                              label={
                                <Typography
                                  variant="h6"
                                  sx={{ fontWeight: "light" }}
                                >
                                  {file?.csvBookingStatus}
                                </Typography>
                              }
                              sx={{
                                backgroundColor: getStatusColor(
                                  file?.csvBookingStatus
                                ),
                                color: theme.palette.background.paper,
                              }}
                              variant="outlined"
                            />
                          </Tooltip>
                        ) : (
                          <Chip
                            label={
                              <Typography
                                variant="h6"
                                sx={{ fontWeight: "light" }}
                              >
                                {file?.csvBookingStatus}
                              </Typography>
                            }
                            sx={{
                              backgroundColor: getStatusColor(
                                file?.csvBookingStatus
                              ),
                              color: theme.palette.background.paper,
                            }}
                            variant="outlined"
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>

            <TableFooter
              style={{
                position: "fixed",
                bottom: 0,
                zIndex: 5,
                backgroundColor: theme.palette.dashboardBackground.main,
                width: "100%",
              }}
            >
              <Grid container spacing={1} sx={{ display: "flex" }}>
                <Grid item xs={4}>
                  <TablePagination
                    sx={{
                      borderBottom: "none",
                      "& .MuiTablePagination-select": {
                        fontSize: isMediumScreen ? "12px" : "18px",
                        border: "1px solid",
                        borderColor: theme.palette.lightGray.main,
                        padding: isMediumScreen ? "5px 8px" : "5px 15px",
                        borderRadius: "4px",
                      },
                      "& .MuiTablePagination-displayedRows": {
                        fontSize: isMediumScreen ? "15px" : "20px",
                      },
                      "& .MuiTablePagination-actions button": {
                        fontSize: isMediumScreen ? "15px" : "20px",
                      },
                      "& .MuiIconButton-root": {
                        fontSize: isMediumScreen ? "15px" : "20px",
                      },
                      "& .MuiSelect-icon": {
                        fontSize: isMediumScreen ? "15px" : "20px",
                      },
                      "& .MuiTablePagination-displayedRows": {
                        marginLeft: "-15px",
                        fontSize: isMediumScreen ? "12px" : "15px",
                        color: theme.palette.lightGray.main,
                      },
                    }}
                    rowsPerPageOptions={
                      AuthRepository.hasAnyRole(["ROLE_ADMINISTRATION"])
                        ? [5, 10, 15, 25, 50, 75, 100]
                        : [5, 10, 15, 25]
                    }
                    rowsPerPage={params?.size ? params?.size : size}
                    labelDisplayedRows={() => `of ${data?.totalElements}`}
                    labelRowsPerPage={
                      <span
                        style={{
                          fontSize: isMediumScreen ? "12px" : "15px",
                          marginLeft: "-20px",
                          color: theme.palette.lightGray.main,
                        }}
                      >
                        {LOCALE.rows_per_page}
                      </span>
                    }
                    SelectProps={{
                      inputProps: {
                        fontSize: isMediumScreen ? "15px" : "20px",
                        "aria-label": LOCALE.rows_per_page,
                      },
                      native: true,
                    }}
                    onRowsPerPageChange={(event, value) => {
                      handleChangeRowsPerPage(event);
                    }}
                    page={data?.number || 0}
                    count={data?.totalElements || 0}
                    size="large"
                    ActionsComponent={() => null}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Pagination
                    sx={{
                      marginTop: "15px",
                      display: "flex",
                      justifyContent: "center",
                      "& .MuiPaginationItem-root": {
                        fontSize: isMediumScreen ? "15px" : "20px",
                      },
                      "& .MuiPaginationItem-page.Mui-selected": {
                        backgroundColor: theme.palette.secondary.main,
                        opacity: "50%",
                        color: "white",
                      },
                      "& .MuiPaginationItem-previousNext": {
                        border: "1px solid",
                        borderColor: theme.palette.lightGray.main,
                      },
                    }}
                    count={
                      (data?.totalElements % size === 0
                        ? data?.totalElements / size
                        : Math.floor(data?.totalElements / size) + 1) || 0
                    }
                    shape="rounded"
                    size={isMediumScreen ? "medium" : "large"}
                    page={data?.number + 1 || 0}
                    onChange={(event, value) => {
                      handleChangePage(event, value - 1);
                    }}
                  />
                </Grid>
              </Grid>
            </TableFooter>
          </Table>
        </Grid>
      </Grid>
      <Dialog
        open={openNotProcessedTicketDialog}
        onClose={handleCloseProcessedTicketsDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <Typography variant="h5" sx={{ fontWeight: "light" }}>
            Unprocessed Tickets
          </Typography>
        </DialogTitle>
        <DialogContent>
          {notProcessedTickets.filter((item) => item.isCreated === false)
            .length > 0 ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">Index</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">Plate Number</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="h6">Ticket Created</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {notProcessedTickets
                  .filter((item) => item.isCreated === false)
                  .map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Typography variant="h6" sx={{ fontWeight: "light" }}>
                          {index + 1}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6" sx={{ fontWeight: "light" }}>
                          {item.plateNumber}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="h6" sx={{ fontWeight: "light" }}>
                          {item.isCreated ? "YES" : "NO" || "N/A"}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          ) : (
            <Typography variant="body1" color="textSecondary">
              No unprocessed tickets available.
            </Typography>
          )}
        </DialogContent>
        <Button
          onClick={() => handleCloseProcessedTicketsDialog()}
          variant="contained"
          style={{ margin: "16px" }}
        >
          Close
        </Button>
      </Dialog>
    </>
  );
};
