import DirectionsBoatFilledTwoToneIcon from "@mui/icons-material/DirectionsBoatFilledTwoTone";
import {
  alpha,
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { notifyShowSuccessMessage } from "../common/CommonActions";
import { LOCALE } from "../properties/Locale";
import { AuthRepository } from "../repositories/AuthRepository";
import { ProductRepository } from "../repositories/ProductRepository";
import AvailableComplexDeparturesCalendar from "./AvailableComplexDeparturesCalendar.js";
import ReactCountryFlag from "react-country-flag";
import EastIcon from "@mui/icons-material/East";
import { ExpandLessOutlined, ExpandMoreOutlined } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import CheckIcon from "@mui/icons-material/Check";
import AvailableComplexDeparturesCalendarMobile from "./mobile/AvailableComplexDeparturesCalendarMobile.js";
export default function AgentComplexTicketDetails({
  availableDepartures,
  data,
  handleChangeData,
  setSurchargesTotal,
  surchargesTotal,
  availableComplexDepartures,
  complexTickets,
  setComplexTickets,
  setAvailableComplexDepartures,
  selectedProductDestinations,
  setSelectedProductDestinations,
  selectedDepartures,
  setSelectedDepartures,
  selectedComplexDepartureKey,
  setSelectedComplexDepartureKey,
  editTicketFlag,
  ticketGroup,
  tmpData,
  changeTicket,
  setChangeTicket,
  showDeparturesForNextYear,
}) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState("");
  const availableComplexDeparturesRef = React.useRef({});
  const [selectedValue, setSelectedValue] = useState(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    let tmpComplexTickets = { ...complexTickets };
    let tmp = { ...data };
    Object.keys(tmpComplexTickets).map((key) => {
      tmpComplexTickets[key].cargoWeight = tmp.cargoWeight;
      tmpComplexTickets[key].dangerousGoods = tmp.dangerousGoods;
      tmpComplexTickets[key].electricPlugin = tmp.electricPlugin;
      tmpComplexTickets[key].liveAnimals = tmp.liveAnimals;
      tmpComplexTickets[key].loaded = tmp.loaded;
      tmpComplexTickets[key].numberOfAxles = tmp.numberOfAxles;
      tmpComplexTickets[key].numberOfDrivers = tmp.numberOfDrivers;
      tmpComplexTickets[key].organization = tmp.organization;
      tmpComplexTickets[key].vehicleLength = tmp.vehicleLength;
      tmpComplexTickets[key].vehicleWidth = tmp.vehicleWidth;
      tmpComplexTickets[key].truckPlateNumber = tmp.truckPlateNumber;
      tmpComplexTickets[key].hasAdjustedPrice = tmp.hasAdjustedPrice;
      tmpComplexTickets[key].adjustedCalculatedPrice =
        tmp.adjustedCalculatedPrice;
      tmpComplexTickets[key].adjustedPrice = tmp.adjustedPrice;
    });
    setComplexTickets(tmpComplexTickets);
  }, [data]);

  useEffect(() => {
    if (
      selectedProductDestinations?.length == 0 ||
      selectedProductDestinations == undefined
    ) {
      prepopulateDefaultSelectedProductDestinations("firstLoad");
    } else if (changeTicket) {
      prepopulateDefaultSelectedProductDestinations();
    }
  }, []);

  useEffect(() => {
    if (
      ticketGroup?.reason === "Complex ticket" &&
      selectedComplexDepartureKey
    ) {
      fetchDataSequentially();
    }
  }, [selectedComplexDepartureKey]);

  const fetchDataSequentially = async () => {
    if (complexTickets && Object.keys(complexTickets).length > 0) {
      Object.keys(complexTickets).map((key) => {
        getAvailableComplexDepartures(
          complexTickets[key]?.departureDay,
          complexTickets[key]?.productDestination?.destination?.code
        );
      });
    } else {
      for (const ticket of ticketGroup.tickets) {
        await getAvailableComplexDepartures(
          ticket.departureDay,
          ticket.productDestination.destination.code
        );
      }
    }
  };

  const prepopulateDefaultSelectedProductDestinations = (load) => {
    // prepopulate selectedProductDestinations with first legs of all availableComplexDepartures
    let tmp = [];
    let tmpComplexTickets = {};
    let firstLeg = 1;
    let tmpDepartures = { ...selectedDepartures };

    Object.keys(availableComplexDepartures).map((key) => {
      if (
        availableComplexDepartures[key]?.legNumber
          .toString()
          .startsWith(firstLeg.toString())
      ) {
        if (editTicketFlag) {
          if (
            ticketGroup?.reason === "Complex ticket" &&
            ticketGroup?.tickets?.find((x) => x.destination.code == key)
          ) {
            tmp.push({
              key: key,
              legNumber: availableComplexDepartures[key].legNumber,
            });
            tmpComplexTickets[key] = { ...tmpData };
            tmpComplexTickets[key].id = ticketGroup?.tickets?.find(
              (x) => x.destination.code === key
            ).id;
            tmpComplexTickets[key].status = ticketGroup?.tickets?.find(
              (x) => x.destination.code === key
            ).status;
            tmpComplexTickets[key].reservationCode = ticketGroup?.tickets?.find(
              (x) => x.destination.code === key
            ).reservationCode;
            tmpComplexTickets[key].ticketNumber = ticketGroup?.tickets?.find(
              (x) => x.destination.code === key
            ).ticketNumber;

            tmpComplexTickets[key].fromCity = ticketGroup?.tickets?.find(
              (x) => x.destination.code === key
            ).fromCity;
            tmpComplexTickets[key].toCity = ticketGroup?.tickets?.find(
              (x) => x.destination.code === key
            ).toCity;
            if (!tmpComplexTickets[key].departureDay) {
              tmpComplexTickets[key].departureDay = ticketGroup?.tickets?.find(
                (x) => x.destination.code === key
              ).departureDay;
            }
            let surcharges = ticketGroup?.tickets?.find(
              (x) => x.destination.code === key
            ).ticketSurcharges;
            tmpComplexTickets[key].ticketSurchargeList = surcharges;
            tmpComplexTickets[key].ticketSurcharges = surcharges;
            let departuresList =
              availableComplexDepartures[key].productAvailableDeparturesDTOList;

            let departure = departuresList?.find(
              (departure) =>
                departure.productDestinationDepartureId ===
                ticketGroup?.tickets?.find((x) => x.destination.code === key)
                  .productDestinationDeparture.id
            );
            if (departure) {
              tmpDepartures[key] = departure;
              tmpDepartures[key].ticketSurchargeList =
                departure.ticketSurchargeList;
            }
            tmpComplexTickets[key].hasAdjustedPrice =
              ticketGroup?.tickets?.find(
                (x) => x.destination.code === key
              ).hasAdjustedPrice;
            tmpComplexTickets[key].adjustedCalculatedPrice =
              ticketGroup?.tickets?.find(
                (x) => x.destination.code === key
              ).adjustedCalculatedPrice;
            tmpComplexTickets[key].adjustedPrice = ticketGroup?.tickets?.find(
              (x) => x.destination.code === key
            ).adjustedPrice;

            firstLeg = firstLeg + 1;
          }
        } else {
          tmp.push({
            key: key,
            legNumber: availableComplexDepartures[key].legNumber,
          });
          tmpComplexTickets[key] = { ...data };
          tmpComplexTickets[key].departureDay =
            availableComplexDepartures[
              key
            ]?.productAvailableDeparturesDTOList[0]?.departureDate;
          firstLeg = firstLeg + 1;
        }
      }
    });
    if (load === "firstLoad") {
      // setSelectedDepartures(tmpDepartures);
    }
    setComplexTickets(tmpComplexTickets);
    setSelectedProductDestinations(tmp);
    if (data?.id) {
      setChangeTicket(false);
    }
  };

  const handleChangeSelectedProductDestinations = (key) => {
    let tmp = [...selectedProductDestinations];
    let tmpComplexTickets = { ...complexTickets };
    // if key is already in array, remove it else add it
    if (
      tmp?.find((item) => item.key == key) != undefined &&
      tmp?.find((item) => item.key == key) != null
    ) {
      tmp = tmp.filter((item) => item.key !== key);
      delete tmpComplexTickets[key];
    } else {
      // check if any of the keys in tmp are in the same legNumber (same legNumber is if the legNumber starts with same digit) as key and remove them from tmp
      Object.keys(availableComplexDepartures).map((item) => {
        if (
          availableComplexDepartures[item].legNumber
            .toString()
            .startsWith(
              availableComplexDepartures[key].legNumber.toString().charAt(0)
            )
        ) {
          // check if item is in tmp and remove it
          if (
            tmp?.find((item2) => item2.key == item) != undefined &&
            tmp?.find((item2) => item2.key == item) != null
          ) {
            tmp = tmp.filter((item3) => item3.key !== item);
            delete tmpComplexTickets[item];
          }
        }
      });
      tmp.push({
        key: key,
        legNumber: availableComplexDepartures[key].legNumber,
      });
      tmpComplexTickets[key] = { ...data };
    }
    setSelectedProductDestinations(tmp);
    setComplexTickets(tmpComplexTickets);
  };

  const getAvailableComplexDepartures = async (date, itemKey) => {
    let loadedValue;
    if (data?.loaded === "2") {
      loadedValue = true;
    } else if (data?.loaded === "1") {
      loadedValue = false;
    } else {
      loadedValue = data?.loaded;
    }
    await ProductRepository.getAvailableComplexDepartures({
      vehicleLength: data?.vehicleLength,
      vehicleWidth: data?.vehicleWidth,
      vehicleHeight: data?.vehicleHeight,
      numberOfDrivers: data?.numberOfDrivers,
      cargoWeight: data?.cargoWeight,
      material: data?.material?.id,
      loaded: loadedValue,
      dangerousGoods: data?.dangerousGoods,
      electricPlugin: data?.electricPlugin,
      liveAnimals: data?.liveAnimals,
      fromCity: data?.product?.isComplex
        ? data?.product?.mainDestination?.fromCity?.id
        : data?.fromCity?.id,
      toCity: data?.product?.isComplex
        ? data?.product?.mainDestination?.toCity?.id
        : data?.toCity?.id,
      departureDate: moment(date).format("YYYY-MM-DD"),
      numberOfAxles: data?.numberOfAxles,
      organizationId: data?.organization?.id,
      ticketStatus: data?.status,
    })
      .then(async (res) => {
        availableComplexDeparturesRef.current[itemKey] =
          res.data[selectedComplexDepartureKey][itemKey];
        if (
          Object.keys(availableComplexDeparturesRef.current)?.length ==
          ticketGroup?.tickets?.length
        ) {
          setAvailableComplexDepartures(availableComplexDeparturesRef.current);
          prepopulateDefaultSelectedProductDestinations();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const [selectedValue, setSelectedValue] = useState("");

  // // Extract and filter options
  // const filteredOptions = Object.keys(availableComplexDepartures).map(
  //   (key) => ({
  //     value: key,
  //     label: availableComplexDepartures[key]?.destination?.name,
  //     disabled: availableComplexDepartures[key]?.isMandatory,
  //   })
  // );

  // const enabledOptions = filteredOptions.filter((option) => !option.disabled);
  // const selectableOptions =
  //   enabledOptions.length > 0 ? enabledOptions : filteredOptions;

  // // Handle selection change
  // const handleChange = (event) => {
  //   const newValue = event.target.value;
  //   setSelectedValue(newValue);
  //   handleChangeSelectedProductDestinations(newValue);
  // };

  // Initialize the selected value based on the props
  useEffect(() => {
    const nonMandatoryKeys = Object.keys(
      availableComplexDepartures || {}
    ).filter((key) => !availableComplexDepartures[key]?.isMandatory);
    const initialSelection =
      selectedProductDestinations[0]?.key &&
      availableComplexDepartures[selectedProductDestinations[0]?.key]
        ? selectedProductDestinations[0]?.key
        : nonMandatoryKeys[0] || null;
    setSelectedValue(initialSelection);
  }, [availableComplexDepartures, selectedProductDestinations]);

  // Helper function to get the lowest price from the list
  const getLowestPrice = (departures) => {
    if (!departures || departures.length === 0) return "N/A";
    const lowest = departures.reduce((prev, current) =>
      prev.salesPrice < current.salesPrice ? prev : current
    );
    return lowest.salesPrice?.toFixed(2);
  };

  // Helper function to concatenate prices
  const getCombinedPrice = (mandatoryDeparture, optionalDeparture) => {
    if (!mandatoryDeparture || !optionalDeparture) return "N/A";
    const mandatoryPrice = getLowestPrice(
      mandatoryDeparture.productAvailableDeparturesDTOList || []
    );
    const optionalPrice = getLowestPrice(
      optionalDeparture.productAvailableDeparturesDTOList || []
    );
    return (parseFloat(mandatoryPrice) + parseFloat(optionalPrice)).toFixed(2);
  };

  // Handle selection changes
  const handleChange = (event) => {
    const newValue = event.target.value;

    // Prevent changing the selected value if the new value is mandatory
    if (availableComplexDepartures[newValue]?.isMandatory) {
      return;
    }
    const selectedOption = options.find((option) => option.key === newValue);
    if (selectedOption) {
      setSelectedLabel(selectedOption.label);
    }
    // Update the selected value and notify parent
    setSelectedValue(newValue);
    handleChangeSelectedProductDestinations(newValue);
  };

  // Determine if all options are mandatory
  const allMandatory = Object.keys(availableComplexDepartures || {}).every(
    (key) => availableComplexDepartures[key]?.isMandatory
  );

  const options = allMandatory
    ? [] // No options if all are mandatory
    : Object.keys(availableComplexDepartures || {})
        .map((key) => {
          const departure = availableComplexDepartures[key];
          const isMandatory = departure?.isMandatory;

          if (!isMandatory) {
            const availableDestination =
              availableComplexDepartures[selectedValue]?.destination?.name ||
              "";
            const departureDestination = departure.destination?.name || "";

            // Split the destinations into segments
            const availableSegments = availableDestination.split("-");
            const departureSegments = departureDestination.split("-");

            // Check if the last segment of availableDestination matches the first segment of departureDestination
            if (
              availableSegments.length > 0 &&
              departureSegments.length > 0 &&
              availableSegments[availableSegments.length - 1] ===
                departureSegments[0]
            ) {
              // Remove the duplicate segment from the departureSegments
              departureSegments.shift();
            }

            // Construct the label
            const label = `${availableSegments.join(
              "-"
            )}-${departureSegments.join("-")}`;

            // Check if the label is concatenated with itself
            if (availableDestination === departureDestination) {
              return null; // Skip this option
            }

            return {
              key,
              label,
              price: getCombinedPrice(
                availableComplexDepartures[selectedValue],
                departure
              ),
            };
          }
          return null;
        })
        .filter((option) => option !== null);

  const getLegLabel = (index) => {
    switch (index) {
      case 0:
        return "First Leg";
      case 1:
        return "Second Leg";
      case 2:
        return "Third Leg";
      default:
        return `Leg ${index + 1}`;
    }
  };
  const getLegLabelIndex = (index) => {
    switch (index) {
      case 0:
        return "1 Leg";
      case 1:
        return "2 Leg";
      case 2:
        return "3 Leg";
      default:
        return `${index + 1} Leg `;
    }
  };

  const getLegLabelMobile = (index) => {
    switch (index) {
      case 0:
        return "1";
      case 1:
        return "2";
      case 2:
        return "3";
      default:
        return ` ${index + 1}`;
    }
  };

  const getCountryCodeForCity = (city) => {
    const departure = Object.values(availableComplexDepartures).find(
      (departure) =>
        departure?.destination?.fromCity.name === city ||
        departure?.destination?.toCity.name === city
    );
    if (departure) {
      if (departure?.destination?.fromCity.name === city) {
        return departure?.destination?.fromCountry?.code || "";
      } else if (departure?.destination?.toCity.name === city) {
        return departure?.destination?.toCountry?.code || "";
      }
    }
    return "";
  };

  const getCountryNameForCity = (city) => {
    const departure = Object.values(availableComplexDepartures).find(
      (departure) =>
        departure?.destination?.fromCity.name === city ||
        departure?.destination?.toCity.name === city
    );
    if (departure) {
      if (departure?.destination?.fromCity.name === city) {
        return departure?.destination?.fromCountry?.nameEn || "";
      } else if (departure?.destination?.toCity.name === city) {
        return departure?.destination?.toCountry?.nameEn || "";
      }
    }
    return "";
  };

  return (
    <>
      {!allMandatory && options.length > 0 && (
        <Grid
          item
          xs={12}
          sx={{
            marginRight: isSmallScreen && "16px",
            marginLeft: !isSmallScreen && "-10px",
          }}
        >
          <FormControl fullWidth sx={{ paddingLeft: "15px" }}>
            <InputLabel sx={{ paddingLeft: "20px" }}>
              Route Variation
            </InputLabel>
            <Select
              label="Route Variation"
              value={selectedValue || "Select a route"}
              onChange={handleChange}
              IconComponent={(props) => {
                return selectedValue ? (
                  <ExpandMoreOutlined
                    {...props}
                    sx={{
                      fontSize: "30px",
                    }}
                  />
                ) : (
                  <ExpandLessOutlined
                    {...props}
                    sx={{
                      fontSize: "30px",
                    }}
                  />
                );
              }}
              renderValue={() => {
                const destinations = !selectedLabel
                  ? options[0]?.label.split("-")
                  : selectedLabel.split("-");

                return (
                  <Typography
                    variant="h6"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    {destinations.map((city, index) => {
                      const countryCode = getCountryCodeForCity(city);
                      const countryName = getCountryNameForCity(city);
                      const displayCity = isSmallScreen
                        ? `${city.slice(0, 2)}...`
                        : city;

                      return (
                        <React.Fragment key={index}>
                          <ReactCountryFlag
                            countryCode={countryCode}
                            svg
                            style={{
                              width: "1.6em",
                              height: "1.6em",
                              borderRadius: "5px",
                              marginRight: "5px",
                            }}
                            title={countryName || "Unknown Country"}
                          />
                          {displayCity}
                          {index < destinations.length - 1 && (
                            <span
                              style={{
                                margin: "0 10px",
                                fontSize: "17px",
                                fontWeight: "lighter",
                              }}
                            >
                              {" ➜ "}
                            </span>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </Typography>
                );
              }}
            >
              {options.map(({ key, label, price }) => {
                const departure = availableComplexDepartures[key] || {};
                const isSelected = selectedProductDestinations?.some(
                  (item) => item.key === key
                );
                const isMandatory = departure?.isMandatory;

                const destinations = label.split("-");

                return (
                  <MenuItem
                    key={key}
                    value={key}
                    disabled={isMandatory}
                    onClick={() => {
                      setSelectedLabel(label);
                      handleChangeSelectedProductDestinations(key);
                    }}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      "&:hover": {
                        backgroundColor: (theme) =>
                          `${theme.palette.secondary.main}80`,
                        color: "white",
                        borderRadius: "5px",
                      },
                      margin: "0 5px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        display: "flex",
                        fontSize: isSmallScreen && "11px",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      {destinations?.map((city, index) => {
                        const countryCode = getCountryCodeForCity(city);
                        const countryName = getCountryNameForCity(city);

                        return (
                          <React.Fragment key={index}>
                            <ReactCountryFlag
                              countryCode={countryCode}
                              svg
                              style={{
                                width: isSmallScreen ? "1.4em" : "1.6em",
                                height: isSmallScreen ? "1.4em" : "1.6em",
                                borderRadius: "5px",
                                marginRight: "4px",
                              }}
                              title={countryName}
                            />
                            {city}
                            <span
                              style={{
                                margin: "0 10px",
                                fontSize: "17px",
                                fontWeight: "lighter",
                              }}
                            >
                              {index < destinations.length - 1 && " ➜ "}
                            </span>
                          </React.Fragment>
                        );
                      })}
                    </Typography>
                    {data?.organization?.showPrice && (
                      <Box
                        component="span"
                        sx={{
                          display: "inline-block",
                          padding: "2px 8px",
                          backgroundColor: isSelected
                            ? "secondary.main"
                            : "white",
                          border: !isSelected ? "1px solid lightGray" : "",
                          borderRadius: "5px",
                          color: isSelected ? "white" : "black",
                          fontSize: "0.875rem",
                          fontWeight: "500",
                        }}
                      >
                        {`≈${Math.round(price)}€`}
                      </Box>
                    )}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      )}

      {/* <FormControl fullWidth>
        <InputLabel id="select-destination-label">
          Select Destination
        </InputLabel>
        <Select
          labelId="select-destination-label"
          value={selectedValue}
          onChange={handleChange}
          displayEmpty
        >
          {selectableOptions.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              disabled={option.disabled && enabledOptions.length > 0}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl> */}
      <Grid
        item
        xs={12}
        md={12}
        style={{
          marginTop: allMandatory && isSmallScreen && "-80px",
          marginLeft: isSmallScreen && "16px",
        }}
      >
        {!editTicketFlag && (
          <IconButton
            onClick={() => {
              setAvailableComplexDepartures(undefined);
              setSelectedProductDestinations([]);
              setSelectedDepartures({});
              setComplexTickets({});
            }}
          >
            <Icon>arrow_back</Icon>
          </IconButton>
        )}
      </Grid>

      {selectedProductDestinations?.length > 0 &&
        selectedProductDestinations
          .sort((a, b) => a.legNumber - b.legNumber)
          .map((item, index) => {
            const isLegSelected = Object.keys(selectedDepartures).includes(
              item.key
            );
            return (
              <Grid
                item
                md={12}
                xs={12}
                key={index}
                style={{
                  marginTop: allMandatory && "-15px",
                  marginLeft: isSmallScreen && "16px",
                  marginRight: isSmallScreen && "16px",
                }}
              >
                <Typography sx={{ mb: 1 }} variant="h6">
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={3}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          fontWeight: "bold",
                        }}
                      >
                        <ReactCountryFlag
                          countryCode={
                            availableComplexDepartures[item.key]?.destination
                              ?.fromCountry?.code
                          }
                          svg
                          style={{
                            width: "1.5em",
                            height: "1.5em",
                            borderRadius: "5px",
                            marginRight: "4px",
                          }}
                          title={
                            availableComplexDepartures[item.key]?.destination
                              ?.fromCountry?.code
                          }
                        />
                        <span style={{ fontSize: "15px" }}>
                          {
                            availableComplexDepartures[item.key]?.destination
                              ?.fromCity?.name
                          }
                        </span>

                        <EastIcon
                          style={{ marginLeft: "5px", marginRight: "5px" }}
                        />

                        <ReactCountryFlag
                          countryCode={
                            availableComplexDepartures[item.key]?.destination
                              ?.toCountry?.code
                          }
                          svg
                          style={{
                            width: "1.5em",
                            height: "1.5em",
                            borderRadius: "5px",
                            marginRight: "4px",
                          }}
                          title={
                            availableComplexDepartures[item.key]?.destination
                              ?.toCountry?.code
                          }
                        />
                        <span style={{ fontSize: "15px" }}>
                          {
                            availableComplexDepartures[item.key]?.destination
                              ?.toCity?.name
                          }
                        </span>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      sx={{ display: { xs: "none", md: "block" } }}
                      xs={7}
                    >
                      <Divider
                        style={{
                          borderBottomWidth: "2px",
                          marginLeft: "100px",
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      sx={{ display: { xs: "block", md: "none" } }}
                      xs={3}
                    ></Grid>
                    <Grid
                      item
                      md={2}
                      xs={3}
                      container
                      sx={{
                        justifyContent: {
                          xs: "flex-start",
                          md: "flex-end",
                        },
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: "bold",
                        }}
                      >
                        <Chip
                          sx={{
                            display: { xs: "none", md: "block" },
                            fontSize: "12px",
                          }}
                          label={getLegLabel(index)}
                          icon={
                            isLegSelected ? (
                              <CheckIcon
                                style={{
                                  fontSize: "18px",
                                  paddingTop: 1,
                                  color: theme.palette.background.default,
                                  verticalAlign: "middle",
                                }}
                              />
                            ) : null
                          }
                          style={{
                            borderRadius: "4px",
                            paddingTop: !isLegSelected ? "10px" : "6px",
                            color: theme.palette.background.default,
                            backgroundColor: isLegSelected
                              ? "#88c078"
                              : alpha(theme.palette.greyContrast.main, 0.7),
                          }}
                        />
                        <Chip
                          sx={{
                            display: { xs: "block", md: "none" },
                            alignContent: "center",
                            marginLeft: isLegSelected ? "125px" : "106px",
                          }}
                          label={
                            isLegSelected
                              ? getLegLabelMobile(index)
                              : getLegLabelIndex(index)
                          }
                          style={{
                            fontSize: "12px",
                            borderRadius: "4px",
                            color: theme.palette.background.default,
                            backgroundColor: isLegSelected
                              ? "#88c078"
                              : alpha(theme.palette.greyContrast.main, 0.7),
                          }}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </Typography>
                <Grid
                  item
                  sx={{ display: { xs: "none", md: " block" } }}
                  mb={2}
                >
                  <AvailableComplexDeparturesCalendar
                    availableDepartures={
                      availableComplexDepartures[item.key]
                        ?.productAvailableDeparturesDTOList
                    }
                    data={data}
                    handleChangeData={handleChangeData}
                    selectedDepartures={selectedDepartures}
                    setSelectedDepartures={setSelectedDepartures}
                    setSurchargesTotal={setSurchargesTotal}
                    surchargesTotal={surchargesTotal}
                    itemKey={item.key}
                    nextItemKey={
                      selectedProductDestinations[index + 1]?.key
                        ? selectedProductDestinations[index + 1]?.key
                        : undefined
                    }
                    availableComplexDepartures={availableComplexDepartures}
                    setAvailableComplexDepartures={
                      setAvailableComplexDepartures
                    }
                    selectedComplexDepartureKey={selectedComplexDepartureKey}
                    setSelectedComplexDepartureKey={
                      setSelectedComplexDepartureKey
                    }
                    complexTickets={complexTickets}
                    setComplexTickets={setComplexTickets}
                    selectedProductDestinations={selectedProductDestinations}
                    ticketGroup={ticketGroup}
                    showDeparturesForNextYear={showDeparturesForNextYear}
                  />
                </Grid>

                <Grid
                  item
                  sx={{ display: { xs: "block", md: " none" } }}
                  mb={2}
                >
                  <AvailableComplexDeparturesCalendarMobile
                    availableDepartures={
                      availableComplexDepartures[item.key]
                        ?.productAvailableDeparturesDTOList
                    }
                    data={data}
                    handleChangeData={handleChangeData}
                    selectedDepartures={selectedDepartures}
                    setSelectedDepartures={setSelectedDepartures}
                    setSurchargesTotal={setSurchargesTotal}
                    surchargesTotal={surchargesTotal}
                    itemKey={item.key}
                    nextItemKey={
                      selectedProductDestinations[index + 1]?.key
                        ? selectedProductDestinations[index + 1]?.key
                        : undefined
                    }
                    availableComplexDepartures={availableComplexDepartures}
                    setAvailableComplexDepartures={
                      setAvailableComplexDepartures
                    }
                    selectedComplexDepartureKey={selectedComplexDepartureKey}
                    setSelectedComplexDepartureKey={
                      setSelectedComplexDepartureKey
                    }
                    complexTickets={complexTickets}
                    setComplexTickets={setComplexTickets}
                    selectedProductDestinations={selectedProductDestinations}
                    ticketGroup={ticketGroup}
                    showDeparturesForNextYear={showDeparturesForNextYear}
                  />
                </Grid>
              </Grid>
            );
          })}
      {/* <Grid item xs={12}>
                {data?.organization?.showPrice && !editTicketFlag && (
                  <Grid container spacing={2}>
                    <Grid item md={6}>
                      <Paper
                        elevation={0}
                        style={{
                          borderRadius: "10px",
                          padding: "5px",
                          backgroundColor: "#edf7ed",
                          textAlign: "center",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "12px",
                            // marginTop: 10,
                          }}
                          align="center"
                        >
                          Total freight
                        </Typography>
                        <Typography
                          style={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            // marginTop: 10,
                          }}
                          align="center"
                        >
                          {complexTickets[item.key]?.adjustedCalculatedPrice !=
                          null ? (
                            <>
                              &euro;
                              {parseFloat(
                                complexTickets[item.key]
                                  ?.adjustedCalculatedPrice
                              )?.toFixed(2)}
                            </>
                          ) : (
                            <>
                              &euro;
                              {
                                //sum of all sales prices in selected departures in complex ticket
                                selectedDepartures[
                                  item.key
                                ]?.salesPrice?.toFixed(2)
                              }
                            </>
                          )}
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item md={6}>
                      <Paper
                        elevation={0}
                        style={{
                          borderRadius: "10px",
                          padding: "5px",
                          backgroundColor: "#edf7ed",
                          textAlign: "center",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "12px",
                            // marginTop: 10,
                          }}
                          align="center"
                        >
                          Total surcharges
                        </Typography>
                        <Typography
                          style={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            // marginTop: 10,
                          }}
                          align="center"
                        >
                          &euro;
                          {
                            //sum of all surcharges in selected departures in complex ticket

                            (selectedDepartures[item.key]?.ticketSurchargeList
                              ? selectedDepartures[
                                  item.key
                                ]?.ticketSurchargeList
                                  ?.map((surcharges) => surcharges?.total)
                                  ?.reduce((a, b) => a + b, 0)
                              : 0
                            ).toFixed(2)
                          }
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item md={12}>
                      <Paper
                        elevation={0}
                        style={{
                          borderRadius: "10px",
                          padding: "5px",
                          backgroundColor: "#EEF7F8",
                          textAlign: "center",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "12px",
                            // marginTop: 10,
                          }}
                          align="center"
                        >
                          Grand total
                        </Typography>
                        <Typography
                          style={{
                            fontWeight: "bold",
                            fontSize: "14px",
                          }}
                          align="center"
                        >
                          {complexTickets[item.key]?.adjustedCalculatedPrice !=
                          null ? (
                            <>
                              &euro;
                              {(
                                +parseFloat(
                                  complexTickets[item.key]
                                    ?.adjustedCalculatedPrice
                                )?.toFixed(2) +
                                (selectedDepartures[item.key]
                                  ?.ticketSurchargeList
                                  ? +selectedDepartures[
                                      item.key
                                    ]?.ticketSurchargeList
                                      ?.map((surcharges) => surcharges?.total)
                                      ?.reduce((a, b) => a + b, 0)
                                  : +0)
                              ).toFixed(2)}
                            </>
                          ) : (
                            <>
                              &euro;
                              {(
                                +selectedDepartures[
                                  item.key
                                ]?.salesPrice?.toFixed(2) +
                                (selectedDepartures[item.key]
                                  ?.ticketSurchargeList
                                  ? +selectedDepartures[
                                      item.key
                                    ]?.ticketSurchargeList
                                      ?.map((surcharges) => surcharges?.total)
                                      ?.reduce((a, b) => a + b, 0)
                                  : +0)
                              ).toFixed(2)}
                            </>
                          )}
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                )}
              </Grid> */}

      {/* <Grid item md={12}>
        <Divider></Divider>
      </Grid> */}

      {/* <Grid item md={12}>
            <Button
              variant="contained"
              color="secondary"
              disableElevation
              fullWidth={data?.showPrice ? true : false}
              disabled={
                loading ||
                Object.keys(selectedProductDestinations)?.length !=
                  Object.keys(selectedDepartures)?.length ||
                !data.truckPlateNumber ||
                selectedWhatsAppEvents?.length == 0 ||
                selectedViberEvents?.length == 0
              }
              size="medium"
              style={{
                marginTop: "5px",
                textAlign: "right",
                float: "right",
                minWidth: data?.showPrice ? "" : "250px",
              }}
              onClick={() => {
                if (currentMail) {
                  handleCreateComplexTicketsFromMailbox();
                } else if (whatsAppConversation) {
                  handleCreateComplexTicketsFromWhatsApp();
                } else if (viberConversation) {
                  handleCreateComplexTicketsFromViber();
                } else if (noteConversation) {
                  handleCreateComplexTicketsFromNotes();
                } else if (
                  !whatsAppConversation &&
                  !viberConversation &&
                  !currentMail
                ) {
                  createComplexTickets();
                }
              }}
            >
              {loading ? <DirectionsBoatFilledTwoToneIcon /> : LOCALE.book_now}
            </Button>
          </Grid> */}
    </>
  );
}
