import Axios from "axios";
import { SETTINGS } from "../properties/Settings";

export const AttachmentRepository = {
  download: (attachmentId) => {
    return Axios({
      url: `${SETTINGS.API_URL}attachment/download/${attachmentId}`,
      method: "GET",
      responseType: "blob",
    });
  },

  downloadMessageAttachment: (attachmentId) => {
    return Axios({
      url: `${SETTINGS.API_URL}attachment/messages/download/${attachmentId}`,
      method: "GET",
      responseType: "blob",
    });
  },

  downloadAll: (ticketId) => {
    return Axios({
      url: `${SETTINGS.API_URL}attachment/downloadAll/${ticketId}`,
      method: "POST",
      responseType: "blob",
    });
  },
  downloadAllMessageAttachments: (ticketId) => {
    return Axios({
      url: `${SETTINGS.API_URL}attachment/messages/downloadAll/${ticketId}`,
      method: "POST",
      responseType: "blob",
    });
  },

  getAllCSVBookings: (
    page,
    size,
    filterParams,
    orderBy = "dateCreated",
    orderDirection = "DESC"
  ) => {
    return Axios({
      url: `${SETTINGS.API_URL}attachment/bookings/all`,
      method: "GET",
      params: {
        page: page,
        size: size,
        searchParams: filterParams,
        orderBy: orderBy,
        orderDirection: orderDirection,
      },
    });
  },

  downloadCSVBookingsFile: (fileId, initFile) => {
    return Axios({
      url: `${SETTINGS.API_URL}attachment/bookings/download/${fileId}`,
      method: "GET",
      responseType: "blob",
      params: {
        initFile: initFile,
      },
    });
  },

  uploadCSVBookingsFile: (file) => {
    const formData = new FormData();
    formData.append("csvBookingsFile", file);
    return Axios({
      url: `${SETTINGS.API_URL}attachment/bookings/new`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });
  },
};
