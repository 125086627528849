import { CssBaseline, LinearProgress } from "@mui/material";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import "./App.css";
import GlobalNotificationSnackbar from "./common/GlobalNotificationSnackbar";
import MainLayout from "./common/MainLayout";
import { AuthRepository } from "./repositories/AuthRepository";
import { MaterialDesignContent } from "notistack";

const momentjs = require("moment");

export const moment = momentjs;

function App() {
  const location = useLocation();
  const globalState = useSelector((state) => state);

  const [typographyFontSize, setTypographyFontSize] = React.useState(10);
  const [h6FontSize, setH6FontSize] = React.useState(12);
  const [h5FontSize, setH5FontSize] = React.useState(16);
  const [h4FontSize, setH4FontSize] = React.useState(20);
  const [themeMode, setThemeMode] = React.useState("light");
  const [theme, setTheme] = React.useState(
    createTheme({
      palette: {
        type: "light",
        darkContrast: {
          main: "#202020",
        },
        greyContrast: {
          main: "#7b7b7b",
        },
        dashboardBackground: {
          main: "#f9f9f9",
        },
        primary: {
          main: "#338e9b",
        },
        secondary: {
          main: "#25b0c0",
        },
        error: {
          main: "#d93535",
        },
        rejected: {
          main: "#e15d5d",
        },
        canceled: {
          main: "#e9b145",
        },
        mutedRed: {
          main: "#762626",
        },
        mutedBrown: {
          main: "#825e18",
        },
        attention: {
          main: "#e49e16",
        },
        warning: {
          main: "#f2d048",
        },
        extra4: {
          main: "#bfff36",
        },
        success: {
          main: "#6ab056",
        },
        in_progress: {
          main: "#5380b6",
        },
        done: {
          main: "#88c078",
        },
        mutedGreen: {
          main: "#537626",
        },
        extra6: {
          main: "#1bbdf1",
        },
        info: {
          main: "#49a2da",
        },
        extra1: {
          main: "#2860a4",
        },
        mutedBlue: {
          main: "#264676",
        },
        extra2: {
          main: "#20506e",
        },
        mutedViolet: {
          main: "#6b2676",
        },
        extra3: {
          main: "#99339b",
        },
        extra5: {
          main: "#ff19cc",
        },
        background: {
          default: "#fff",
          paper: "#fff",
        },
        blueGray: {
          main: "#607d8b",
        },
        lightGray: {
          main: "#8f8f8f",
        },
        tableRow: {
          main: "#f2f2f2",
        },
      },
      typography: {
        fontSize: typographyFontSize,
        fontWeightLight: 300,
        fontFamily: `"DM Sans", sans-serif`,
        h6: {
          fontWeight: 700,
          fontSize: `${h6FontSize}px`,
          lineHeight: `${h6FontSize + 1}px`,
          letterSpacing: "-3%",
        },
        h5: {
          fontWeight: 800,
          fontSize: `${h5FontSize}px`,
          lineHeight: `${h5FontSize + 1}px`,
          letterSpacing: "-3%",
        },
        h4: {
          fontWeight: 700,
          fontSize: `${h4FontSize}px`,
          lineHeight: `${h4FontSize + 1}px`,
          letterSpacing: "-3%",
        },
      },
      props: {
        MuiList: {
          dense: true,
        },
        MuiMenuItem: {
          dense: true,
        },
        MuiTable: {
          size: "small",
        },
        MuiTooltip: {
          arrow: true,
        },
      },
      components: {
        MuiTableCell: {
          styleOverrides: {
            root: {
              padding: "6px 6px",
            },
          },
        },
      },
    })
  );
  const navigate = useNavigate();
  React.useEffect(() => {
    if (
      location.pathname !== "/login" &&
      !location.pathname.startsWith("/explore") &&
      location.pathname !== "/forgotPassword" &&
      location.pathname !== "/reset-password" &&
      !location.pathname.includes("/invitation")
    ) {
      if (!AuthRepository.getToken()) {
        navigate("/login");
      }
    }
  }, [location]);

  //use effect to set the font size of the typography
  React.useEffect(() => {
    theme.typography.fontSize = typographyFontSize;
  }, [typographyFontSize]);

  React.useEffect(() => {
    theme.typography.h6.fontSize = `${h6FontSize}px`;
    theme.typography.h6.lineHeight = `${h6FontSize + 1}px`;
  }, [h6FontSize]);

  React.useEffect(() => {
    theme.typography.h5.fontSize = `${h5FontSize}px`;
    theme.typography.h5.lineHeight = `${h5FontSize + 1}px`;
  }, [h5FontSize]);

  React.useEffect(() => {
    theme.typography.h4.fontSize = `${h4FontSize}px`;
    theme.typography.h4.lineHeight = `${h4FontSize + 1}px`;
  }, [h4FontSize]);

  useEffect(() => {
    if (themeMode === "dark") {
      setTheme(
        createTheme({
          palette: {
            mode: "dark", // Use "mode" instead of "type" for MUI v5
            darkContrast: {
              main: "#f0f0f0",
            },
            greyContrast: {
              main: "#c2c2c2",
            },
            dashboardBackground: {
              main: "#1a1a1a",
            },
            primary: {
              main: "#64b5f6",
            },
            secondary: {
              main: "#4dd0e1",
            },
            error: {
              main: "#ef5350",
            },
            rejected: {
              main: "#e57373",
            },
            canceled: {
              main: "#ffb74d",
            },
            mutedRed: {
              main: "#c62828",
            },
            mutedBrown: {
              main: "#8d6e63",
            },
            attention: {
              main: "#ffca28",
            },
            warning: {
              main: "#ffd54f",
            },
            extra4: {
              main: "#76ff03",
            },
            success: {
              main: "#81c784",
            },
            in_progress: {
              main: "#64b5f6",
            },
            done: {
              main: "#66bb6a",
            },
            mutedGreen: {
              main: "#388e3c",
            },
            extra6: {
              main: "#00bcd4",
            },
            info: {
              main: "#29b6f6",
            },
            extra1: {
              main: "#1976d2",
            },
            mutedBlue: {
              main: "#1e88e5",
            },
            extra2: {
              main: "#0288d1",
            },
            mutedViolet: {
              main: "#8e24aa",
            },
            extra3: {
              main: "#d81b60",
            },
            extra5: {
              main: "#ff4081",
            },
            background: {
              default: "#121212",
              paper: "#1e1e1e",
            },
            blueGray: {
              main: "#78909c",
            },
            lightGray: {
              main: "#bdbdbd",
            },
            tableRow: {
              main: "#2e2e2e",
            },
          },
          typography: {
            fontSize: typographyFontSize,
            fontWeightLight: 300,
            fontFamily: `"DM Sans", sans-serif`,
            h6: {
              fontWeight: 700,
              fontSize: `${h6FontSize}px`,
              lineHeight: `${h6FontSize + 1}px`,
              letterSpacing: "-3%",
            },
            h5: {
              fontWeight: 800,
              fontSize: `${h5FontSize}px`,
              lineHeight: `${h5FontSize + 1}px`,
              letterSpacing: "-3%",
            },
            h4: {
              fontWeight: 700,
              fontSize: `${h4FontSize}px`,
              lineHeight: `${h4FontSize + 1}px`,
              letterSpacing: "-3%",
            },
          },
          props: {
            MuiList: {
              dense: true,
            },
            MuiMenuItem: {
              dense: true,
            },
            MuiTable: {
              size: "small",
            },
            MuiTooltip: {
              arrow: true,
            },
          },
          components: {
            MuiTableCell: {
              styleOverrides: {
                root: {
                  padding: "6px 6px",
                },
              },
            },
          },
        })
      );
    } else {
      setTheme(
        createTheme({
          palette: {
            type: "light",
            darkContrast: {
              main: "#202020",
            },
            greyContrast: {
              main: "#7b7b7b",
            },
            dashboardBackground: {
              main: "#f9f9f9",
            },
            primary: {
              main: "#338e9b",
            },
            secondary: {
              main: "#25b0c0",
            },
            error: {
              main: "#d93535",
            },
            rejected: {
              main: "#e15d5d",
            },
            canceled: {
              main: "#e9b145",
            },
            mutedRed: {
              main: "#762626",
            },
            mutedBrown: {
              main: "#825e18",
            },
            attention: {
              main: "#e49e16",
            },
            warning: {
              main: "#f2d048",
            },
            extra4: {
              main: "#bfff36",
            },
            success: {
              main: "#6ab056",
            },
            in_progress: {
              main: "#5380b6",
            },
            done: {
              main: "#88c078",
            },
            mutedGreen: {
              main: "#537626",
            },
            extra6: {
              main: "#1bbdf1",
            },
            info: {
              main: "#49a2da",
            },
            extra1: {
              main: "#2860a4",
            },
            mutedBlue: {
              main: "#264676",
            },
            extra2: {
              main: "#20506e",
            },
            mutedViolet: {
              main: "#6b2676",
            },
            extra3: {
              main: "#99339b",
            },
            extra5: {
              main: "#ff19cc",
            },
            background: {
              default: "#fff",
              paper: "#fff",
            },
            blueGray: {
              main: "#607d8b",
            },
            lightGray: {
              main: "#8f8f8f",
            },
            tableRow: {
              main: "#f2f2f2",
            },
          },
          typography: {
            fontSize: typographyFontSize,
            fontWeightLight: 300,
            fontFamily: `"DM Sans", sans-serif`,
            h6: {
              fontWeight: 700,
              fontSize: `${h6FontSize}px`,
              lineHeight: `${h6FontSize + 1}px`,
              letterSpacing: "-3%",
            },
            h5: {
              fontWeight: 800,
              fontSize: `${h5FontSize}px`,
              lineHeight: `${h5FontSize + 1}px`,
              letterSpacing: "-3%",
            },
            h4: {
              fontWeight: 700,
              fontSize: `${h4FontSize}px`,
              lineHeight: `${h4FontSize + 1}px`,
              letterSpacing: "-3%",
            },
          },
          props: {
            MuiList: {
              dense: true,
            },
            MuiMenuItem: {
              dense: true,
            },
            MuiTable: {
              size: "small",
            },
            MuiTooltip: {
              arrow: true,
            },
          },
          components: {
            MuiTableCell: {
              styleOverrides: {
                root: {
                  padding: "6px 6px",
                },
              },
            },
          },
        })
      );
    }
  }, [themeMode]);

  const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    "&.notistack-MuiContent-APPROVED": {
      backgroundColor: theme.palette.secondary.main,
    },
    "&.notistack-MuiContent-PENDING": {
      backgroundColor: theme.palette.extra3.main,
    },
    "&.notistack-MuiContent-IN_PROGRESS": {
      backgroundColor: theme.palette.in_progress.main,
    },
    "&.notistack-MuiContent-REJECTED": {
      backgroundColor: theme.palette.rejected.main,
    },
    "&.notistack-MuiContent-CANCELLED": {
      backgroundColor: theme.palette.canceled.main,
    },
    "&.notistack-MuiContent-DONE": {
      backgroundColor: theme.palette.done.main,
    },
    "&.notistack-MuiContent-NO_SHOW": {
      backgroundColor: theme.palette.mutedRed.main,
    },
  }));

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider
        autoHideDuration={10000}
        dense
        classes={{
          variantInfo: "custom-info",
        }}
        Components={{
          Content: StyledMaterialDesignContent,
          APPROVED: StyledMaterialDesignContent,
          PENDING: StyledMaterialDesignContent,
          IN_PROGRESS: StyledMaterialDesignContent,
          REJECTED: StyledMaterialDesignContent,
          CANCELLED: StyledMaterialDesignContent,
          DONE: StyledMaterialDesignContent,
          NO_SHOW: StyledMaterialDesignContent,
        }}
      >
        <div
          className="App"
          style={{
            backgroundColor: theme.palette.dashboardBackground.main,
            height: "100vh",
          }}
        >
          <div
            style={{
              position: "fixed",
              width: "100%",
              marginTop: window.location.pathname.startsWith("/explore")
                ? "0px"
                : "50px",
            }}
          >
            {globalState.loading && <LinearProgress color="secondary" />}
          </div>
          <GlobalNotificationSnackbar />
          <MainLayout
            setTypographyFontSize={setTypographyFontSize}
            typographyFontSize={typographyFontSize}
            setH6FontSize={setH6FontSize}
            h6FontSize={h6FontSize}
            setH5FontSize={setH5FontSize}
            h5FontSize={h5FontSize}
            setH4FontSize={setH4FontSize}
            h4FontSize={h4FontSize}
            themeMode={themeMode}
            setThemeMode={setThemeMode}
          ></MainLayout>
        </div>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
