import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import AgricultureOutlinedIcon from "@mui/icons-material/AgricultureOutlined";
import AirlineStopsIcon from "@mui/icons-material/AirlineStops";
import AirportShuttleOutlinedIcon from "@mui/icons-material/AirportShuttleOutlined";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import DirectionsCarFilledOutlinedIcon from "@mui/icons-material/DirectionsCarFilledOutlined";
import ForestOutlinedIcon from "@mui/icons-material/ForestOutlined";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import TrainOutlinedIcon from "@mui/icons-material/TrainOutlined";
import {
  alpha,
  Autocomplete,
  Box,
  Divider,
  FormControl,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond/dist/filepond.min.css";
import React, { useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { registerPlugin } from "react-filepond";
import { moment } from "../App";
import { LOCALE } from "../properties/Locale";
import { DestinationsRepository } from "../repositories/DestinationsRepository";
import { SettingsRepository } from "../repositories/SettingsRepository";

registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginFileValidateSize);

const filterOptions = createFilterOptions({
  matchFrom: "any",
  stringify: (option) =>
    option?.name +
    option?.country?.nameEn +
    option?.ports?.map((port) => port?.name),
});

export default function AgentOneFormTicketDestinationDetails({
  handleChangeData,
  data,
  setData,
  fromCities,
  toCities,
  setToCities,
  vehicleCategories,
  formDataErrors,
  fromCitiesSearch,
  setFromCitiesSearch,
  toCitiesSearch,
  setToCitiesSearch,
  handleChangeSearchParams,
  handleChangeSwitch,
  checked,
  materials,
  swapFromCityToCity,
  setReturnComplexDestinationChecked,
  returnComplexDestinationChecked,
  showComplexDepartures,
  setShowComplexDepartures,
  allComplexDepartures,
  setSelectedDeparture,
  selectedDeparture,
  selectedDepartures,
  setAdditionalDocuments,
  showDeparturesForNextYear,
}) {
  const [
    renderReturnComplexDestinationCheckbox,
    setRenderReturnComplexDestinationCheckbox,
  ] = React.useState(false);

  const [
    renderComplexDestinationCheckbox,
    setRenderComplexDestinationCheckbox,
  ] = React.useState(false);

  const [selectedValue, setSelectedValue] = React.useState("2");
  const [selectedCargoType, setSelectedCargoType] = useState(
    data?.material ?? ""
  );
  const [selectedReturn, setSelectedReturn] = useState("one");
  const [countDrivers, setCountDrivers] = useState(1);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("md"));
  const [toggle, setToggle] = useState(data?.electricPlugin === true);
  const [toggleDangerousGoods, setDangerousGoods] = useState(
    data?.dangerousGoods === true
  );
  const [toggleLiveAnimals, setLiveAnimals] = useState(
    data?.liveAnimals === true
  );
  const [isReturnButtonEnabled, setIsReturnButtonEnabled] = useState(false);
  const [isComplexButtonEnabled, setIsComplexButtonEnabled] = useState(false);

  const IncrementDrivers = () => {
    if (countDrivers < 4) {
      setCountDrivers((prevState) => {
        const newCount = prevState + 1;
        handleChangeData("numberOfDrivers", newCount);
        return newCount;
      });
    }
  };

  const DecrementDrivers = () => {
    if (countDrivers > 0) {
      setCountDrivers((prevState) => {
        const newCount = prevState - 1;
        handleChangeData("numberOfDrivers", newCount);
        return newCount;
      });
    }
  };
  useEffect(() => {
    setRenderReturnComplexDestinationCheckbox(false);
    setRenderComplexDestinationCheckbox(false);
    setShowComplexDepartures(false);
    if (!allComplexDepartures) {
      setRenderReturnComplexDestinationCheckbox(false);
    } else {
      Object.keys(allComplexDepartures).map((key) => {
        if (key.endsWith("CMPX-RT")) {
          setRenderReturnComplexDestinationCheckbox(true);
          setRenderComplexDestinationCheckbox(true);
        }
        if (key.endsWith("CMPX")) {
          setRenderComplexDestinationCheckbox(true);
        }
        //handle complex default and return default
        Object.keys(allComplexDepartures[key]).find((key2) => {
          if (
            allComplexDepartures[key][key2]?.destination?.fromCity?.id ===
              data?.fromCity?.id &&
            allComplexDepartures[key][key2]?.destination?.toCity?.id ===
              data?.toCity?.id
          ) {
            if (
              allComplexDepartures[key][key2]?.destination?.complexDefault !==
                null &&
              allComplexDepartures[key][key2]?.destination?.complexDefault ===
                true
            ) {
              setShowComplexDepartures(true);
            }
            if (
              allComplexDepartures[key][key2]?.destination?.returnDefault !==
                null &&
              allComplexDepartures[key][key2]?.destination?.returnDefault ===
                true
            ) {
              setReturnComplexDestinationChecked(true);
            }
          }
        });
      });
    }
  }, [allComplexDepartures]);

  useEffect(() => {
    if (data.fromCity) {
      getAllAvailableCityDestination({
        "fromCity.id": data.fromCity.id,
      });
    }
  }, [data.fromCity]);

  const getAllAvailableCityDestination = (params) => {
    DestinationsRepository.getAllAvailableCityDestination({
      searchParams: params,
    }).then((response) => {
      setToCities(response.data.sort((a, b) => a.name.localeCompare(b.name)));
    });
  };
  const handleToggleChange = (event, newValue) => {
    setSelectedValue(newValue);
    handleChangeData("loaded", newValue);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedCargoType(value);
    handleChangeData("material", value);
  };

  const handleToggle = () => {
    setToggle(!toggle);
    handleChangeData("electricPlugin", !toggle);
  };

  const handleToggleDangerousGoods = () => {
    setDangerousGoods(!toggleDangerousGoods);
    handleChangeData("dangerousGoods", !toggleDangerousGoods);
  };

  const handleToggleLiveAnimals = () => {
    setLiveAnimals(!toggleLiveAnimals);
    handleChangeData("liveAnimals", !toggleLiveAnimals);
  };

  useEffect(() => {
    hasKeyEndingWithCmpxRt();
  }, [allComplexDepartures]);

  const hasKeyEndingWithCmpxRt = () => {
    if (!allComplexDepartures) {
      setIsReturnButtonEnabled(false);
      return;
    }
    const keyEndsWithCmpxRt = Object.keys(allComplexDepartures).some((key) =>
      key.endsWith("CMPX-RT")
    );
    setIsReturnButtonEnabled(keyEndsWithCmpxRt);
  };

  useEffect(() => {
    hasKeyEndingWithCmpx();
  }, [allComplexDepartures]);

  const hasKeyEndingWithCmpx = () => {
    if (!allComplexDepartures) {
      setIsComplexButtonEnabled(false);
      return;
    }
    const keyEndsWithCmpx = Object.keys(allComplexDepartures).some((key) =>
      key.endsWith("CMPX")
    );
    setIsComplexButtonEnabled(keyEndsWithCmpx);
  };

  useEffect(() => {
    if (Array.isArray(vehicleCategories) && vehicleCategories.length > 0) {
      const defaultCategory =
        vehicleCategories.find((category) => category.defaultCategory) ||
        vehicleCategories[0];
      const tmp = { ...data };
      tmp["vehicleCategory"] = defaultCategory;
      tmp["vehicleLength"] = defaultCategory.defaultLength || "";
      setData(tmp);
    }
  }, [vehicleCategories]);

  const handleChangeVehicle = (event) => {
    console.log(event.target.value);
    const tmp = { ...data };
    tmp["vehicleCategory"] = event.target.value;
    tmp["vehicleLength"] = event.target.value?.defaultLength
      ? event.target.value?.defaultLength
      : data?.vehicleLength;
    setData(tmp);
  };

  const handleLengthChange = (event) => {
    const newLength = event.target.value;
    const tmp = { ...data };
    tmp["vehicleLength"] = newLength;

    const filteredCategory = vehicleCategories
      .filter(
        (category) =>
          (category.minLength == category.maxLength &&
            category.minLength == newLength) ||
          (category.minLength <= newLength && category.maxLength >= newLength)
      )
      .reverse()[0];

    tmp["vehicleCategory"] = filteredCategory
      ? filteredCategory
      : tmp.vehicleCategory;
    setData(tmp);
  };

  useEffect(() => {
    if (!isComplexButtonEnabled && !isReturnButtonEnabled) {
      setSelectedReturn("one");
      setShowComplexDepartures(false);
      setReturnComplexDestinationChecked(false);
    } else if (isComplexButtonEnabled) {
      setSelectedReturn("cmpx");
      setShowComplexDepartures(true);
    } else if (isReturnButtonEnabled) {
      setSelectedReturn("return");
      setReturnComplexDestinationChecked(true);
      setShowComplexDepartures(true);
    }
  }, [isComplexButtonEnabled, isReturnButtonEnabled]);

  // useEffect(() => {
  //   setCountDrivers(1);
  //   setLiveAnimals(false);
  //   setDangerousGoods(false);
  //   setToggle(false);
  //   setSelectedValue("2");
  // }, [data?.toCity, data?.fromCity]);

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.primary.main,
          opacity: 1,
          border: 0,
          ...theme.applyStyles("dark", {
            backgroundColor: theme.palette.primary.main,
          }),
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: theme.palette.grey[100],
        ...theme.applyStyles("dark", {
          color: theme.palette.grey[600],
        }),
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.7,
        ...theme.applyStyles("dark", {
          opacity: 0.3,
        }),
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: "#E9E9EA",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
      ...theme.applyStyles("dark", {
        backgroundColor: "#39393D",
      }),
    },
  }));

  const handleOnProcessFile = (error, file) => {
    if (error) {
      console.log(error);
    }
  };

  const handleRemoveFile = (file) => {
    setAdditionalDocuments((prevDocs) =>
      prevDocs.filter((doc) => doc.file !== file.file)
    );
  };

  return (
    <>
      <Grid item xs={6} md={6}>
        <Typography
          style={{
            ...theme.typography.fontWeight,
            margin: "3px",
            opacity: 0.6,
          }}
        >
          From City{" "}
        </Typography>
        <Autocomplete
          autoFocus={true}
          noOptionsText={LOCALE.noOptionsText}
          autoComplete={false}
          size="small"
          value={data?.fromCity ? data?.fromCity : ""}
          onChange={(event, newValue) => {
            handleChangeData("fromCity", newValue);
            handleChangeSearchParams("fromCity", newValue?.name);
          }}
          inputValue={fromCitiesSearch ? fromCitiesSearch : ""}
          onInputChange={(event, newInputValue) => {
            setFromCitiesSearch(newInputValue);
          }}
          options={fromCities}
          filterOptions={filterOptions}
          getOptionLabel={(option) => (option.name ? option.name : "")}
          renderOption={(props, city) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <ReactCountryFlag
                countryCode={city.country.code}
                svg
                style={{
                  width: "1.6em",
                  borderRadius: "8px",

                  height: "1.6em",
                }}
                title={city.country.name}
              />
              <b>
                {city.name} / {city.country.nameEn}
              </b>
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              autoFocus={true}
              {...params}
              label={
                data?.fromCity ? "" : fromCitiesSearch ? "" : LOCALE.from_city
              }
              fullWidth
              variant="outlined"
              error={formDataErrors[`fromCity.id`] !== undefined}
              helperText={formDataErrors[`fromCity.id`]}
              InputLabelProps={{
                shrink: false,
              }}
              InputProps={{
                ...params.InputProps,
                startAdornment: data.fromCity ? (
                  <ReactCountryFlag
                    countryCode={data.fromCity.country.code}
                    svg
                    style={{
                      width: "1.6em",
                      height: "1.6em",
                      borderRadius: "8px",
                      marginRight: "4px",
                      marginLeft: "10px",
                    }}
                    title={data.fromCity.country.name}
                  />
                ) : null,
                endAdornment: params.InputProps.endAdornment,
                style: {
                  fontWeight: "bold",
                },
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={6} md={6}>
        <Typography
          style={{
            ...theme.typography.fontWeight,
            margin: "3px",
            opacity: 0.6,
          }}
        >
          To{" "}
        </Typography>
        <Autocomplete
          noOptionsText={LOCALE.noOptionsText}
          autoComplete={false}
          size="small"
          value={data?.toCity ? data?.toCity : ""}
          onChange={(event, newValue) => {
            handleChangeData("toCity", newValue);
          }}
          inputValue={toCitiesSearch ? toCitiesSearch : ""}
          onInputChange={(event, newInputValue) => {
            setToCitiesSearch(newInputValue);
          }}
          options={toCities}
          filterOptions={filterOptions}
          getOptionLabel={(option) => (option.name ? option.name : "")}
          renderOption={(props, city) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <ReactCountryFlag
                countryCode={city.country.code}
                svg
                style={{
                  width: "1.6em",
                  borderRadius: "8px",

                  height: "1.6em",
                }}
                title={city.country.name}
              />
              <b>
                {city.name} / {city.country.nameEn}{" "}
              </b>
              {city.ports && city.ports?.length > 0 && (
                <span>
                  {" "}
                  / ({city.ports.map((port) => port.name).join("), (")})
                </span>
              )}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={
                data?.toCity ? "" : toCitiesSearch ? "" : LOCALE.destination
              }
              fullWidth
              variant="outlined"
              error={formDataErrors[`toCity.id`] !== undefined}
              helperText={formDataErrors[`toCity.id`]}
              InputLabelProps={{
                shrink: false,
              }}
              InputProps={{
                ...params.InputProps,
                startAdornment: data.toCity ? (
                  <ReactCountryFlag
                    countryCode={data.toCity.country.code}
                    svg
                    style={{
                      width: "1.6em",
                      height: "1.6em",
                      borderRadius: "8px",

                      marginRight: "4px",
                      marginLeft: "10px",
                    }}
                    title={data.toCity.country.name}
                  />
                ) : null,
                endAdornment: params.InputProps.endAdornment,
                style: {
                  fontWeight: "bold",
                },
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <Typography
          style={{
            ...theme.typography.fontWeight,
            margin: "3px",
            opacity: 0.6,
          }}
        >
          Route Type{" "}
        </Typography>
        <ToggleButtonGroup
          exclusive
          fullWidth
          sx={{
            border: "solid 1px ",
            borderColor: alpha(theme.palette.lightGray.main, 0.6),
          }}
        >
          <ToggleButton
            value="one"
            aria-label="left aligned"
            style={{
              width: "50%",
              height: "37px",
              border: "none",
              borderRadius: "4px",
              backgroundColor:
                selectedReturn === "one" ? theme.palette.primary.main : "",
              color:
                selectedReturn === "one"
                  ? theme.palette.background.default
                  : theme.palette.darkContrast.main,

              fontWeight: "bold",
            }}
            onClick={() => {
              setSelectedReturn("one");
              setReturnComplexDestinationChecked(false);
              setShowComplexDepartures(false);
            }}
          >
            <ArrowRightAltIcon /> One Way
          </ToggleButton>

          {isComplexButtonEnabled ? (
            <ToggleButton
              value="cmpx"
              aria-label="Cmpx"
              style={{
                width: "50.5%",
                height: "37px",
                border: "none",
                borderRadius: "4px",

                backgroundColor: showComplexDepartures
                  ? theme.palette.primary.main
                  : "",
                color: showComplexDepartures
                  ? theme.palette.background.default
                  : theme.palette.darkContrast.main,
                fontWeight: "bold",
              }}
              onClick={() => {
                const newCheckedState = !showComplexDepartures;
                setSelectedReturn("cmpx");
                setShowComplexDepartures(newCheckedState);
                if (newCheckedState) {
                  setSelectedDeparture(null);
                }
              }}
            >
              <AirlineStopsIcon /> Cmpx
            </ToggleButton>
          ) : (
            <ToggleButton
              value="return"
              aria-label="centered"
              checked={returnComplexDestinationChecked}
              style={{
                width: "50.5%",
                height: "37px",
                border: "none",
                borderRadius: "4px",

                backgroundColor: !isReturnButtonEnabled
                  ? theme.palette.background.default
                  : selectedReturn === "return"
                  ? theme.palette.primary.main
                  : "",
                color: !isReturnButtonEnabled
                  ? "gray"
                  : selectedReturn === "return"
                  ? theme.palette.background.default
                  : theme.palette.darkContrast.main,
                opacity: !isReturnButtonEnabled ? 0.6 : 1,
                fontWeight: "bold",
                cursor: isReturnButtonEnabled ? "pointer" : "not-allowed",
              }}
              onClick={() => {
                if (isReturnButtonEnabled) {
                  setSelectedReturn("return");
                  setReturnComplexDestinationChecked(true);
                  setShowComplexDepartures(true);
                }
              }}
              disabled={!isReturnButtonEnabled}
            >
              <SyncAltIcon /> Return
            </ToggleButton>
          )}
        </ToggleButtonGroup>
      </Grid>
      {/* {(renderComplexDestinationCheckbox ||
        renderReturnComplexDestinationCheckbox) && (
        <Grid item xs={12} md={2}>
          <Box style={{ display: "inline-flex" }}>
            {renderComplexDestinationCheckbox && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showComplexDepartures}
                    onChange={(event) => {
                      setShowComplexDepartures(event.target.checked);
                      if (event.target.checked) {
                        setSelectedDeparture(null);
                      }
                    }}
                  />
                }
                label={"Cmpx"}
              />
            )}
            {renderReturnComplexDestinationCheckbox && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={returnComplexDestinationChecked}
                    onChange={(event) => {
                      setReturnComplexDestinationChecked(event.target.checked);
                    }}
                  />
                }
                label={"Return"}
              />
            )}
          </Box>
        </Grid>
      )} */}
      <Grid item xs={12} md={6}>
        <Typography
          style={{
            ...theme.typography.fontWeight,
            padding: "3px",
            opacity: 0.6,
          }}
        >
          Departure Date
        </Typography>
        <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              format="DD.MM.YYYY"
              value={data.departureDay ? data.departureDay : null}
              onChange={(newValue) => {
                handleChangeData("departureDay", moment(newValue));
                handleChangeSearchParams("date", moment(newValue));
              }}
              slots={{ openPickerIcon: CalendarMonthOutlinedIcon }}
              orientation="landscape"
              sx={{
                "& .MuiInputBase-input": {
                  fontWeight: "bold",
                },
              }}
              size="small"
              slotProps={{ textField: { size: "small" } }}
              shouldDisableDate={(date) => {
                const currentYearEnd = moment().endOf("year");
                return (
                  !showDeparturesForNextYear &&
                  moment(date).isAfter(currentYearEnd, "day")
                );
              }}
            />
          </LocalizationProvider>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography
          style={{
            ...theme.typography.fontWeight,
            margin: "3px",
            opacity: 0.6,
          }}
        >
          Customer Reference Number
        </Typography>
        <TextField
          label={
            data?.customerReferenceNumber ? "" : "Customer Reference Number"
          }
          value={
            data?.customerReferenceNumber ? data?.customerReferenceNumber : ""
          }
          onChange={(e) => {
            handleChangeData("customerReferenceNumber", e.target.value);
          }}
          fullWidth={true}
          size="small"
          variant="outlined"
          error={formDataErrors[`customerReferenceNumber`] !== undefined}
          helperText={formDataErrors[`customerReferenceNumber`]}
          InputLabelProps={{
            shrink: false,
          }}
          inputProps={{ maxLength: 20, style: { fontWeight: "bold" } }}
        />
      </Grid>

      <Grid item xs={12}>
        <Divider
          style={{
            // marginTop: "2px",
            // marginBottom: "2px",
            borderBottomWidth: "2px",
          }}
        />
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12} md={6} style={{ paddingLeft: "16px" }}>
          <Typography
            style={{
              ...theme.typography.fontWeight,
              margin: "3px",
              opacity: 0.6,
            }}
          >
            Vehicle category{" "}
          </Typography>
          <FormControl
            fullWidth
            size="small"
            sx={{
              position: "relative",
              "& .MuiOutlinedInput-root": {
                borderRadius: isMediumScreen
                  ? "4px 0 0 4px"
                  : "4px 4px 4px 4px",
              },
              "& .MuiInputLabel-root": {
                transform: "none",
                opacity: data?.vehicleCategory === "" ? 1 : 0,
              },
            }}
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={data?.vehicleCategory ? data?.vehicleCategory : ""}
              onChange={handleChangeVehicle}
            >
              {vehicleCategories &&
                vehicleCategories?.map((category, index) => (
                  <MenuItem value={category} key={index}>
                    {category.name.startsWith("Van") ? (
                      <Icon
                        style={{
                          color: theme.palette.darkContrast.main,
                          verticalAlign: "middle",
                        }}
                      >
                        <AirportShuttleOutlinedIcon />
                      </Icon>
                    ) : category.name.startsWith("Solo tractor") ? (
                      <Icon
                        style={{
                          color: theme.palette.darkContrast.main,
                          verticalAlign: "middle",
                        }}
                      >
                        <AgricultureOutlinedIcon />
                      </Icon>
                    ) : category.name.startsWith("Lorry") ? (
                      <Icon
                        style={{
                          color: theme.palette.darkContrast.main,
                          verticalAlign: "middle",
                        }}
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_341_1673)">
                            <path
                              d="M20 8H17V4H3C1.9 4 1 4.9 1 6V17H3C3 18.66 4.34 20 6 20C7.66 20 9 18.66 9 17H15C15 18.66 16.34 20 18 20C19.66 20 21 18.66 21 17H23V12L20 8ZM19.5 9.5L21.46 12H17V9.5H19.5ZM6 18C5.45 18 5 17.55 5 17C5 16.45 5.45 16 6 16C6.55 16 7 16.45 7 17C7 17.55 6.55 18 6 18ZM8.22 15C7.67 14.39 6.89 14 6 14C5.11 14 4.33 14.39 3.78 15H3V6H15V15H8.22ZM18 18C17.45 18 17 17.55 17 17C17 16.45 17.45 16 18 16C18.55 16 19 16.45 19 17C19 17.55 18.55 18 18 18Z"
                              fill="#202020"
                              style={{
                                fill: "#202020",
                                fill: theme.palette.darkContrast.main,
                                fillOpacity: 1,
                              }}
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_341_1673">
                              <rect
                                width="24"
                                height="24"
                                fill="white"
                                style={{ fill: "white", fillOpacity: 1 }}
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </Icon>
                    ) : category.name.startsWith("Artic") ? (
                      <Icon
                        style={{
                          color: theme.palette.darkContrast.main,
                          verticalAlign: "middle",
                        }}
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_341_1665)">
                            <path
                              d="M20 17V11C20 9.9 19.1 9 18 9H7V7L4 10L7 13V11H11V14H4V17C4 18.1 4.9 19 6 19H8C8 20.66 9.34 22 11 22C12.66 22 14 20.66 14 19H22V17H20ZM11 20C10.45 20 10 19.55 10 19C10 18.45 10.45 18 11 18C11.55 18 12 18.45 12 19C12 19.55 11.55 20 11 20ZM18 14H11V11H18V14ZM17 2V4H9V6H17V8L20 5L17 2Z"
                              fill="#202020"
                              style={{
                                fill: "#202020",
                                fill: theme.palette.darkContrast.main,
                                fillOpacity: 1,
                              }}
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_341_1665">
                              <rect
                                width="24"
                                height="24"
                                fill="white"
                                style={{ fill: "white", fillOpacity: 1 }}
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </Icon>
                    ) : category.name.startsWith("Road train") ? (
                      <Icon
                        style={{
                          color: theme.palette.darkContrast.main,
                          verticalAlign: "middle",
                        }}
                      >
                        <DirectionsCarFilledOutlinedIcon />
                      </Icon>
                    ) : category.name.startsWith("Train") ? (
                      <Icon
                        style={{
                          color: theme.palette.darkContrast.main,
                          verticalAlign: "middle",
                        }}
                      >
                        <TrainOutlinedIcon />
                      </Icon>
                    ) : (
                      ""
                    )}
                    <span
                      style={{
                        marginLeft: "5px",
                        verticalAlign: "middle",
                        fontWeight: "bold",
                      }}
                    >
                      {category?.productId ? (
                        <>
                          <span style={{ fontWeight: "bold" }}>
                            {category?.name}
                          </span>
                          {"   "}
                        </>
                      ) : (
                        <span style={{ fontWeight: "bold" }}>
                          {category?.name}
                        </span>
                      )}
                    </span>
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={isSmallScreen ? 3 : 12}
          md={2}
          style={{ marginLeft: isSmallScreen && "16px" }}
        >
          <Typography
            style={{
              ...theme.typography.fontWeight,
              margin: "3px",
              opacity: 0.6,
            }}
          >
            Length{" "}
          </Typography>
          <TextField
            value={data?.vehicleLength ? data?.vehicleLength : ""}
            onChange={handleLengthChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start" style={{ opacity: 0.7 }}>
                  <b>m</b>
                </InputAdornment>
              ),
              inputProps: {
                min: 0.0,
                max: 30.0,
              },
            }}
            size="small"
            fullWidth
            type="number"
            variant="outlined"
            error={formDataErrors[`vehicleLength`] !== undefined}
            helperText={formDataErrors[`vehicleLength`]}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: isMediumScreen ? 0 : "4px 4px 4px 4px",
                "& fieldset": {
                  borderLeft: isMediumScreen && "none",
                },
                "& input": {
                  fontWeight: "bold",
                  textAlign: "center",
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={isSmallScreen ? 3 : 12} md={2} ml={isSmallScreen && 3}>
          <Typography
            style={{
              ...theme.typography.fontWeight,
              margin: "3px",
              opacity: 0.6,
            }}
          >
            Width{" "}
          </Typography>
          <TextField
            value={data?.vehicleWidth ? data?.vehicleWidth : ""}
            onChange={(e) => {
              handleChangeData("vehicleWidth", e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start" style={{ opacity: 0.7 }}>
                  <b>m</b>
                </InputAdornment>
              ),
              inputProps: {
                min: 0.0,
                max: 30.0,
              },
            }}
            size="small"
            fullWidth
            type="number"
            variant="outlined"
            error={formDataErrors[`vehicleWidth`] !== undefined}
            helperText={formDataErrors[`vehicleWidth`]}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: isMediumScreen ? 0 : "4px 4px 4px 4px",
                "& fieldset": {
                  borderLeft: isMediumScreen && "none",
                },
                "& input": {
                  fontWeight: "bold",
                  textAlign: "center",
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={isSmallScreen ? 3 : 12} md={2} ml={isSmallScreen && 2}>
          <Typography
            style={{
              ...theme.typography.fontWeight,
              margin: "3px",
              opacity: 0.6,
            }}
          >
            Height{" "}
          </Typography>
          <TextField
            value={data?.vehicleHeight ? data?.vehicleHeight : ""}
            onChange={(e) => {
              handleChangeData("vehicleHeight", e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start" style={{ opacity: 0.7 }}>
                  <b>m</b>
                </InputAdornment>
              ),
              inputProps: {
                min: 0.0,
                max: 30.0,
              },
            }}
            size="small"
            fullWidth
            type="number"
            variant="outlined"
            error={formDataErrors[`vehicleHeight`] !== undefined}
            helperText={formDataErrors[`vehicleHeight`]}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: isMediumScreen
                  ? "0 4px 4px 0"
                  : "4px 4px 4px 4px",
                "& fieldset": {
                  borderLeft: isMediumScreen && "none",
                },
                "& input": {
                  fontWeight: "bold",
                  textAlign: "center",
                },
              },
            }}
          />
        </Grid>
      </Grid>
      {/* <Grid item xs={12} md={2}>
        <FormControlLabel
          control={
            <Checkbox
              checked={checkAbnormalVehicleSize}
              onChange={(event) => {
                setCheckAbnormalVehicleSize(event.target.checked);
              }}
            />
          }
          label={"Abnormal Vehicle Size"}
        />
      </Grid> */}
      {/* {checkAbnormalVehicleSize ? (
        <>
          <Grid item xs={12} md={2}>
            <TextField
              label={"Vehicle width"}
              value={data?.vehicleWidth ? data?.vehicleWidth : ""}
              onChange={(e) => {
                handleChangeData("vehicleWidth", e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">m</InputAdornment>
                ),
                inputProps: {
                  min: 0.0,
                  max: 30.0,
                },
              }}
              size="small"
              type="number"
              fullWidth
              variant="outlined"
              error={formDataErrors[`vehicleWidth`] !== undefined}
              helperText={formDataErrors[`vehicleWidth`]}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              label={"Vehicle height"}
              value={data?.vehicleHeight ? data?.vehicleHeight : ""}
              onChange={(e) => {
                handleChangeData("vehicleHeight", e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">m</InputAdornment>
                ),
                inputProps: {
                  min: 0.0,
                  max: 30.0,
                },
              }}
              size="small"
              type="number"
              fullWidth
              variant="outlined"
              error={formDataErrors[`vehicleHeight`] !== undefined}
              helperText={formDataErrors[`vehicleHeight`]}
            />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12} md={2}></Grid>
          <Grid item xs={12} md={2}></Grid>
        </>
      )} */}
      <Grid item xs={12} md={6}>
        <Typography
          style={{
            ...theme.typography.fontWeight,
            margin: "3px",
            opacity: 0.6,
          }}
        >
          Truck Plate Number{" "}
        </Typography>
        <TextField
          label={data?.truckPlateNumber ? "" : LOCALE.truck_plate_number}
          name="truckPlateNumber"
          fullWidth={true}
          size="small"
          variant="outlined"
          value={data?.truckPlateNumber ? data?.truckPlateNumber : ""}
          onChange={(e) =>
            handleChangeData(
              "truckPlateNumber",
              e.target.value
                .trim()
                .replace(/[^a-z0-9]/gi, "")
                .toUpperCase()
            )
          }
          InputLabelProps={{
            shrink: false,
          }}
          inputProps={{ maxLength: 20, style: { fontWeight: "bold" } }}
          error={formDataErrors[`truckPlateNumber`] !== undefined}
          helperText={formDataErrors[`truckPlateNumber`]}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography
          style={{
            ...theme.typography.fontWeight,
            margin: "3px",
            opacity: 0.6,
          }}
        >
          Trailer Plate Number{" "}
        </Typography>
        <TextField
          label={data?.trailerPlateNumber ? "" : LOCALE.trailer_plate_number}
          name="trailerPlateNumber"
          fullWidth={true}
          size="small"
          variant="outlined"
          value={data?.trailerPlateNumber ? data?.trailerPlateNumber : ""}
          onChange={(e) =>
            handleChangeData(
              "trailerPlateNumber",
              e.target.value
                .trim()
                .replace(/[^a-z0-9]/gi, "")
                .toUpperCase()
            )
          }
          InputLabelProps={{
            shrink: false,
          }}
          inputProps={{ maxLength: 20, style: { fontWeight: "bold" } }}
          error={formDataErrors[`trailerPlateNumber`] !== undefined}
          helperText={formDataErrors[`trailerPlateNumber`]}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider
          style={{
            // marginTop: "2px",
            // marginBottom: "2px",
            borderBottomWidth: "2px",
          }}
        />
      </Grid>
      {isSmallScreen && (
        <Grid item xs={12} md={9}>
          <Typography
            style={{
              ...theme.typography.fontWeight,
              margin: "3px",
              opacity: 0.6,
            }}
          >
            Driver Full Name{" "}
          </Typography>
          <TextField
            label={data?.driverName ? "" : LOCALE.driver_name}
            name="driverName"
            fullWidth={true}
            size="small"
            variant="outlined"
            value={data?.driverName ? data?.driverName : ""}
            inputProps={{ maxLength: 80, style: { fontWeight: "bold" } }}
            InputLabelProps={{ shrink: false }}
            onChange={(e) => handleChangeData("driverName", e.target.value)}
          />
        </Grid>
      )}

      <Grid item xs={12} md={3}>
        <Typography
          style={{
            ...theme.typography.fontWeight,
            margin: "3px",
            opacity: 0.6,
          }}
        >
          Number of Drivers{" "}
        </Typography>
        <Grid container alignItems="center">
          <Grid item>
            <IconButton
              onClick={IncrementDrivers}
              style={{
                padding: "0",
                border: "solid 1px",
                width: "45px",
                height: "35px",
                borderRadius: "4px 0 0 4px",
                display: "flex",
                fontWeight: "bold",
                fontSize: "15px",
                alignItems: "center",
                color: theme.palette.darkContrast.main,
                justifyContent: "center",
                borderColor: alpha(theme.palette.lightGray.main, 0.6),
                borderRight: "none",
              }}
            >
              <span>+</span>
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              style={{
                padding: "0",
                border: "solid 1px",
                width: "45px",
                height: "35px",
                borderColor: alpha(theme.palette.lightGray.main, 0.6),
                borderRadius: 0,
                display: "flex",
                fontSize: "15px",
                color: theme.palette.darkContrast.main,
                fontWeight: "bold",
                alignItems: "center",
                justifyContent: "center",
                ...theme.typography.fontWeight,
              }}
            >
              {countDrivers}
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              onClick={DecrementDrivers}
              style={{
                padding: "0",
                border: "solid 1px",
                width: "45px",
                height: "35px",
                borderColor: alpha(theme.palette.lightGray.main, 0.6),
                borderRadius: "0 4px 4px 0",
                display: "flex",
                fontWeight: "bold",
                fontSize: "19px",
                color: theme.palette.darkContrast.main,
                alignItems: "center",
                borderLeft: "none",
                justifyContent: "center",
              }}
            >
              <span>-</span>
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      {/* {(Object.values(selectedDepartures).some(
        (departure) => departure?.showNumberOfAxlesOnWeb
      ) ||
        selectedDeparture?.showNumberOfAxlesOnWeb) && (
        <Grid item xs={12} md={3}>
          <TextField
            label={LOCALE.number_of_axles}
            name="numberOfAxles"
            fullWidth={true}
            size="small"
            variant="outlined"
            value={data?.numberOfAxles ? data?.numberOfAxles : ""}
            onChange={(e) => handleChangeData("numberOfAxles", e.target.value)}
          />
        </Grid>
      )} */}

      {isMediumScreen && (
        <Grid item xs={12} md={9}>
          <Typography
            style={{
              ...theme.typography.fontWeight,
              margin: "3px",
              opacity: 0.6,
            }}
          >
            Driver Full Name{" "}
          </Typography>
          <TextField
            label={data?.driverName ? "" : LOCALE.driver_name}
            name="driverName"
            fullWidth={true}
            size="small"
            variant="outlined"
            value={data?.driverName ? data?.driverName : ""}
            inputProps={{ maxLength: 80, style: { fontWeight: "bold" } }}
            InputLabelProps={{ shrink: false }}
            onChange={(e) => handleChangeData("driverName", e.target.value)}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Divider
          style={{
            marginTop: "2px",
            marginBottom: "2px",
            borderBottomWidth: "2px",
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={3}>
            <Typography
              style={{
                ...theme.typography.fontWeight,
                margin: "3px",
                opacity: 0.6,
              }}
            >
              Loaded{" "}
            </Typography>
            <ToggleButtonGroup
              exclusive
              aria-label="loaded"
              onChange={handleToggleChange}
            >
              <ToggleButton
                value="2"
                aria-label="left aligned"
                style={{
                  width: "68px",
                  height: "36px",
                  backgroundColor:
                    selectedValue === "2" ? theme.palette.primary.main : "",
                  color:
                    selectedValue === "2"
                      ? theme.palette.background.default
                      : theme.palette.darkContrast.main,

                  fontWeight: selectedValue === "1" ? "bold" : "",
                }}
              >
                Yes
              </ToggleButton>
              <ToggleButton
                value="1"
                aria-label="centered"
                style={{
                  width: "68px",
                  height: "36px",
                  backgroundColor:
                    selectedValue === "1" ? theme.palette.primary.main : "",
                  color:
                    selectedValue === "1"
                      ? theme.palette.background.default
                      : theme.palette.darkContrast.main,
                  fontWeight: selectedValue === "2" ? "bold" : "",
                }}
              >
                No
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          {(Object.values(selectedDepartures).some(
            (departure) => departure?.showMaterialsOnWeb
          ) ||
            selectedDeparture?.showMaterialsOnWeb) && (
            <Grid item xs={12} md={6}>
              <Typography
                style={{
                  ...theme.typography.fontWeight,
                  margin: "3px",
                  opacity: 0.6,
                }}
              >
                Cargo Type{" "}
              </Typography>
              <FormControl
                fullWidth
                size="small"
                style={{
                  "& .MuiInputLabel-root": {
                    transform: "none",
                    opacity: selectedCargoType === "" ? 1 : 0,
                  },
                }}
              >
                <InputLabel
                  id="demo-simple-select-label"
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "40px",
                    transform: "translate(-50%, -50%)",
                    display: selectedCargoType === "" ? "fixed" : "none",
                  }}
                >
                  {LOCALE.materials}
                </InputLabel>
                <Select
                  value={data?.loaded == "1" ? "N/A" : data?.material ?? ""}
                  disabled={data?.loaded == "1"}
                  onChange={handleChange}
                >
                  <MenuItem value={"N/A"}>N/A</MenuItem>
                  {materials?.map((material, index) => (
                    <MenuItem value={material} key={index}>
                      {material.name === "Wood" && <ForestOutlinedIcon />}{" "}
                      {material.name === "Paper" && (
                        <Icon>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_341_1664)">
                              <path
                                d="M19.5 3.5L18 2L16.5 3.5L15 2L13.5 3.5L12 2L10.5 3.5L9 2L7.5 3.5L6 2V16H3V19C3 20.66 4.34 22 6 22H18C19.66 22 21 20.66 21 19V2L19.5 3.5ZM15 20H6C5.45 20 5 19.55 5 19V18H15V20ZM19 19C19 19.55 18.55 20 18 20C17.45 20 17 19.55 17 19V16H8V5H19V19Z"
                                fill="#202020"
                                style={{
                                  fill: "#202020",
                                  fill: "color(display-p3 0.1255 0.1255 0.1255)",
                                  fillOpacity: 1,
                                }}
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_341_1664">
                                <rect
                                  width="24"
                                  height="24"
                                  fill="white"
                                  style={{ fill: "white", fillOpacity: 1 }}
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </Icon>
                      )}
                      {material.name === "Cars" && (
                        <DirectionsCarFilledOutlinedIcon />
                      )}
                      {material.name === "Metal" && (
                        <Icon>
                          <svg
                            width="15"
                            height="20"
                            viewBox="0 0 15 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.5 4.5H1V1H14V4.5H10.5H9.5V5.5V14.5V15.5H10.5H14V19H1V15.5H4.5H5.5V14.5V5.5V4.5H4.5Z"
                              stroke="#202020"
                              style={{
                                stroke: "#202020",
                                stroke:
                                  "color(display-p3 0.1255 0.1255 0.1255)",
                                strokeOpacity: "1",
                              }}
                              stroke-width="2"
                            />
                          </svg>
                        </Icon>
                      )}
                      <b>{material.name}</b>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12} md={2}>
            <Typography
              style={{
                ...theme.typography.fontWeight,
                margin: "3px",
                opacity: 0.6,
              }}
            >
              Weight{" "}
            </Typography>
            <TextField
              name="cargoWeight"
              disabled={data?.loaded == "1"}
              fullWidth={true}
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start" style={{ opacity: 0.7 }}>
                    <b>t</b>
                  </InputAdornment>
                ),
                inputProps: {
                  min: 0.0,
                  max: 10000.0,
                },
              }}
              variant="outlined"
              type="number"
              value={data?.cargoWeight ?? ""}
              onChange={(e) => handleChangeData("cargoWeight", e.target.value)}
              error={formDataErrors[`cargoWeight`] !== undefined}
              helperText={formDataErrors[`cargoWeight`]}
              sx={{
                "& input": {
                  fontWeight: "bold",
                  textAlign: "center",
                },
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      {!(
        Object.values(selectedDepartures).some(
          (departure) => departure?.showMaterialsOnWeb
        ) || selectedDeparture?.showMaterialsOnWeb
      ) && <Grid item xs={12} md={12}></Grid>}
      <Grid item xs={12}>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="start"
          wrap="wrap"
        >
          {/* Dangerous Goods */}
          <Grid item xs={4.2} md={2.5}>
            <Typography
              style={{
                ...theme.typography.fontWeight,
                margin: "3px",
                opacity: 0.6,
              }}
            >
              Dangerous Goods{" "}
            </Typography>
            <IOSSwitch
              checked={toggleDangerousGoods || false}
              onClick={handleToggleDangerousGoods}
            />
          </Grid>

          {/* Electric Plugin */}
          <Grid item xs={4} md={2.5}>
            <Typography
              style={{
                ...theme.typography.fontWeight,
                margin: "3px",
                opacity: 0.6,
              }}
            >
              Electric Plugin{" "}
            </Typography>
            <IOSSwitch checked={toggle || false} onClick={handleToggle} />
          </Grid>

          {/* Live Animals */}
          <Grid item xs={3.5} md={3}>
            <Typography
              style={{
                ...theme.typography.fontWeight,
                margin: "3px",
                opacity: 0.6,
              }}
            >
              Live Animals{" "}
            </Typography>
            <IOSSwitch
              checked={toggleLiveAnimals || false}
              onClick={handleToggleLiveAnimals}
            />
          </Grid>
          <Grid item xs={0} md={2.5}></Grid>
          <Grid item xs={0} md={2.5}></Grid>

          {/* Electric Plugin Temperature Field */}
          {isMediumScreen &&
            toggle &&
            data?.electricPlugin &&
            data?.loaded !== "1" && (
              <Grid item xs={12} md={2}>
                <TextField
                  label={LOCALE.temperature}
                  name="temperature"
                  fullWidth
                  size="small"
                  type="number"
                  variant="outlined"
                  value={
                    data?.loaded === "1" ? null : data?.temperature ?? null
                  }
                  onChange={(e) =>
                    handleChangeData("temperature", e.target.value)
                  }
                />
              </Grid>
            )}

          {/* Responsive Temperature Field for Small Screens */}
          {isSmallScreen &&
            toggle &&
            data?.electricPlugin &&
            data?.loaded !== "1" && (
              <Grid item xs={12}>
                <TextField
                  label={LOCALE.temperature}
                  name="temperature"
                  fullWidth
                  size="small"
                  type="number"
                  variant="outlined"
                  value={
                    data?.loaded === "1" ? null : data?.temperature ?? null
                  }
                  onChange={(e) =>
                    handleChangeData("temperature", e.target.value)
                  }
                />
              </Grid>
            )}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider
          style={{
            // marginTop: "2px",
            // marginBottom: "2px",
            borderBottomWidth: "2px",
          }}
        />
      </Grid>

      <Grid item xs={12} md={selectedDeparture?.isFinCorrection ? 9 : 12}>
        <Typography
          style={{
            ...theme.typography.fontWeight,
            margin: "3px",
            opacity: 0.6,
          }}
        >
          Customer Info{" "}
        </Typography>
        <TextField
          multiline
          maxRows={4}
          minRows={3}
          label={data?.additionalInfo ? "" : LOCALE.additional_info}
          name="additionalInfo"
          fullWidth={true}
          size="small"
          variant="outlined"
          value={data?.additionalInfo ? data?.additionalInfo : ""}
          onChange={(e) => handleChangeData("additionalInfo", e.target.value)}
          InputLabelProps={{
            shrink: false,
          }}
          inputProps={{ style: { fontWeight: "bold" } }}
        />
      </Grid>
    </>
  );
}
