import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  alpha,
  Box,
  Divider,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import ReactCountryFlag from "react-country-flag";
import EastIcon from "@mui/icons-material/East";
import { useTheme } from "@emotion/react";
import moment from "moment";

export default function Pricing({ data, selectedDeparture, surchargesTotal }) {
  const ticketSurchargeList = selectedDeparture?.ticketSurchargeList || [];
  const theme = useTheme();

  return (
    <>
      <Grid
        container
        spacing={2}
        mb={2}
        sx={{
          paddingTop: "15px",
          height: "520px",
          overflowY: "auto",
        }}
      >
        <Grid item xs={12}>
          <Grid container alignItems="center" spacing={3}>
            {data?.fromCity && (
              <Grid item xs={1.5}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "bold",
                  }}
                >
                  <ReactCountryFlag
                    countryCode={data.fromCity.country.code}
                    svg
                    style={{
                      width: "1.6em",
                      height: "1.6em",
                      borderRadius: "8px",
                      marginRight: "4px",
                    }}
                    title={data.fromCity.country.name}
                  />
                  <span style={{ fontSize: "15px" }}>{data.fromCity.name}</span>
                </Box>
              </Grid>
            )}

            {data?.fromCity && data?.toCity && (
              <Grid item xs={0}>
                <EastIcon />
              </Grid>
            )}

            {data?.toCity && (
              <Grid item xs={1.5}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "bold",
                  }}
                >
                  <ReactCountryFlag
                    countryCode={data.toCity.country.code}
                    svg
                    style={{
                      width: "1.6em",
                      height: "1.6em",
                      borderRadius: "8px",
                      marginRight: "4px",
                    }}
                    title={data.toCity.country.name}
                  />
                  <span style={{ fontSize: "15px" }}>{data.toCity.name}</span>
                </Box>
              </Grid>
            )}
            {data?.fromCity && data?.toCity && (
              <Grid item xs={6} sx={{ marginLeft: "20px" }}>
                <Divider
                  style={{
                    borderBottomWidth: "2px",
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12} mb={2} sx={{ minHeight: "450px" }}>
          <Box
            sx={{
              backgroundColor: "#88c078",
              color: "white",
              border: "none",
              boxShadow: "none",
              position: "relative",
              padding: "0 10px",
              borderRadius: "6px 6px 0 0",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#88c078",
                color: "white",
                padding: "0 15px",
                paddingTop: "10px",
                paddingBottom: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                  overflow: "visible",
                  flexShrink: 0,
                }}
              >
                {selectedDeparture?.operatorName
                  ? selectedDeparture?.operatorName
                  : selectedDeparture?.supplier?.name}
              </Typography>

              <Grid
                container
                spacing={2}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Grid item xs={6} sx={{ marginBottom: "15px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Typography variant="h6" sx={{ opacity: "50%" }}>
                        Departure
                      </Typography>
                      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                        {selectedDeparture?.departureTime}
                      </Typography>
                      <Typography variant="h6">
                        {moment(selectedDeparture?.departureDate).format(
                          "DD.MM.YYYY"
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} sx={{ alignContent: "center" }}>
                      <Typography variant="h5">
                        <EastIcon />
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="h6" sx={{ opacity: "50%" }}>
                        Arrival
                      </Typography>
                      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                        {selectedDeparture?.arrivalTime}
                      </Typography>
                      <Typography variant="h6">
                        {selectedDeparture?.arrivalDay == "0"
                          ? moment(selectedDeparture?.departureDate).format(
                              "DD.MM.YYYY"
                            )
                          : selectedDeparture?.arrivalDay == "1"
                          ? moment(selectedDeparture?.departureDate)
                              .add("days", 1)
                              .format("DD.MM.YYYY")
                          : selectedDeparture?.arrivalDay == "2"
                          ? moment(selectedDeparture?.departureDate)
                              .add("days", 2)
                              .format("DD.MM.YYYY")
                          : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ padding: "0 10px 0 20px" }}>
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      backgroundColor: "rgba(160, 205, 147, 0.1)",
                      borderRadius: "5px",
                      overflow: "hidden",
                    }}
                  >
                    {ticketSurchargeList.map((surcharge, index) => (
                      <Grid
                        item
                        container
                        key={index}
                        sx={{
                          height: "60px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          backgroundColor:
                            index % 2 !== 0
                              ? "rgba(160, 205, 147, 0.3)"
                              : "rgba(160, 205, 147, 0.6)",

                          padding: "10px",
                        }}
                      >
                        <Grid item xs={10}>
                          <Typography sx={{ flex: 1, ...theme.typography.h6 }}>
                            {surcharge.surchargeName}
                          </Typography>
                        </Grid>
                        {/* {data?.organization?.showPrice && (
                          <Typography
                            sx={{
                              flex: 1,
                              textAlign: "center",
                              ...theme.typography.h6,
                            }}
                          >
                            {surcharge.quantity}
                          </Typography>
                        )}
                        {data?.organization?.showPrice && (
                          <Typography
                            sx={{
                              flex: 1,
                              textAlign: "center",
                              ...theme.typography.h6,
                            }}
                          >
                            x
                          </Typography>
                        )}
                        {data?.organization?.showPrice && (
                          <Typography
                            sx={{
                              flex: 1,
                              textAlign: "center",
                              ...theme.typography.h6,
                            }}
                          >
                            {parseFloat(surcharge.total).toFixed(2)}&euro;
                          </Typography>
                        )}
                        {data?.organization?.showPrice && (
                          <Typography
                            sx={{
                              flex: 1,
                              textAlign: "center",
                              ...theme.typography.h6,
                            }}
                          >
                            =
                          </Typography>
                        )} */}
                        {selectedDeparture &&
                        selectedDeparture?.salesPrice != null &&
                        selectedDeparture?.price != null ? (
                          <Grid item xs={2}>
                            <Typography
                              sx={{
                                flex: 1,
                                textAlign: "center",
                                ...theme.typography.h6,
                              }}
                            >
                              {parseFloat(surcharge.total).toFixed(2)}
                              &euro;
                            </Typography>
                          </Grid>
                        ) : (
                          ""
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box
            sx={{
              backgroundColor: "#88c078",
              color: "white",
              border: "none",
              boxShadow: "none",
              padding: "10px 15px",
              borderRadius: " 0 0 6px 6px",
            }}
          >
            {selectedDeparture &&
            selectedDeparture?.salesPrice != null &&
            selectedDeparture?.price != null ? (
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={4}>
                  <Typography
                    sx={{ opacity: "50%", fontSize: "12px" }}
                    align="left"
                  >
                    Total Freight
                  </Typography>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "20px",
                      // marginTop: 10,
                    }}
                    align="left"
                  >
                    {data?.adjustedCalculatedPrice ? (
                      <>
                        {parseFloat(data?.adjustedCalculatedPrice)?.toFixed(2)}{" "}
                        &euro;
                      </>
                    ) : (
                      <>{selectedDeparture?.salesPrice?.toFixed(2)}&euro;</>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    sx={{ opacity: "50%", fontSize: "12px" }}
                    align="center"
                  >
                    Total Surcharges
                  </Typography>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "20px",
                      // marginTop: 10,
                    }}
                    align="center"
                  >
                    {surchargesTotal ? (
                      <> {surchargesTotal?.toFixed(2)}&euro;</>
                    ) : (
                      "/"
                    )}
                  </Typography>
                </Grid>
                {/* {selectedDeparture?.surchargesIncludedInSalesPrice >
                            0 && (
                            <Grid item md={4}>
                              <Paper
                                elevation={0}
                                style={{
                                  borderRadius: "10px",
                                  padding: "5px",
                                  backgroundColor: "#edf7ed",
                                  textAlign: "center",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography
                                  style={{
                                    fontSize: "12px",
                                    // marginTop: 10,
                                  }}
                                  align="center"
                                >
                                  Surch. included in price
                                </Typography>
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                  }}
                                  align="center"
                                >
                                  {selectedDeparture?.surchargesIncludedInSalesPrice?.toFixed(
                                    2
                                  )}
                                </Typography>
                              </Paper>
                            </Grid>
                          )} */}
                <Grid item xs={4}>
                  <Typography
                    sx={{ opacity: "50%", fontSize: "12px" }}
                    align="right"
                  >
                    Total Price
                  </Typography>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "20px",
                    }}
                    align="right"
                  >
                    {surchargesTotal ? (
                      data?.adjustedCalculatedPrice ? (
                        <>
                          {(
                            parseFloat(data?.adjustedCalculatedPrice) +
                            surchargesTotal -
                            selectedDeparture?.surchargesIncludedInSalesPrice
                          )?.toFixed(2)}
                          &euro;
                        </>
                      ) : (
                        <>
                          {(
                            selectedDeparture?.salesPrice +
                            surchargesTotal -
                            selectedDeparture?.surchargesIncludedInSalesPrice
                          )?.toFixed(2)}
                          &euro;
                        </>
                      )
                    ) : data?.adjustedCalculatedPrice ? (
                      <>
                        <>
                          {parseFloat(data?.adjustedCalculatedPrice)?.toFixed(
                            2
                          )}
                          &euro;
                        </>
                      </>
                    ) : (
                      <>{selectedDeparture?.salesPrice?.toFixed(2)}&euro;</>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              ""
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
