import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  notifyShowErrorMessage,
  notifyShowSuccessMessage,
} from "../../common/CommonActions";
import { ErrorMessageResolver } from "../../common/ErrorMessageResolver";
import { LOCALE } from "../../properties/Locale";
import { AuthRepository } from "../../repositories/AuthRepository";
import { ConsignmentRepository } from "../../repositories/ConsignmentRepository";
import { CountriesV2Repository } from "../../repositories/CountriesV2Repository";
import { ConsignmentValidator } from "./ConsignmentValidator";

function generateFourDigitNumber() {
  return Math.floor(1000 + Math.random() * 9000);
}

export const ConsignmentDialog = () => {
  const [consignments, setConsignments] = useState();
  const [selectedConsignment, setSelectedConsignment] = useState();
  const [consignmentStatuses, setConsignmentStatuses] = useState([]);
  const statusLabels = {
    IM: "IM - import from 3rd country",
    T1: "T1 - goods accompanied by a NCTS T1 document",
    T2: "T2 - goods accompanied by a NCTS T2 document",
    T: "T - goods accompanied by a NCTS T1+T2 document",
    RP: "RP - Return EU packaging",
    TA: "TA - ATA/TIR carnet",
    AR: "AR - NATO Army",
  };
  const mainTransportDocumentLabels = {
    N820: "Transit declaration",
    N952: "TIR declaration",
    N955: "ATA carnet",
    N990: "Customs declaration",
  };

  const [mainTransportDocuments, setMainTransportDocuments] = useState([]);
  const [selectedReferenceType, setSelectedReferenceType] = useState("MRN");
  const [addressFlagConsignor, setAddressFlagConsignor] = useState(true);
  const [addressFlagConsignee, setAddressFlagConsignee] = useState(true);
  const [addressFlagSeller, setAddressFlagSeller] = useState(true);
  const [addressFlagBuyer, setAddressFlagBuyer] = useState(true);
  const [countries, setCountries] = useState([]);
  const [countriesSearchC1, setCountriesSearchC1] = useState("");
  const [countriesSearchS, setCountriesSearchS] = useState("");
  const [countriesSearchB, setCountriesSearchB] = useState("");
  const [countriesSearchC2, setCountriesSearchC2] = useState("");
  const [countriesSearchPlateNationality, setCountriesSearchPlateNationality] =
    useState("");
  const [citiesConsignor, setCitiesConsignor] = useState([]);
  const [citiesConsignee, setCitiesConsignee] = useState([]);
  const [citiesSeller, setCitiesSeller] = useState([]);
  const [citiesBuyer, setCitiesBuyer] = useState([]);
  const [citiesSearchConsignor, setCitiesSearchConsignor] = useState("");
  const [citiesSearchConsignee, setCitiesSearchConsignee] = useState("");
  const [citiesSearchSeller, setCitiesSearchSeller] = useState("");
  const [citiesSearchBuyer, setCitiesSearchBuyer] = useState("");
  const [goodsRows, setGoodsRows] = useState([]);
  const [packageTypes, setPackageTypes] = useState([]);
  const [customsOffices, setCustomsOffices] = useState([]);
  const [customsOfficeSearchS, setCustomsOfficeSearchS] = useState("");
  const [loading, setLoading] = useState(false);
  const [formDataErrors, setFormDataErrors] = useState({});
  const dispatch = useDispatch();
  const id = useParams().id;
  const navigate = useNavigate();

  useEffect(() => {
    getAllMainTransportDocuments();
    getAllStatuses();
    getAllCountries();
    getAllPackageTypes();
    getAllCustomsOffices();
    if (id && id !== "new") {
      ConsignmentRepository.getConsignmentById(id)
        .then((response) => {
          if (!response?.data?.id) {
            navigate(window.location.pathname.replace(/\/[^/]*$/, ""));
          }
          console.log(response.data);
          setConsignments([response.data]);
          setSelectedConsignment(response.data);
          setGoodsRows(response.data.goodsList);
        })
        .catch((err) => {
          console.log(err);
          navigate(window.location.pathname.replace(/\/[^/]*$/, ""));
        });
    } else {
      setConsignments([
        {
          consignmentNumber: generateFourDigitNumber(),
          organization: AuthRepository.getUserDetails().organization,
          // tommorow at 00:00 UTC
          approxArrivalDateAndTime: moment()
            .add(1, "days")
            .startOf("day")
            .format(),
          accompanied: true,
          transportMode: "FERRY",
          // customsStatus: "T1",
          mainTransportDocument: "N990",
          consignor: {},
          consignee: {},
          seller: {},
          buyer: {},
          goodsList: [
            {
              numberOfPackages: 0,
              packageType: null,
              hsCode: "",
              description: "",
              grossWeight: 0,
            },
          ],
        },
      ]);
    }
  }, []);

  useEffect(() => {
    if (consignments?.length === 1) {
      setSelectedConsignment(consignments[0]);
      setGoodsRows(consignments[0]?.goodsList);
    }
  }, [consignments]);

  useEffect(() => {
    console.log(selectedConsignment);
    if (selectedConsignment?.consignor?.country?.code) {
      getAllCitiesByCountry(
        selectedConsignment?.consignor?.country?.code,
        setCitiesConsignor
      );
    }
  }, [selectedConsignment?.consignor?.country?.code]);

  useEffect(() => {
    if (selectedConsignment?.consignee?.country?.code) {
      getAllCitiesByCountry(
        selectedConsignment?.consignee?.country?.code,
        setCitiesConsignee
      );
    }
  }, [selectedConsignment?.consignee?.country?.code]);

  useEffect(() => {
    if (selectedConsignment?.seller?.country?.code) {
      getAllCitiesByCountry(
        selectedConsignment?.seller?.country?.code,
        setCitiesSeller
      );
    }
  }, [selectedConsignment?.seller?.country?.code]);

  useEffect(() => {
    if (selectedConsignment?.buyer?.country?.code) {
      getAllCitiesByCountry(
        selectedConsignment?.buyer?.country?.code,
        setCitiesBuyer
      );
    }
  }, [selectedConsignment?.buyer?.country?.code]);

  const getAllStatuses = () => {
    ConsignmentRepository.getConsignmentStatuses()
      .then((response) => {
        setConsignmentStatuses(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllMainTransportDocuments = () => {
    ConsignmentRepository.getConsignmentMainTransportDocuments()
      .then((response) => {
        setMainTransportDocuments(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllCountries = () => {
    CountriesV2Repository.fetchAllWithoutPaging()
      .then((res) => {
        setCountries(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllCitiesByCountry = (countryId, setCities) => {
    setLoading(true);
    CountriesV2Repository.getCitiesByCountry(countryId)
      .then((res) => {
        console.log(res.data);
        setCities(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getAllPackageTypes = () => {
    ConsignmentRepository.getConsignmentPackageTypes()
      .then((response) => {
        setPackageTypes(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const save = () => {
    setLoading(true);

    let tmpSelectedConsignment = { ...selectedConsignment };
    tmpSelectedConsignment.goodsList = goodsRows;
    tmpSelectedConsignment.approxArrivalDateAndTime = moment(
      tmpSelectedConsignment.approxArrivalDateAndTime
    )
      .utc(false)
      .format();
    tmpSelectedConsignment.addressFlagConsignor = addressFlagConsignor;
    tmpSelectedConsignment.selectedReferenceType = selectedReferenceType;
    tmpSelectedConsignment.consignor.addressFlagConsignor =
      addressFlagConsignor;
    tmpSelectedConsignment.consignee.addressFlagConsignee =
      addressFlagConsignee;
    tmpSelectedConsignment.seller.addressFlagSeller = addressFlagSeller;
    tmpSelectedConsignment.buyer.addressFlagBuyer = addressFlagBuyer;

    validateConsignement(tmpSelectedConsignment);
    if (ConsignmentValidator.isValidSync(tmpSelectedConsignment)) {
      ConsignmentRepository.save(tmpSelectedConsignment)
        .then((response) => {
          const updatedConsignments = consignments.map((item) =>
            item.consignmentNumber === selectedConsignment?.consignmentNumber
              ? response.data
              : item
          );
          setConsignments(updatedConsignments);
          setSelectedConsignment(response.data);
          // loadData();
          dispatch(notifyShowSuccessMessage("Consignment saved successfully"));
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const handleReferenceTypeChange = (event) => {
    setSelectedReferenceType(event.target.value);

    const updatedConsignment = { ...selectedConsignment };
    if (event.target.value === "LRN") {
      delete updatedConsignment.mrnReference;
    } else if (event.target.value === "MRN") {
      delete updatedConsignment.lrnReference;
    }

    setSelectedConsignment(updatedConsignment);
  };

  const handleChange = (key, value) => {
    if (key === "customsStatus" && (value === "T2" || value === "T")) {
      setSelectedReferenceType("MRN");
    }

    if (
      key === "customsStatus" &&
      (value !== "T2" || value !== "T" || value !== "T1")
    ) {
      const updatedConsignment = { ...selectedConsignment };
      delete updatedConsignment.mrnReference;
      delete updatedConsignment.lrnReference;
      setSelectedConsignment(_.set({ ...updatedConsignment }, key, value));
    } else {
      setSelectedConsignment(_.set({ ...selectedConsignment }, key, value));
    }
  };

  const handleAddRow = () => {
    const newRow = {
      numberOfPackages: 0,
      packageType: null,
      hsCode: "",
      description: "",
      grossWeight: 0,
    };
    setGoodsRows([...goodsRows, newRow]);
  };

  const handleDeleteRow = (index) => {
    setGoodsRows(goodsRows.filter((row, index1) => index1 !== index));
  };

  const handleChangeRows = (index1, field, value) => {
    setGoodsRows((prevGoodsRows) =>
      prevGoodsRows
        ? prevGoodsRows.map((row, index) =>
            index === index1 ? { ...row, [field]: value } : row
          )
        : []
    );
  };

  const getAllCustomsOffices = () => {
    ConsignmentRepository.getCustomsOffices()
      .then((response) => {
        console.log(response.data);
        setCustomsOffices(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validateConsignement = (data) => {
    console.log(data);
    if (!ConsignmentValidator.isValidSync(data)) {
      var validationErrors = {};
      (async () => {
        await ConsignmentValidator.validate(data, {
          abortEarly: false,
        }).catch((err) => {
          err.inner.forEach((validationError) => {
            validationErrors[validationError.path] = {};
            validationErrors[validationError.path] = validationError.message;
          });
          console.log(validationErrors);
          setFormDataErrors(validationErrors);
          setLoading(false);
          return;
        });
      })();
    } else {
      setFormDataErrors({});
    }
  };

  return (
    <>
      <Dialog open={true} fullScreen fullWidth sx={{ padding: 5 }}>
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{ display: "flex", alignItems: "center", fontSize: "20px" }}
          >
            <Icon color="primary" fontSize="large">
              assignment
            </Icon>
            <span
              style={{ marginLeft: 8, color: "#323232", lineHeight: "1.2em" }}
            >
              ENS Declaration
            </span>
            {selectedConsignment?.status && (
              <Chip
                label={selectedConsignment?.status}
                color={
                  selectedConsignment?.status == "ERROR" ||
                  selectedConsignment?.status == "REJECTED"
                    ? "error"
                    : selectedConsignment?.status == "DONE"
                    ? "success"
                    : selectedConsignment?.status == "PENDING"
                    ? "warning"
                    : "primary"
                }
                style={{ marginLeft: 8 }}
                size="small"
              />
            )}
          </div>
          <IconButton
            onClick={() => {
              // remove everything after the last / of the current url
              navigate(window.location.pathname.replace(/\/[^/]*$/, ""));
            }}
            disabled={loading}
          >
            <Icon fontSize="medium">close</Icon>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {selectedConsignment?.consignmentIssues?.length > 0 &&
                selectedConsignment?.consignmentIssues.map((issue, index) => (
                  <>
                    {index == 0 && (
                      <Alert severity="warning" key={index}>
                        <AlertTitle>Note</AlertTitle>
                        Please check and correct the following issues before
                        saving the consignment.
                      </Alert>
                    )}
                    <Alert severity="error" key={index}>
                      <AlertTitle>{issue?.typ}</AlertTitle>
                      {issue?.description}
                    </Alert>
                  </>
                ))}
            </Grid>

            {/* Section: General Details */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                General Details
              </Typography>
            </Grid>

            {/* Client Information */}
            <Grid item xs={12} md={2}>
              <TextField
                fullWidth
                disabled
                size="small"
                label="Client"
                value={selectedConsignment?.organization?.nameEn || ""}
                sx={{ backgroundColor: "#f2f2f2" }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl fullWidth size="small" variant="outlined">
                <InputLabel>{"Transport mode"}</InputLabel>
                <Select
                  size="small"
                  value={selectedConsignment?.transportMode || ""}
                  label={"Transport mode"}
                  onChange={(event) => {
                    handleChange("transportMode", event.target.value);
                  }}
                >
                  <MenuItem key={"1"} value={"FERRY"}>
                    {"Sea transport (RoRo)"}
                  </MenuItem>
                  <MenuItem key={"1"} value={"TRAIN"}>
                    {"Rail transport (RoRo)"}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <Autocomplete
                noOptionsText={LOCALE.noOptionsText}
                autoComplete={false}
                size="small"
                value={
                  selectedConsignment?.customsOffice?.id
                    ? customsOffices?.filter(
                        (office) =>
                          office.id === selectedConsignment?.customsOffice?.id
                      )[0]
                    : null
                }
                onChange={(event, newValue) => {
                  handleChange("customsOffice", newValue);
                }}
                inputValue={customsOfficeSearchS ? customsOfficeSearchS : ""}
                onInputChange={(event, newInputValue) => {
                  setCustomsOfficeSearchS(newInputValue);
                }}
                options={customsOffices}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => {
                  return (
                    <li {...props}>
                      <div>{`${option.name} (${option.code})`}</div>
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"Customs office of first entry"}
                    fullWidth
                    variant="outlined"
                    error={formDataErrors[`customsOffice.id`] !== undefined}
                    helperText={formDataErrors[`customsOffice.id`]}
                  />
                )}
              />
            </Grid>

            {/* Estimated Arrival Date */}
            <Grid item xs={12} md={3}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker
                  renderInput={(props) => <TextField {...props} size="small" />}
                  label="Approx. Estimated Arrival Date and Time"
                  size="small"
                  value={
                    selectedConsignment?.approxArrivalDateAndTime
                      ? moment(selectedConsignment?.approxArrivalDateAndTime)
                      : null
                  }
                  onChange={(newValue) => {
                    setSelectedConsignment({
                      ...selectedConsignment,
                      approxArrivalDateAndTime: newValue,
                    });
                  }}
                  format="DD.MM.YYYY HH:mm"
                  minDate={moment()}
                  slotProps={{
                    textField: {
                      size: "small",
                      fullWidth: true,
                      error:
                        formDataErrors[`approxArrivalDateAndTime`] !==
                        undefined,
                      helperText: formDataErrors[`approxArrivalDateAndTime`],
                    },
                  }}
                  ampm={false}
                />
              </LocalizationProvider>
            </Grid>

            {/* Divider */}
            <Grid item xs={12}>
              <Divider />
            </Grid>

            {/* Section: Vehicle Details */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Vehicle Details
              </Typography>
            </Grid>

            {/* Plate Nationality */}
            <Grid item xs={12} md={2}>
              <Autocomplete
                noOptionsText={LOCALE.noOptionsText}
                autoComplete={false}
                size="small"
                value={
                  selectedConsignment?.truckPlateNationality?.name
                    ? selectedConsignment?.truckPlateNationality?.name
                    : ""
                }
                onChange={(event, newValue) => {
                  handleChange(
                    "truckPlateNationality",
                    countries?.filter((country) => country.name === newValue)[0]
                  );
                }}
                inputValue={
                  countriesSearchPlateNationality
                    ? countriesSearchPlateNationality
                    : ""
                }
                onInputChange={(event, newInputValue) => {
                  setCountriesSearchPlateNationality(newInputValue);
                }}
                options={countries?.map((country) => country.name)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"Nationality (of the registration plate)"}
                    fullWidth
                    variant="outlined"
                    error={
                      formDataErrors[`truckPlateNationality.name`] !== undefined
                    }
                    helperText={formDataErrors[`truckPlateNationality.name`]}
                  />
                )}
              />
            </Grid>
            {/* Truck Plate Number */}
            <Grid item xs={12} md={2}>
              <TextField
                fullWidth
                size="small"
                label="Vehicle Reg Num"
                value={selectedConsignment?.truckPlateNumber || ""}
                onChange={(event) =>
                  handleChange("truckPlateNumber", event.target.value)
                }
                error={formDataErrors[`truckPlateNumber`] !== undefined}
                helperText={formDataErrors[`truckPlateNumber`]}
              />
            </Grid>

            {/* Trailer Plate Number */}
            <Grid item xs={12} md={2}>
              <TextField
                fullWidth
                size="small"
                label="Trailer Reg Num"
                value={selectedConsignment?.trailerPlateNumber || ""}
                onChange={(event) =>
                  handleChange("trailerPlateNumber", event.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                fullWidth
                size="small"
                label="IMO Number"
                value={selectedConsignment?.imoNumber || ""}
                onChange={(event) =>
                  handleChange("imoNumber", event.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      selectedConsignment?.accompanied
                        ? selectedConsignment?.accompanied
                        : false
                    }
                    onChange={(event) => {
                      handleChange("accompanied", event.target.checked);
                    }}
                  />
                }
                label={"Accompanied"}
              />
            </Grid>
            {/* Divider */}
            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={4} lg={2.4}>
                  <Paper
                    elevation={3}
                    style={{
                      padding: 10,
                      minHeight: "38vh",
                    }}
                  >
                    <Grid item xs={12}>
                      <Typography variant="h6">Consignment Details</Typography>
                    </Grid>
                    {!selectedConsignment?.customsStatus && (
                      <>
                        <Grid item xs={12}>
                          <FormControl
                            fullWidth
                            size="small"
                            variant="outlined"
                            style={{ marginTop: "16px" }}
                          >
                            <InputLabel>{"Main transport document"}</InputLabel>
                            <Select
                              size="small"
                              value={
                                selectedConsignment?.mainTransportDocument || ""
                              }
                              label={"Main transport document"}
                              onChange={(event) => {
                                handleChange(
                                  "mainTransportDocument",
                                  event.target.value
                                );
                              }}
                            >
                              {mainTransportDocuments?.map((status) => (
                                <MenuItem key={status} value={status}>
                                  {mainTransportDocumentLabels[status] ||
                                    status}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Reference"
                            variant="outlined"
                            fullWidth
                            size="small"
                            margin="normal"
                            value={selectedConsignment?.mrnReference || ""}
                            onChange={(event) => {
                              handleChange("mrnReference", event.target.value);
                            }}
                            error={formDataErrors[`mrnReference`] !== undefined}
                            helperText={formDataErrors[`mrnReference`]}
                          />
                        </Grid>
                      </>
                    )}
                    {selectedConsignment?.customsStatus && (
                      <Grid item xs={12}>
                        <FormControl
                          fullWidth
                          size="small"
                          variant="outlined"
                          style={{ marginTop: "16px" }}
                        >
                          <InputLabel>{"Customs Status"}</InputLabel>
                          <Select
                            size="small"
                            value={selectedConsignment?.customsStatus || ""}
                            label={"Customs Status"}
                            onChange={(event) => {
                              handleChange("customsStatus", event.target.value);
                            }}
                          >
                            {consignmentStatuses?.map((status) => (
                              <MenuItem key={status} value={status}>
                                {statusLabels[status] || status}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      {(selectedConsignment?.customsStatus === "T1" ||
                        selectedConsignment?.customsStatus === "T2" ||
                        selectedConsignment?.customsStatus === "T") && (
                        <FormControl component="fieldset" margin="normal">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "16px",
                            }}
                          >
                            <RadioGroup
                              value={selectedReferenceType}
                              onChange={handleReferenceTypeChange}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                marginRight: "16px",
                                marginTop: "8px",
                              }}
                            >
                              <FormControlLabel
                                value="MRN"
                                control={<Radio />}
                                label="MRN"
                              />
                              {selectedConsignment?.customsStatus === "T1" && (
                                <FormControlLabel
                                  value="LRN"
                                  control={<Radio />}
                                  label="LRN"
                                />
                              )}
                            </RadioGroup>
                            {selectedReferenceType === "MRN" && (
                              <TextField
                                label="MRN reference"
                                variant="outlined"
                                fullWidth
                                size="small"
                                margin="normal"
                                value={selectedConsignment?.mrnReference || ""}
                                onChange={(event) => {
                                  handleChange(
                                    "mrnReference",
                                    event.target.value
                                  );
                                }}
                                error={
                                  formDataErrors[`mrnReference`] !== undefined
                                }
                                helperText={formDataErrors[`mrnReference`]}
                              />
                            )}
                            {selectedReferenceType === "LRN" && (
                              <TextField
                                label="LRN reference"
                                variant="outlined"
                                fullWidth
                                size="small"
                                margin="normal"
                                value={selectedConsignment?.lrnReference || ""}
                                onChange={(event) => {
                                  handleChange(
                                    "lrnReference",
                                    event.target.value
                                  );
                                }}
                                error={
                                  formDataErrors[`lrnReference`] !== undefined
                                }
                                helperText={formDataErrors[`lrnReference`]}
                              />
                            )}
                          </div>
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {(selectedConsignment?.customsStatus === "T1" ||
                        selectedConsignment?.customsStatus === "IM" ||
                        selectedConsignment?.customsStatus === "T") && (
                        <TextField
                          label="GGB reference (only for veterinary goods)"
                          variant="outlined"
                          fullWidth
                          size="small"
                          margin="normal"
                          value={selectedConsignment?.ggbReference || ""}
                          onChange={(event) => {
                            handleChange("ggbReference", event.target.value);
                          }}
                        />
                      )}
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4} lg={2.4}>
                  <Paper
                    elevation={3}
                    style={{
                      padding: 10,
                      minHeight: "38vh",
                    }}
                  >
                    <Grid container>
                      <Grid item xs={12} style={{ marginBottom: "12px" }}>
                        <Typography variant="h6">Consignor</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Name"
                          variant="outlined"
                          fullWidth
                          size="small"
                          margin="normal"
                          value={selectedConsignment?.consignor?.name || ""}
                          onChange={(event) => {
                            handleChange("consignor.name", event.target.value);
                          }}
                          error={formDataErrors[`consignor.name`] !== undefined}
                          helperText={formDataErrors[`consignor.name`]}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Autocomplete
                          noOptionsText={LOCALE.noOptionsText}
                          autoComplete={false}
                          size="small"
                          value={
                            selectedConsignment?.consignor?.country?.name
                              ? selectedConsignment?.consignor?.country?.name
                              : ""
                          }
                          onChange={(event, newValue) => {
                            handleChange(
                              "consignor.country",
                              countries?.filter(
                                (country) => country.name === newValue
                              )[0]
                            );
                          }}
                          inputValue={
                            countriesSearchC1 ? countriesSearchC1 : ""
                          }
                          onInputChange={(event, newInputValue) => {
                            setCountriesSearchC1(newInputValue);
                          }}
                          options={countries?.map((country) => country.name)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={LOCALE.country}
                              fullWidth
                              variant="outlined"
                              error={
                                formDataErrors[`consignor.country.name`] !==
                                undefined
                              }
                              helperText={
                                formDataErrors[`consignor.country.name`]
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "inline-flex",
                        }}
                      >
                        <>
                          <Autocomplete
                            noOptionsText={LOCALE.noOptionsText}
                            autoComplete={false}
                            size="small"
                            value={
                              selectedConsignment?.consignor?.city?.cityName
                                ? selectedConsignment?.consignor?.city?.cityName
                                : ""
                            }
                            onChange={(event, newValue) => {
                              handleChange(
                                "consignor.city",
                                citiesConsignor?.filter(
                                  (item) => item.cityName === newValue
                                )[0]
                              );
                            }}
                            inputValue={
                              citiesSearchConsignor ? citiesSearchConsignor : ""
                            }
                            onInputChange={(event, newInputValue) => {
                              setCitiesSearchConsignor(newInputValue);
                            }}
                            options={citiesConsignor?.map(
                              (item) => item.cityName
                            )}
                            fullWidth
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={LOCALE.city}
                                style={{ width: "90%", marginRight: "5px" }}
                                variant="outlined"
                                size="small"
                                margin="normal"
                                error={
                                  formDataErrors[`consignor.city.cityName`] !==
                                  undefined
                                }
                                helperText={
                                  formDataErrors[`consignor.city.cityName`]
                                }
                              />
                            )}
                          />
                          <TextField
                            label="Post Code"
                            variant="outlined"
                            size="small"
                            margin="normal"
                            value={
                              selectedConsignment?.consignor?.postCode || ""
                            }
                            onChange={(event) => {
                              handleChange(
                                "consignor.postCode",
                                event.target.value
                              );
                            }}
                            error={
                              formDataErrors[`consignor.postCode`] !== undefined
                            }
                            helperText={formDataErrors[`consignor.postCode`]}
                          />
                        </>
                      </Grid>
                      <Grid item md={12}>
                        <Button
                          variant="text"
                          color="info"
                          size="small"
                          onClick={() => {
                            setAddressFlagConsignor(!addressFlagConsignor);
                          }}
                          style={{ float: "right", marginBottom: "-20px" }}
                        >
                          {addressFlagConsignor ? "PO Box" : "Address"}
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: addressFlagConsignor ? "inline-flex" : "",
                        }}
                      >
                        {addressFlagConsignor ? (
                          <>
                            <TextField
                              label="Address"
                              variant="outlined"
                              size="small"
                              style={{ width: "90%", marginRight: "5px" }}
                              margin="normal"
                              value={
                                selectedConsignment?.consignor?.address || ""
                              }
                              onChange={(event) => {
                                handleChange(
                                  "consignor.address",
                                  event.target.value
                                );
                              }}
                              error={
                                formDataErrors[`consignor.address`] !==
                                undefined
                              }
                              helperText={formDataErrors[`consignor.address`]}
                            />

                            <TextField
                              label="Number"
                              variant="outlined"
                              size="small"
                              margin="normal"
                              value={
                                selectedConsignment?.consignor?.addressNumber ||
                                ""
                              }
                              onChange={(event) => {
                                handleChange(
                                  "consignor.addressNumber",
                                  event.target.value
                                );
                              }}
                              error={
                                formDataErrors[`consignor.addressNumber`] !==
                                undefined
                              }
                              helperText={
                                formDataErrors[`consignor.addressNumber`]
                              }
                            />
                          </>
                        ) : (
                          <TextField
                            label="PO Box Number"
                            variant="outlined"
                            fullWidth
                            size="small"
                            margin="normal"
                            value={
                              selectedConsignment?.consignor?.poBoxNumber || ""
                            }
                            onChange={(event) => {
                              handleChange(
                                "consignor.poBoxNumber",
                                event.target.value
                              );
                            }}
                            error={
                              formDataErrors[`consignor.poBoxNumber`] !==
                              undefined
                            }
                            helperText={formDataErrors[`consignor.poBoxNumber`]}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>

                <Grid item xs={12} md={4} lg={2.4}>
                  <Paper
                    elevation={3}
                    style={{
                      padding: 10,
                      minHeight: "38vh",
                    }}
                  >
                    <Grid container>
                      <Grid item xs={6}>
                        <Typography variant="h6">Seller / Sender</Typography>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: "right" }}>
                        <Button
                          variant="text"
                          color="info"
                          size="small"
                          onClick={() => {
                            handleChange("seller", {
                              ...selectedConsignment?.consignor,
                            });
                          }}
                        >
                          same as consignor
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Name"
                          variant="outlined"
                          fullWidth
                          size="small"
                          margin="normal"
                          value={selectedConsignment?.seller?.name || ""}
                          onChange={(event) => {
                            handleChange("seller.name", event.target.value);
                          }}
                          error={formDataErrors[`seller.name`] !== undefined}
                          helperText={formDataErrors[`seller.name`]}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Autocomplete
                          noOptionsText={LOCALE.noOptionsText}
                          autoComplete={false}
                          size="small"
                          value={
                            selectedConsignment?.seller?.country?.name
                              ? selectedConsignment?.seller?.country?.name
                              : ""
                          }
                          onChange={(event, newValue) => {
                            handleChange(
                              "seller.country",
                              countries?.filter(
                                (country) => country.name === newValue
                              )[0]
                            );
                          }}
                          inputValue={countriesSearchS ? countriesSearchS : ""}
                          onInputChange={(event, newInputValue) => {
                            setCountriesSearchS(newInputValue);
                          }}
                          options={countries?.map((country) => country.name)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={LOCALE.country}
                              fullWidth
                              variant="outlined"
                              error={
                                formDataErrors[`seller.country.name`] !==
                                undefined
                              }
                              helperText={formDataErrors[`seller.country.name`]}
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "inline-flex",
                        }}
                      >
                        <Autocomplete
                          noOptionsText={LOCALE.noOptionsText}
                          autoComplete={false}
                          size="small"
                          value={
                            selectedConsignment?.seller?.city?.cityName
                              ? selectedConsignment?.seller?.city?.cityName
                              : ""
                          }
                          onChange={(event, newValue) => {
                            handleChange(
                              "seller.city",
                              citiesSeller?.filter(
                                (item) => item.cityName === newValue
                              )[0]
                            );
                          }}
                          inputValue={
                            citiesSearchSeller ? citiesSearchSeller : ""
                          }
                          onInputChange={(event, newInputValue) => {
                            setCitiesSearchSeller(newInputValue);
                          }}
                          options={citiesSeller?.map((item) => item.cityName)}
                          fullWidth
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={LOCALE.city}
                              style={{ width: "90%", marginRight: "5px" }}
                              variant="outlined"
                              size="small"
                              margin="normal"
                              error={
                                formDataErrors[`seller.city.cityName`] !==
                                undefined
                              }
                              helperText={
                                formDataErrors[`seller.city.cityName`]
                              }
                            />
                          )}
                        />
                        <TextField
                          label="Post Code"
                          variant="outlined"
                          size="small"
                          margin="normal"
                          value={selectedConsignment?.seller?.postCode || ""}
                          onChange={(event) => {
                            handleChange("seller.postCode", event.target.value);
                          }}
                          error={
                            formDataErrors[`seller.postCode`] !== undefined
                          }
                          helperText={formDataErrors[`seller.postCode`]}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Button
                          variant="text"
                          color="info"
                          size="small"
                          onClick={() => {
                            setAddressFlagSeller(!addressFlagSeller);
                          }}
                          style={{ float: "right", marginBottom: "-20px" }}
                        >
                          {addressFlagSeller ? "PO Box" : "Address"}
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: addressFlagSeller ? "inline-flex" : "",
                        }}
                      >
                        {addressFlagSeller ? (
                          <>
                            <TextField
                              label="Address"
                              variant="outlined"
                              size="small"
                              style={{ width: "90%", marginRight: "5px" }}
                              margin="normal"
                              value={selectedConsignment?.seller?.address || ""}
                              onChange={(event) => {
                                handleChange(
                                  "seller.address",
                                  event.target.value
                                );
                              }}
                              error={
                                formDataErrors[`seller.address`] !== undefined
                              }
                              helperText={formDataErrors[`seller.address`]}
                            />

                            <TextField
                              label="Number"
                              variant="outlined"
                              size="small"
                              margin="normal"
                              value={
                                selectedConsignment?.seller?.addressNumber || ""
                              }
                              onChange={(event) => {
                                handleChange(
                                  "seller.addressNumber",
                                  event.target.value
                                );
                              }}
                              error={
                                formDataErrors[`seller.addressNumber`] !==
                                undefined
                              }
                              helperText={
                                formDataErrors[`seller.addressNumber`]
                              }
                            />
                          </>
                        ) : (
                          <TextField
                            label="PO Box Number"
                            variant="outlined"
                            fullWidth
                            size="small"
                            margin="normal"
                            value={
                              selectedConsignment?.seller?.poBoxNumber || ""
                            }
                            onChange={(event) => {
                              handleChange(
                                "seller.poBoxNumber",
                                event.target.value
                              );
                            }}
                            error={
                              formDataErrors[`seller.poBoxNumber`] !== undefined
                            }
                          />
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="EORI (optional)"
                          variant="outlined"
                          fullWidth
                          size="small"
                          margin="normal"
                          value={selectedConsignment?.seller?.eori || ""}
                          onChange={(event) => {
                            handleChange("seller.eori", event.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>

                <Grid item xs={12} md={4} lg={2.4}>
                  <Paper
                    elevation={3}
                    style={{
                      padding: 10,
                      minHeight: "38vh",
                    }}
                  >
                    <Grid container>
                      <Grid item xs={12} style={{ marginBottom: "12px" }}>
                        <Typography variant="h6">Buyer / Receiver</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Name"
                          variant="outlined"
                          fullWidth
                          size="small"
                          margin="normal"
                          value={selectedConsignment?.buyer?.name || ""}
                          onChange={(event) => {
                            handleChange("buyer.name", event.target.value);
                          }}
                          error={formDataErrors[`buyer.name`] !== undefined}
                          helperText={formDataErrors[`buyer.name`]}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Autocomplete
                          noOptionsText={LOCALE.noOptionsText}
                          autoComplete={false}
                          size="small"
                          value={
                            selectedConsignment?.buyer?.country?.name
                              ? selectedConsignment?.buyer?.country?.name
                              : ""
                          }
                          onChange={(event, newValue) => {
                            handleChange(
                              "buyer.country",
                              countries?.filter(
                                (country) => country.name === newValue
                              )[0]
                            );
                          }}
                          inputValue={countriesSearchB ? countriesSearchB : ""}
                          onInputChange={(event, newInputValue) => {
                            setCountriesSearchB(newInputValue);
                          }}
                          options={countries?.map((country) => country.name)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={LOCALE.country}
                              fullWidth
                              variant="outlined"
                              error={
                                formDataErrors[`buyer.country.name`] !==
                                undefined
                              }
                              helperText={formDataErrors[`buyer.country.name`]}
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "inline-flex",
                        }}
                      >
                        <Autocomplete
                          noOptionsText={LOCALE.noOptionsText}
                          autoComplete={false}
                          size="small"
                          value={
                            selectedConsignment?.buyer?.city?.cityName
                              ? selectedConsignment?.buyer?.city?.cityName
                              : ""
                          }
                          onChange={(event, newValue) => {
                            handleChange(
                              "buyer.city",
                              citiesBuyer?.filter(
                                (item) => item.cityName === newValue
                              )[0]
                            );
                          }}
                          inputValue={
                            citiesSearchBuyer ? citiesSearchBuyer : ""
                          }
                          onInputChange={(event, newInputValue) => {
                            setCitiesSearchBuyer(newInputValue);
                          }}
                          options={citiesBuyer?.map((item) => item.cityName)}
                          fullWidth
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={LOCALE.city}
                              style={{ width: "90%", marginRight: "5px" }}
                              variant="outlined"
                              size="small"
                              margin="normal"
                              error={
                                formDataErrors[`buyer.city.cityName`] !==
                                undefined
                              }
                              helperText={formDataErrors[`buyer.city.cityName`]}
                            />
                          )}
                        />
                        <TextField
                          label="Post Code"
                          variant="outlined"
                          size="small"
                          margin="normal"
                          value={selectedConsignment?.buyer?.postCode || ""}
                          onChange={(event) => {
                            handleChange("buyer.postCode", event.target.value);
                          }}
                          error={formDataErrors[`buyer.postCode`] !== undefined}
                          helperText={formDataErrors[`buyer.postCode`]}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          variant="text"
                          color="info"
                          size="small"
                          onClick={() => {
                            setAddressFlagBuyer(!addressFlagBuyer);
                          }}
                          style={{ float: "right", marginBottom: "-20px" }}
                        >
                          {addressFlagBuyer ? "PO Box" : "Address"}
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: addressFlagBuyer ? "inline-flex" : "",
                        }}
                      >
                        {addressFlagBuyer ? (
                          <>
                            <TextField
                              label="Address"
                              variant="outlined"
                              size="small"
                              style={{ width: "90%", marginRight: "5px" }}
                              margin="normal"
                              value={selectedConsignment?.buyer?.address || ""}
                              onChange={(event) => {
                                handleChange(
                                  "buyer.address",
                                  event.target.value
                                );
                              }}
                              error={
                                formDataErrors[`buyer.address`] !== undefined
                              }
                              helperText={formDataErrors[`buyer.address`]}
                            />

                            <TextField
                              label="Number"
                              variant="outlined"
                              size="small"
                              margin="normal"
                              value={
                                selectedConsignment?.buyer?.addressNumber || ""
                              }
                              onChange={(event) => {
                                handleChange(
                                  "buyer.addressNumber",
                                  event.target.value
                                );
                              }}
                              error={
                                formDataErrors[`buyer.addressNumber`] !==
                                undefined
                              }
                              helperText={formDataErrors[`buyer.addressNumber`]}
                            />
                          </>
                        ) : (
                          <TextField
                            label="PO Box Number"
                            variant="outlined"
                            fullWidth
                            size="small"
                            margin="normal"
                            value={
                              selectedConsignment?.buyer?.poBoxNumber || ""
                            }
                            onChange={(event) => {
                              handleChange(
                                "buyer.poBoxNumber",
                                event.target.value
                              );
                            }}
                            error={
                              formDataErrors[`buyer.poBoxNumber`] !== undefined
                            }
                            helperText={formDataErrors[`buyer.poBoxNumber`]}
                          />
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          label="EORI (optional)"
                          variant="outlined"
                          fullWidth
                          size="small"
                          margin="normal"
                          value={selectedConsignment?.buyer?.eori || ""}
                          onChange={(event) => {
                            handleChange("buyer.eori", event.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>

                <Grid item xs={12} md={4} lg={2.4}>
                  <Paper
                    elevation={3}
                    style={{
                      padding: 10,
                      minHeight: "38vh",
                    }}
                  >
                    <Grid container>
                      <Grid item xs={6}>
                        <Typography variant="h6">Consignee</Typography>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: "right" }}>
                        <Button
                          variant="text"
                          color="info"
                          size="small"
                          onClick={() => {
                            handleChange("consignee", {
                              ...selectedConsignment?.buyer,
                            });
                          }}
                        >
                          same as buyer
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Name"
                          variant="outlined"
                          fullWidth
                          size="small"
                          margin="normal"
                          value={selectedConsignment?.consignee?.name || ""}
                          onChange={(event) => {
                            handleChange("consignee.name", event.target.value);
                          }}
                          error={formDataErrors[`consignee.name`] !== undefined}
                          helperText={formDataErrors[`consignee.name`]}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                          noOptionsText={LOCALE.noOptionsText}
                          autoComplete={false}
                          size="small"
                          value={
                            selectedConsignment?.consignee?.country?.name
                              ? selectedConsignment?.consignee?.country?.name
                              : ""
                          }
                          onChange={(event, newValue) => {
                            handleChange(
                              "consignee.country",
                              countries?.filter(
                                (country) => country.name === newValue
                              )[0]
                            );
                          }}
                          inputValue={
                            countriesSearchC2 ? countriesSearchC2 : ""
                          }
                          onInputChange={(event, newInputValue) => {
                            setCountriesSearchC2(newInputValue);
                          }}
                          options={countries?.map((country) => country.name)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={LOCALE.country}
                              fullWidth
                              variant="outlined"
                              error={
                                formDataErrors[`consignee.country.name`] !==
                                undefined
                              }
                              helperText={
                                formDataErrors[`consignee.country.name`]
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "inline-flex",
                        }}
                      >
                        <Autocomplete
                          noOptionsText={LOCALE.noOptionsText}
                          autoComplete={false}
                          size="small"
                          value={
                            selectedConsignment?.consignee?.city?.cityName
                              ? selectedConsignment?.consignee?.city?.cityName
                              : ""
                          }
                          onChange={(event, newValue) => {
                            handleChange(
                              "consignee.city",
                              citiesConsignee?.filter(
                                (item) => item.cityName === newValue
                              )[0]
                            );
                          }}
                          inputValue={
                            citiesSearchConsignee ? citiesSearchConsignee : ""
                          }
                          onInputChange={(event, newInputValue) => {
                            setCitiesSearchConsignee(newInputValue);
                          }}
                          options={citiesConsignee?.map(
                            (item) => item.cityName
                          )}
                          fullWidth
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={LOCALE.city}
                              style={{ width: "90%", marginRight: "5px" }}
                              variant="outlined"
                              size="small"
                              margin="normal"
                              error={
                                formDataErrors[`consignee.city.cityName`] !==
                                undefined
                              }
                              helperText={
                                formDataErrors[`consignee.city.cityName`]
                              }
                            />
                          )}
                        />
                        <TextField
                          label="Post Code"
                          variant="outlined"
                          size="small"
                          margin="normal"
                          value={selectedConsignment?.consignee?.postCode || ""}
                          onChange={(event) => {
                            handleChange(
                              "consignee.postCode",
                              event.target.value
                            );
                          }}
                          error={
                            formDataErrors[`consignee.postCode`] !== undefined
                          }
                          helperText={formDataErrors[`consignee.postCode`]}
                        />
                      </Grid>{" "}
                      <Grid item xs={12}>
                        <Button
                          variant="text"
                          color="info"
                          size="small"
                          onClick={() => {
                            setAddressFlagConsignee(!addressFlagConsignee);
                          }}
                          style={{ float: "right", marginBottom: "-20px" }}
                        >
                          {addressFlagConsignee ? "PO Box" : "Address"}
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: addressFlagConsignee ? "inline-flex" : "",
                        }}
                      >
                        {addressFlagConsignee ? (
                          <>
                            <TextField
                              label="Address"
                              variant="outlined"
                              size="small"
                              style={{ width: "90%", marginRight: "5px" }}
                              margin="normal"
                              value={
                                selectedConsignment?.consignee?.address || ""
                              }
                              onChange={(event) => {
                                handleChange(
                                  "consignee.address",
                                  event.target.value
                                );
                              }}
                              error={
                                formDataErrors[`consignee.address`] !==
                                undefined
                              }
                              helperText={formDataErrors[`consignee.address`]}
                            />

                            <TextField
                              label="Number"
                              variant="outlined"
                              size="small"
                              margin="normal"
                              value={
                                selectedConsignment?.consignee?.addressNumber ||
                                ""
                              }
                              onChange={(event) => {
                                handleChange(
                                  "consignee.addressNumber",
                                  event.target.value
                                );
                              }}
                              error={
                                formDataErrors[`consignee.addressNumber`] !==
                                undefined
                              }
                              helperText={
                                formDataErrors[`consignee.addressNumber`]
                              }
                            />
                          </>
                        ) : (
                          <TextField
                            label="PO Box Number"
                            variant="outlined"
                            fullWidth
                            size="small"
                            margin="normal"
                            value={
                              selectedConsignment?.consignee?.poBoxNumber || ""
                            }
                            onChange={(event) => {
                              handleChange(
                                "consignee.poBoxNumber",
                                event.target.value
                              );
                            }}
                            error={
                              formDataErrors[`consignee.poBoxNumber`] !==
                              undefined
                            }
                            helperText={formDataErrors[`consignee.poBoxNumber`]}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>

                <Grid item xs={12}>
                  <Divider style={{ width: "100%" }} />
                </Grid>

                <Grid item xs={12}>
                  <Paper
                    elevation={3}
                    style={{
                      // width: "99%",
                      padding: 10,
                      minHeight: 150,
                    }}
                  >
                    <Grid item xs={12}>
                      <Typography variant="h6" gutterBottom>
                        Goods Details
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell style={{ border: "none" }}>
                                  No.
                                </TableCell>
                                <TableCell style={{ border: "none" }}>
                                  No. packages
                                </TableCell>
                                <TableCell style={{ border: "none" }}>
                                  Package type
                                </TableCell>
                                <TableCell style={{ border: "none" }}>
                                  HS-code (6 char.)
                                </TableCell>
                                <TableCell style={{ border: "none" }}>
                                  UN Dangerous Goods Code
                                </TableCell>
                                <TableCell style={{ border: "none" }}>
                                  Goods description
                                </TableCell>
                                <TableCell style={{ border: "none" }}>
                                  Gross weight (kg)
                                </TableCell>
                                <TableCell
                                  style={{ border: "none" }}
                                ></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody style={{ border: "none" }}>
                              {goodsRows?.map((row, index) => (
                                <TableRow key={index}>
                                  <TableCell style={{ border: "none" }}>
                                    {index + 1}
                                  </TableCell>
                                  <TableCell
                                    style={{ border: "none", width: "10%" }}
                                  >
                                    <TextField
                                      type="number"
                                      value={row.numberOfPackages}
                                      onChange={(e) =>
                                        handleChangeRows(
                                          index,
                                          "numberOfPackages",
                                          e.target.value
                                        )
                                      }
                                      variant="outlined"
                                      size="small"
                                      error={
                                        formDataErrors[
                                          `goodsList[${index}].numberOfPackages`
                                        ] !== undefined
                                      }
                                      helperText={
                                        formDataErrors[
                                          `goodsList[${index}].numberOfPackages`
                                        ]
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    style={{ border: "none", width: "15%" }}
                                  >
                                    <Autocomplete
                                      size="small"
                                      value={row.packageType || null}
                                      onChange={(event, newValue) => {
                                        handleChangeRows(
                                          index,
                                          "packageType",
                                          newValue
                                        );
                                      }}
                                      inputValue={row.packageTypeSearch || ""}
                                      onInputChange={(event, newInputValue) => {
                                        handleChangeRows(
                                          index,
                                          "packageTypeSearch",
                                          newInputValue
                                        );
                                      }}
                                      options={packageTypes}
                                      getOptionLabel={(option) =>
                                        `${option.code} - ${option.name}`
                                      }
                                      fullWidth
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Package type"
                                          fullWidth
                                          variant="outlined"
                                          size="small"
                                          error={
                                            formDataErrors[
                                              `goodsList[${index}].packageType`
                                            ] !== undefined
                                          }
                                          helperText={
                                            formDataErrors[
                                              `goodsList[${index}].packageType`
                                            ]
                                          }
                                        />
                                      )}
                                    />
                                  </TableCell>
                                  <TableCell
                                    style={{ border: "none", width: "10%" }}
                                  >
                                    <TextField
                                      value={row.hsCode}
                                      onChange={(e) =>
                                        handleChangeRows(
                                          index,
                                          "hsCode",
                                          // only allow alphanumeric characters
                                          e.target.value.replace(
                                            /[^a-zA-Z0-9]/g,
                                            ""
                                          )
                                        )
                                      }
                                      variant="outlined"
                                      size="small"
                                      error={
                                        formDataErrors[
                                          `goodsList[${index}].hsCode`
                                        ] !== undefined
                                      }
                                      helperText={
                                        formDataErrors[
                                          `goodsList[${index}].hsCode`
                                        ]
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    style={{ border: "none", width: "10%" }}
                                  >
                                    <TextField
                                      value={row.dgCode}
                                      fullWidth
                                      placeholder="UN code (optional)"
                                      onChange={(e) =>
                                        handleChangeRows(
                                          index,
                                          "dgCode",
                                          e.target.value
                                        )
                                      }
                                      variant="outlined"
                                      size="small"
                                    />
                                  </TableCell>
                                  <TableCell
                                    style={{ border: "none", width: "40%" }}
                                  >
                                    <TextField
                                      value={row.description}
                                      fullWidth
                                      onChange={(e) =>
                                        handleChangeRows(
                                          index,
                                          "description",
                                          e.target.value
                                        )
                                      }
                                      variant="outlined"
                                      size="small"
                                      error={
                                        formDataErrors[
                                          `goodsList[${index}].description`
                                        ] !== undefined
                                      }
                                      helperText={
                                        formDataErrors[
                                          `goodsList[${index}].description`
                                        ]
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    style={{ border: "none", width: "10%" }}
                                  >
                                    <TextField
                                      type="number"
                                      value={row.grossWeight}
                                      onChange={(e) =>
                                        handleChangeRows(
                                          index,
                                          "grossWeight",
                                          e.target.value
                                        )
                                      }
                                      variant="outlined"
                                      size="small"
                                      error={
                                        formDataErrors[
                                          `goodsList[${index}].grossWeight`
                                        ] !== undefined
                                      }
                                      helperText={
                                        formDataErrors[
                                          `goodsList[${index}].grossWeight`
                                        ]
                                      }
                                    />
                                  </TableCell>
                                  <TableCell style={{ border: "none" }}>
                                    <IconButton
                                      color="error"
                                      onClick={() => handleDeleteRow(index)}
                                    >
                                      <Icon>delete</Icon>
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <Button
                          onClick={handleAddRow}
                          variant="text"
                          size="small"
                          color="info"
                          startIcon={<Icon>add</Icon>}
                        >
                          Add goods
                        </Button>
                      </Box>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={save}
            color="success"
            startIcon={<Icon>save</Icon>}
            disabled={
              loading ||
              !selectedConsignment ||
              selectedConsignment?.status === "DONE" ||
              selectedConsignment?.status === "SENT" ||
              selectedConsignment?.status === "ACK" ||
              selectedConsignment?.status === "REJECTED" ||
              selectedConsignment?.status === "APPROVED"
            }
          >
            Save
          </Button>
          <Button
            onClick={() => {
              navigate(window.location.pathname.replace(/\/[^/]*$/, ""));
            }}
            color="error"
            startIcon={<Icon>close</Icon>}
            disabled={loading}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
