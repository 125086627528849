import { useTheme } from "@emotion/react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import React from "react";
import ReactCountryFlag from "react-country-flag";
import { useNavigate } from "react-router-dom";
import { getTicketStatusColor } from "../../common/VisualElementsHelper";
import { LOCALE } from "../../properties/Locale";

export const TicketGroupDetails = ({
  data,
  ticketGroup,
  loading,
  setTmpData,
  setData,
  setChangeTicket,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const statusColors = {
    APPROVED: theme.palette.secondary.main,
    PENDING: theme.palette.extra3.main,
    IN_PROGRESS: theme.palette.in_progress.main,
    REJECTED: theme.palette.rejected.main,
    CANCELLED: theme.palette.canceled.main,
    DONE: theme.palette.done.main,
    NO_SHOW: theme.palette.mutedRed.main,
  };

  return (
    <>
      <Accordion
        elevation={0}
        sx={{
          border: `1px solid ${theme.palette.dashboardBackground.main}`,
        }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              style={{ color: theme.palette.dashboardBackground.main }}
            />
          }
          style={{
            backgroundColor: theme.palette.success.light,
            color: theme.palette.dashboardBackground.main,
            height: 30,
          }}
        >
          <Typography variant="body1">{`Group name: ${ticketGroup?.name} Group reason: ${ticketGroup?.reason}`}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell style={{ textAlign: "left" }}>
                  {LOCALE.ticket_number}{" "}
                </TableCell>
                <TableCell style={{ textAlign: "left" }}>
                  {LOCALE.departure_date}
                </TableCell>
                <TableCell style={{ textAlign: "left" }}>
                  {LOCALE.client}
                </TableCell>
                <TableCell style={{ textAlign: "left" }}>
                  {LOCALE.destination}
                </TableCell>
                <TableCell style={{ textAlign: "left" }}>
                  {LOCALE.vehicle}
                </TableCell>
                <TableCell style={{ textAlign: "left" }}>
                  {data?.organization?.showSupplier && LOCALE.supplier}
                </TableCell>
                <TableCell></TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  {LOCALE.go_to_ticket}
                </TableCell>
              </TableRow>
            </TableHead>

            {ticketGroup &&
              ticketGroup?.tickets
                ?.sort((a, b) => a.ticketNumber - b.ticketNumber)
                .map((ticket, index) => (
                  <TableBody key={index}>
                    <TableRow
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(
                          `/client/booking/${0}/${10}/${JSON.stringify({})}/${
                            ticket?.id
                          }`
                        );
                      }}
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "#E4E4E4",
                        },
                      }}
                    >
                      <TableCell>
                        <Typography variant="subtitle2">
                          {ticket?.ticketNumber}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography variant="subtitle2">
                          {moment(ticket?.departureDay)
                            .utc()
                            .format("DD/MM/YYYY")}{" "}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography variant="subtitle2">
                          {ticket?.organization?.nameEn}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography variant="subtitle2">
                          <ReactCountryFlag
                            countryCode={ticket?.destination?.fromCountry?.code}
                            svg
                            style={{
                              marginRight: "3px",
                              width: "1.6em",
                              height: "1.1em",
                            }}
                            title={ticket?.destination?.fromCountry?.nameEn}
                          />
                          {ticket?.destination?.name?.split("-").join(" ➜ ")}
                          <ReactCountryFlag
                            countryCode={ticket?.destination?.toCountry?.code}
                            svg
                            style={{
                              marginLeft: "3px",
                              width: "1.6em",
                              height: "1.1em",
                            }}
                            title={ticket?.destination?.toCountry?.nameEn}
                          />
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">
                          {ticket?.truckPlateNumber}
                        </Typography>
                      </TableCell>
                      <TableCell style={{ textAlign: "left" }}>
                        {ticket?.productDestination ? (
                          <Typography variant="subtitle2">
                            {data?.organization?.showSupplier &&
                            ticket?.productDestination?.operator?.name
                              ? ticket?.productDestination?.operator?.name
                              : ticket?.productDestination?.supplier?.name}
                            {data?.organization?.showSupplier && " - "}
                            {
                              ticket?.productDestinationDeparture?.departureHour
                            }{" "}
                            (Arrive{" "}
                            {ticket?.productDestinationDeparture?.arrivalDay ===
                            "0"
                              ? "same day"
                              : ticket?.productDestinationDeparture
                                  ?.arrivalDay === "1"
                              ? "next day"
                              : "after 2 days"}{" "}
                            at{" "}
                            {ticket?.productDestinationDeparture?.arrivalHour})
                          </Typography>
                        ) : (
                          <Typography variant="subtitle2">
                            {`Departure hour: ${ticket?.departureHour}`}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        <Chip
                          variant="contained"
                          style={{
                            width: 100,
                            borderRadius: 4,
                            cursor: "pointer",
                            height: 22,
                            backgroundColor: statusColors[ticket?.status],
                          }}
                          size="small"
                          label={
                            <span
                              style={{ fontWeight: "bold", color: "white" }}
                            >
                              {ticket?.status?.replaceAll("_", " ")}
                            </span>
                          }
                        />
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {data?.id == ticket?.id ? (
                          <Typography>{""}</Typography>
                        ) : (
                          <Tooltip
                            arrow={true}
                            title={
                              <Typography variant="subtitle2">
                                {LOCALE.open}
                              </Typography>
                            }
                          >
                            <IconButton
                              style={{ padding: 0, paddingRight: 5 }}
                              color="primary"
                              onClick={() => {
                                setData(ticket);
                                setTmpData(ticket);
                                setChangeTicket(true);
                                navigate(
                                  `/client/booking/${0}/${10}/${JSON.stringify(
                                    {}
                                  )}/${ticket?.id}`
                                );
                              }}
                            >
                              <Icon>launch</Icon>
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ))}
          </Table>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
