import { useTheme } from "@emotion/react";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  alpha,
  Icon,
  IconButton,
  List,
  Tooltip,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { jwtDecode } from "jwt-decode";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AstaAdriaLogo from "../assets/img/Asta-Adria-logo.svg";
import NotificationSettings from "../components/settings/NotificationSettings";
import { AuthRepository } from "../repositories/AuthRepository";
import { Drawer } from "./MainLayout";
import { SideMenuLinks, SideMenuLinksDown } from "./SideMenuLink";

export const Sidebar = ({ setOpen, open }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = React.useState();
  const [menuLinks, setMenuLinks] = React.useState(SideMenuLinks);
  const [menuLinksDown, setMenuLinksDown] = React.useState(SideMenuLinksDown);
  const [submenuExpanded, setSubmenuExpanded] = React.useState({
    submenuPath: undefined,
  });

  const isCreateNewBooking =
    window.location.pathname.includes("/client/newBooking");
  const areBookings =
    window.location.pathname.includes("/client/bookings") ||
    window.location.pathname.includes("/client/uploads") ||
    window.location.pathname.includes("/client/consignment");

  const checkActiveTab = (item) => {
    return (
      (window.location.pathname.includes(item.selectedPath) &&
        window.location.pathname !== "/" &&
        item.path !== "/") ||
      (window.location.pathname === item.selectedPath &&
        item.selectedPath === "/")
    );
  };

  const getUserInitials = (user) => {
    if (!user) return "";
    const firstNameInitial = user.firstName?.charAt(0)?.toUpperCase() || "";
    const lastNameInitial = user.lastName?.charAt(0)?.toUpperCase() || "";
    return firstNameInitial + lastNameInitial;
  };

  React.useEffect(() => {
    loadCurrentUser();
  }, []);

  const loadCurrentUser = () => {
    if (localStorage.getItem("jwt")) {
      setCurrentUser(jwtDecode(localStorage.getItem("jwt")));
    }
  };

  const getUserFullName = (user) => {
    if (!user) return "";
    return `${user.firstName || ""} ${user.lastName || ""}`;
  };

  const getUserEmail = (user) => {
    if (!user) return "";
    return `${user.email || ""}`;
  };

  useEffect(() => {
    loadCurrentUser();
    if (
      window.location.hostname === "localhost" &&
      window.location.port === "3001"
    ) {
      // Ensure all menu items are visible when on localhost:3001
      setMenuLinks(SideMenuLinks);
      setMenuLinksDown(SideMenuLinksDown);
    }
  }, []);

  const handleLogout = () => {
    AuthRepository.authLogout(currentUser?.userId)
      .then((res) => {
        AuthRepository.logout();
      })
      .catch((err) => {
        AuthRepository.logout();
      });
  };

  return (
    <>
      <Drawer
        variant="permanent"
        open={open}
        color="default"
        sx={{
          display: { xs: "none", md: "block" },
          position: "relative",
          boxShadow: `0px 1px 1px rgba(0, 0, 0, 0.2)`,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        PaperProps={{
          sx: {
            width: open ? 240 : 20,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: open ? 240 : 20,
              boxSizing: "border-box",
              zIndex: (theme) => theme.zIndex.drawer,
              position: "relative",
            },
          },
        }}
      >
        <div style={{ flex: 1, paddingLeft: "17px", paddingRight: "17px" }}>
          <List>
            {menuLinks.map((item, index) => (
              <React.Fragment key={index}>
                <>
                  {/* Only show the item if it's not "Upload Bookings" OR if it is "Upload Bookings" and the user has permission */}
                  {(item.name !== "Upload Bookings" ||
                    (item.name === "Upload Bookings" &&
                      currentUser?.organization?.allowCSVBookings === true)) &&
                    !(
                      item.name === "Create New Booking" && isCreateNewBooking
                    ) && (
                      <ListItem
                        button
                        key={index}
                        onClick={() => {
                          navigate(item.path);
                          setSubmenuExpanded(
                            submenuExpanded.submenuPath == item.path
                              ? { submenuPath: "" }
                              : { submenuPath: item.path }
                          );
                        }}
                        style={{
                          backgroundColor:
                            item.name === "Create New Booking"
                              ? isCreateNewBooking
                                ? theme.palette.background.default
                                : theme.palette.primary.main
                              : checkActiveTab(item) && areBookings
                              ? alpha(theme.palette.secondary.main, 0.6)
                              : theme.palette.background.default,
                          color:
                            checkActiveTab(item) &&
                            theme.palette.background.default,
                          borderRadius: "8px",
                          transition: "all 0.3s ease",
                          marginTop:
                            item.name === "Upload Bookings" ? "5px" : "0px",
                        }}
                      >
                        <ListItemIcon
                          style={{
                            minWidth: "40px",
                            minHeight: "30px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {item.name === "ASTA ADRIA" ? (
                            <img
                              src={AstaAdriaLogo}
                              alt="Asta Adria"
                              style={{
                                width: "50px",
                                height: "50px",
                                marginLeft: "-33px",
                              }}
                            />
                          ) : (
                            <Icon
                              sx={{
                                color:
                                  item.name === "Create New Booking"
                                    ? theme.palette.background.default
                                    : checkActiveTab(item) && areBookings
                                    ? theme.palette.background.default
                                    : theme.palette.darkContrast.main,
                                fontSize: "20px",
                                marginLeft: "-33px",
                              }}
                            >
                              {item.icon}
                            </Icon>
                          )}
                        </ListItemIcon>
                        {open && (
                          <>
                            <ListItemText
                              primary={
                                item.name === "ASTA ADRIA" ? (
                                  <Typography
                                    variant="body1"
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "22px",
                                      marginLeft: "-5px",
                                      color: theme.palette.darkContrast.main,
                                    }}
                                  >
                                    {item.name}
                                  </Typography>
                                ) : (
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      fontSize: "14px",
                                      marginLeft: "-15px",
                                      color:
                                        item.name === "Create New Booking"
                                          ? theme.palette.background.default
                                          : checkActiveTab(item) && areBookings
                                          ? theme.palette.background.default
                                          : theme.palette.darkContrast.main,
                                    }}
                                  >
                                    {item.name}
                                  </Typography>
                                )
                              }
                            />
                          </>
                        )}

                        {open && item.submenus && item.submenus.length > 0 ? (
                          submenuExpanded.submenuPath == item.path ? (
                            <ListItemIcon style={{ minWidth: "30px" }}>
                              <ExpandLess />
                            </ListItemIcon>
                          ) : (
                            <ListItemIcon style={{ minWidth: "30px" }}>
                              <ExpandMore />
                            </ListItemIcon>
                          )
                        ) : (
                          ""
                        )}
                      </ListItem>
                    )}
                  {item.name === "Booking" && !isCreateNewBooking && (
                    <Divider
                      style={{
                        margin: "5px",
                        borderBottomWidth: "2px",
                      }}
                    />
                  )}
                </>
              </React.Fragment>
            ))}
          </List>
        </div>
        <Divider style={{ borderColor: "rgb(255 255 255 / 20%)" }} />

        <div>
          <List>
            <ListItem
              button
              style={{
                backgroundColor: checkActiveTab({ name: "Settings" })
                  ? "#d0d0d0"
                  : "transparent",
                paddingLeft: "11px",
                paddingRight: "5px",
              }}
            >
              <ListItemIcon
                style={{
                  minWidth: "30px",
                  minHeight: "25px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <NotificationSettings />
              </ListItemIcon>
              {open && (
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "14px",
                      }}
                    >
                      Settings
                    </Typography>
                  }
                  style={{ marginLeft: "5px" }}
                />
              )}
            </ListItem>

            {menuLinksDown.map(
              (item, index) =>
                AuthRepository.hasAnyRole(item.roles) && (
                  <React.Fragment key={index}>
                    <>
                      <ListItem
                        button
                        key={index}
                        onClick={() => {
                          if (item.name === "Logout") {
                            handleLogout();
                          } else {
                            navigate(item.path);
                          }
                          setSubmenuExpanded(
                            submenuExpanded.submenuPath == item.path
                              ? { submenuPath: "" }
                              : { submenuPath: item.path }
                          );
                        }}
                        style={{
                          backgroundColor: checkActiveTab(item)
                            ? "#d0d0d0"
                            : "transparent",
                          paddingLeft: "17px",
                          paddingRight: "5px",
                        }}
                      >
                        <ListItemIcon
                          style={{
                            minWidth: "30px",
                            minHeight: "25px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {item.name === "Profile" ? (
                            <div
                              style={{
                                borderColor: alpha(
                                  theme.palette.lightGray.main,
                                  0.6
                                ),
                                borderRadius: "4px",
                                width: "37px",
                                height: "39px",
                                minWidth: "30px",
                                minHeight: "25px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderWidth: "1px",
                                borderStyle: "solid",
                                fontSize: "19px",
                                fontWeight: "bold",
                                color: theme.palette.darkContrast.main,
                              }}
                            >
                              {getUserInitials(currentUser)}
                            </div>
                          ) : (
                            <Tooltip title={item.name}>
                              <Icon
                                sx={{
                                  color: theme.palette.darkContrast.main,
                                  fontSize: "22px",
                                  marginLeft: "7px",
                                }}
                              >
                                {item.icon}
                              </Icon>
                            </Tooltip>
                          )}
                        </ListItemIcon>
                        {open && (
                          <>
                            <ListItemText
                              primary={
                                item.name === "Profile" ? (
                                  <div>
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        color: theme.palette.lightGray.main,
                                        fontSize: "10px",
                                      }}
                                    >
                                      {currentUser?.organization?.nameEn}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        marginTop: "-5px",
                                        marginBottom: "-5px",
                                        fontSize: "12px",
                                      }}
                                    >
                                      <b>{getUserFullName(currentUser)}</b>
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        fontSize: "11px",
                                        color: theme.palette.secondary.main,
                                      }}
                                    >
                                      {getUserEmail(currentUser)}
                                    </Typography>
                                  </div>
                                ) : (
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      fontSize: "15px",
                                    }}
                                  >
                                    {item.name}
                                  </Typography>
                                )
                              }
                              style={{ marginLeft: "5px" }}
                            />
                          </>
                        )}
                      </ListItem>
                      {item.name === "Profile" && (
                        <Divider
                          style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            borderBottomWidth: "2px",
                          }}
                        />
                      )}
                    </>
                  </React.Fragment>
                )
            )}
          </List>
        </div>
        <IconButton
          onClick={() => setOpen(!open)}
          sx={{
            position: "absolute",
            right: -10,
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: (theme) => theme.zIndex.drawer + 20,
            borderRadius: "3px",
          }}
        >
          {!open ? (
            <ArrowForwardIosIcon
              style={{ color: theme.palette.lightGray.main }}
            />
          ) : (
            <ArrowBackIosOutlinedIcon
              style={{ color: theme.palette.lightGray.main }}
            />
          )}
        </IconButton>
      </Drawer>
    </>
  );
};
