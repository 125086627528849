import * as React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import HomeComponent from "../components/HomeComponent";
import Login from "../components/Login";
import CreateTicket from "../components/CreateTicket";
import { InvitationRegistration } from "../components/registration/InvitationRegistration";
import { UserInvitationRegistration } from "../components/registration/UserInvitationRegistration";
import { ClientTicketList } from "../components/tickets/ClientTicketList";
import ClientTicket from "../components/tickets/ClientTicket";
import ForgotPassword from "../components/ForgotPassword";
import ResetPassword from "../components/ResetPassword";
import ResetPasswordComponent from "../components/ResetPasswordComponent";
import LoginMobile from "../components/mobile/LoginMobile";
import { useMediaQuery } from "@mui/material";
import ForgotPasswordMobile from "../components/mobile/ForgotPasswordMobile";
import { CsvBookings } from "../components/CsvBookings";
import { ConsignmentList } from "../components/consignment/ConsignmentList";
import { ConsignmentDialog } from "../components/consignment/ConsignmentDialog";
import { Explore } from "../components/explore/Explore";
import { ExploreTimetable } from "../components/explore/ExploreTimetable";
import { ExploreBAF } from "../components/explore/ExploreBAF";

export default function AppRoutes() {
  const isSmallScreen = useMediaQuery("(max-width: 768px)");

  return (
    <>
      <Routes>
        <Route path="/" element={<HomeComponent />} />
        <Route
          path="/login"
          element={isSmallScreen ? <LoginMobile /> : <Login />}
        />
        <Route path="/explore" element={<Explore />} />
        <Route path="/explore/timetable/:name" element={<ExploreTimetable />} />
        <Route path="/explore/baf" element={<ExploreBAF />} />
        <Route
          path="/forgotPassword"
          element={
            isSmallScreen ? <ForgotPasswordMobile /> : <ForgotPassword />
          }
        />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/client/newBooking" element={<CreateTicket />} />
        <Route
          path="/dashboard/resetPassword"
          element={<ResetPasswordComponent />}
        />
        <Route
          path="/invitation/:invitationCode"
          element={<InvitationRegistration />}
        ></Route>
        <Route
          path="/invitation/users/:invitationCode"
          element={<UserInvitationRegistration />}
        ></Route>

        <Route
          path="/client/bookings/:page/:size/:params/:orderValue/:orderDirection"
          element={<ClientTicketList />}
        ></Route>
        <Route
          path="/client/booking/:page/:size/:params/:id"
          element={<ClientTicket />}
        ></Route>

        <Route
          path="/client/uploads/:page/:size/:params/:orderValue/:orderDirection"
          element={<CsvBookings />}
        ></Route>

        <Route
          path="/client/consignment/:page/:size/:params/:orderValue/:orderDirection"
          element={<ConsignmentList />}
        >
          <Route path=":id" element={<ConsignmentDialog />} />
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
        {/* <Route path="/forgotPassword" element={<ForgotPassword />} /> */}
        {/* <Route path="/reset-password" element={<ResetPassword />} /> */}
      </Routes>
    </>
  );
}
