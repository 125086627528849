import { useTheme } from "@mui/system";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ProductRepository } from "../../repositories/ProductRepository";
import {
  Autocomplete,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Grid,
  TableFooter,
  FormControl,
} from "@mui/material";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";

export const ExploreTimetable = ({}) => {
  const theme = useTheme();
  // get :name from path using
  const { name } = useParams();
  const [data, setData] = React.useState([]);
  const [departureDate, setDepartureDate] = React.useState(moment());

  useEffect(() => {
    if (name && departureDate) {
      ProductRepository.getAvailableDeparturesForWeb(
        name,
        departureDate.format("YYYY-MM-DD")
      ).then(
        (res) => {
          setData(res.data);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }, [departureDate]);

  return (
    <>
      <Paper>
        <Grid container spacing={2} padding={3}>
          <Grid item xs={12}>
            <Typography variant="h4" textAlign={"center"}>
              {name?.toLocaleUpperCase()}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              style={{
                ...theme.typography.fontWeight,
                padding: "3px",
                opacity: 0.6,
              }}
            >
              Date
            </Typography>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  format="DD.MM.YYYY"
                  value={departureDate ? departureDate : null}
                  onChange={(newValue) => {
                    setDepartureDate(moment(newValue));
                  }}
                  slots={{ openPickerIcon: CalendarMonthOutlinedIcon }}
                  orientation="landscape"
                  sx={{
                    "& .MuiInputBase-input": {
                      fontWeight: "bold",
                    },
                  }}
                  size="small"
                  slotProps={{ textField: { size: "small" } }}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography
              style={{
                color: "grey",
                textAlign: "center",
              }}
            >
              <b>Route schedule </b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TableContainer>
              <Table style={{ tableLayout: "fixed" }}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        textAlign: "left",
                      }}
                    >
                      <Typography
                        variant="h5"
                        style={{
                          color: theme.palette.primary.main,
                        }}
                      >
                        Name
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        variant="h5"
                        style={{
                          color: theme.palette.primary.main,
                        }}
                      >
                        Departure
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "right",
                      }}
                    >
                      <Typography
                        variant="h5"
                        style={{
                          color: theme.palette.primary.main,
                        }}
                      >
                        Arrival
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell
                        sx={{
                          textAlign: "left",
                        }}
                      >
                        <Typography>{row.level}</Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <Typography>{row.departureHour}</Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "right",
                        }}
                      >
                        <Typography>
                          {(row.arrivalDay == "0"
                            ? " Same day at"
                            : row.arrivalDay == "1"
                            ? " Next day at"
                            : row.arrivalDay == "2"
                            ? " 2 days later at"
                            : "") +
                            " " +
                            row.arrivalHour}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12}>
            <Typography
              style={{
                color: "grey",
                textAlign: "center",
              }}
            >
              <strong>
                {" "}
                All times shown are in local time. <br />
                Please note that the timetables may change subject to weather
                conditions.
              </strong>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};
