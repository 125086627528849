import * as yup from "yup";
import { LOCALE } from "../properties/Locale";

export const AgentMultipleTicketsValidator = yup.object().shape({
  tickets: yup.array().of(
    yup.object().shape({
      organization: yup.object().shape({
        id: yup.string().required(LOCALE.required_field),
      }),
      source: yup.object().shape({
        id: yup.string().required(LOCALE.required_field),
      }),
      fromCity: yup.object().shape({
        id: yup.string().required(LOCALE.required_field),
      }),
      toCity: yup.object().shape({
        id: yup.string().required(LOCALE.required_field),
      }),
      departureDay: yup.date().required(LOCALE.required_field),
      vehicleLength: yup
        .number()
        .min(1)
        .max(40)
        .required(LOCALE.required_field),
      truckPlateNumber: yup.string().required(LOCALE.required_field),
      cargoWeight: yup.number().when("loaded", {
        is: (loaded) => loaded,
        then: yup.number().min(1).max(100).required(LOCALE.required_field),
      }),
      priceList: yup.object().shape({
        id: yup.string().required(LOCALE.required_field),
      }),
      customerReferenceNumber: yup
        .string()
        .max(30, LOCALE.max_characters_30)
        .nullable(),
      trailerPlateNumber: yup
        .string()
        .max(20, LOCALE.max_characters_20)
        .nullable(),
      truckPlateNumber: yup
        .string()
        .max(20, LOCALE.max_characters_20)
        .nullable(),
    })
  ),
});

export const AgentNewTicketValidator = yup.object().shape({
  organization: yup.object().shape({
    id: yup.string().required(LOCALE.required_field),
  }),
  source: yup.string().required(LOCALE.required_field),
  fromCity: yup.object().shape({
    id: yup.string().required(LOCALE.required_field),
  }),
  toCity: yup.object().shape({
    id: yup.string().required(LOCALE.required_field),
  }),
  departureDay: yup.date().required(LOCALE.required_field),
  vehicleLength: yup.number().min(1).max(40).required(LOCALE.required_field),
  truckPlateNumber: yup
    .string()
    .max(20, LOCALE.max_characters_20)
    .required(LOCALE.required_field),
  cargoWeight: yup.number().when("loaded", {
    is: (loaded) => loaded,
    then: () => yup.number().min(1).max(100).required(LOCALE.required_field),
  }),
  customerReferenceNumber: yup
  .string()
  .max(30, LOCALE.max_characters_30)
  .nullable()
  .when('crnMandatory', {
    is: true,
    then: yup.string().required(LOCALE.required_field),
  }),
  trailerPlateNumber: yup.string().max(20, LOCALE.max_characters_20).nullable(),
  driverName: yup.string().max(80, LOCALE.max_characters_80).nullable(),
});


export const AgentNewTicketValidatorMailbox = yup.object().shape({
  organization: yup.object().shape({
    id: yup.string().required(LOCALE.required_field),
  }),
  source: yup.string().required(LOCALE.required_field),
  fromCity: yup.object().shape({
    id: yup.string().required(LOCALE.required_field),
  }),
  toCity: yup.object().shape({
    id: yup.string().required(LOCALE.required_field),
  }),
  departureDay: yup.date().required(LOCALE.required_field),
  vehicleLength: yup.number().min(1).max(40).required(LOCALE.required_field),
  cargoWeight: yup.number().when("loaded", {
    is: (loaded) => loaded,
    then: () => yup.number().min(1).max(100).required(LOCALE.required_field),
  }),
  // priceList: yup.object().shape({
  //   id: yup.string().required(LOCALE.required_field),
  // }),
});
export const AgentNewTicketApiValidator = yup.object().shape({
  organization: yup.object().shape({
    id: yup.string().required(LOCALE.required_field),
  }),
  source: yup.string().required(LOCALE.required_field),
  fromCity: yup.object().shape({
    id: yup.string().required(LOCALE.required_field),
  }),
  toCity: yup.object().shape({
    id: yup.string().required(LOCALE.required_field),
  }),
  departureDay: yup.date().required(LOCALE.required_field),
  vehicleLength: yup.number().min(1).max(40).required(LOCALE.required_field),
  numberOfTickets: yup.number().min(1).max(30).required(LOCALE.required_field),
  //reservationCodes which checks if array values are empty if stenaLineChecked is false
  reservationCodes: yup.array().when("stenaLineChecked", {
    is: (stenaLineChecked) => !stenaLineChecked,
    then: () => yup.array().of(yup.string().required(LOCALE.required_field)),
  }),
});
