import {
  Box,
  Chip,
  Divider,
  Grid,
  Icon,
  IconButton,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Select,
  MenuItem,
  Button,
  TextField,
  Dialog,
  Paper,
  Autocomplete,
  InputLabel,
} from "@mui/material";
import { alpha, useMediaQuery, useTheme } from "@mui/system";
import moment from "moment";
import { addDays, set } from "date-fns";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { useNavigate, useParams } from "react-router";
import { notifyShowErrorMessage } from "../../common/CommonActions";
import { ErrorMessageResolver } from "../../common/ErrorMessageResolver";
import { LOCALE } from "../../properties/Locale";
import { AuthRepository } from "../../repositories/AuthRepository";
import { ConsignmentRepository } from "../../repositories/ConsignmentRepository";
import axios from "axios";
import { SETTINGS } from "../../properties/Settings";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { CalendarMonth } from "@mui/icons-material";
import { DateCreatedCalendar } from "../tickets/DateCreatedCalendar";
import Draggable from "react-draggable";
import { LocalizationProvider as LocalizationProvider1 } from "@mui/x-date-pickers";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export const ConsignmentList = ({}) => {
  const theme = useTheme();
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const params = useParams();
  const [size, setSize] = React.useState(15);
  const [page, setPage] = React.useState(0);
  const [searchParams, setSearchParams] = React.useState({});
  const [expanded, setExpanded] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fromDateCreated = new Date(searchParams.fromDayCreated);
  const formattedFromDateCreated = fromDateCreated.toString().substring(4, 15);
  const toDateCreated = new Date(searchParams.toDayCreated);
  const formattedToDateCreated = toDateCreated.toString().substring(4, 15);
  const [openDateCreatedCalendar, setOpenDateCreatedCalendar] = useState(false);
  const [customsOffices, setCustomsOffices] = useState([]);
  const [customsOfficeSearchS, setCustomsOfficeSearchS] = useState("");

  const handleDateCreatedCalendar = () => {
    setOpenDateCreatedCalendar(true);
  };

  const handleDateCreated = () => {
    {
      let fromDayCreated = "fromDayCreated";
      let toDayCreated = "toDayCreated";
      dateCreatedState.map((dateCreatedState) => {
        let start = moment(dateCreatedState.startDate).toString();
        let end = moment(dateCreatedState.endDate).toString();
        let tmp = { ...searchParams };
        tmp[fromDayCreated] = start;
        tmp[toDayCreated] = end;
        setSearchParams(tmp);
      });
    }
  };

  const [dateCreatedState, setDateCreatedState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  useEffect(() => {
    loadData();
    getAllCustomsOffices();
  }, []);

  const getAllCustomsOffices = () => {
    ConsignmentRepository.getCustomsOffices()
      .then((response) => {
        setCustomsOffices(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate(
        `/client/consignment/${params?.page}/${params?.size}/${JSON.stringify(
          searchParams
        )}/${params?.orderValue}/${params?.orderDirection}`
      );
    }, 500);
    return () => clearTimeout(timer);
  }, [searchParams]);

  useEffect(() => {
    if (params && params.page && params.size) {
      loadData(
        params?.page,
        params?.size,
        params?.params,
        params?.orderValue,
        params?.orderDirection
      );
    }
    setSearchParams(JSON.parse(params.params));
  }, [
    params?.page,
    params?.size,
    params?.params,
    params?.orderValue,
    params?.orderDirection,
  ]);

  const loadData = (
    providedPage,
    providedSize,
    filterParams,
    orderValue,
    orderDirection
  ) => {
    setLoading(true);
    let tmp = filterParams ? filterParams : JSON.stringify({ ...searchParams });
    let tmpObj = JSON.parse(tmp);
    tmp = JSON.stringify(tmpObj);
    let tmpPage = providedPage ? providedPage : page;
    let tmpSize = providedSize ? providedSize : size;

    ConsignmentRepository.getAllConsignments(
      tmpPage,
      tmpSize,
      tmp,
      orderValue,
      orderDirection
    )
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
        setLoading(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    navigate(
      `/client/consignment/${newPage}/${params?.size}/${JSON.stringify(
        JSON.parse(params.params)
      )}/${params?.orderValue}/${params?.orderDirection}`
    );
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(parseInt(event.target.value, 10));
    navigate(
      `/client/consignment/${0}/${event.target.value}/${JSON.stringify(
        JSON.parse(params.params)
      )}/${params?.orderValue}/${params?.orderDirection}`
    );
  };

  const handleDownloadEns = (id, mrn) => {
    setLoading(true);

    axios({
      url: `${SETTINGS.API_URL}consignments/download/${id}`,
      method: "GET",
      responseType: "blob",
    })
      .then((res) => {
        // Extract the filename from the `content-disposition` header
        console.log(res);
        const contentDisposition = res.headers["content-disposition"];
        let filename = mrn + ".pdf"; // Default filename
        console.log("test");
        if (contentDisposition) {
          console.log("test2");
          const match = contentDisposition.match(
            /filename\*?=['"]?(?:UTF-8['"]*)?([^;'"]+)/i
          );
          if (match && match[1]) {
            console.log(match[1]);
            filename = decodeURIComponent(match[1]);
          }
        }

        // Create a Blob from the response data
        const file = new Blob([res.data], {
          type: "application/pdf",
        });

        // Create a downloadable link
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = fileURL;
        link.download = filename; // Set the filename
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        URL.revokeObjectURL(fileURL);

        setLoading(false);
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage(err.message));
        setLoading(false);
      });
  };

  const checkNumberOfFilters = () => {
    let numberOfFilters = 0;
    Object.keys(searchParams).forEach((key) => {
      if (
        searchParams[key] !== undefined &&
        searchParams[key] !== "" &&
        searchParams[key] !== null
      ) {
        numberOfFilters++;
      }
    });
    return numberOfFilters;
  };

  const handleChange = (name, value) => {
    let tmp = { ...searchParams };
    tmp[name] = value;
    setSearchParams(tmp);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={4}>
          <Stack
            direction="row"
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography
              variant="h5"
              key="2"
              color="text.primary"
              sx={{
                fontWeight: "bold",
                typography: { xs: "h4", md: "h5" },
                alignContent: "center",
                marginLeft: { xs: "8px", md: "0px" },
              }}
            >
              Your Consignments
              <IconButton
                onClick={() => loadData()}
                sx={{
                  border: "1px solid lightGray",
                  borderRadius: "5px",
                  padding: "1px",
                  minWidth: "auto",
                  height: "auto",
                  marginLeft: "5px",
                  display: { xs: "none", md: "inline-flex" },
                }}
              >
                <Icon
                  style={{ color: theme.palette.darkContrast.main }}
                  fontSize="20"
                >
                  refresh
                </Icon>
              </IconButton>
              <IconButton
                onClick={() => navigate(window.location.pathname + "/new")}
                sx={{
                  border: "1px solid lightGray",
                  borderRadius: "5px",
                  padding: "1px",
                  minWidth: "auto",
                  height: "auto",
                  marginLeft: "5px",
                  display: { xs: "none", md: "inline-flex" },
                }}
              >
                <Icon
                  style={{ color: theme.palette.darkContrast.main }}
                  fontSize="20"
                >
                  add
                </Icon>
              </IconButton>
            </Typography>
            <Box
              sx={{
                display: { xs: "inline-flex", md: "none", marginRight: "11px" },
              }}
            >
              <IconButton
                onClick={() => loadData()}
                sx={{
                  border: "1px solid lightGray",
                  borderRadius: "5px",
                  padding: "1px",
                  minWidth: 55,
                  height: 55,
                  marginLeft: "5px",
                }}
              >
                <Icon
                  style={{
                    color: theme.palette.darkContrast.main,
                    fontSize: "28px",
                  }}
                >
                  refresh
                </Icon>
              </IconButton>
              <IconButton
                onClick={() =>
                  // current url + /new
                  navigate(window.location.pathname + "/new")
                }
                sx={{
                  border: "1px solid lightGray",
                  borderRadius: "5px",
                  padding: "1px",
                  minWidth: 55,
                  height: 55,
                  marginLeft: "5px",
                }}
              >
                <Icon
                  style={{
                    color: theme.palette.darkContrast.main,
                    fontSize: "28px",
                  }}
                >
                  add
                </Icon>
              </IconButton>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: 2 }}>
          <Divider />
        </Grid>
        {/* <Grid container spacing={2} sx={{ paddingTop: 2, paddingBottom: 2 }}> */}
        <Grid item xs={12}>
          <Accordion
            style={{
              boxShadow: "none",
              backgroundColor: "transparent",
            }}
            expanded={expanded}
          >
            <AccordionSummary
              style={{
                cursor: "default",
                backgroundColor: theme.palette.dashboardBackground.main,
              }}
            >
              <Grid
                item
                xs={12}
                md={6}
                lg={5}
                xl={4.5}
                style={{
                  textAlign: "left",
                  marginLeft: isSmallScreen ? "-8px" : "-15px",
                  marginTop: "-10px",
                }}
              >
                <Grid container alignItems="center" spacing={1}>
                  {isSmallScreen ? (
                    <Grid item xs={4} sx={{ display: "flex" }}>
                      <Box sx={{ width: "100%", position: "relative" }}>
                        <Button
                          fullWidth
                          color="primary"
                          variant={expanded ? "contained" : "outlined"}
                          onClick={() => setExpanded(!expanded)}
                          sx={{
                            height: "50px",
                            fontSize: "15px",
                            marginTop: "16px",
                            width: "100%",
                          }}
                          endIcon={
                            expanded ? (
                              <Tooltip
                                title={"Clear filters"}
                                arrow
                                placement="right"
                              >
                                <IconButton
                                  size="small"
                                  sx={{
                                    color:
                                      theme.palette.dashboardBackground.main,
                                    fontSize: "15px",
                                    width: "10px",
                                    marginLeft: "5px",
                                  }}
                                  onClick={() => {
                                    setSearchParams({ status: "ALL" });
                                    navigate(
                                      `/client/consignment/${params.page}/${
                                        params.size
                                      }/${JSON.stringify(
                                        JSON.parse(params.params)
                                      )}/${params?.orderValue}/${
                                        params?.orderDirection
                                      }`
                                    );
                                  }}
                                >
                                  <Icon>clear</Icon>
                                </IconButton>
                              </Tooltip>
                            ) : null
                          }
                        >
                          F
                          <span
                            style={{
                              textTransform: "lowercase",
                            }}
                          >
                            ilters
                          </span>
                        </Button>
                        {checkNumberOfFilters() > 0 && (
                          <Box
                            sx={{
                              position: "absolute",
                              bottom: -8,
                              right: -8,
                              backgroundColor: theme.palette.primary.main,
                              color: "white",
                              borderRadius: 1,
                              fontSize: "12px",
                              padding: "0 6px",
                              minWidth: "20px",
                              height: "20px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              border: "1px solid",
                              borderColor: expanded
                                ? theme.palette.dashboardBackground.main
                                : "none",
                            }}
                          >
                            {checkNumberOfFilters()}
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  ) : (
                    <Grid item md={3}>
                      <Button
                        color="primary"
                        variant={expanded ? "contained" : "outlined"}
                        onClick={() => setExpanded(!expanded)}
                        style={{
                          transition: "width 0.3s ease",
                          width: expanded ? "125px" : "100px",
                          height: "50px",
                          fontSize: "15px",
                          marginTop: "16px",
                        }}
                        endIcon={
                          expanded ? (
                            <Tooltip
                              title={"Clear filters"}
                              arrow
                              placement="right"
                            >
                              <IconButton
                                size="small"
                                sx={{
                                  color: theme.palette.dashboardBackground.main,
                                  fontSize: "15px",
                                  width: "10px",
                                  marginLeft: "5px",
                                }}
                                onClick={() => {
                                  setSearchParams({ status: "ALL" });
                                  navigate(
                                    `/client/consignment/${params.page}/${
                                      params.size
                                    }/${JSON.stringify(
                                      JSON.parse(params.params)
                                    )}/${params?.orderValue}/${
                                      params?.orderDirection
                                    }`
                                  );
                                }}
                              >
                                <Icon>clear</Icon>
                              </IconButton>
                            </Tooltip>
                          ) : (
                            ""
                          )
                        }
                      >
                        F
                        <span
                          style={{
                            textTransform: "lowercase",
                          }}
                        >
                          ilters
                        </span>
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails
              style={{
                // marginLeft: isSmallScreen ? "-6px" : "-15px",
                paddingLeft: isSmallScreen ? "9px" : "1px",
                marginRight: isSmallScreen ? "-5px" : "0px",
                backgroundColor: theme.palette.dashboardBackground.main,
                marginTop: "-18px",
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} md={1.7} lg={1.2}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <TextField
                      autoComplete="off"
                      label={LOCALE.date_created}
                      {...params}
                      helperText={null}
                      fullWidth
                      value={
                        searchParams.fromDayCreated && searchParams.toDayCreated
                          ? formattedFromDateCreated +
                            " to " +
                            formattedToDateCreated
                          : ""
                      }
                      onClick={() => handleDateCreatedCalendar()}
                      InputProps={{
                        endAdornment: <CalendarMonth />,
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={1.7} lg={1.2}>
                  <Autocomplete
                    noOptionsText={LOCALE.noOptionsText}
                    autoComplete={false}
                    value={
                      searchParams["customsOffice.id"]
                        ? customsOffices.find(
                            (customOffice) =>
                              customOffice.id ===
                              searchParams["customsOffice.id"]
                          )
                        : null
                    }
                    onChange={(event, newValue) => {
                      handleChange(
                        "customsOffice.id",
                        newValue?.id ? newValue?.id : ""
                      );
                    }}
                    inputValue={
                      customsOfficeSearchS ? customsOfficeSearchS : ""
                    }
                    onInputChange={(event, newInputValue) => {
                      setCustomsOfficeSearchS(newInputValue);
                    }}
                    options={customsOffices}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option) => {
                      return (
                        <li {...props}>
                          <div>{`${option.name} (${option.code})`}</div>
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={"Customs office"}
                        fullWidth
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={1.7} lg={1.2}>
                  <TextField
                    onChange={(e) => {
                        handleChange("truckTrailerPlate", e.target.value.trim().replace(/[^a-z0-9]/gi, ""));
                    }}
                    label={"Truck or Trailer Plate"}
                    value={
                      searchParams?.truckTrailerPlate
                        ? searchParams?.truckTrailerPlate
                        : ""
                    }
                    variant="outlined"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={1.7} lg={1.2}>
                  <LocalizationProvider1 dateAdapter={AdapterMoment}>
                    <DateTimePicker
                      renderInput={(props) => <TextField {...props} />}
                      label="ETA"
                      value={
                        searchParams?.approxArrivalDateAndTime
                          ? moment(searchParams?.approxArrivalDateAndTime)
                          : null
                      }
                      onChange={(newValue) => {
                        // handleChange("approxArrivalDateAndTime", newValue);
                      }}
                      onAccept={(newValue) => {
                        handleChange("approxArrivalDateAndTime", newValue);
                      }}
                      format="DD.MM.YYYY HH:mm"
                      slotProps={{
                        textField: {
                          fullWidth: true,
                        },
                      }}
                      ampm={false}
                    />
                  </LocalizationProvider1>
                </Grid>
                <Grid item xs={12} md={1.7} lg={1.2}>
                  <TextField
                    onChange={(e) => {
                      handleChange("mainTpDocument", e.target.value.trim().replace(/[^a-z0-9]/gi, ""));
                      
                    }}
                    label={"Main Tp. document"}
                    value={
                      searchParams?.mainTpDocument
                        ? searchParams?.mainTpDocument
                        : ""
                    }
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={1.7} lg={1.2}>
                  <TextField
                    onChange={(e) => handleChange("mrn", e.target.value.trim().replace(/[^a-z0-9]/gi, ""))}
                    label={"MRN"}
                    value={searchParams?.mrn ? searchParams?.mrn : ""}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={1.7} lg={1.2}>
                  <FormControl fullWidth>
                    <InputLabel>Status</InputLabel>
                    <Select
                      label="Status"
                      value={searchParams?.status ? searchParams?.status : ""}
                      onChange={(event) => {
                        handleChange("status", event.target.value);
                      }}
                      color="lightGray"
                      disabled={loading}
                      style={{
                        width: "100%",
                        fontSize: "12px",
                        borderRadius: "5px",
                        fontWeight: "bold",
                      }}
                    >
                      <MenuItem value="ALL">
                        <span style={{ marginLeft: 10 }}>{"ALL"}</span>
                      </MenuItem>
                      <MenuItem value={"PENDING"}>
                        <span style={{ marginLeft: 10 }}>{LOCALE.PENDING}</span>
                      </MenuItem>
                      <MenuItem value={"APPROVED"}>
                        <span style={{ marginLeft: 10 }}>
                          {LOCALE.APPROVED}
                        </span>
                      </MenuItem>
                      <MenuItem value={"SENT"}>
                        <span style={{ marginLeft: 10 }}>{LOCALE.SENT}</span>
                      </MenuItem>
                      <MenuItem value={"ACK"}>
                        <span style={{ marginLeft: 10 }}>{LOCALE.ACK}</span>
                      </MenuItem>
                      <MenuItem value={"DONE"}>
                        <span style={{ marginLeft: 10 }}>{LOCALE.DONE}</span>
                      </MenuItem>
                      <MenuItem value={"ERROR"}>
                        <span style={{ marginLeft: 10 }}>{LOCALE.ERROR}</span>
                      </MenuItem>
                      <MenuItem value={"REJECTED"}>
                        <span style={{ marginLeft: 10 }}>
                          {LOCALE.REJECTED}
                        </span>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        {/* </Grid> */}

        <Grid
          item
          xs={12}
          sx={{
            display: {
              xs: "block",
              md: "none",
            },
            height: "75vh",
            overflowY: "auto",
            scrollbarWidth: "none", // For Firefox
            "&::-webkit-scrollbar": {
              display: "none", // For Webkit browsers (Chrome, Safari, Edge)
            },
          }}
        >
          <Table
            sx={{
              borderSpacing: "8px",
              borderCollapse: "separate",
              paddingBottom: "10px",
            }}
          >
            <TableBody>
              {data &&
                data?.totalElements > 0 &&
                data?.content?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell colSpan={12} sx={{ padding: { xs: 1, sm: 2 } }}>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <Typography
                            variant="h5"
                            sx={{ fontWeight: "bold", fontSize: "16px" }}
                          >
                            Date Created
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant="h5"
                            sx={{ fontWeight: "light", fontSize: "16px" }}
                          >
                            {item?.dateCreated
                              ? moment(item?.dateCreated).format(
                                  "DD.MM.YYYY HH:mm"
                                )
                              : "N/A"}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant="h5"
                            sx={{ fontWeight: "bold", fontSize: "16px" }}
                          >
                            Customs Office
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant="h5"
                            sx={{ fontWeight: "light", fontSize: "16px" }}
                          >
                            {`${item?.customsOffice?.name} (${item?.customsOffice?.code})`}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant="h5"
                            sx={{ fontWeight: "bold", fontSize: "16px" }}
                          >
                            Truck/Trailer Plate
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant="h5"
                            sx={{ fontWeight: "light", fontSize: "16px" }}
                          >
                            {`${item?.truckPlateNumber} ${
                              item?.trailerPlateNumber
                                ? "/ " + item?.trailerPlateNumber
                                : ""
                            }`}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant="h5"
                            sx={{ fontWeight: "bold", fontSize: "16px" }}
                          >
                            ETA
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant="h5"
                            sx={{ fontWeight: "light", fontSize: "16px" }}
                          >
                            {item?.approxArrivalDateAndTime
                              ? moment(item?.approxArrivalDateAndTime).format(
                                  "DD.MM.YYYY HH:mm"
                                )
                              : "N/A"}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant="h5"
                            sx={{ fontWeight: "bold", fontSize: "16px" }}
                          >
                            Main Tp. Document
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant="h5"
                            sx={{ fontWeight: "light", fontSize: "16px" }}
                          >
                            {item?.mrnReference
                              ? item?.mrnReference
                              : item?.lrnReference
                              ? item?.lrnReference
                              : item?.ggbReference
                              ? item?.ggbReference
                              : "N/A"}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant="h5"
                            sx={{ fontWeight: "bold", fontSize: "16px" }}
                          >
                            MRN
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant="h5"
                            sx={{ fontWeight: "light", fontSize: "16px" }}
                          >
                            {item?.mrn ? item?.mrn : "N/A"}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant="h5"
                            sx={{ fontWeight: "bold", fontSize: "16px" }}
                          >
                            Status
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Chip
                            // style={{ width: "80px" }}
                            label={item?.status ? item?.status : "N/A"}
                            size="small"
                            color={
                              item?.status == "ERROR" ||
                              item?.status == "REJECTED"
                                ? "error"
                                : item?.status == "DONE"
                                ? "success"
                                : item?.status == "PENDING"
                                ? "warning"
                                : "primary"
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: "right" }}>
                          {item?.mrn && item?.mrn != "ENS Confirmed" && (
                            <Tooltip title="Download ENS">
                              <IconButton
                                onClick={() => {
                                  handleDownloadEns(item?.id, item?.mrn);
                                }}
                              >
                                <Icon
                                  style={{
                                    color: theme.palette.secondary.main,
                                  }}
                                  fontSize="large"
                                >
                                  get_app
                                </Icon>
                              </IconButton>
                            </Tooltip>
                          )}
                          <Tooltip title="Open consignment">
                            <IconButton
                              onClick={() => {
                                navigate(
                                  window.location.pathname + "/" + item?.id
                                );
                              }}
                            >
                              <Icon
                                style={{
                                  color: theme.palette.primary.main,
                                }}
                                fontSize="large"
                              >
                                open_in_new
                              </Icon>
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter
              style={{
                position: "fixed",
                bottom: 0,
                zIndex: 5,
                backgroundColor: theme.palette.background.paper,
                width: "100%",
                left: 0,
                right: 0,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <TableRow>
                <TableCell colSpan={20} sx={{ borderBottom: "none" }}>
                  <Grid item xs={12} justifyContent="center"></Grid>
                  <Grid
                    container
                    sx={{
                      marginBottom: "10px",
                    }}
                  >
                    <Grid item xs={12}>
                      <Pagination
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          "& .MuiPagination-ul": {
                            display: "flex",
                            justifyContent: "center",
                            flexGrow: 1,
                          },
                          "& .MuiPaginationItem-root": {
                            fontSize: "24px",
                          },
                          "& .MuiPaginationItem-page": {
                            height: 48,
                            width: 48,
                          },
                          "& .MuiPaginationItem-page.Mui-selected": {
                            backgroundColor: alpha(
                              theme.palette.primary.main,
                              0.5
                            ),
                            color: "white",
                            height: 48,
                            width: 48,
                          },
                          "& .MuiPaginationItem-previousNext": {
                            border: "1px solid",
                            borderColor: theme.palette.lightGray.main,
                            height: 48,
                            width: 48,
                            "& svg": {
                              fontSize: "24px",
                            },
                          },
                        }}
                        count={
                          (data?.totalElements % size === 0
                            ? data?.totalElements / size
                            : Math.floor(data?.totalElements / size) + 1) || 0
                        }
                        shape="rounded"
                        size="small"
                        page={data?.number + 1 || 0}
                        onChange={(event, value) => {
                          handleChangePage(event, value - 1);
                        }}
                      />
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </Grid>

        <Grid
          item
          md={12}
          xs={12}
          sx={{
            height: "78vh",
            overflowY: "auto",
            display: { xs: "none", md: "block" },
          }}
        >
          <Table
            sx={{
              borderCollapse: "separate",
              borderSpacing: " 0 8px",
            }}
          >
            <TableHead
              sx={{
                position: "sticky",
                zIndex: 5,
                top: 0,
                backgroundColor: theme.palette.dashboardBackground.main,
                width: "100%",
              }}
            >
              <TableRow>
                <TableCell
                  style={{
                    textAlign: "left",
                    color: "#8f8f8f",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    borderBottom: "none",
                  }}
                >
                  <Typography
                    variant={isMediumScreen ? "subtitle2" : "subtitle1"}
                  >
                    Created date
                  </Typography>
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "left",
                    color: "#8f8f8f",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    borderBottom: "none",
                  }}
                >
                  <Typography
                    variant={isMediumScreen ? "subtitle2" : "subtitle1"}
                  >
                    Customs office
                  </Typography>
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "left",
                    color: "#8f8f8f",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    borderBottom: "none",
                  }}
                >
                  <Typography
                    noWrap
                    variant={isMediumScreen ? "subtitle2" : "subtitle1"}
                  >
                    Truck/trailer plate
                  </Typography>
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "left",
                    color: "#8f8f8f",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    borderBottom: "none",
                  }}
                >
                  <Typography
                    noWrap
                    variant={isMediumScreen ? "subtitle2" : "subtitle1"}
                  >
                    ETA
                  </Typography>
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "left",
                    color: "#8f8f8f",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    borderBottom: "none",
                  }}
                >
                  <Typography
                    noWrap
                    variant={isMediumScreen ? "subtitle2" : "subtitle1"}
                  >
                    Main Tp. document
                  </Typography>
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "left",
                    color: "#8f8f8f",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    borderBottom: "none",
                  }}
                >
                  <Typography
                    noWrap
                    variant={isMediumScreen ? "subtitle2" : "subtitle1"}
                  >
                    MRN
                  </Typography>
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "center",
                    color: "#8f8f8f",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    borderBottom: "none",
                  }}
                >
                  <Typography
                    noWrap
                    variant={isMediumScreen ? "subtitle2" : "subtitle1"}
                  >
                    Status
                  </Typography>
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "right",
                    color: "#8f8f8f",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    borderBottom: "none",
                  }}
                >
                  <Typography
                    noWrap
                    variant={isMediumScreen ? "subtitle2" : "subtitle1"}
                  >
                    Actions
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data?.totalElements > 0 &&
                data?.content?.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <Typography variant="h5" sx={{ fontWeight: "light" }}>
                          {item?.dateCreated
                            ? moment(item?.dateCreated).format(
                                "DD.MM.YYYY HH:mm"
                              )
                            : "N/A"}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography variant="h5" sx={{ fontWeight: "light" }}>
                          {`${item?.customsOffice?.name} (${item?.customsOffice?.code})`}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h5" sx={{ fontWeight: "light" }}>
                          {`${item?.truckPlateNumber} ${
                            item?.trailerPlateNumber
                              ? "/ " + item?.trailerPlateNumber
                              : ""
                          }`}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography variant="h5" sx={{ fontWeight: "light" }}>
                          {item?.approxArrivalDateAndTime
                            ? moment(item?.approxArrivalDateAndTime).format(
                                "DD.MM.YYYY HH:mm"
                              )
                            : "N/A"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h5" sx={{ fontWeight: "light" }}>
                          {item?.mrnReference
                            ? item?.mrnReference
                            : item?.lrnReference
                            ? item?.lrnReference
                            : item?.ggbReference
                            ? item?.ggbReference
                            : "N/A"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h5" sx={{ fontWeight: "light" }}>
                          {item?.mrn ? item?.mrn : "N/A"}
                        </Typography>
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <Chip
                          style={{ width: "80px" }}
                          label={item?.status ? item?.status : "N/A"}
                          color={
                            item?.status == "ERROR" ||
                            item?.status == "REJECTED"
                              ? "error"
                              : item?.status == "DONE"
                              ? "success"
                              : item?.status == "PENDING"
                              ? "warning"
                              : "primary"
                          }
                        />
                      </TableCell>
                      <TableCell style={{ textAlign: "right" }}>
                        {item?.mrn && item?.mrn != "ENS Confirmed" && (
                          <Tooltip title="Download ENS">
                            <IconButton
                              onClick={() => {
                                handleDownloadEns(item?.id, item?.mrn);
                              }}
                            >
                              <Icon
                                style={{
                                  color: theme.palette.secondary.main,
                                }}
                                fontSize="medium"
                              >
                                get_app
                              </Icon>
                            </IconButton>
                          </Tooltip>
                        )}
                        <Tooltip title="Open consignment">
                          <IconButton
                            onClick={() => {
                              navigate(
                                window.location.pathname + "/" + item?.id
                              );
                            }}
                          >
                            <Icon
                              style={{
                                color: theme.palette.primary.main,
                              }}
                              fontSize="medium"
                            >
                              open_in_new
                            </Icon>
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>

            <TableFooter
              style={{
                position: "fixed",
                bottom: 0,
                zIndex: 5,
                backgroundColor: theme.palette.dashboardBackground.main,
                width: "100%",
              }}
            >
              <Grid container spacing={1} sx={{ display: "flex" }}>
                <Grid item xs={4}>
                  <TablePagination
                    sx={{
                      borderBottom: "none",
                      "& .MuiTablePagination-select": {
                        fontSize: isMediumScreen ? "12px" : "18px",
                        border: "1px solid",
                        borderColor: theme.palette.lightGray.main,
                        padding: isMediumScreen ? "5px 8px" : "5px 15px",
                        borderRadius: "4px",
                      },
                      "& .MuiTablePagination-displayedRows": {
                        fontSize: isMediumScreen ? "15px" : "20px",
                      },
                      "& .MuiTablePagination-actions button": {
                        fontSize: isMediumScreen ? "15px" : "20px",
                      },
                      "& .MuiIconButton-root": {
                        fontSize: isMediumScreen ? "15px" : "20px",
                      },
                      "& .MuiSelect-icon": {
                        fontSize: isMediumScreen ? "15px" : "20px",
                      },
                      "& .MuiTablePagination-displayedRows": {
                        marginLeft: "-15px",
                        fontSize: isMediumScreen ? "12px" : "15px",
                        color: theme.palette.lightGray.main,
                      },
                    }}
                    rowsPerPageOptions={
                      AuthRepository.hasAnyRole(["ROLE_ADMINISTRATION"])
                        ? [5, 10, 15, 25, 50, 75, 100]
                        : [5, 10, 15, 25]
                    }
                    rowsPerPage={params?.size ? params?.size : size}
                    labelDisplayedRows={() => `of ${data?.totalElements}`}
                    labelRowsPerPage={
                      <span
                        style={{
                          fontSize: isMediumScreen ? "12px" : "15px",
                          marginLeft: "-20px",
                          color: theme.palette.lightGray.main,
                        }}
                      >
                        {LOCALE.rows_per_page}
                      </span>
                    }
                    SelectProps={{
                      inputProps: {
                        fontSize: isMediumScreen ? "15px" : "20px",
                        "aria-label": LOCALE.rows_per_page,
                      },
                      native: true,
                    }}
                    onRowsPerPageChange={(event, value) => {
                      handleChangeRowsPerPage(event);
                    }}
                    page={data?.number || 0}
                    count={data?.totalElements || 0}
                    size="large"
                    ActionsComponent={() => null}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Pagination
                    sx={{
                      marginTop: "15px",
                      display: "flex",
                      justifyContent: "center",
                      "& .MuiPaginationItem-root": {
                        fontSize: isMediumScreen ? "15px" : "20px",
                      },
                      "& .MuiPaginationItem-page.Mui-selected": {
                        backgroundColor: theme.palette.secondary.main,
                        opacity: "50%",
                        color: "white",
                      },
                      "& .MuiPaginationItem-previousNext": {
                        border: "1px solid",
                        borderColor: theme.palette.lightGray.main,
                      },
                    }}
                    count={
                      (data?.totalElements % size === 0
                        ? data?.totalElements / size
                        : Math.floor(data?.totalElements / size) + 1) || 0
                    }
                    shape="rounded"
                    size={isMediumScreen ? "medium" : "large"}
                    page={data?.number + 1 || 0}
                    onChange={(event, value) => {
                      handleChangePage(event, value - 1);
                    }}
                  />
                </Grid>
              </Grid>
            </TableFooter>
          </Table>
        </Grid>
      </Grid>
      <Dialog
        open={openDateCreatedCalendar}
        onClose={() => {
          setOpenDateCreatedCalendar(false);
        }}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        maxWidth={isSmallScreen ? "xs" : "lg"}
        hideBackdrop={true}
        style={{ textAlign: "right", marginTop: "90px" }}
      >
        <DateCreatedCalendar
          setOpenDateCreatedCalendar={setOpenDateCreatedCalendar}
          openDateCreatedCalendar={openDateCreatedCalendar}
          handleDateCreated={handleDateCreated}
          dateCreatedState={dateCreatedState}
          setDateCreatedState={setDateCreatedState}
        ></DateCreatedCalendar>
      </Dialog>
      <Outlet />
    </>
  );
};
