import Axios from "axios";
import { SETTINGS } from "../properties/Settings";

export const SettingsRepository = {
  fetchPropByKey: (key) => {
    return Axios({
      url: SETTINGS.API_URL + "settings/prop",
      method: "GET",
      params: {
        key: key,
      },
    });
  },
};
