import {
  Autocomplete,
  Button,
  createFilterOptions,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Box, useTheme } from "@mui/system";
import React, { useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { LOCALE } from "../../properties/Locale";
import { DestinationCitiesRepository } from "../../repositories/DestinationCitiesRepository";
import { DestinationsRepository } from "../../repositories/DestinationsRepository";
import OpenLayerExplore from "../map/OpenLayerExplore";

const filterOptions = createFilterOptions({
  matchFrom: "any",
  stringify: (option) =>
    option?.name +
    option?.country?.nameEn +
    option?.ports?.map((port) => port?.name),
});

export const Explore = ({}) => {
  const theme = useTheme();
  const [data, setData] = useState({});
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [fromCities, setFromCities] = useState([]);
  const [toCities, setToCities] = useState([]);
  const [toCityCallback, setToCityCallback] = useState();
  const [fromCitiesSearch, setFromCitiesSearch] = useState("");
  const [toCitiesSearch, setToCitiesSearch] = useState("");

  useEffect(() => {
    let tmp = { ...data };
    tmp.fromCity = undefined;
    tmp.toCountry = undefined;
    tmp.toCity = undefined;
    setData(tmp);
    loadAllCities("from");
  }, []);

  const handleChangeData = (name, value) => {
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name && value) {
      setFormIsDirty(true);
    }
  };

  const loadAllCities = (direction) => {
    DestinationCitiesRepository.fetchAllWithoutPaging().then((response) => {
      if (direction == "from") {
        setFromCities(
          response.data.sort((a, b) => a.name.localeCompare(b.name))
        );
      } else {
      }
    });
  };

  useEffect(() => {
    if (data.fromCity) {
      getAllAvailableCityDestination({
        "fromCity.id": data.fromCity.id,
      });
    }
  }, [data.fromCity]);

  const getAllAvailableCityDestination = (params) => {
    DestinationsRepository.getAllAvailableCityDestination({
      searchParams: params,
    }).then((response) => {
      setToCities(response.data.sort((a, b) => a.name.localeCompare(b.name)));
    });
  };

  const handleRedirect = () => {
    window.parent.postMessage(
      {
        type: "REDIRECT",
        path:
          "/routes/" +
          data?.fromCity?.name?.toLowerCase() +
          "-" +
          data?.toCity?.name?.toLowerCase(),
      },
      "*"
    );
  };

  return (
    <>
      <Box sx={{ position: "relative", width: "100%" }}>
        <Paper
          elevation={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "30vw",
            mx: "auto",
            p: 3,
            borderRadius: 3,
            boxShadow: 3,
            backgroundColor: "white",
            position: "absolute",
            top: "40%",
            left: "25%",
            transform: "translateX(-50%)",
            zIndex: 10,
          }}
        >
          <Typography variant="h4" fontWeight={600} gutterBottom>
            Route details
          </Typography>
          <br></br>

          <Box sx={{ display: "flex", gap: 2, width: "100%", mb: 2 }}>
            <Autocomplete
              autoFocus={true}
              fullWidth
              noOptionsText={LOCALE.noOptionsText}
              autoComplete={false}
              size="small"
              value={data?.fromCity ? data?.fromCity : ""}
              onChange={(event, newValue) => {
                handleChangeData("fromCity", newValue);
              }}
              inputValue={fromCitiesSearch ? fromCitiesSearch : ""}
              onInputChange={(event, newInputValue) => {
                setFromCitiesSearch(newInputValue);
              }}
              options={fromCities}
              filterOptions={filterOptions}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              renderOption={(props, city) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <ReactCountryFlag
                    countryCode={city.country.code}
                    svg
                    style={{
                      width: "1.6em",
                      borderRadius: "8px",

                      height: "1.6em",
                    }}
                    title={city.country.name}
                  />
                  <b>
                    {city.name} / {city.country.nameEn}
                  </b>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  autoFocus={true}
                  {...params}
                  label={
                    data?.fromCity
                      ? ""
                      : fromCitiesSearch
                      ? ""
                      : LOCALE.from_city
                  }
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: false,
                  }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: data.fromCity ? (
                      <ReactCountryFlag
                        countryCode={data.fromCity.country.code}
                        svg
                        style={{
                          width: "1.6em",
                          height: "1.6em",
                          borderRadius: "8px",
                          marginRight: "4px",
                          marginLeft: "10px",
                        }}
                        title={data.fromCity.country.name}
                      />
                    ) : null,
                    endAdornment: params.InputProps.endAdornment,
                    style: {
                      fontWeight: "bold",
                    },
                  }}
                />
              )}
            />

            <Autocomplete
              noOptionsText={LOCALE.noOptionsText}
              autoComplete={false}
              size="small"
              fullWidth
              value={data?.toCity ? data?.toCity : ""}
              onChange={(event, newValue) => {
                handleChangeData("toCity", newValue);
              }}
              inputValue={toCitiesSearch ? toCitiesSearch : ""}
              onInputChange={(event, newInputValue) => {
                setToCitiesSearch(newInputValue);
              }}
              options={toCities}
              filterOptions={filterOptions}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              renderOption={(props, city) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <ReactCountryFlag
                    countryCode={city.country.code}
                    svg
                    style={{
                      width: "1.6em",
                      borderRadius: "8px",

                      height: "1.6em",
                    }}
                    title={city.country.name}
                  />
                  <b>
                    {city.name} / {city.country.nameEn}{" "}
                  </b>
                  {city.ports && city.ports?.length > 0 && (
                    <span>
                      {" "}
                      / ({city.ports.map((port) => port.name).join("), (")})
                    </span>
                  )}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    data?.toCity ? "" : toCitiesSearch ? "" : LOCALE.to_city
                  }
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: false,
                  }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: data.toCity ? (
                      <ReactCountryFlag
                        countryCode={data.toCity.country.code}
                        svg
                        style={{
                          width: "1.6em",
                          height: "1.6em",
                          borderRadius: "8px",

                          marginRight: "4px",
                          marginLeft: "10px",
                        }}
                        title={data.toCity.country.name}
                      />
                    ) : null,
                    endAdornment: params.InputProps.endAdornment,
                    style: {
                      fontWeight: "bold",
                    },
                  }}
                />
              )}
            />
          </Box>

          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleRedirect}
            disabled={!data.fromCity || !data.toCity}
            sx={{
              backgroundColor: theme.palette.primary.main,
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
          >
            Explore route
          </Button>
        </Paper>
        <OpenLayerExplore
          handleChangeData={handleChangeData}
          setData={setData}
          data={data}
          fromCities={fromCities}
          toCityCallback={toCityCallback}
          setToCityCallback={(value) => setToCityCallback(value)}
        />
      </Box>
    </>
  );
};
