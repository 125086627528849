import * as yup from "yup";

export const ConsignmentValidator = yup.object().shape({
  transportMode: yup.string().required("Transport mode is required"),
  customsOffice: yup
    .object()
    .shape({
      id: yup.string().required("Customs office is required"),
    })
    .nullable()
    .required("Customs office is required"),
  approxArrivalDateAndTime: yup
    .date()
    .required("Approximate arrival date and time is required"),
  truckPlateNationality: yup
    .object()
    .shape({
      name: yup.string().required("Truck plate nationality is required"),
    })
    .nullable()
    .required("Truck plate nationality is required"),
  truckPlateNumber: yup.string().required("Truck plate number is required"),
  // trailerPlateNumber: yup.string().required("Trailer plate number is required"),
  // imoNumber: yup.string().required("IMO number is required"),
  accompanied: yup.boolean().required("Accompanied status is required"),
  // customsStatus: yup.string().required("Customs status is required"),
  selectedReferenceType: yup.string().oneOf(["MRN", "Other"]).required(),
  mrnReference: yup.string().when("selectedReferenceType", {
    is: (val) => val === "MRN",
    then: () =>
      yup
        .string()
        .required("MRN reference is required")
        // only alphanumeric characters exactly 18 characters long
        .matches(
          /^[a-zA-Z0-9]*$/,
          "MRN reference must contain only alphanumeric characters"
        )
        .max(18, "MRN reference must be at most 18 characters")
        .nullable(),
  }),
  lrnReference: yup.string().when("selectedReferenceType", {
    is: (val) => val === "LRN",
    then: () => yup.string().required("LRN reference is required"),
    otherwise: () => yup.string().nullable(),
  }),

  consignor: yup
    .object()
    .shape({
      name: yup
        .string()
        .required("Consignor name is required")
        .matches(
          /^[a-zA-Z0-9\s\/\-\,\.\']+$/,
          "Consignor name contain only letters (wo diacritics), numbers, spaces, slashes, dashes, commas, dots"
        )
        .max(35, "Consignor name be at most 35 characters"),
      country: yup
        .object()
        .shape({
          name: yup.string().required("Consignor country is required"),
        })
        .nullable()
        .required("Consignor country is required"),
      city: yup
        .object()
        .shape({
          cityName: yup.string().required("Consignor city is required"),
        })
        .nullable()
        .required("Consignor city is required"),
      postCode: yup
        .string()
        .required("Consignor post code is required")
        // without blank spaces
        .matches(/^\S*$/, "Post code cannot contain blank spaces"),
      addressFlagConsignor: yup
        .boolean()
        .required("Consignor address flag is required"),
      address: yup.string().when("addressFlagConsignor", {
        is: (val) => val === true,
        then: () =>
          yup
            .string()
            .required("Consignor address is required")
            .max(30, "Consignor address must be at most 30 characters")
            .matches(
              /^[a-zA-Z0-9\s\/\-\,\.\']+$/,
              "Consignor address must contain only letters (wo diacritics), numbers, spaces, slashes, dashes, commas, dots"
            ),
        otherwise: () => yup.string().nullable(),
      }),
      addressNumber: yup.string().when("addressFlagConsignor", {
        is: (val) => val === true,
        then: () =>
          yup
            .string()
            .required("Consignor address number is required")
            .max(5, "Consignor address number must be at most 5 characters")
            .matches(
              /^[a-zA-Z0-9\s\/\-\,\.\']+$/,
              "Consignor address number must contain only letters (wo diacritics), numbers, spaces, slashes, dashes, commas, dots"
            ),
        otherwise: () => yup.string().nullable(),
      }),
      poBoxNumber: yup.string().when("addressFlagConsignor", {
        is: (val) => val === false,
        then: () =>
          yup.string().required("Consignor PO Box number is required"),
        otherwise: () => yup.string().nullable(),
      }),
    })
    .nullable()
    .required("Consignor details are required"),
  seller: yup
    .object()
    .shape({
      name: yup
        .string()
        .required("Seller name is required")
        .matches(
          /^[a-zA-Z0-9\s\/\-\,\.\']+$/,
          "Seller name contain only letters (wo diacritics), numbers, spaces, slashes, dashes, commas, dots"
        )
        .max(35, "Seller name be at most 35 characters"),
      country: yup
        .object()
        .shape({
          name: yup.string().required("Seller country is required"),
        })
        .nullable()
        .required("Seller country is required"),
      city: yup
        .object()
        .shape({
          cityName: yup.string().required("Seller city is required"),
        })
        .nullable()
        .required("Seller city is required"),
      postCode: yup
        .string()
        .required("Seller post code is required")
        .matches(/^\S*$/, "Post code cannot contain blank spaces"),
      address: yup.string().when("addressFlagSeller", {
        is: (val) => val === true,
        then: () =>
          yup
            .string()
            .required("Seller address is required")
            .max(30, "Seller address must be at most 30 characters")
            .matches(
              /^[a-zA-Z0-9\s\/\-\,\.\']+$/,
              "Seller address must contain only letters (wo diacritics), numbers, spaces, slashes, dashes, commas, dots"
            ),
        otherwise: () => yup.string().nullable(),
      }),
      addressNumber: yup.string().when("addressFlagSeller", {
        is: (val) => val === true,
        then: () =>
          yup
            .string()
            .required("Seller address number is required")
            .max(5, "Seller address number must be at most 5 characters")
            .matches(
              /^[a-zA-Z0-9\s\/\-\,\.\']+$/,
              "Seller address number must contain only letters (wo diacritics), numbers, spaces, slashes, dashes, commas, dots"
            ),
        otherwise: () => yup.string().nullable(),
      }),
      poBoxNumber: yup.string().when("addressFlagSeller", {
        is: () => false,
        then: () => yup.string().required("Seller PO Box number is required"),
        otherwise: () => yup.string().nullable(),
      }),
      eori: yup.string().nullable(),
    })
    .nullable()
    .required("Seller details are required"),
  buyer: yup
    .object()
    .shape({
      name: yup
        .string()
        .required("Buyer name is required")
        .matches(
          /^[a-zA-Z0-9\s\/\-\,\.\']+$/,
          "Buyer name contain only letters (wo diacritics), numbers, spaces, slashes, dashes, commas, dots"
        )
        .max(35, "Buyer name be at most 35 characters"),
      country: yup
        .object()
        .shape({
          name: yup.string().required("Buyer country is required"),
        })
        .nullable()
        .required("Buyer country is required"),
      city: yup
        .object()
        .shape({
          cityName: yup.string().required("Buyer city is required"),
        })
        .nullable()
        .required("Buyer city is required"),
      postCode: yup
        .string()
        .required("Buyer post code is required")
        .matches(/^\S*$/, "Post code cannot contain blank spaces"),
      address: yup.string().when("addressFlagBuyer", {
        is: (val) => val === true,
        then: () =>
          yup
            .string()
            .required("Buyer address is required")
            .max(30, "Buyer address must be at most 30 characters")
            .matches(
              /^[a-zA-Z0-9\s\/\-\,\.\']+$/,
              "Buyer address must contain only letters (wo diacritics), numbers, spaces, slashes, dashes, commas, dots"
            ),
        otherwise: () => yup.string().nullable(),
      }),
      addressNumber: yup.string().when("addressFlagBuyer", {
        is: (val) => val === true,
        then: () =>
          yup
            .string()
            .required("Buyer address number is required")
            .max(5, "Buyer address number must be at most 5 characters")
            .matches(
              /^[a-zA-Z0-9\s\/\-\,\.\']+$/,
              "Buyer address number must contain only letters (wo diacritics), numbers, spaces, slashes, dashes, commas, dots"
            ),
        otherwise: () => yup.string().nullable(),
      }),
      poBoxNumber: yup.string().when("addressFlagBuyer", {
        is: () => false,
        then: () => yup.string().required("Buyer PO Box number is required"),
        otherwise: () => yup.string().nullable(),
      }),
      eori: yup.string().nullable(),
    })
    .nullable()
    .required("Buyer details are required"),
  consignee: yup
    .object()
    .shape({
      name: yup
        .string()
        .required("Consignee name is required")
        .matches(
          /^[a-zA-Z0-9\s\/\-\,\.\']+$/,
          "Consignee name contain only letters (wo diacritics), numbers, spaces, slashes, dashes, commas, dots"
        )
        .max(35, "Consignee name be at most 35 characters"),
      country: yup
        .object()
        .shape({
          name: yup.string().required("Consignee country is required"),
        })
        .nullable()
        .required("Consignee country is required"),
      city: yup
        .object()
        .shape({
          cityName: yup.string().required("Consignee city is required"),
        })
        .nullable()
        .required("Consignee city is required"),
      postCode: yup
        .string()
        .required("Consignee post code is required")
        .matches(/^\S*$/, "Post code cannot contain blank spaces"),
      address: yup.string().when("addressFlagConsignee", {
        is: (val) => val === true,
        then: () =>
          yup
            .string()
            .required("Consignee address is required")
            .max(30, "Consignee address must be at most 30 characters")
            .matches(
              /^[a-zA-Z0-9\s\/\-\,\.\']+$/,
              "Consignee address must contain only letters (wo diacritics), numbers, spaces, slashes, dashes, commas, dots"
            ),
        otherwise: () => yup.string().nullable(),
      }),
      addressNumber: yup.string().when("addressFlagConsignee", {
        is: (val) => val === true,
        then: () =>
          yup
            .string()
            .required("Consignee address number is required")
            .max(5, "Consignee address number must be at most 5 characters")
            .matches(
              /^[a-zA-Z0-9\s\/\-\,\.\']+$/,
              "Consignee address number must contain only letters (wo diacritics), numbers, spaces, slashes, dashes, commas, dots"
            ),
        otherwise: () => yup.string().nullable(),
      }),
      poBoxNumber: yup.string().when("addressFlagConsignee", {
        is: () => false,
        then: () =>
          yup.string().required("Consignee PO Box number is required"),
        otherwise: () => yup.string().nullable(),
      }),
    })
    .nullable()
    .required("Consignee details are required"),
  goodsList: yup
    .array()
    .of(
      yup.object().shape({
        numberOfPackages: yup
          .number()
          .required("Number of packages is required")
          .min(1, "Number of packages must be at least 1"),
        packageType: yup
          .object()
          .shape({
            code: yup.string().required("Package type code is required"),
            name: yup.string().required("Package type name is required"),
          })
          .nullable()
          .required("Package type is required"),
        hsCode: yup
          .string()
          .required("HS code is required")
          .min(6, "HS code must be at least 6 characters"),
        // description is required and max 255 characters
        description: yup
          .string()
          .required("Description is required")
          .max(255, "Description must be at most 255 characters")
          .matches(
            /^[a-zA-Z0-9\s\/\-\,\.\']+$/,
            "Description must contain only letters (wo diacritics), numbers, spaces, slashes, dashes, commas, dots"
          ),
        grossWeight: yup
          .number()
          .required("Gross weight is required")
          .min(0, "Gross weight must be a positive number")
          .test(
            "is-decimal",
            "Gross weight cannot contain decimal point",
            (value) => {
              return value % 1 === 0;
            }
          ),
      })
    )
    .min(1, "At least one goods row is required"),
});
