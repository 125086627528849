import {
  Alert,
  alpha,
  Box,
  Chip,
  Grid,
  Icon,
  IconButton,
  Paper,
  Popover,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import EastIcon from "@mui/icons-material/East";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useParams } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { ProductRepository } from "../../repositories/ProductRepository";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
export default function AvailableComplexDeparturesCalendarMobile({
  availableDepartures,
  data,
  handleChangeData,
  selectedDepartures,
  setSelectedDepartures,
  itemKey,
  nextItemKey,
  availableComplexDepartures,
  setAvailableComplexDepartures,
  setSelectedComplexDepartureKey,
  selectedComplexDepartureKey,
  complexTickets,
  handleChangeSearchParams,
  setComplexTickets,
  selectedProductDestinations,
  ticketGroup,
  showDeparturesForNextYear,
}) {
  const selectedDate = useRef(undefined);
  const availableComplexDeparturesForNextLeg = useRef(undefined);
  const [checkDate, setCheckDate] = useState();
  const [currentDate, setCurrentDate] = useState(
    moment(complexTickets[itemKey]?.departureDay)
  );
  const [openCalendar, setOpenCalendar] = useState(false);
  const [selectedDateCalendar, setSelectedDateCalendar] = useState(
    complexTickets[itemKey]?.departureDay
      ? moment(complexTickets[itemKey]?.departureDay)
      : null
  );
  const theme = useTheme();
  const monthHeader = currentDate.format("MMMM");
  const yearHeader = currentDate.format("YYYY");

  useEffect(() => {
    setCheckDate(data?.departureDay);
    complexTickets[itemKey].departureDay = data?.departureDay;
  }, []);

  useEffect(() => {
    setCurrentDate(moment(complexTickets[itemKey]?.departureDay));
  }, [complexTickets[itemKey]?.departureDay]);

  const handleDateChange = (date) => {
    let tmpComplexTickets = { ...complexTickets };
    console.log(date);
    tmpComplexTickets[itemKey].departureDay = date;
    setComplexTickets(tmpComplexTickets);
    let tmpSelectedDepartures = { ...selectedDepartures };
    delete tmpSelectedDepartures[itemKey];
    setSelectedDepartures(tmpSelectedDepartures);
    getAvailableComplexDepartures(date);
    setCurrentDate(moment(date));
  };

  const handleDateChangeForTheNextLeg = (
    date,
    departure,
    tmpSelectedDepartures
  ) => {
    let tmpComplexTickets = { ...complexTickets };
    tmpComplexTickets[nextItemKey].departureDay = date;
    setComplexTickets(tmpComplexTickets);
    delete tmpSelectedDepartures[nextItemKey];
    getAvailableComplexDeparturesForNextLeg(
      date,
      departure,
      tmpSelectedDepartures
    );
    setCurrentDate(moment(date)); // Update currentDate state
  };

  const recursiveFindFirstPossibleDepartureDateForTheNextLeg = (
    departure,
    tmpSelectedDepartures
  ) => {
    if (!availableComplexDeparturesForNextLeg.current) {
      availableComplexDeparturesForNextLeg.current =
        availableComplexDepartures[
          nextItemKey
        ]?.productAvailableDeparturesDTOList;
    }
    let flag = false;
    if (availableComplexDeparturesForNextLeg.current?.length == 0) {
      flag = true;
    } else {
      availableComplexDeparturesForNextLeg.current?.forEach((nextDeparutre) => {
        flag = checkDepartureHourOffsetForNextLeg(
          nextDeparutre,
          tmpSelectedDepartures
        );
      });
    }
    if (!selectedDate.current) {
      selectedDate.current = moment(departure.departureDate, "YYYY-MM-DD");
    } else if (
      selectedDate.current.isBefore(
        moment(departure.departureDate, "YYYY-MM-DD")
      )
    ) {
      selectedDate.current = moment(departure.departureDate, "YYYY-MM-DD");
    }
    //check if selectedDate.current is more then 30 days after departure date
    if (
      selectedDate.current.diff(
        moment(departure.departureDate, "YYYY-MM-DD"),
        "days"
      ) > 30
    ) {
      return;
    }
    if (flag) {
      let tmpDate = selectedDate.current;
      tmpDate = moment(tmpDate).add(1, "days");
      selectedDate.current = tmpDate;
      handleDateChangeForTheNextLeg(
        tmpDate.format("YYYY-MM-DD"),
        departure,
        tmpSelectedDepartures
      );
    } else {
      selectedDate.current = undefined;
    }
  };

  const selectClickHandler = (departure) => {
    console.log(departure);
    let tmp = { ...selectedDepartures };
    tmp[itemKey] = departure;
    setSelectedDepartures(tmp);
    recursiveFindFirstPossibleDepartureDateForTheNextLeg(departure, tmp);
  };

  const getAvailableComplexDepartures = (date) => {
    let loadedValue;
    if (data?.loaded === "2") {
      loadedValue = true;
    } else if (data?.loaded === "1") {
      loadedValue = false;
    } else {
      loadedValue = data?.loaded;
    }
    ProductRepository.getAvailableComplexDepartures({
      vehicleLength: data.vehicleLength,
      vehicleWidth: data.vehicleWidth,
      vehicleHeight: data.vehicleHeight,
      numberOfDrivers: data.numberOfDrivers,
      cargoWeight: data.cargoWeight,
      material: data?.material?.id,
      loaded: loadedValue,
      dangerousGoods: data.dangerousGoods,
      electricPlugin: data.electricPlugin,
      liveAnimals: data.liveAnimals,
      fromCity: data?.product?.isComplex
        ? data?.product?.mainDestination?.fromCity?.id
        : data?.fromCity?.id,
      toCity: data?.product?.isComplex
        ? data?.product?.mainDestination?.toCity?.id
        : data?.toCity?.id,
      departureDate: moment(date).format("YYYY-MM-DD"),
      numberOfAxles: data?.numberOfAxles,
      organizationId: data?.organization?.id,
      ticketStatus: data?.status,
    })
      .then((res) => {
        let tmp = { ...availableComplexDepartures };

        if (tmp) {
          tmp[itemKey] = res.data[selectedComplexDepartureKey][itemKey];
        }
        setAvailableComplexDepartures(tmp);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAvailableComplexDeparturesForNextLeg = (
    date,
    departure,
    tmpSelectedDepartures
  ) => {
    let loadedValue;
    if (data?.loaded === "2") {
      loadedValue = true;
    } else if (data?.loaded === "1") {
      loadedValue = false;
    } else {
      loadedValue = data?.loaded;
    }
    ProductRepository.getAvailableComplexDepartures({
      vehicleLength: data.vehicleLength,
      vehicleWidth: data.vehicleWidth,
      vehicleHeight: data.vehicleHeight,
      numberOfDrivers: data.numberOfDrivers,
      cargoWeight: data.cargoWeight,
      material: data?.material?.id,
      loaded: loadedValue,
      dangerousGoods: data.dangerousGoods,
      electricPlugin: data.electricPlugin,
      liveAnimals: data.liveAnimals,
      fromCity: data?.product?.isComplex
        ? data?.product?.mainDestination?.fromCity?.id
        : data?.fromCity?.id,
      toCity: data?.product?.isComplex
        ? data?.product?.mainDestination?.toCity?.id
        : data?.toCity?.id,
      departureDate: moment(date).format("YYYY-MM-DD"),
      numberOfAxles: data?.numberOfAxles,
      organizationId: data?.organization?.id,
      ticketStatus: data?.status,
    })
      .then((res) => {
        let tmp = { ...availableComplexDepartures };

        if (tmp) {
          tmp[nextItemKey] = res.data[selectedComplexDepartureKey][nextItemKey];
        }
        setAvailableComplexDepartures(tmp);
        availableComplexDeparturesForNextLeg.current =
          tmp[nextItemKey]?.productAvailableDeparturesDTOList;
        recursiveFindFirstPossibleDepartureDateForTheNextLeg(
          departure,
          tmpSelectedDepartures
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkDepartureHourOffset = (departure) => {
    let currentLegNumber = availableComplexDepartures[itemKey].legNumber;
    // get the first char of current leg number
    let currentLegNumberFirstChar = currentLegNumber.toString().charAt(0);
    let previousLegNumber = parseInt(currentLegNumberFirstChar) - 1;
    if (previousLegNumber == 0) {
      return false;
    }
    // console.log(previousLegNumber);
    //find all departures with leg number starting with previousLegNumber
    let previousLegDepartures = Object.keys(availableComplexDepartures).filter(
      (key) =>
        availableComplexDepartures[key].legNumber
          .toString()
          .startsWith(previousLegNumber.toString())
    );
    // console.log(previousLegDepartures);
    let previousLegDeparture =
      availableComplexDepartures[previousLegDepartures[0]];
    // console.log(previousLegDeparture);
    //find which departure is selected in previous leg
    let previousLegSelectedDeparture =
      selectedDepartures[previousLegDepartures[0]];
    // console.log(previousLegSelectedDeparture);
    //find the arrivalTime of selected departure in previous leg
    let previousLegSelectedDepartureArrivalTime = moment(
      previousLegSelectedDeparture?.arrivalTime,
      "HH:mm:ss"
    ).format("HH:mm:ss");
    // console.log(previousLegSelectedDepartureArrivalTime);
    //find the departureTime of selected departure in current leg
    let currentLegSelectedDepartureDepartureTime = moment(
      departure?.departureTime,
      "HH:mm:ss"
    ).format("HH:mm:ss");
    //get the departure day of the selected departure in previous leg and set the time to the arrival time of selected departure in previous leg
    let previousLegSelectedDepartureDepartureDay = moment(
      previousLegSelectedDeparture?.departureDate,
      "YYYY-MM-DD HH:mm:ss"
    ).format("YYYY-MM-DD");
    let previousLegSelectedDepartureDepartureDayWithArrivalTime = moment(
      previousLegSelectedDepartureDepartureDay +
        " " +
        previousLegSelectedDepartureArrivalTime,
      "YYYY-MM-DD HH:mm:ss"
    );
    // console.log(previousLegSelectedDepartureDepartureDayWithArrivalTime);
    //get the departure day of the selected departure in current leg and set the time to the departure time of selected departure in current leg
    let currentLegSelectedDepartureDepartureDay = moment(
      departure?.departureDate,
      "YYYY-MM-DD HH:mm:ss"
    ).format("YYYY-MM-DD");
    let currentLegSelectedDepartureDepartureDayWithDepartureTime = moment(
      currentLegSelectedDepartureDepartureDay +
        " " +
        currentLegSelectedDepartureDepartureTime,
      "YYYY-MM-DD HH:mm:ss"
    );
    // console.log(currentLegSelectedDepartureDepartureDayWithDepartureTime);
    //check if the departure time of selected departure in current leg is after the arrival time of selected departur in previous leg plus the offset of the previous leg selected departure
    if (
      currentLegSelectedDepartureDepartureDayWithDepartureTime.isAfter(
        previousLegSelectedDepartureDepartureDayWithArrivalTime.add(
          departure?.departureHourOffset,
          "hours"
        )
      )
    ) {
      // console.log("ok");
      return false;
    }
    // console.log("not ok");
    return true;
  };

  const getAvailableComplexDeparturesForFirstLeg = async (date) => {
    try {
      let loadedValue;
      if (data?.loaded === "2") {
        loadedValue = true;
      } else if (data?.loaded === "1") {
        loadedValue = false;
      } else {
        loadedValue = data?.loaded;
      }

      const res = await ProductRepository.getAvailableComplexDepartures({
        vehicleLength: data.vehicleLength,
        vehicleWidth: data.vehicleWidth,
        vehicleHeight: data.vehicleHeight,
        numberOfDrivers: data.numberOfDrivers,
        cargoWeight: data.cargoWeight,
        material: data?.material?.id,
        loaded: loadedValue,
        dangerousGoods: data.dangerousGoods,
        electricPlugin: data.electricPlugin,
        liveAnimals: data.liveAnimals,
        fromCity: data?.product?.isComplex
          ? data?.product?.mainDestination?.fromCity?.id
          : data?.fromCity?.id,
        toCity: data?.product?.isComplex
          ? data?.product?.mainDestination?.toCity?.id
          : data?.toCity?.id,
        departureDate: moment(date).format("YYYY-MM-DD"),
        numberOfAxles: data?.numberOfAxles,
        organizationId: data?.organization?.id,
        ticketStatus: data?.status,
      });

      let tmp = { ...availableComplexDepartures };

      if (tmp) {
        tmp[itemKey] = res.data[selectedComplexDepartureKey][itemKey];
      }

      return tmp[itemKey].productAvailableDeparturesDTOList;
    } catch (err) {
      console.log(err);
    }
  };

  const checkDepartureHourOffsetForNextLeg = (
    departure,
    tmpSelectedDepartures
  ) => {
    let currentLegNumber = availableComplexDepartures[nextItemKey].legNumber;
    // get the first char of current leg number
    let currentLegNumberFirstChar = currentLegNumber.toString().charAt(0);
    let previousLegNumber = parseInt(currentLegNumberFirstChar) - 1;
    if (previousLegNumber == 0) {
      return false;
    }
    // console.log(previousLegNumber);
    //find all departures with leg number starting with previousLegNumber
    let previousLegDepartures = Object.keys(availableComplexDepartures).filter(
      (key) =>
        availableComplexDepartures[key].legNumber
          .toString()
          .startsWith(previousLegNumber.toString())
    );
    // console.log(previousLegDepartures);
    let previousLegDeparture =
      availableComplexDepartures[previousLegDepartures[0]];
    // console.log(previousLegDeparture);
    //find which departure is selected in previous leg
    // console.log(selectedDepartures);
    let previousLegSelectedDeparture =
      tmpSelectedDepartures[previousLegDepartures[0]];
    // console.log(previousLegSelectedDeparture);
    //find the arrivalTime of selected departure in previous leg
    let previousLegSelectedDepartureArrivalTime = moment(
      previousLegSelectedDeparture?.arrivalTime,
      "HH:mm:ss"
    ).format("HH:mm:ss");
    // console.log(previousLegSelectedDepartureArrivalTime);
    //find the departureTime of selected departure in current leg
    let currentLegSelectedDepartureDepartureTime = moment(
      departure?.departureTime,
      "HH:mm:ss"
    ).format("HH:mm:ss");
    //get the departure day of the selected departure in previous leg and set the time to the arrival time of selected departure in previous leg
    let previousLegSelectedDepartureDepartureDay = moment(
      previousLegSelectedDeparture?.departureDate,
      "YYYY-MM-DD HH:mm:ss"
    ).format("YYYY-MM-DD");
    let previousLegSelectedDepartureDepartureDayWithArrivalTime = moment(
      previousLegSelectedDepartureDepartureDay +
        " " +
        previousLegSelectedDepartureArrivalTime,
      "YYYY-MM-DD HH:mm:ss"
    );
    // console.log(previousLegSelectedDepartureDepartureDayWithArrivalTime);
    //get the departure day of the selected departure in current leg and set the time to the departure time of selected departure in current leg
    let currentLegSelectedDepartureDepartureDay = moment(
      departure?.departureDate,
      "YYYY-MM-DD HH:mm:ss"
    ).format("YYYY-MM-DD");
    let currentLegSelectedDepartureDepartureDayWithDepartureTime = moment(
      currentLegSelectedDepartureDepartureDay +
        " " +
        currentLegSelectedDepartureDepartureTime,
      "YYYY-MM-DD HH:mm:ss"
    );
    // console.log(currentLegSelectedDepartureDepartureDayWithDepartureTime);
    //check if the departure time of selected departure in current leg is after the arrival time of selected departur in previous leg plus the offset of the previous leg selected departure
    if (
      currentLegSelectedDepartureDepartureDayWithDepartureTime.isAfter(
        previousLegSelectedDepartureDepartureDayWithArrivalTime.add(
          departure?.departureHourOffset,
          "hours"
        )
      )
    ) {
      // console.log("ok");
      return false;
    }
    // console.log("not ok");
    return true;
  };

  // useEffect(() => {
  //   console.log(availableDepartures);
  //   console.log(availableComplexDepartures);
  // }, [availableDepartures, availableComplexDepartures]);

  const checkClosestNextDate = async (date) => {
    let found = false;
    for (let i = 1; i <= 30; i++) {
      const currentDate = moment(date)
        .add(i, "days")
        .format("YYYY-MM-DD HH:mm:ss");
      const departures = await getAvailableComplexDeparturesForFirstLeg(
        currentDate
      );
      console.log(departures);
      if (departures && departures.length > 0) {
        handleDateChange(currentDate);
        found = true;
        return;
      }
    }
    if (!found) {
      handleDateChange(
        moment(date).add(30, "days").format("YYYY-MM-DD HH:mm:ss")
      );
    }
  };

  const checkClosestPreviousDate = async (date) => {
    let found = false;
    for (let i = 1; i <= 30; i++) {
      const currentDate = moment(date)
        .subtract(i, "days")
        .format("YYYY-MM-DD HH:mm:ss");
      const departures = await getAvailableComplexDeparturesForFirstLeg(
        currentDate
      );
      console.log(departures);
      if (departures && departures.length > 0) {
        handleDateChange(currentDate);
        found = true;
        return;
      }
    }
    if (!found) {
      handleDateChange(
        moment(date).subtract(30, "days").format("YYYY-MM-DD HH:mm:ss")
      );
    }
  };

  const selectedCalendarDate = moment(
    complexTickets[itemKey]?.departureDay
  )?.format("YYYY-MM-DD HH:mm:ss");

  const calculateTotalSurcharges = () => {
    return (
      selectedDepartures[itemKey]?.ticketSurchargeList?.reduce(
        (total, surcharge) => {
          return (
            total +
            surcharge.price *
              (selectedDepartures[itemKey]?.ticketSurchargeList.find(
                (s) => s.productSurcharge.id === surcharge.productSurcharge.id
              )?.quantity || surcharge.quantity)
          );
        },
        0
      ) || 0
    );
  };

  useEffect(() => {
    if (complexTickets[itemKey]?.departureDay) {
      setSelectedDateCalendar(moment(complexTickets[itemKey]?.departureDay));
    }
  }, [complexTickets, itemKey]);

  useEffect(() => {
    console.log("Selected Date Calendar:", selectedDateCalendar);
    console.log(
      "Complex Tickets Departure Day:",
      complexTickets[itemKey]?.departureDay
    );
  }, [selectedDateCalendar, complexTickets[itemKey]?.departureDay]);
  const handleDateChangeCalendar = (newValue) => {
    const date = moment(newValue);
    setSelectedDateCalendar(date);
    handleDateChange(date.format("YYYY-MM-DD HH:mm:ss"));
    setOpenCalendar(false);
  };

  const checkPlusMonth = () => {
    const futureDate = currentDate.clone().add(1, "month");
    handleDateChange(futureDate.format("YYYY-MM-DD HH:mm:ss"));
  };

  const checkMinusMonth = () => {
    const previousDate = currentDate.clone().subtract(1, "month");
    handleDateChange(previousDate.format("YYYY-MM-DD HH:mm:ss"));
  };

  return (
    <>
      <Grid
        container
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Grid item xs={2} style={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="h6" style={{ padding: "10px" }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Tooltip title="Select Date">
                <IconButton
                  onClick={() => setOpenCalendar(true)}
                  style={{
                    border: "solid 1px ",
                    borderColor: alpha(theme.palette.lightGray.main, 0.4),
                    borderRadius: "4px",
                  }}
                >
                  <CalendarMonthOutlinedIcon
                    style={{ fontSize: "21px", color: "black" }}
                  />
                </IconButton>
              </Tooltip>
              <DatePicker
                open={openCalendar}
                onClose={() => setOpenCalendar(false)}
                value={selectedDateCalendar}
                onChange={handleDateChangeCalendar}
                renderInput={(params) => <input type="hidden" {...params} />}
                sx={{
                  display: "none",
                  position: "absolute",
                  top: "100%",
                  left: "100px",
                  zIndex: 1300,
                }}
                shouldDisableDate={(date) => {
                  const currentYearEnd = moment().endOf("year");
                  return (
                    !showDeparturesForNextYear &&
                    moment(date).isAfter(currentYearEnd, "day")
                  );
                }}
              />
            </LocalizationProvider>
          </Typography>
        </Grid>
        <Grid item xs={7} style={{ display: "flex", justifyContent: "center" }}>
          <Typography
            style={{
              padding: "10px",
              display: "flex",
              alignItems: "center",
              ...theme.typography.h6,
            }}
          >
            <IconButton onClick={checkMinusMonth} style={{ paddingRight: 10 }}>
              <ArrowBackIosNewIcon style={{ fontSize: "14px" }} />
            </IconButton>
            <span
              style={{
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              {monthHeader}
            </span>
            <IconButton onClick={checkPlusMonth} style={{ paddingLeft: 10 }}>
              <ArrowForwardIosOutlinedIcon style={{ fontSize: "14px" }} />
            </IconButton>
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Typography variant="h6" style={{ marginTop: "3px" }}>
            <span
              style={{
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              {" "}
              {yearHeader}
            </span>
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Table
          elevation={1}
          style={{
            borderCollapse: "separate",
            borderSpacing: " 0 10px",
            marginTop: "-10px",
          }}
        >
          <TableHead>
            <TableRow component="div">
              <Grid>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "96%",
                    borderBottom: `3px solid ${alpha(
                      theme.palette.lightGray.main,
                      0.2
                    )}`,
                  }}
                >
                  <Tabs
                    indicatorColor="transparent"
                    value={selectedCalendarDate}
                    onChange={(event, value) => {
                      handleDateChange(moment(value));
                    }}
                    variant="scrollable"
                    scrollButtons="auto"
                    sx={{
                      backgroundColor: theme.palette.background.paper,
                      clipPath: "inset(0 round 0.5em)",
                      position: "relative",
                      zIndex: 1,
                      padding: 0,
                    }}
                  >
                    {[-2, -1, 0, 1, 2].map((i) => {
                      const tabDate = moment(
                        complexTickets[itemKey]?.departureDay
                      )
                        ?.clone()
                        ?.add(i, "days")
                        ?.format("YYYY-MM-DD HH:mm:ss");

                      const isSelected = tabDate === selectedCalendarDate;
                      const isTwoBefore = i === -2;
                      const isTwoAfter = i === 2;

                      return (
                        <Tab
                          key={i}
                          label={moment(complexTickets[itemKey]?.departureDay)
                            ?.clone()
                            ?.add(i, "days")
                            ?.format("D dddd")}
                          value={tabDate}
                          style={{
                            flex: "1 0 auto",
                            minWidth: 0,
                            color: isSelected
                              ? theme.palette.background.default
                              : isTwoBefore || isTwoAfter
                              ? "gray"
                              : "inherit",
                            opacity: isTwoBefore || isTwoAfter ? 0.8 : "",
                            backgroundColor: isSelected
                              ? theme.palette.primary.main
                              : "transparent",
                            borderRadius: "5px",
                            fontWeight: "bold",
                            height: "48px",
                            borderRight: "2px solid white",
                            opacity: isTwoBefore || isTwoAfter ? 0.8 : 1,
                            borderRadius: "0.5em",
                            margin: "0",
                            padding: "9px",
                            display: "flex",
                            fontSize: isSelected ? "11px" : "9px",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        />
                      );
                    })}
                  </Tabs>
                </Box>
              </Grid>
            </TableRow>
          </TableHead>
          <TableBody>
            {availableDepartures && availableDepartures.length > 0 ? (
              availableDepartures
                ?.slice()
                ?.sort((a, b) => {
                  if (a.supplier?.id === b.supplier?.id) {
                    return (
                      new Date(`${a.departureDate}T${a.departureTime}`) -
                      new Date(`${b.departureDate}T${b.departureTime}`)
                    );
                  }

                  return a.supplier?.id - b.supplier?.id;
                })
                .map((departure) => (
                  <>
                    {departure?.productDestinationDepartureId ===
                    selectedDepartures[itemKey]
                      ?.productDestinationDepartureId ? (
                      <TableRow
                        key={departure.productDestinationDepartureId}
                        style={{
                          height: "48px",
                          borderSpacing: "0 10px",
                          borderLeft: "0",
                          borderRight: "0",
                        }}
                      >
                        <TableCell colSpan={12} style={{ border: "none" }}>
                          <Grid
                            container
                            direction="column"
                            spacing={2}
                            mb={-1}
                          >
                            <Grid item xs>
                              <Box
                                sx={{
                                  backgroundColor: "#88c078",
                                  color: "white",
                                  border: "none",
                                  boxShadow: "none",
                                  position: "relative",
                                  padding: "0 10px",
                                  borderRadius: "6px 6px  0 0",
                                }}
                              >
                                <Box
                                  sx={{
                                    backgroundColor: "#88c078",
                                    color: "white",
                                    padding: "0 15px",
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                  }}
                                >
                                  <Grid container spacing={2}>
                                    <Grid
                                      item
                                      xs={5}
                                      style={{ marginLeft: "-10px" }}
                                    >
                                      <Typography
                                        variant="h6"
                                        sx={{ opacity: "50%" }}
                                      >
                                        Departure
                                      </Typography>
                                      <Typography
                                        sx={{
                                          fontWeight: "bold",
                                          fontSize: "14px",
                                          marginTop: "-5px",
                                        }}
                                      >
                                        {departure?.departureTime}
                                      </Typography>
                                      <Typography
                                        style={{
                                          marginTop: "-5px",
                                        }}
                                      >
                                        {moment(
                                          departure?.departureDate
                                        ).format("DD.MM.YYYY")}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={1}
                                      sx={{
                                        alignContent: "center",
                                        marginLeft: "-15px",
                                      }}
                                    >
                                      <Typography variant="h5">
                                        <EastIcon
                                          style={{
                                            fontSize: "24px",
                                            marginLeft: "10px",
                                          }}
                                        />
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={5}
                                      style={{
                                        marginLeft: "10px",
                                        marginRight: "10px",
                                      }}
                                    >
                                      <Typography
                                        variant="h6"
                                        sx={{
                                          opacity: "50%",
                                          marginLeft: "50px",
                                        }}
                                      >
                                        Arrival
                                      </Typography>
                                      <Typography
                                        sx={{
                                          fontSize: "14px",
                                          fontWeight: "bold",
                                          marginLeft: "50px",
                                          marginTop: "-5px",
                                        }}
                                      >
                                        {departure?.arrivalTime}
                                      </Typography>
                                      <Typography
                                        style={{
                                          marginLeft: "50px",
                                          marginTop: "-5px",
                                        }}
                                      >
                                        {departure?.arrivalDay == "0"
                                          ? moment(
                                              departure?.departureDate
                                            ).format("DD.MM.YYYY")
                                          : departure?.arrivalDay == "1"
                                          ? moment(departure?.departureDate)
                                              .add("days", 1)
                                              .format("DD.MM.YYYY")
                                          : departure?.arrivalDay == "2"
                                          ? moment(departure?.departureDate)
                                              .add("days", 2)
                                              .format("DD.MM.YYYY")
                                          : ""}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                      <Icon
                                        style={{
                                          verticalAlign: "bottom",
                                          fontSize: "30px",
                                          marginTop: "5px",
                                        }}
                                        color="white"
                                      >
                                        radio_button_checked
                                      </Icon>
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    container
                                    style={{
                                      marginTop: "15px",
                                      marginBottom: "15px",
                                    }}
                                  >
                                    <Grid
                                      item
                                      xs={2}
                                      style={{
                                        marginLeft: "-5px",
                                        marginTop: "3px",
                                      }}
                                    >
                                      <Tooltip title={departure?.level}>
                                        {departure?.levelValue === "MEDIUM" && (
                                          <Icon
                                            style={{
                                              verticalAlign: "middle",
                                              height: "24px",
                                              width: "24px",
                                            }}
                                          >
                                            <svg
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <g clip-path="url(#clip0_504_7214)">
                                                <rect
                                                  x="5.50012"
                                                  y="11"
                                                  width="3"
                                                  height="6"
                                                  rx="1"
                                                  fill="#E9B145"
                                                  style={{
                                                    fill: "#E9B145",
                                                    fill: "white",
                                                    fillOpacity: 1,
                                                  }}
                                                />
                                                <rect
                                                  x="10.5001"
                                                  y="9"
                                                  width="3"
                                                  height="8"
                                                  rx="1"
                                                  fill="#E9B145"
                                                  style={{
                                                    fill: "#E9B145",
                                                    fill: "white",
                                                    fillOpacity: 1,
                                                  }}
                                                />
                                                <rect
                                                  x="15.5001"
                                                  y="7"
                                                  width="3"
                                                  height="10"
                                                  rx="1"
                                                  fill="#E9B145"
                                                  fill-opacity="0.25"
                                                  style={{
                                                    fill: "#E9B145",
                                                    fill: "white",
                                                    fillOpacity: 0.25,
                                                  }}
                                                />
                                              </g>
                                              <rect
                                                x="0.500122"
                                                y="0.5"
                                                width="23"
                                                height="23"
                                                rx="3.5"
                                                stroke="#E9B145"
                                                style={{
                                                  stroke: "#E9B145",
                                                  stroke: "white",
                                                  strokeOpacity: 1,
                                                }}
                                              />
                                              <defs>
                                                <clipPath id="clip0_504_7214">
                                                  <rect
                                                    x="0.00012207"
                                                    width="24"
                                                    height="24"
                                                    rx="4"
                                                    fill="white"
                                                    style={{
                                                      fill: "white",
                                                      fillOpacity: 1,
                                                    }}
                                                  />
                                                </clipPath>
                                              </defs>
                                            </svg>
                                          </Icon>
                                        )}
                                      </Tooltip>
                                      <Tooltip title={departure?.level}>
                                        {" "}
                                        {departure?.levelValue === "HIGH" && (
                                          <Icon
                                            style={{
                                              verticalAlign: "middle",
                                              height: "24px",
                                              width: "24px",
                                            }}
                                          >
                                            <svg
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <g clip-path="url(#clip0_504_7228)">
                                                <rect
                                                  x="5.50012"
                                                  y="11"
                                                  width="3"
                                                  height="6"
                                                  rx="1"
                                                  fill="white"
                                                  style={{
                                                    fill: "white",
                                                    fill: "white",
                                                    fillOpacity: 1,
                                                  }}
                                                />
                                                <rect
                                                  x="10.5001"
                                                  y="9"
                                                  width="3"
                                                  height="8"
                                                  rx="1"
                                                  fill="white"
                                                  style={{
                                                    fill: "white",
                                                    fill: "white",
                                                    fillOpacity: 1,
                                                  }}
                                                />
                                                <rect
                                                  x="15.5001"
                                                  y="7"
                                                  width="3"
                                                  height="10"
                                                  rx="1"
                                                  fill="white"
                                                  style={{
                                                    fill: "white",
                                                    fill: "white",
                                                    fillOpacity: 1,
                                                  }}
                                                />
                                              </g>
                                              <rect
                                                x="0.500122"
                                                y="0.5"
                                                width="23"
                                                height="23"
                                                rx="3.5"
                                                stroke="white"
                                                style={{
                                                  stroke: "white",
                                                  stroke: "white",
                                                  strokeOpacity: 1,
                                                }}
                                              />
                                              <defs>
                                                <clipPath id="clip0_504_7228">
                                                  <rect
                                                    x="0.00012207"
                                                    width="24"
                                                    height="24"
                                                    rx="4"
                                                    fill="white"
                                                    style={{
                                                      fill: "white",
                                                      fillOpacity: 1,
                                                    }}
                                                  />
                                                </clipPath>
                                              </defs>
                                            </svg>
                                          </Icon>
                                        )}
                                      </Tooltip>
                                      <Tooltip title={departure?.level}>
                                        {departure?.levelValue === "LOW" && (
                                          <Icon
                                            style={{
                                              verticalAlign: "middle",
                                              height: "24px",
                                              width: "24px",
                                            }}
                                          >
                                            <svg
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <g clip-path="url(#clip0_504_7314)">
                                                <rect
                                                  x="5.5"
                                                  y="11"
                                                  width="3"
                                                  height="6"
                                                  rx="1"
                                                  fill="white"
                                                  style={{
                                                    fill: "white",
                                                    fillOpacity: 1,
                                                  }}
                                                />
                                                <rect
                                                  x="10.5"
                                                  y="9"
                                                  width="3"
                                                  height="8"
                                                  rx="1"
                                                  fill="white"
                                                  fill-opacity="0.25"
                                                  style={{
                                                    fill: "white",
                                                    fillOpacity: 0.25,
                                                  }}
                                                />
                                                <rect
                                                  x="15.5"
                                                  y="7"
                                                  width="3"
                                                  height="10"
                                                  rx="1"
                                                  fill="white"
                                                  fill-opacity="0.25"
                                                  style={{
                                                    fill: "white",
                                                    fillOpacity: 0.25,
                                                  }}
                                                />
                                              </g>
                                              <rect
                                                x="0.5"
                                                y="0.5"
                                                width="23"
                                                height="23"
                                                rx="3.5"
                                                stroke="white"
                                                style={{
                                                  stroke: "white",
                                                  strokeOpacity: 1,
                                                }}
                                              />
                                              <defs>
                                                <clipPath id="clip0_504_7314">
                                                  <rect
                                                    width="24"
                                                    height="24"
                                                    rx="4"
                                                    fill="white"
                                                    style={{
                                                      fill: "white",
                                                      fillOpacity: 1,
                                                    }}
                                                  />
                                                </clipPath>
                                              </defs>
                                            </svg>
                                          </Icon>
                                        )}
                                      </Tooltip>
                                    </Grid>

                                    <Grid item xs={7}>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        {data?.organization?.showSupplier && (
                                          <Typography
                                            sx={{
                                              fontWeight: "bold",
                                              whiteSpace: "nowrap",
                                              overflow: "visible",
                                              flexShrink: 0,
                                              fontSize: "14px",
                                              marginLeft: "-12px",
                                            }}
                                          >
                                            {departure?.operatorName
                                              ? departure?.operatorName
                                              : departure?.supplier?.name}
                                          </Typography>
                                        )}
                                        <Typography
                                          sx={{
                                            fontSize: "10px",
                                            color: "white",
                                            marginTop: data?.organization
                                              ?.showSupplier
                                              ? "-7px"
                                              : "5px",
                                            marginLeft: "-12px",
                                          }}
                                        >
                                          {departure?.level}
                                        </Typography>
                                      </div>
                                    </Grid>
                                    <Grid item xs={3}>
                                      {departure &&
                                      departure?.salesPrice != null &&
                                      departure?.price != null ? (
                                        <Typography
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "17px",
                                            marginTop: "7px",
                                          }}
                                          align="center"
                                        >
                                          {data?.adjustedCalculatedPrice ? (
                                            <>
                                              {(
                                                parseFloat(
                                                  data.adjustedCalculatedPrice
                                                ) +
                                                calculateTotalSurcharges() -
                                                (departure?.surchargesIncludedInSalesPrice ||
                                                  0)
                                              ).toFixed(2)}
                                              &euro;
                                            </>
                                          ) : (
                                            <>
                                              {(
                                                (departure?.salesPrice || 0) +
                                                calculateTotalSurcharges() -
                                                (departure?.surchargesIncludedInSalesPrice ||
                                                  0)
                                              ).toFixed(2)}
                                              &euro;
                                            </>
                                          )}
                                        </Typography>
                                      ) : (
                                        ""
                                      )}
                                    </Grid>
                                  </Grid>
                                </Box>
                                <Box sx={{ padding: "0 10px 0 20px" }}>
                                  <Grid container spacing={2}>
                                    <Grid item md={12}>
                                      <Grid
                                        container
                                        spacing={2}
                                        sx={{
                                          backgroundColor:
                                            "rgba(160, 205, 147, 0.1)",
                                          borderRadius: "5px",
                                          minWidth: "285px",
                                          overflow: "hidden",
                                        }}
                                      >
                                        {departure?.productDestinationDepartureId ==
                                          selectedDepartures[itemKey]
                                            ?.productDestinationDepartureId &&
                                          departure?.ticketSurchargeList?.map(
                                            (surcharge, index) => (
                                              <Grid
                                                item
                                                xs={12}
                                                key={
                                                  surcharge?.productDestinationDepartureId
                                                }
                                                sx={{
                                                  height: "60px",
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                  backgroundColor:
                                                    index % 2 !== 0
                                                      ? "rgba(160, 205, 147, 0.3)"
                                                      : "rgba(160, 205, 147, 0.6)",
                                                  alignItems: "center",

                                                  padding: "10px",
                                                }}
                                              >
                                                <Typography
                                                  sx={{
                                                    flex: 1,
                                                    ...theme.typography.h6,
                                                  }}
                                                >
                                                  {surcharge.surchargeName}
                                                </Typography>
                                                {/* {data?.organization
                                                  ?.showPrice && (
                                                  <Typography
                                                    sx={{
                                                      flex: 1,
                                                      textAlign: "center",
                                                      ...theme.typography.h6,
                                                      marginLeft: "15px",
                                                    }}
                                                  >
                                                    <input
                                                      disabled={
                                                        surcharge?.disabled
                                                      }
                                                      value={
                                                        selectedDepartures[
                                                          itemKey
                                                        ]?.ticketSurchargeList?.find(
                                                          (s) =>
                                                            s.productSurcharge
                                                              .id ==
                                                            surcharge
                                                              .productSurcharge
                                                              .id
                                                        )?.quantity
                                                          ? selectedDepartures[
                                                              itemKey
                                                            ]?.ticketSurchargeList?.find(
                                                              (s) =>
                                                                s
                                                                  .productSurcharge
                                                                  .id ==
                                                                surcharge
                                                                  .productSurcharge
                                                                  .id
                                                            )?.quantity
                                                          : surcharge?.quantity
                                                      }
                                                      onChange={(e) => {
                                                        let newDeparture = {
                                                          ...selectedDepartures[
                                                            itemKey
                                                          ],
                                                        };
                                                        newDeparture.ticketSurchargeList.find(
                                                          (s) =>
                                                            s.productSurcharge
                                                              .id ==
                                                            surcharge
                                                              .productSurcharge
                                                              .id
                                                        ).quantity =
                                                          e.target.valueAsNumber;
                                                        newDeparture.ticketSurchargeList.find(
                                                          (s) =>
                                                            s.productSurcharge
                                                              .id ==
                                                            surcharge
                                                              .productSurcharge
                                                              .id
                                                        ).total =
                                                          e.target
                                                            .valueAsNumber *
                                                          surcharge.price;
                                                        let tmp = {
                                                          ...selectedDepartures,
                                                        };
                                                        tmp[itemKey] =
                                                          newDeparture;
                                                        setSelectedDepartures(
                                                          tmp
                                                        );
                                                      }}
                                                      size="small"
                                                      type={"number"}
                                                      style={{
                                                        border: "none",
                                                        backgroundColor:
                                                          "transparent",
                                                        width: "30px",
                                                        color: "white",
                                                      }}
                                                    />
                                                  </Typography>
                                                )}
                                                {data?.organization
                                                  ?.showPrice && (
                                                  <Typography
                                                    sx={{
                                                      flex: 1,
                                                      textAlign: "center",
                                                      ...theme.typography.h6,
                                                      marginLeft: "-15px",
                                                    }}
                                                  >
                                                    x
                                                  </Typography>
                                                )}
                                                {data?.organization
                                                  ?.showPrice && (
                                                  <Typography
                                                    sx={{
                                                      flex: 1,
                                                      textAlign: "center",
                                                      ...theme.typography.h6,
                                                    }}
                                                  >
                                                    {surcharge.price?.toFixed(
                                                      2
                                                    )}
                                                    &euro;
                                                  </Typography>
                                                )}
                                                {data?.organization
                                                  ?.showPrice && (
                                                  <Typography
                                                    sx={{
                                                      flex: 1,
                                                      textAlign: "center",
                                                      ...theme.typography.h6,
                                                      marginLeft: "5px",
                                                      marginRight: "5px",
                                                    }}
                                                  >
                                                    =
                                                  </Typography>
                                                )} */}
                                                {departure &&
                                                departure?.salesPrice != null &&
                                                departure?.price != null ? (
                                                  <Typography
                                                    sx={{
                                                      flex: 1,
                                                      textAlign: "center",
                                                      ...theme.typography.h6,
                                                    }}
                                                  >
                                                    {parseFloat(
                                                      surcharge.price *
                                                        surcharge.quantity
                                                    ).toFixed(2)}
                                                    &euro;
                                                  </Typography>
                                                ) : (
                                                  ""
                                                )}
                                              </Grid>
                                            )
                                          )}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Box>
                              <Grid item>
                                <Box
                                  sx={{
                                    backgroundColor: "#88c078",
                                    color: "white",
                                    border: "none",
                                    boxShadow: "none",
                                    padding: "10px 15px",
                                    borderRadius: "  0 0 6px 6px",
                                  }}
                                >
                                  {departure &&
                                  departure?.salesPrice != null &&
                                  departure?.price != null ? (
                                    <Grid
                                      container
                                      spacing={2}
                                      justifyContent="center"
                                    >
                                      <Grid item xs={4}>
                                        <Typography
                                          style={{
                                            marginLeft: "-30px",
                                            fontSize: "12px",
                                          }}
                                          align="center"
                                        >
                                          Total Freight
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={2}>
                                        <Typography
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "11px",
                                            marginLeft: "-37px",
                                            border: "1px solid white",
                                            height: "20px",
                                            width: "60px",
                                            borderRadius: "4px",
                                            backgroundColor: "white",
                                            padding: 1,
                                            color: "#88c078",
                                            // marginTop: 10,
                                          }}
                                          align="center"
                                        >
                                          {data?.adjustedCalculatedPrice ? (
                                            <>
                                              {parseFloat(
                                                data?.adjustedCalculatedPrice
                                              )?.toFixed(2)}
                                              &euro;
                                            </>
                                          ) : (
                                            <>
                                              {departure?.salesPrice?.toFixed(
                                                2
                                              )}
                                              &euro;
                                            </>
                                          )}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={4}>
                                        <Typography
                                          style={{
                                            marginLeft: "-7px",
                                            fontSize: "12px",
                                          }}
                                          align="center"
                                        >
                                          Total Surcharges
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={2}>
                                        <Typography
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "11px",
                                            marginLeft: "-15px",
                                            border: "1px solid white",
                                            borderRadius: "4px",
                                            height: "20px",
                                            width: "60px",
                                            backgroundColor: "white",
                                            padding: 1,
                                            color: "#88c078",
                                            // marginTop: 10,
                                          }}
                                          align="center"
                                        >
                                          <>
                                            {" "}
                                            {parseFloat(
                                              calculateTotalSurcharges()
                                            ).toFixed(2)}
                                            &euro;
                                          </>
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  ) : (
                                    ""
                                  )}
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow
                        key={departure.productDestinationDepartureId}
                        style={{
                          backgroundColor:
                            departure?.productDestinationDepartureId ==
                            selectedDepartures[itemKey]
                              ?.productDestinationDepartureId
                              ? "#4caf5033"
                              : alpha(theme.palette.lightGray.main, 0.1),
                          height: "48px",
                          borderSpacing: "0 10px",
                          borderLeft: "0",
                          borderRight: "0",
                        }}
                      >
                        <TableCell
                          style={{
                            borderTopLeftRadius: "10px",
                            borderBottomLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                            borderBottomRightRadius: "10px",
                          }}
                        >
                          {departure?.productDestinationDepartureId !=
                            selectedDepartures[itemKey]
                              ?.productDestinationDepartureId && (
                            <Grid container>
                              {checkDepartureHourOffset(departure) && (
                                <>
                                  <Grid item xs={1}>
                                    <Icon style={{ color: "#54B4D3" }}>
                                      info
                                    </Icon>
                                  </Grid>
                                  <Grid item xs={11}>
                                    <Typography
                                      style={{
                                        fontSize: "10px",
                                        color: "#54B4D3",
                                        marginLeft: "-5px",
                                      }}
                                    >
                                      Departure has departure hour offset.
                                      Please choose another date.
                                    </Typography>
                                  </Grid>
                                </>
                              )}
                            </Grid>
                          )}
                          <Grid container spacing={2}>
                            <Grid item xs={4}>
                              <Typography style={{ marginLeft: "10px" }}>
                                Departure{" "}
                              </Typography>
                            </Grid>
                            <Grid item xs={5}>
                              <Typography style={{ marginLeft: "100px" }}>
                                Arrival
                              </Typography>
                            </Grid>
                            {departure?.productDestinationDeparture
                              ?.openTicket ? (
                              <Grid item>Open ticket</Grid>
                            ) : (
                              <Grid />
                            )}
                            <Grid item xs={3}>
                              {departure?.productDestinationDepartureId ==
                                selectedDepartures[itemKey]
                                  ?.productDestinationDepartureId && (
                                <Icon
                                  style={{
                                    verticalAlign: "bottom",
                                    marginLeft: "20px",
                                    fontSize: "30px",
                                  }}
                                  color="success"
                                >
                                  radio_button_checked
                                </Icon>
                              )}
                              {departure?.productDestinationDepartureId !=
                                selectedDepartures[itemKey]
                                  ?.productDestinationDepartureId && (
                                <>
                                  <IconButton
                                    disabled={checkDepartureHourOffset(
                                      departure
                                    )}
                                    style={{
                                      verticalAlign: "bottom",
                                      cursor: "pointer",
                                    }}
                                    color="black"
                                    onClick={() => {
                                      selectClickHandler(departure);
                                    }}
                                  >
                                    <Icon
                                      style={{
                                        fontSize: "30px",
                                        marginLeft: "20px",
                                      }}
                                    >
                                      radio_button_unchecked
                                    </Icon>
                                  </IconButton>
                                </>
                              )}
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item xs={5}>
                              {" "}
                              <Typography
                                variant="subtitle1"
                                style={{
                                  ...theme.typography.h6,
                                  marginLeft: "10px",
                                  marginTop: "-30px",
                                }}
                              >
                                {departure.departureTime}
                              </Typography>
                            </Grid>
                            <Grid item xs={2}>
                              {" "}
                              <Typography
                                variant="subtitle1"
                                style={{
                                  ...theme.typography.h6,
                                  marginLeft: "-5px",
                                  marginTop: "-30px",
                                }}
                              >
                                <EastIcon style={{}} />
                              </Typography>
                            </Grid>
                            <Grid item xs={5}>
                              <Typography
                                variant="subtitle1"
                                style={{
                                  ...theme.typography.h6,
                                  marginLeft: "18px",
                                  marginTop: "-30px",
                                }}
                              >
                                {departure.arrivalTime}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item xs={5}>
                              <Typography
                                variant="subtitle2"
                                style={{
                                  ...theme.typography.h6,
                                  marginTop: "-17px",
                                  marginLeft: "10px",
                                }}
                              >
                                {moment(departure.departureDate).format(
                                  "DD.MM.YY"
                                )}
                              </Typography>
                            </Grid>
                            <Grid item xs={5}>
                              <Typography
                                variant="subtitle2"
                                style={{
                                  ...theme.typography.h6,
                                  marginLeft: "72px",
                                  marginTop: "-15px",
                                }}
                              >
                                {departure.arrivalDay === "0"
                                  ? moment(departure.departureDate).format(
                                      "DD.MM.YY"
                                    )
                                  : departure.arrivalDay === "1"
                                  ? moment(departure.departureDate)
                                      .add(1, "days")
                                      .format("DD.MM.YY")
                                  : departure.arrivalDay === "2"
                                  ? moment(departure.departureDate)
                                      .add(2, "days")
                                      .format("DD.MM.YY")
                                  : ""}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item xs={1} style={{ marginTop: "10px" }}>
                              <Typography
                                variant="subtitle2"
                                style={{
                                  marginLeft: "10px",
                                  marginTop: "10px",
                                }}
                              >
                                <Tooltip title={departure?.level}>
                                  {departure?.levelValue === "MEDIUM" && (
                                    <Icon
                                      style={{
                                        verticalAlign: "middle",
                                        height: "24px",
                                        width: "24px",
                                      }}
                                    >
                                      <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <g clip-path="url(#clip0_504_7214)">
                                          <rect
                                            x="5.50012"
                                            y="11"
                                            width="3"
                                            height="6"
                                            rx="1"
                                            fill="#E9B145"
                                            style={{
                                              fill: "#E9B145",
                                              fill: "color(display-p3 0.9137 0.6941 0.2706)",
                                              fillOpacity: 1,
                                            }}
                                          />
                                          <rect
                                            x="10.5001"
                                            y="9"
                                            width="3"
                                            height="8"
                                            rx="1"
                                            fill="#E9B145"
                                            style={{
                                              fill: "#E9B145",
                                              fill: "color(display-p3 0.9137 0.6941 0.2706)",
                                              fillOpacity: 1,
                                            }}
                                          />
                                          <rect
                                            x="15.5001"
                                            y="7"
                                            width="3"
                                            height="10"
                                            rx="1"
                                            fill="#E9B145"
                                            fill-opacity="0.25"
                                            style={{
                                              fill: "#E9B145",
                                              fill: "color(display-p3 0.9137 0.6941 0.2706)",
                                              fillOpacity: 0.25,
                                            }}
                                          />
                                        </g>
                                        <rect
                                          x="0.500122"
                                          y="0.5"
                                          width="23"
                                          height="23"
                                          rx="3.5"
                                          stroke="#E9B145"
                                          style={{
                                            stroke: "#E9B145",
                                            stroke:
                                              "color(display-p3 0.9137 0.6941 0.2706)",
                                            strokeOpacity: 1,
                                          }}
                                        />
                                        <defs>
                                          <clipPath id="clip0_504_7214">
                                            <rect
                                              x="0.00012207"
                                              width="24"
                                              height="24"
                                              rx="4"
                                              fill="white"
                                              style={{
                                                fill: "white",
                                                fillOpacity: 1,
                                              }}
                                            />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    </Icon>
                                  )}
                                </Tooltip>
                                <Tooltip title={departure?.level}>
                                  {" "}
                                  {departure?.levelValue === "HIGH" && (
                                    <Icon
                                      style={{
                                        verticalAlign: "middle",
                                        height: "24px",
                                        width: "24px",
                                      }}
                                    >
                                      <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <g clip-path="url(#clip0_504_7228)">
                                          <rect
                                            x="5.50012"
                                            y="11"
                                            width="3"
                                            height="6"
                                            rx="1"
                                            fill="#E15D5D"
                                            style={{
                                              fill: "#E15D5D",
                                              fill: "color(display-p3 0.8824 0.3647 0.3647)",
                                              fillOpacity: 1,
                                            }}
                                          />
                                          <rect
                                            x="10.5001"
                                            y="9"
                                            width="3"
                                            height="8"
                                            rx="1"
                                            fill="#E15D5D"
                                            style={{
                                              fill: "#E15D5D",
                                              fill: "color(display-p3 0.8824 0.3647 0.3647)",
                                              fillOpacity: 1,
                                            }}
                                          />
                                          <rect
                                            x="15.5001"
                                            y="7"
                                            width="3"
                                            height="10"
                                            rx="1"
                                            fill="#E15D5D"
                                            style={{
                                              fill: "#E15D5D",
                                              fill: "color(display-p3 0.8824 0.3647 0.3647)",
                                              fillOpacity: 1,
                                            }}
                                          />
                                        </g>
                                        <rect
                                          x="0.500122"
                                          y="0.5"
                                          width="23"
                                          height="23"
                                          rx="3.5"
                                          stroke="#E15D5D"
                                          style={{
                                            stroke: "#E15D5D",
                                            stroke:
                                              "color(display-p3 0.8824 0.3647 0.3647)",
                                            strokeOpacity: 1,
                                          }}
                                        />
                                        <defs>
                                          <clipPath id="clip0_504_7228">
                                            <rect
                                              x="0.00012207"
                                              width="24"
                                              height="24"
                                              rx="4"
                                              fill="white"
                                              style={{
                                                fill: "white",
                                                fillOpacity: 1,
                                              }}
                                            />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    </Icon>
                                  )}
                                </Tooltip>
                                <Tooltip title={departure?.level}>
                                  {departure?.levelValue === "LOW" && (
                                    <Icon
                                      style={{
                                        verticalAlign: "middle",
                                        height: "24px",
                                        width: "24px",
                                      }}
                                    >
                                      <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <g clip-path="url(#clip0_504_7200)">
                                          <rect
                                            x="5.50012"
                                            y="11"
                                            width="3"
                                            height="6"
                                            rx="1"
                                            fill="#88C078"
                                            style={{
                                              fill: "#88C078",
                                              fill: "color(display-p3 0.5333 0.7529 0.4706)",
                                              fillOpacity: 1,
                                            }}
                                          />
                                          <rect
                                            x="10.5001"
                                            y="9"
                                            width="3"
                                            height="8"
                                            rx="1"
                                            fill="#88C078"
                                            fill-opacity="0.25"
                                            style={{
                                              fill: "#88C078",
                                              fill: "color(display-p3 0.5333 0.7529 0.4706)",
                                              fillOpacity: 0.25,
                                            }}
                                          />
                                          <rect
                                            x="15.5001"
                                            y="7"
                                            width="3"
                                            height="10"
                                            rx="1"
                                            fill="#88C078"
                                            fill-opacity="0.25"
                                            style={{
                                              fill: "#88C078",
                                              fill: "color(display-p3 0.5333 0.7529 0.4706)",
                                              fillOpacity: 0.25,
                                            }}
                                          />
                                        </g>
                                        <rect
                                          x="0.500122"
                                          y="0.5"
                                          width="23"
                                          height="23"
                                          rx="3.5"
                                          stroke="#88C078"
                                          style={{
                                            stroke: "#88C078",
                                            stroke:
                                              "color(display-p3 0.5333 0.7529 0.4706)",
                                            strokeOpacity: 1,
                                          }}
                                        />
                                        <defs>
                                          <clipPath id="clip0_504_7200">
                                            <rect
                                              x="0.00012207"
                                              width="24"
                                              height="24"
                                              rx="4"
                                              fill="white"
                                              style={{
                                                fill: "white",
                                                fillOpacity: 1,
                                              }}
                                            />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    </Icon>
                                  )}
                                </Tooltip>
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              {data?.organization?.showSupplier && (
                                <>
                                  <Typography
                                    variant="subtitle2"
                                    style={{
                                      marginLeft: "20px",
                                      marginTop: "15px",
                                      fontWeight: "bold",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {departure?.operatorName
                                      ? departure?.operatorName
                                      : departure?.supplier?.name}
                                  </Typography>
                                </>
                              )}
                              <Typography
                                sx={{
                                  fontSize: "10px",
                                  marginLeft: "20px",
                                  color:
                                    departure?.levelValue === "LOW"
                                      ? "#88C078"
                                      : departure?.levelValue === "MEDIUM"
                                      ? "#E9B145"
                                      : "#E15D5D",
                                  marginTop: data?.organization?.showSupplier
                                    ? "-7px"
                                    : "20px",
                                }}
                              >
                                {departure?.level}
                              </Typography>
                            </Grid>
                            <Grid item xs={5}>
                              {departure &&
                              departure?.salesPrice != null &&
                              departure?.price != null ? (
                                <Typography
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "20px",
                                    fontWeight: "bold",
                                    marginLeft: "40px",
                                    fontSize: "16px",
                                  }}
                                >
                                  {!data?.id ? (
                                    <>
                                      {complexTickets[itemKey]
                                        ?.adjustedCalculatedPrice != null &&
                                      departure?.productDestinationDepartureId ==
                                        selectedDepartures[itemKey]
                                          ?.productDestinationDepartureId ? (
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <Icon
                                            style={{
                                              margin: 3,
                                              fontSize: 12,
                                            }}
                                          >
                                            note_alt
                                          </Icon>
                                          {parseFloat(
                                            complexTickets[itemKey]
                                              ?.adjustedCalculatedPrice
                                          )?.toFixed(2)}
                                          &euro;
                                        </div>
                                      ) : (
                                        <>
                                          {departure?.salesPrice?.toFixed(2)}
                                          &euro;
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {complexTickets[itemKey]
                                        ?.productDestination?.id ===
                                      departure?.productDestinationId ? (
                                        <>
                                          {complexTickets[itemKey]
                                            ?.adjustedCalculatedPrice != null &&
                                          departure?.productDestinationDepartureId ==
                                            selectedDepartures[itemKey]
                                              ?.productDestinationDepartureId ? (
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <Icon
                                                style={{
                                                  margin: 3,
                                                  fontSize: 12,
                                                }}
                                              >
                                                note_alt
                                              </Icon>
                                              {parseFloat(
                                                complexTickets[itemKey]
                                                  ?.adjustedCalculatedPrice
                                              )?.toFixed(2)}{" "}
                                              &euro;
                                            </div>
                                          ) : (
                                            <>
                                              {departure?.salesPrice?.toFixed(
                                                2
                                              )}
                                              &euro;
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {ticketGroup != null ||
                                          ticketGroup != undefined ? (
                                            <>
                                              {ticketGroup?.tickets?.find(
                                                (ticket) =>
                                                  ticket?.productDestination
                                                    ?.id ==
                                                  departure?.productDestinationId
                                              )?.adjustedCalculatedPrice !=
                                              null ? (
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                  }}
                                                >
                                                  <Icon
                                                    style={{
                                                      margin: 3,
                                                      fontSize: 12,
                                                    }}
                                                  >
                                                    note_alt
                                                  </Icon>
                                                  &euro;
                                                  {parseFloat(
                                                    ticketGroup?.tickets?.find(
                                                      (ticket) =>
                                                        ticket
                                                          ?.productDestination
                                                          ?.id ==
                                                        departure?.productDestinationId
                                                    )?.adjustedCalculatedPrice
                                                  )?.toFixed(2)}
                                                </div>
                                              ) : (
                                                <>
                                                  &euro;
                                                  {departure?.salesPrice?.toFixed(
                                                    2
                                                  )}
                                                </>
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              &euro;
                                              {departure?.salesPrice?.toFixed(
                                                2
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </Typography>
                              ) : (
                                ""
                              )}
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={10}
                  style={{ padding: "0px", border: "none" }}
                >
                  <Alert
                    severity="info"
                    icon={false}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                      margin: "0px",
                    }}
                  >
                    <Tooltip title="View Previous Available">
                      <IconButton
                        color="primary"
                        variant="contained"
                        size="small"
                        style={{ marginLeft: "-5px" }}
                        onClick={() => {
                          checkClosestPreviousDate(checkDate);
                        }}
                      >
                        <ArrowBackIosNewIcon style={{ fontSize: "12px" }} />
                      </IconButton>
                    </Tooltip>
                    No departures available for the selected date
                    <Tooltip title="View Next Available">
                      <IconButton
                        color="primary"
                        onClick={() => {
                          checkClosestNextDate(checkDate);
                        }}
                      >
                        <ArrowForwardIosIcon style={{ fontSize: "12px" }} />
                      </IconButton>
                    </Tooltip>
                  </Alert>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Grid>
    </>
  );
}
