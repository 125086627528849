import {
  Button,
  capitalize,
  Chip,
  Divider,
  Grid,
  Icon,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import Draggable from "react-draggable";
import { useDispatch } from "react-redux";
import { moment } from "../../App";
import { notifyShowSuccessMessage } from "../../common/CommonActions";
import { getTicketStatusColor } from "../../common/VisualElementsHelper";
import { LOCALE } from "../../properties/Locale";
import { TicketRepository } from "../../repositories/TicketRepository";
import { useTheme } from "@emotion/react";
import { PendingOutlined } from "@mui/icons-material";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export const ClientTicketHeader = ({
  data,
  setData,
  setShowAuditLog,
  showAuditLog,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const statusColors = {
    APPROVED: theme.palette.secondary.main,
    PENDING: theme.palette.extra3.main,
    IN_PROGRESS: theme.palette.in_progress.main,
    REJECTED: theme.palette.rejected.main,
    CANCELLED: theme.palette.canceled.main,
    DONE: theme.palette.done.main,
    NO_SHOW: theme.palette.mutedRed.main,
  };

  return (
    <>
      <Grid
        container
        style={{
          padding: "0 15px 0 15px",
        }}
        spacing={2}
      >
        <Grid
          item
          xs={4}
          md={3}
          lg={2.2}
          xl={1.8}
          style={{ textAlign: "left" }}
        >
          <Chip
            style={{
              fontWeight: "bold",
              height: 40,
              width: 90,
              backgroundColor: statusColors[data?.status],
              textTransform: "capitalize",
              color: "#f5f5f5",
              borderRadius: "6px",
            }}
            variant="contained"
            label={
              <Typography variant="h6">
                {data?.status?.replaceAll("_", " ").toLowerCase()}
              </Typography>
            }
          />
        </Grid>
        <Grid
          item
          xs={4}
          md={3.5}
          sx={{ textAlign: { xs: "right", lg: "left" } }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                style={{
                  color: theme.palette.lightGray.main,
                  fontWeight: 400,
                }}
              >
                Booking
                {data?.onWaitingList && " (on waiting list)"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                style={{
                  color: theme.palette.darkContrast.main,
                }}
              >
                {data?.ticketNumber}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} md={5.5} lg={6.3} xl={6.5} sx={{ textAlign: "end" }}>
          {data?.reservationCode && (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  style={{
                    color: theme.palette.lightGray.main,
                    fontWeight: 400,
                  }}
                >
                  Reservation code
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  style={{
                    color: theme.palette.darkContrast.main,
                  }}
                >
                  {data?.reservationCode}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            padding: "0 15px 0 15px",
            display: { xs: "none", md: "block" },
          }}
        >
          <Divider />
        </Grid>
      </Grid>
    </>
  );
};
