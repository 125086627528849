import { useTheme } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ProductRepository } from "../../repositories/ProductRepository";
import {
  Autocomplete,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Grid,
  TableFooter,
  FormControl,
  Pagination,
  alpha,
  TablePagination,
} from "@mui/material";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { SuppliersRepository } from "../../repositories/SuppliersRepository";
import { LOCALE } from "../../properties/Locale";

export const ExploreBAF = ({}) => {
  const theme = useTheme();
  const { name } = useParams();
  const [data, setData] = React.useState([]);
  const [departureDate, setDepartureDate] = React.useState(moment());
  const [searchParams, setSearchParams] = useState({});
  const [operators, setOperators] = useState([]);
  const [operatorSearchS, setOperatorSearchS] = useState("");
  const calculationTypes = {
    FIXED: "",
    PERCENTAGE: "Percentage of total price",
    FIXED_PER_LENGTH: "Per meter",
    FIXED_PER_WEIGHT: "Per ton",
  };
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);

  useEffect(() => {
    getOperators();
  }, []);

  useEffect(() => {
    getData();
  }, [searchParams, page]);

  const getData = () => {
    ProductRepository.getAllBAFSurchargesForWeb({
      page: page,
      size: size,
      orderBy: "productDestination",
      orderDirection: "asc",
      searchParams: JSON.stringify(searchParams),
    }).then(
      (res) => {
        setData(res.data);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getOperators = () => {
    SuppliersRepository.findAllForWeb().then(
      (res) => {
        setOperators(res.data);
      },
      (error) => {
        console.log(error);
      }
    );
  };
  return (
    <>
      <Paper>
        <Grid container spacing={2} padding={3}>
          <Grid item xs={12}>
            <Autocomplete
              noOptionsText={LOCALE.noOptionsText}
              autoComplete={false}
              size="small"
              value={
                searchParams?.operator
                  ? operators?.filter(
                      (operator) => operator?.id === searchParams.operator
                    )[0]
                  : null
              }
              onChange={(event, newValue) => {
                setSearchParams({
                  ...searchParams,
                  operator: newValue?.id,
                });
              }}
              inputValue={operatorSearchS ? operatorSearchS : ""}
              onInputChange={(event, newInputValue) => {
                setOperatorSearchS(newInputValue);
              }}
              options={operators}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"Choose a shipowner"}
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TableContainer>
              <Table style={{ tableLayout: "fixed" }}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        textAlign: "left",
                      }}
                    >
                      <Typography
                        variant="h5"
                        style={{
                          color: theme.palette.primary.main,
                        }}
                      >
                        Name
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        variant="h5"
                        style={{
                          color: theme.palette.primary.main,
                        }}
                      >
                        Operator
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        variant="h5"
                        style={{
                          color: theme.palette.primary.main,
                        }}
                      >
                        Destination
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "right",
                      }}
                    >
                      <Typography
                        variant="h5"
                        style={{
                          color: theme.palette.primary.main,
                        }}
                      >
                        Rate
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.content?.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell
                        sx={{
                          textAlign: "left",
                        }}
                      >
                        <Typography>{row.name}</Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <Typography>{row.operator}</Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <Typography>{row.destination}</Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "right",
                        }}
                      >
                        <Typography>
                          {`${calculationTypes[row?.calculationType]} ${
                            row?.price
                          } €`}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter
                  style={{
                    zIndex: 5,
                    backgroundColor: theme.palette.dashboardBackground.main,
                    width: "100%",
                  }}
                >
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Pagination
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          "& .MuiPaginationItem-root": {
                            fontSize: "12px",
                            width: "25px",
                            height: "25px",
                          },
                          "& .MuiPaginationItem-page.Mui-selected": {
                            backgroundColor: theme.palette.secondary.main,
                            opacity: "50%",
                            color: "white",
                            width: "25px",
                            height: "25px",
                          },
                          "& .MuiPaginationItem-previousNext": {
                            border: "1px solid",
                            borderColor: theme.palette.lightGray.main,
                            width: "25px",
                            height: "25px",
                          },
                        }}
                        count={
                          (data?.totalElements % size === 0
                            ? data?.totalElements / size
                            : Math.floor(data?.totalElements / size) + 1) || 0
                        }
                        shape="rounded"
                        size={"large"}
                        page={data?.number + 1 || 0}
                        onChange={(event, value) => {
                          setPage(value - 1);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};
