import { useTheme } from "@emotion/react";
import {
  AssignmentLateOutlined,
  AssignmentTurnedInOutlined,
  Close,
  CreditCardOffOutlined,
  DangerousOutlined,
  EvStationOutlined,
  HourglassEmpty,
  HourglassTop,
  PendingOutlined,
  PersonOutlineOutlined,
  ReceiptLongOutlined,
  VerifiedOutlined,
} from "@mui/icons-material";
import {
  alpha,
  Box,
  Chip,
  Grid,
  Icon,
  Skeleton,
  TableCell,
  TableRow,
  Tooltip,
  tooltipClasses,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { moment } from "../../App";
import { TableRowEmptyData } from "../../common/TableRowEmptyData";

export const ClientTicketListTableBody = ({
  loading,
  data,
  setHoveredRow,
  hoveredRow,
  navigate,
  newlyAddedTicketNumber,
  expanded,
}) => {
  const params = useParams();

  const [newlyAddedTickets, setNewlyAddedTickets] = useState([]);
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const statusColors = {
    APPROVED: theme.palette.secondary.main,
    PENDING: theme.palette.extra3.main,
    IN_PROGRESS: theme.palette.in_progress.main,
    REJECTED: theme.palette.rejected.main,
    CANCELLED: theme.palette.canceled.main,
    DONE: theme.palette.done.main,
    NO_SHOW: theme.palette.mutedRed.main,
  };

  useEffect(() => {
    if (newlyAddedTicketNumber) {
      setNewlyAddedTickets((prevTickets) => [
        ...prevTickets,
        newlyAddedTicketNumber,
      ]);
    }
  }, [newlyAddedTicketNumber]);

  const getIconsForItem = (item, theme) => {
    const iconList = [];

    if (item?.onWaitingList) {
      iconList.push({
        component: (
          <PendingOutlined
            style={{
              opacity: "80%",
              fontSize: "24px",
              color: theme.palette.extra1.main,
            }}
          />
        ),
        title: "Waiting list",
      });
    }

    if (item?.missingMandatorySurcharge) {
      iconList.push({
        component: (
          <CreditCardOffOutlined
            style={{
              fontSize: "24px",
              color: theme.palette.error.main,
              opacity: "80%",
            }}
          />
        ),
        title: "Missing mandatory surcharge",
      });
    }

    if (item?.electricPlugin == true) {
      iconList.push({
        component: (
          <EvStationOutlined
            color={theme.palette.darkContrast.main}
            style={{
              fontSize: "24px",
              color: theme.palette.darkContrast.main,
            }}
          />
        ),
        title: "Electricity plug-in",
      });
    }

    if (item?.dangerousGoods == true) {
      iconList.push({
        component: (
          <DangerousOutlined
            style={{
              fontSize: "24px",
              color: theme.palette.error.main,
              opacity: "80%",
            }}
          />
        ),
        title: "Dangerous goods",
      });
    }

    if (item?.liveAnimals == true) {
      iconList.push({
        component: (
          <Icon
            style={{
              fontSize: "24px",
              color: theme.palette.darkContrast.main,
            }}
          >
            <svg
              width="16"
              height="24"
              viewBox="0 0 14 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 12C11.76 11.76 11.56 11.51 11.35 11.25C12.51 9.5 14 6.56 14 3C14 1.05 13.26 0 12 0C10.46 0 8.04 2.06 7 5.97C5.96 2.06 3.54 0 2 0C0.74 0 0 1.05 0 3C0 6.56 1.49 9.5 2.65 11.25C2.44 11.51 2.24 11.76 2 12C1.75 12.25 0 13.39 0 15.5C0 17.98 2.02 20 4.5 20C6 20 7 19.5 7 19.5C7 19.5 8 20 9.5 20C11.98 20 14 17.98 14 15.5C14 13.39 12.25 12.25 12 12ZM11.88 2.03C11.94 2.2 12 2.51 12 3C12 5.84 10.89 8.24 9.93 9.78C9.55 9.52 9.1 9.3 8.53 9.16C8.77 4.64 10.97 2.33 11.88 2.03ZM2 3C2 2.51 2.06 2.2 2.12 2.03C3.03 2.33 5.23 4.64 5.48 9.16C4.9 9.3 4.45 9.51 4.08 9.78C3.11 8.24 2 5.84 2 3ZM9.5 18C8.5 18 7.7 17.67 7.28 17.44C7.7 17.26 8 16.73 8 16.5C8 16.22 7.55 16 7 16C6.45 16 6 16.22 6 16.5C6 16.73 6.3 17.26 6.72 17.44C6.3 17.67 5.5 18 4.5 18C3.12 18 2 16.88 2 15.5C2 14.8 2.43 14.26 3 13.77C3.44 13.41 3.61 13.25 4.3 12.4C5.06 11.45 5.39 11 7 11C8.61 11 8.94 11.45 9.7 12.4C10.39 13.25 10.56 13.41 11 13.77C11.57 14.26 12 14.8 12 15.5C12 16.88 10.88 18 9.5 18ZM9 14C9 14.41 8.78 14.75 8.5 14.75C8.22 14.75 8 14.41 8 14C8 13.59 8.22 13.25 8.5 13.25C8.78 13.25 9 13.59 9 14ZM6 14C6 14.41 5.78 14.75 5.5 14.75C5.22 14.75 5 14.41 5 14C5 13.59 5.22 13.25 5.5 13.25C5.78 13.25 6 13.59 6 14Z"
                fill={`${theme.palette.darkContrast.main}`}
                style={{
                  fill: theme.palette.darkContrast.main,
                  fillOpacity: 1,
                }}
              />
            </svg>
          </Icon>
        ),
        title: "Live animals",
      });
    }

    if (item?.material != null) {
      iconList.push({
        component: (
          <Icon
            style={{
              fontSize: "24px",
              color: theme.palette.darkContrast.main,
            }}
          >
            <svg
              width="16"
              height="24"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 8C7.9 8 6.875 7.79583 5.925 7.3875C4.975 6.97917 4.13333 6.41667 3.4 5.7L5.2 4.625C5.6 4.95833 6.03333 5.23333 6.5 5.45C6.96667 5.66667 7.46667 5.81667 8 5.9V0.6L9 0L10 0.6V5.9C10.5333 5.81667 11.0333 5.66667 11.5 5.45C11.9667 5.23333 12.4 4.95833 12.8 4.625L14.6 5.7C13.8667 6.41667 13.025 6.97917 12.075 7.3875C11.125 7.79583 10.1 8 9 8ZM7.775 17.375L5.925 16.4C5.95833 16.25 5.97917 16.1 5.9875 15.95C5.99583 15.8 6 15.65 6 15.5C6 15.1167 5.96667 14.7375 5.9 14.3625C5.83333 13.9875 5.73333 13.6333 5.6 13.3L1.025 16.05L0 15.5V14.325L4.55 11.6C4.21667 11.2167 3.83333 10.875 3.4 10.575C2.96667 10.275 2.5 10.0333 2 9.85V7.75C3.73333 8.2 5.16667 9.1375 6.3 10.5625C7.43333 11.9875 8 13.6333 8 15.5C8 15.8333 7.98333 16.1542 7.95 16.4625C7.91667 16.7708 7.85833 17.075 7.775 17.375ZM10.225 17.375C10.1417 17.075 10.0833 16.7708 10.05 16.4625C10.0167 16.1542 10 15.8333 10 15.5C10 13.6333 10.5667 11.9875 11.7 10.5625C12.8333 9.1375 14.2667 8.2 16 7.75V9.85C15.5 10.0333 15.0333 10.275 14.6 10.575C14.1667 10.875 13.7833 11.2167 13.45 11.6L18 14.325V15.5L16.975 16.05L12.4 13.3C12.2667 13.6333 12.1667 13.9875 12.1 14.3625C12.0333 14.7375 12 15.1167 12 15.5C12 15.65 12.0042 15.8 12.0125 15.95C12.0208 16.1 12.0417 16.25 12.075 16.4L10.225 17.375Z"
                fill={`${theme.palette.darkContrast.main}`}
                style={{
                  fill: theme.palette.darkContrast.main,
                  fillOpacity: "1",
                }}
              />
            </svg>
          </Icon>
        ),
        title: "Material: " + item?.material?.name,
      });
    }
    if (item?.invoiceId != null) {
      iconList.push({
        component: (
          <ReceiptLongOutlined
            style={{
              opacitiy: "80%",
              fontSize: "24px",
              color: theme.palette.extra1.main,
            }}
          />
        ),
        title: "Invoiced",
      });
    }

    if (item?.waitingForDfdsAcknowledgement) {
      iconList.push({
        component: (
          <HourglassEmpty
            style={{
              opacitiy: "80%",
              fontSize: "24px",
              color: theme.palette.extra1.main,
            }}
          />
        ),
        title: "Waiting for DFDS acknowledgement",
      });
    }

    if (item?.waitingForScandlinesAcknowledgement) {
      iconList.push({
        component: (
          <HourglassEmpty
            style={{
              opacitiy: "80%",
              fontSize: "24px",
              color: theme.palette.extra1.main,
            }}
          />
        ),
        title: "Waiting for Scandlines acknowledgement",
      });
    }

    if (item?.checkedIn == true) {
      iconList.push({
        component: (
          <VerifiedOutlined
            style={{
              opacitiy: "80%",
              fontSize: "24px",
              color: theme.palette.success.main,
            }}
          />
        ),
        title: "Checked-in",
      });
    }

    if (item?.productDestination?.ensDeclarationRequired == true) {
      if (item?.consignments && item?.consignments.length > 0) {
        iconList.push({
          component: (
            <AssignmentTurnedInOutlined
              style={{
                opacitiy: "80%",
                fontSize: "24px",
                color: theme.palette.success.main,
              }}
            />
          ),
          title: "ENS Declaration ✔",
        });
      } else {
        iconList.push({
          component: (
            <AssignmentLateOutlined
              style={{
                opacitiy: "80%",
                fontSize: "24px",
                color: theme.palette.warning.main,
              }}
            />
          ),
          title: "ENS Declaration Required",
        });
      }
    }

    return iconList;
  };

  const rowsToRender = data ? data.length : 0;
  const emptyRows = expanded ? 8 - rowsToRender : 10 - rowsToRender;

  return (
    <>
      {!loading && data && data?.length === 0 && <TableRowEmptyData />}
      {(loading || !data) && (
        <>
          <TableRow>
            <TableCell colSpan={20}>
              <Skeleton variant="react" height={30} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={20}>
              <Skeleton variant="react" height={30} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={20}>
              <Skeleton variant="react" height={30} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={20}>
              <Skeleton variant="react" height={30} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={20}>
              <Skeleton variant="react" height={30} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={20}>
              <Skeleton variant="react" height={30} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={20}>
              <Skeleton variant="react" height={30} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={20}>
              <Skeleton variant="react" height={30} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={20}>
              <Skeleton variant="react" height={30} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={20}>
              <Skeleton variant="react" height={30} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={20}>
              <Skeleton variant="react" height={30} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={20}>
              <Skeleton variant="react" height={30} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={20}>
              <Skeleton variant="react" height={30} />
            </TableCell>
          </TableRow>
        </>
      )}
      {!loading &&
        data &&
        data.map((item, index) => (
          <TableRow
            key={index}
            onMouseEnter={() => {
              setHoveredRow(item?.id);
            }}
            onMouseLeave={() => {
              setHoveredRow(null);
            }}
            sx={{
              backgroundColor: newlyAddedTickets.includes(item.ticketNumber)
                ? alpha(statusColors[item?.status], 0.25)
                : item.priority === "CRITICAL" || item.priority === "HIGH"
                ? "#ffc107"
                : theme.palette.tableRow.main,
              ...(hoveredRow === item.id && {
                backgroundColor: `${statusColors[item?.status] || "#f2f2f2"}50`,
              }),
              transition: "background-color 0.3s ease",
              zIndex: "100",
              cursor: "pointer",
              height: "48px",
              clipPath: "inset(0 round 0.5em)",
            }}
            onClick={() => {
              item.productPrice
                ? navigate(
                    `/client/booking/${params?.page}/${params?.size}/${params?.params}/${item.id}`
                  )
                : navigate(
                    `/client/booking/${params?.page}/${params?.size}/${params?.params}/${item.id}`
                  );
            }}
          >
            <TableCell
              style={{
                textAlign: "left",
                position: "relative",
                borderBottom: "none",

                minWidth: isMediumScreen ? "80px" : "100px",
                maxWidth: isMediumScreen ? "80px" : "100px",
              }}
            >
              {["APPROVED", "PENDING", "IN_PROGRESS", "NO_SHOW"].includes(
                item?.status
              ) && (
                <Chip
                  style={{
                    minWidth: isMediumScreen ? "80px" : "100px",
                    maxWidth: isMediumScreen ? "80px" : "100px",
                    height: 48,
                    borderRadius: 5,
                    cursor: "pointer",
                    position: "absolute",
                    top: "50%",
                    left: 0,
                    transform: "translateY(-50%)",
                    // transition: "width 0.3s ease-in-out",
                    zIndex: 2,
                    backgroundColor: statusColors[item?.status],
                    color: "white",
                    fontSize: isMediumScreen ? "11px" : "14px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textTransform: "capitalize",
                  }}
                  size="small"
                  label={item?.status?.replaceAll("_", " ").toLowerCase()}
                  variant="contained"
                />
              )}

              {["REJECTED", "CANCELLED", "DONE"].includes(item?.status) && (
                <Chip
                  style={{
                    width: "100%",
                    height: 48,
                    borderRadius: 4,
                    cursor: "pointer",
                    position: "absolute",
                    top: "50%",
                    left: 0,
                    transform: "translateY(-50%)",
                    // transition: "width 0.3s ease-in-out",
                    zIndex: 2,
                    backgroundColor: statusColors[item?.status],
                    color: "white",
                    fontSize: isMediumScreen
                      ? "11px"
                      : item?.status === "CANCELLED"
                      ? "13px"
                      : "14px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textTransform: "capitalize",
                  }}
                  size="small"
                  label={item?.status?.replaceAll("_", " ").toLowerCase()}
                  icon={
                    item?.status === "REJECTED" ? (
                      <Close color="white" style={{ paddingLeft: "5px" }} />
                    ) : item?.status === "CANCELLED" ? (
                      <HourglassTop
                        color="white"
                        style={{ paddingLeft: "5px" }}
                      />
                    ) : item?.status === "DONE" ? (
                      <VerifiedOutlined
                        color="white"
                        style={{ paddingLeft: "5px" }}
                      />
                    ) : null
                  }
                  variant="contained"
                />
              )}
            </TableCell>
            <TableCell
              style={{
                textAlign: "left",
                borderBottom: "none",
              }}
            >
              <Typography
                variant={isMediumScreen ? "subtitle2" : "h6"}
                style={{
                  color: theme.palette.lightGray.main,
                  fontWeight: "bold",
                }}
              >
                {item?.ticketNumber}
              </Typography>
            </TableCell>

            {/* <TableCell
                style={{
                  width: "5px",
                  padding: "10px 0",
                  position: "relative",
                  borderLeft: "none",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "8px",
                    bottom: "10px",
                    left: "-8%",
                    width: "1px",
                    backgroundColor: alpha(theme.palette.lightGray.main, 0.5),
                    height: "calc(100% - 14px)",
                  }}
                />
              </TableCell> */}
            <TableCell style={{ textAlign: "left", borderBottom: "none" }}>
              <Typography
                variant={isMediumScreen ? "subtitle2" : "h6"}
                sx={{ fontWeight: "bold" }}
              >
                {item?.reservationCode ? item?.reservationCode : "-"}
              </Typography>
            </TableCell>
            <TableCell
              style={{
                textAlign: "left",
                whiteSpace: "nowrap",
                borderBottom: "none",
              }}
            >
              <Typography
                variant={isMediumScreen ? "subtitle2" : "h6"}
                style={{ fontWeight: "bold" }}
              >
                {item?.product?.directionsRange === "ONE_WAY"
                  ? item?.destination?.name?.split("-").join(" ➜ ")
                  : item?.product?.directionsRange === "RETURN"
                  ? item?.destination?.name?.split("-").join(" ⇄ ")
                  : item?.destination?.name}
              </Typography>
            </TableCell>
            <TableCell
              style={{
                textAlign: "left",
                whiteSpace: "nowrap",
                borderBottom: "none",
              }}
            >
              <Tooltip
                title={
                  item?.priceList ? (
                    <Typography
                      variant={isMediumScreen ? "subtitle2" : "h6"}
                      style={{ fontWeight: "bold", borderBottom: "none" }}
                    >
                      Arrive{" "}
                      {item?.priceList?.arrivalDay === "0"
                        ? "same day"
                        : item?.priceList?.arrivalDay === "1"
                        ? "next day"
                        : "after 2 days"}{" "}
                      at {item?.priceList?.arrivalHour}
                    </Typography>
                  ) : (
                    <Typography
                      variant={isMediumScreen ? "subtitle2" : "h6"}
                      style={{ fontWeight: "bold" }}
                    >
                      Arrive{" "}
                      {item?.productDestinationDeparture?.arrivalDay === "0"
                        ? "same day"
                        : item?.productDestinationDeparture?.arrivalDay === "1"
                        ? "next day"
                        : "after 2 days"}{" "}
                      at {item?.productDestinationDeparture?.arrivalHour}
                    </Typography>
                  )
                }
                arrow
                placement="top"
              >
                <Typography
                  variant={isMediumScreen ? "subtitle2" : "h6"}
                  style={{ fontWeight: "bold" }}
                >
                  {`${moment(item?.departureDay).format("DD.MM.YYYY")} 
                  ${
                    item?.priceList
                      ? item?.priceList?.departureHour
                      : item?.departureHour
                      ? item?.departureHour
                      : ""
                  }`}
                </Typography>
              </Tooltip>
            </TableCell>
            {item?.organization?.showSupplier && (
              <TableCell style={{ textAlign: "left", borderBottom: "none" }}>
                {item?.productDestination ? (
                  <Typography
                    noWrap
                    variant={isMediumScreen ? "subtitle2" : "h6"}
                    style={{ fontWeight: "bold" }}
                  >
                    {item?.productDestination?.operator?.name
                      ? item?.productDestination?.operator?.name
                      : item?.productDestination?.supplier?.name}
                  </Typography>
                ) : (
                  <Typography variant="h6" style={{ fontWeight: "bold" }}>
                    {`Price list not selected`}
                  </Typography>
                )}
              </TableCell>
            )}

            <TableCell style={{ textAlign: "left", borderBottom: "none" }}>
              <Typography
                variant={isMediumScreen ? "subtitle2" : "h6"}
                style={{ fontWeight: "bold" }}
              >
                {item?.vehicleLength}m
              </Typography>
            </TableCell>
            <TableCell style={{ textAlign: "left", borderBottom: "none" }}>
              <Typography
                variant={isMediumScreen ? "subtitle2" : "h6"}
                style={{ fontWeight: "bold" }}
              >
                {item?.truckPlateNumber}
              </Typography>
            </TableCell>

            <TableCell
              style={{
                textAlign: "left",
                whiteSpace: "nowrap",
                borderBottom: "none",
              }}
            >
              {item?.assignedTo ? (
                <Tooltip
                  title={
                    <Box sx={{ p: 1, width: "260px" }}>
                      <Grid container>
                        <Grid item xs={2}>
                          <Box
                            sx={{
                              width: 27,
                              height: 27,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor:
                                theme.palette.dashboardBackground.main,
                              color: theme.palette.darkContrast.main,
                              border: `1px solid ${theme.palette.darkContrast.main}`,
                              borderRadius: "4px",
                              fontWeight: "bold",
                            }}
                          >
                            <Typography
                              variant={isMediumScreen ? "subtitle2" : "h6"}
                              fontWeight="bold"
                            >
                              {item?.assignedTo?.firstName[0] +
                                item?.assignedTo?.lastName[0]}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography
                            variant={isMediumScreen ? "subtitle2" : "h6"}
                            fontWeight="bold"
                            color={`${theme.palette.darkContrast.main}`}
                          >
                            {item.assignedTo.firstName}{" "}
                            {item.assignedTo.lastName}
                          </Typography>
                          <Typography variant="subtitle1" color="#5bc4d0">
                            {item.assignedTo.email}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  }
                  placement="bottom-start"
                  slotProps={{
                    popper: {
                      sx: {
                        [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                          {
                            marginTop: "-45px",
                          },

                        [`.${tooltipClasses.tooltip}`]: {
                          backgroundColor:
                            theme.palette.dashboardBackground.main,
                          marginLeft: "-5px",
                        },
                      },
                    },
                  }}
                >
                  <Box
                    sx={{
                      width: 27,
                      height: 27,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: theme.palette.dashboardBackground.main,
                      border:
                        theme.palette.mode === "light"
                          ? `1px solid${theme.palette.darkContrast.main}`
                          : "none",
                      borderRadius: "4px",
                      fontWeight: "bold",
                    }}
                  >
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      sx={{ color: theme.palette.darkContrast.main }}
                    >
                      {item?.assignedTo?.firstName[0] +
                        item?.assignedTo?.lastName[0]}
                    </Typography>
                  </Box>
                </Tooltip>
              ) : (
                <Box sx={{ width: 32, height: 36 }}></Box>
              )}
            </TableCell>

            <Tooltip
              title={
                <Box>
                  {getIconsForItem(item, theme).map(
                    ({ component, title }, index) => (
                      <Grid container spacing={2}>
                        <Grid item xs={2}>
                          <Box
                            key={index}
                            sx={{
                              height: 32,
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                          >
                            {component}
                          </Box>
                        </Grid>
                        <Grid item xs={10}>
                          <Box
                            key={index}
                            sx={{
                              height: 32,
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="h6"
                              style={{
                                color: theme.palette.darkContrast.main,
                              }}
                            >
                              {title}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    )
                  )}
                  {item?.numberOfDrivers > 1 && (
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Box
                          sx={{
                            width: 40,
                            height: 24,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: theme.palette.primary.main,
                            color: "white",
                            border: `2px solid rgba(87,155,165, 0.5)`,
                            borderRadius: "4px",
                            fontWeight: "bold",
                          }}
                        >
                          <PersonOutlineOutlined
                            style={{
                              padding: 0,
                              fontSize: "24px",
                            }}
                          />
                          <Typography
                            variant="subtitle2"
                            style={{ fontSize: "16px" }}
                          >
                            {item?.numberOfDrivers}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography
                          variant="h6"
                          style={{
                            color: theme.palette.darkContrast.main,
                            marginTop: "5px",
                          }}
                        >
                          Drivers
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Box>
              }
              placement="bottom-start"
              slotProps={{
                popper: {
                  sx: {
                    [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                      {
                        marginTop: "-45px",
                      },

                    [`.${tooltipClasses.tooltip}`]: {
                      backgroundColor: theme.palette.dashboardBackground.main,
                      marginLeft: "0px",
                    },
                  },
                },
              }}
            >
              <TableCell
                style={{
                  textAlign: "center",
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  alignContent: "center",
                  gap: 1,
                  borderBottom: 0,
                  minHeight: "32px",
                  borderBottom: "none",
                }}
              >
                {getIconsForItem(item, theme)
                  .slice(0, 2)
                  .map(({ component, title }, i) => (
                    <Box
                      key={i}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {component}
                    </Box>
                  ))}

                {getIconsForItem(item, theme).length > 2 && (
                  <Box
                    sx={{
                      width: 24,
                      height: 24,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      backgroundColor: theme.palette.primary.main,
                      color: "white",
                      border: `2px solid rgba(87,155,165, 0.5)`,
                      borderRadius: "4px",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontSize: "16px",
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      {`+${getIconsForItem(item, theme).length - 2}`}
                    </Typography>
                  </Box>
                )}
                {item?.numberOfDrivers > 1 && (
                  <Box
                    sx={{
                      width: 40,
                      height: 24,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: theme.palette.primary.main,
                      color: "white",
                      border: `2px solid rgba(87,155,165, 0.5)`,
                      borderRadius: "4px",
                      fontWeight: "bold",
                    }}
                  >
                    <PersonOutlineOutlined
                      style={{
                        padding: 0,
                        fontSize: "24px",
                      }}
                    />
                    <Typography
                      variant="subtitle2"
                      style={{ fontSize: "16px" }}
                    >
                      {item?.numberOfDrivers}
                    </Typography>
                  </Box>
                )}
              </TableCell>
            </Tooltip>

            {item?.organization?.showPrice && (
              <>
                <TableCell style={{ textAlign: "right", borderBottom: "none" }}>
                  <Typography
                    variant={isMediumScreen ? "subtitle2" : "h6"}
                    style={{ fontWeight: "bold" }}
                  >
                    {item?.showPrice ? (
                      <strong>{`${(
                        +item?.calculatedPriceWithFees - +item?.calculatedPrice
                      ).toFixed(2)}€`}</strong>
                    ) : (
                      ""
                    )}
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "right",
                    borderBottom: "none",
                    paddingRight: "10px",
                    borderRight: `1.5px solid ${theme.palette.dashboardBackground.main}`,
                    borderTopRightRadius: "5px",
                    borderBottomRightRadius: "5px",
                  }}
                >
                  <Typography
                    variant={isMediumScreen ? "subtitle2" : "h6"}
                    style={{ fontWeight: "bold" }}
                  >
                    {item?.showPrice ? (
                      <>
                        {item?.adjustedCalculatedPrice ? (
                          <Tooltip title="Adjusted price">
                            <div style={{ textAlign: "right" }}>
                              <span
                                style={{
                                  display: "inline-block",
                                  verticalAlign: "-webkit-baseline-middle",
                                }}
                              >
                                <Icon
                                  style={{
                                    fontSize: "13px",
                                    color: "#FF6B1A",
                                  }}
                                >
                                  published_with_changes
                                </Icon>
                              </span>
                              <strong
                                style={{
                                  display: "inline-block",
                                  verticalAlign: "middle",
                                }}
                              >
                                {`${+item?.adjustedCalculatedPrice.toFixed(
                                  2
                                )}€ `}
                              </strong>
                            </div>
                          </Tooltip>
                        ) : (
                          <strong>{`${(+item?.calculatedPriceWithFees).toFixed(
                            2
                          )}€ `}</strong>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </Typography>
                </TableCell>
              </>
            )}
          </TableRow>
        ))}
      {/* Render empty rows to make up a total of 10 rows */}
      {(!loading || data) &&
        Array.from({ length: emptyRows }).map((_, index) => (
          <TableRow
            key={`empty-${index}`}
            style={{ height: expanded ? "72px" : "48px", border: "none" }}
          >
            <TableCell
              colSpan={30}
              sx={{ borderBottom: "none", paddingBottom: 0 }}
            />
          </TableRow>
        ))}
    </>
  );
};
