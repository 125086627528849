import {
  Box,
  Divider,
  Grid,
  Paper,
  Skeleton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { jwtDecode } from "jwt-decode";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import {
  clearReceiveNewTicket,
  notifyShowErrorMessage,
  notifyShowSuccessMessage,
  notifyShowWarningMessage,
} from "../../common/CommonActions";
import { ErrorMessageResolver } from "../../common/ErrorMessageResolver";
import { AuthRepository } from "../../repositories/AuthRepository";
import { MessageTemplateRepository } from "../../repositories/MessageTemplateRepository";
import { TicketGroupRepository } from "../../repositories/TicketGroupRepository";
import { TicketRepository } from "../../repositories/TicketRepository";
import { ClientTicketCommunication } from "./ClientTicketCommunication";
import { ClientTicketContent } from "./ClientTicketContent.js";
import { ClientTicketHeader } from "./ClientTicketHeader.js";
import { ClientTicketNewMessageDialog } from "./ClientTicketNewMessageDialog.js";
import { TicketGroupDetails } from "./TicketGroupDetails.js";
import TicketPreviewAvailableDeparturesCalendar from "./TicketPreviewAvailableDeparturesCalendar";
import { useTheme } from "@emotion/react";
import { ClientTicketLogContent } from "./ClientTicketLogContent.js";
import ClientTicketPreviewOpenLayer from "../map/ClientTicketPreviewOpenLayer.js";
import { ClientTicketTabsMobile } from "../mobile/ClientTicketTabsMobile.js";
import { TicketGroupDetailsMobile } from "../mobile/TicketGroupDetailsMobile.js";
import { AttachmentRepository } from "../../repositories/AttachmentRepository.js";
import { getMimeTypeByFileExtension } from "../../common/MimeTypesHelper.js";
import { ClientTicketAttachments } from "./ClientTicketAttachments.js";
import {
  AttachFile,
  Chat,
  ChatOutlined,
  ChecklistRtlOutlined,
  ConfirmationNumber,
  ConfirmationNumberOutlined,
  FilePresent,
  RouteOutlined,
} from "@mui/icons-material";

export default function ClientTicket() {
  const { id } = useParams();
  const [dialogMessageType, setDialogMessageType] = useState("CLIENT");
  const [openDialogComment, setOpenDialogComment] = React.useState(false);
  const [data, setData] = React.useState();
  const [messages, setMessages] = React.useState();
  const [newMessage, setNewMessage] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [assignedToCurrentUser, setAssignedToCurrentUser] = useState();

  const dispatch = useDispatch();
  const [messageTemplates, setMessageTemplates] = React.useState();

  const navigate = useNavigate();
  const [ticketGroup, setTicketGroup] = React.useState();
  const [showAuditLog, setShowAuditLog] = React.useState(false);

  const [readyForInvoice, setReadyForInvoice] = React.useState(false);
  const [checkIfLocked, setCheckIfLocked] = useState(false);
  const [invoiced, setInvoiced] = useState(false);
  const [currentUser, setCurrentUser] = React.useState();
  const [availableDepartures, setAvailableDepartures] = useState();
  const [tmpData, setTmpData] = React.useState(undefined);
  const [allComplexDepartures, setAllComplexDepartures] = useState();
  const [selectedComplexDepartureKey, setSelectedComplexDepartureKey] =
    useState();
  const [availableComplexDepartures, setAvailableComplexDepartures] =
    useState();
  const [changeTicket, setChangeTicket] = useState(false);
  const [priceLists, setPriceLists] = useState([]);
  const [departuresLoading, setDeparturesLoading] = useState(false);

  const globalState = useSelector((state) => state);
  const theme = useTheme();
  const [communicationTabValue, setCommunicationTabValue] = useState("1");
  const [lifeCycleMessages, setLifeCycleMessages] = useState();
  const [toCityCallback, setToCityCallback] = useState();
  const [chatDocument, setChatDocument] = useState();
  const [files, setFiles] = useState([]);
  const [openUpload, setOpenUpload] = useState(false);
  const [messageAttachments, setMessageAttachments] = useState([]);

  useEffect(() => {
    loadInitData(id);
  }, [id]);

  useEffect(() => {
    if (data?.ticketGroupId) {
      loadTicketGroup(data?.ticketGroupId);
    }
  }, [data]);

  useEffect(() => {
    loadMessageAttachments();
  }, [messages]);

  useEffect(() => {
    if (globalState.receiveNewTicket?.statusChangedOnTicketNotification) {
      updateDataOnChange(
        globalState.receiveNewTicket?.statusChangedOnTicketNotification
      );
      dispatch(clearReceiveNewTicket());
    }
    if (globalState.receiveNewTicket?.newMessageOnTicketNotification) {
      TicketRepository.getCommunication(id).then((res) => {
        setMessages(res.data);
      });
      dispatch(clearReceiveNewTicket());
    }
  }, [globalState.receiveNewTicket]);

  const updateDataOnChange = (dataToUpdate) => {
    setData((prevData) => ({
      ...prevData,
      assignedTo: dataToUpdate?.assignedTo,
      reservationCode: dataToUpdate?.reservationCode,
      status: dataToUpdate?.status,
    }));
  };

  const loadTicketGroup = (ticketGroupId) => {
    setLoading(true);
    TicketGroupRepository.findByID(ticketGroupId)
      .then((res) => {
        setTicketGroup(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const loadInitData = () => {
    setLoading(true);
    TicketRepository.get(id).then((res) => {
      if (
        res.data?.assignedTo &&
        res.data?.assignedTo?.id === AuthRepository.getUserDetails().userId
      ) {
        setAssignedToCurrentUser(true);
      }

      setData(res.data);

      let tmp = { ...res.data };

      delete tmp.ticketSurcharges;
      tmp.departureDay = moment(res.data.departureDay)
        .set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        })
        .utc(true)
        .toISOString();
      setTmpData(tmp);
      setLoading(false);
      setNewMessage({
        message: "",
        ticket: { id: id },
        type: "CLIENT",
      });
      TicketRepository.getCommunication(id).then((res) => {
        setMessages(res.data);
      });
      getMessageTemplates(res.data.status);
    });
  };

  const handleChangeMessageData = (name, value) => {
    setNewMessage(_.set({ ...newMessage }, name, value));
  };

  const addNewMessage = () => {
    setLoading(true);

    if (chatDocument != null) {
      TicketRepository.createMessageWithAttachment(
        newMessage,
        chatDocument
      ).then((res) => {
        setOpenDialogComment(false);
        setLoading(false);
        setNewMessage({
          message: "",
          ticket: { id: data.id },
          type: res.data.type,
        });
        TicketRepository.getCommunication(data.id).then((res) => {
          setMessages(res.data);
        });
        setChatDocument();
        setFiles([]);
      });
    } else {
      TicketRepository.createMessage(newMessage).then((res) => {
        setOpenDialogComment(false);
        setLoading(false);
        setNewMessage({
          message: "",
          ticket: { id: data.id },
          type: res.data.type,
        });
        TicketRepository.getCommunication(data.id).then((res) => {
          setMessages(res.data);
        });
      });
    }
  };

  const loadMessageAttachments = () => {
    TicketRepository.getAllMessageAttachments(id)
      .then((res) => {
        setMessageAttachments(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangeData = (name, value) => {
    let newData = { ...data };
    if (name === "priceList") {
      newData[name] = value;
      newData.finalPrice = value?.calculatedPrice;
      setData(newData);
    } else {
      setData(_.set({ ...data }, name, value));
    }
  };

  const getMessageTemplates = (status) => {
    MessageTemplateRepository.getAllTemplatesByTicketStatus(
      status ? status : data?.status
    )
      .then((res) => {
        setMessageTemplates(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadCurrentUser = () => {
    if (localStorage.getItem("jwt")) {
      setCurrentUser(jwtDecode(localStorage.getItem("jwt")));
    }
  };

  React.useEffect(() => {
    loadCurrentUser();
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (data?.id) {
        getProductDestination();
      }
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [
    tmpData?.toCity,
    tmpData?.fromCity,
    tmpData?.vehicleLength,
    tmpData?.vehicleWidth,
    tmpData?.vehicleHeight,
    tmpData?.departureDay,
    tmpData?.cargoWeight,
    tmpData?.loaded,
    tmpData?.numberOfDrivers,
    tmpData?.dangerousGoods,
    tmpData?.electricPlugin,
    tmpData?.liveAnimals,
    tmpData?.material,
    tmpData?.organization?.id,
  ]);

  const getProductDestination = () => {
    setAvailableComplexDepartures(undefined);
    setSelectedComplexDepartureKey(undefined);
    setPriceLists([]);
    setDeparturesLoading(true);
    TicketRepository.getAvailableDeparturesOneFormTicket({
      organizationId: tmpData?.organization?.id,
      fromCityId: tmpData?.product?.isComplex
        ? tmpData?.product?.mainDestination?.fromCity?.id
        : tmpData?.fromCity?.id,
      toCityId: tmpData?.product?.isComplex
        ? tmpData?.product?.mainDestination?.toCity?.id
        : tmpData?.toCity?.id,
      vehicleLength: tmpData?.vehicleLength,
      cargoWeight: tmpData?.cargoWeight,
      numberOfDrivers: tmpData?.numberOfDrivers,
      loaded:
        (typeof tmpData?.loaded == "boolean" && tmpData?.loaded) ||
        tmpData?.loaded == "2" ||
        tmpData.loaded == "true"
          ? true
          : false,
      dangerousGoods: tmpData?.dangerousGoods,
      electricPlugin: tmpData?.electricPlugin,
      liveAnimals: tmpData?.liveAnimals,
      departureMonth: moment(tmpData?.departureDay).utc().month(),
      selectedPriceListId: tmpData?.selectedPriceListId,
      departureDate: moment(tmpData?.departureDay).format("YYYY-MM-DD"),
      vehicleWidth: tmpData?.vehicleWidth,
      vehicleHeight: tmpData?.vehicleHeight,
      material: tmpData?.material?.id,
      numberOfAxles: tmpData?.numberOfAxles,
      isComplexProduct: false,
      complexProductId: null,
      departureDay:
        moment(tmpData?.departureDay).utc().day() == 0
          ? 7
          : moment(tmpData?.departureDay).utc().day(),
      ticketStatus: tmpData?.status,
      extra: tmpData?.extra,
    })
      .then((res) => {
        setDeparturesLoading(false);
        let availableDeparturesTmp =
          res.data.body.productAvailableDeparturesDTOs;
        if (data?.status === "DONE" && data?.ticketSurcharges?.length > 0) {
          availableDeparturesTmp?.map((item) => {
            item?.ticketSurchargeList?.map((surchargeFromDeparture) => {
              let surcharge = data?.ticketSurcharges?.find(
                (ticketSurcharge) =>
                  ticketSurcharge?.productSurcharge?.id ===
                    surchargeFromDeparture?.productSurcharge?.id &&
                  ticketSurcharge?.productSurcharge?.showAfterDone === true
              );
              if (surcharge) {
                surchargeFromDeparture.quantity = surcharge?.quantity;
                surchargeFromDeparture.total = surcharge?.total;
              }
            });
          });
        }
        setAvailableDepartures(availableDeparturesTmp);
        setAllComplexDepartures(
          res.data.body.productAvailableComplexDeparturesDTOs
        );
        if (res.data.body.productAvailableComplexDeparturesDTOs != null) {
          if (
            Object.keys(res.data.body.productAvailableComplexDeparturesDTOs)
              .length == 0
          ) {
            setAvailableComplexDepartures(undefined);
            setSelectedComplexDepartureKey(undefined);
          } else if (
            Object.keys(res.data.body.productAvailableComplexDeparturesDTOs)
              .length == 1
          ) {
            setAvailableComplexDepartures(
              res.data.body.productAvailableComplexDeparturesDTOs[
                Object.keys(
                  res.data.body.productAvailableComplexDeparturesDTOs
                )[0]
              ]
            );

            setSelectedComplexDepartureKey(
              Object.keys(
                res.data.body.productAvailableComplexDeparturesDTOs
              )[0]
            );
          } else {
            Object.keys(
              res.data.body.productAvailableComplexDeparturesDTOs
            ).map((key) => {
              console.log(
                res.data.body.productAvailableComplexDeparturesDTOs[key]
              );
              Object.keys(
                res.data.body.productAvailableComplexDeparturesDTOs[key]
              ).map((key2) => {
                console.log(
                  res.data.body.productAvailableComplexDeparturesDTOs[key][key2]
                    .productAvailableDeparturesDTOList
                );
                // find first productAvailableDeparturesDTOList with length > 0 and productId == data.product.id
                res.data.body.productAvailableComplexDeparturesDTOs[key][
                  key2
                ].productAvailableDeparturesDTOList.map((item) => {
                  if (item?.productId == data?.product?.id) {
                    setAvailableComplexDepartures(
                      res.data.body.productAvailableComplexDeparturesDTOs[key]
                    );
                    setSelectedComplexDepartureKey(key);
                  }
                });
              });
            });
          }
        }

        if (
          editMode &&
          res.data.body.productAvailableDeparturesDTOs === null &&
          Object.keys(res.data.body.productAvailableComplexDeparturesDTOs)
            .length == 0
        ) {
          dispatch(
            notifyShowWarningMessage(
              "There are no available departures for this date"
            )
          );
        }
        if (res.data.body.priceLists?.length > 0) {
          setPriceLists(res.data.body.priceLists);
        }
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
        setDeparturesLoading(false);
        console.log(err);
      });
  };

  const handleCommunicationTabChange = (event, newValue) => {
    setCommunicationTabValue(newValue);
  };

  useEffect(() => {
    loadLifeCycleMessages();
  }, [data]);

  const loadLifeCycleMessages = async () => {
    await TicketRepository.getLifeCycle(data?.id).then((res) => {
      setLifeCycleMessages(res.data);
    });
  };

  const downloadAttachments = async () => {
    try {
      const response = await AttachmentRepository.downloadAll(data?.id);

      var link = document.createElement("a");
      link.href = URL.createObjectURL(new Blob([response.data]));
      link.download =
        `ticket - ${data?.id} - attachments.` +
        response.data.type.split("/").pop();
      link.click();
    } catch (err) {
      dispatch(notifyShowErrorMessage("Error while downloading attachments"));
    }
  };

  const downloadMessageAttachments = async () => {
    try {
      const response = await AttachmentRepository.downloadAllMessageAttachments(
        data?.id
      );

      var link = document.createElement("a");
      link.href = URL.createObjectURL(new Blob([response.data]));
      link.download =
        `ticket - ${data?.id} - message_attachments.` +
        response.data.type.split("/").pop();
      link.click();
    } catch (err) {
      dispatch(notifyShowErrorMessage("Error while downloading attachments"));
    }
  };

  const handleDownloadAttachment = (attachment) => {
    AttachmentRepository.download(attachment?.id)
      .then((res) => {
        let file;
        file = new Blob([res.data], {
          type: getMimeTypeByFileExtension(
            attachment?.fileExtension.split(".").pop()
          ),
        });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleDownloadMessageAttachment = (attachment) => {
    AttachmentRepository.downloadMessageAttachment(attachment?.id)
      .then((res) => {
        let file;
        file = new Blob([res.data], {
          type: getMimeTypeByFileExtension(
            attachment?.fileExtension.split(".").pop()
          ),
        });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUploadDocument = (file, setFile) => {
    if (file) {
      setFile(file);
    }
  };

  const handleOnProcessFile = (error, file) => {
    if (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Grid
        container
        spacing={3}
        sx={{ display: "flex", justifyContent: "space-between" }}
        mt={0.2}
      >
        {data?.ticketGroupId ? (
          ticketGroup ? (
            <>
              <Grid item md={12} sx={{ display: { xs: "none", md: "block" } }}>
                <TicketGroupDetails
                  data={data}
                  ticketGroup={ticketGroup}
                  loading={loading}
                  setTmpData={setTmpData}
                  setData={setData}
                  setChangeTicket={setChangeTicket}
                />
              </Grid>
              <Grid item xs={12} sx={{ display: { xs: "block", md: "none" } }}>
                <TicketGroupDetailsMobile
                  data={data}
                  ticketGroup={ticketGroup}
                  loading={loading}
                  setTmpData={setTmpData}
                  setData={setData}
                  setChangeTicket={setChangeTicket}
                />
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Skeleton height={80} />
            </Grid>
          )
        ) : (
          ""
        )}

        <Grid item xs={12} md={6}>
          <Paper
            className="roundedBorder"
            sx={{
              height: "797px",
              display: { xs: "none", md: "block" },
            }}
          >
            <Grid container spacing={2}>
              {data ? (
                <Grid
                  item
                  xs={12}
                  sx={{ display: { xs: "none", md: "block" } }}
                >
                  <ClientTicketHeader
                    data={data}
                    setData={setData}
                    setShowAuditLog={setShowAuditLog}
                    showAuditLog={showAuditLog}
                  />
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Grid
                    container
                    style={{
                      padding: "0 15px 0 15px",
                    }}
                  >
                    <Grid item xs={4}>
                      <Skeleton height="50px" width="80%" />
                    </Grid>
                    <Grid item xs={4}>
                      <Skeleton height="50px" width="80%" />{" "}
                    </Grid>
                    <Grid item xs={4}>
                      <Skeleton height="50px" width="80%" />{" "}
                    </Grid>
                  </Grid>
                </Grid>
              )}

              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container spacing={0}>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: {
                            xs: "none",
                            md: "block",
                            maxHeight:
                              data?.ticketSurcharges?.length > 0
                                ? "529px"
                                : "535px",
                            overflowY: "auto",
                          },
                        }}
                      >
                        <ClientTicketContent
                          data={data}
                          setData={setData}
                          readyForInvoice={readyForInvoice}
                          checkIfLocked={checkIfLocked}
                          invoiced={invoiced}
                          loadData={loadInitData}
                        />
                      </Grid>

                      {data?.showPrice ? (
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: {
                              xs: "none",
                              md: "block",
                              marginTop: "4px",
                            },
                          }}
                        >
                          {!departuresLoading && !loading ? (
                            <TicketPreviewAvailableDeparturesCalendar
                              availableDepartures={availableDepartures}
                              data={data}
                              handleChangeData={handleChangeData}
                              selectedDeparture={{
                                productDestinationId:
                                  data?.productDestination?.id,
                                priceId: data?.productPrice?.id,
                                departureDate: moment(data?.departureDay),
                                arrivalDay:
                                  data?.productDestinationDeparture?.arrivalDay,
                                ticketSurchargeList: data?.ticketSurcharges,
                                productDestinationDepartureId:
                                  data?.productDestinationDeparture?.id,
                                departureTime: data?.departureHour,
                                arrivalTime:
                                  data?.productDestinationDeparture
                                    ?.arrivalHour,
                              }}
                              setData={setData}
                            />
                          ) : (
                            <Grid container spacing={2}>
                              <Grid
                                item
                                xs={12}
                                style={{ padding: "0 20px 0 20px" }}
                              >
                                <Skeleton height={80} />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                style={{ padding: "0 20px 0 20px" }}
                              >
                                <Skeleton height={80} />
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          <Grid container sx={{ display: { xs: "block", md: "none" } }}>
            <Grid item xs={12}>
              <ClientTicketTabsMobile
                data={data}
                lifeCycleMessages={lifeCycleMessages}
                messages={messages}
                addNewMessage={addNewMessage}
                newMessage={newMessage}
                setNewMessage={setNewMessage}
                dialogMessageType={dialogMessageType}
                loading={loading}
                loadLifeCycleMessages={loadLifeCycleMessages}
                handleChangeMessageData={handleChangeMessageData}
                messageTemplates={messageTemplates}
                setData={setData}
                handleChangeData={handleChangeData}
                toCityCallback={toCityCallback}
                setToCityCallback={setToCityCallback}
                availableDepartures={availableDepartures}
                readyForInvoice={readyForInvoice}
                checkIfLocked={checkIfLocked}
                invoiced={invoiced}
                handleDownloadAttachment={handleDownloadAttachment}
                handleDownloadMessageAttachment={
                  handleDownloadMessageAttachment
                }
                downloadAttachments={downloadAttachments}
                downloadMessageAttachments={downloadMessageAttachments}
                files={files}
                setFiles={setFiles}
                chatDocument={chatDocument}
                setChatDocument={setChatDocument}
                handleUploadDocument={handleUploadDocument}
                handleOnProcessFile={handleOnProcessFile}
                openUpload={openUpload}
                setOpenUpload={setOpenUpload}
                messageAttachments={messageAttachments}
                loadData={loadInitData}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: { xs: "none", md: "block" } }}>
          <Paper className="roundedBorder" style={{ minHeight: "797px" }}>
            <TabContext value={communicationTabValue}>
              <Tabs
                value={communicationTabValue}
                onChange={handleCommunicationTabChange}
                aria-label="Communication tabs"
                sx={{
                  width: "100%",
                  marginTop: { xs: "10px", md: "-15px" },
                  marginBottom: "15px",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                  borderTopLeftRadius: "4px",
                  borderTopRightRadius: "4px",
                }}
                indicatorColor="none"
                textColor={theme.palette.darkContrast.main}
              >
                <Tab
                  value="1"
                  label={
                    <>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          md={3}
                          xs={5}
                          sx={{ display: "block", justifyContent: "end" }}
                        >
                          <Box
                            sx={{
                              width: { xs: 20, md: 28 },
                              height: { xs: 20, md: 28 },
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              color:
                                communicationTabValue === "1"
                                  ? theme.palette.secondary.main
                                  : "black",
                              backgroundColor: "white",
                              border:
                                communicationTabValue === "1"
                                  ? " "
                                  : "2px solid " +
                                    theme.palette.darkContrast.main,
                              borderRadius: "4px",
                              fontWeight: "bold",
                            }}
                          >
                            <Typography
                              sx={{ typography: { xs: "h6", md: "h5" } }}
                            >
                              {messages ? messages.length : "0"}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={9}
                          sx={{
                            display: "flex",
                            alignContent: "center",
                            alignItems: "center",
                            width: "100%",
                            overflow: "hidden",
                          }}
                        >
                          <Typography
                            // noWrap
                            sx={{
                              typography: { xs: "h6", xl: "h5" },
                              width: "100%",
                              display: { xs: "none", lg: "block" },
                              marginLeft: { md: "0px", xl: "-10px" },
                            }}
                          >
                            {"Communication".toLowerCase()}
                          </Typography>
                          <ChatOutlined
                            sx={{
                              marginLeft: "25px",
                              fontSize: "25px",
                              display: { xs: "block", lg: "none" },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </>
                  }
                  sx={{
                    flex: 1,
                    backgroundColor:
                      communicationTabValue === "1"
                        ? theme.palette.secondary.main
                        : "transparent",
                    color:
                      communicationTabValue === "1"
                        ? "white"
                        : theme.palette.darkContrast.main,
                    borderRight: "1px solid #c4c4c4",
                    borderBottom: "1px solid #c4c4c4",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                    height: "48px",
                  }}
                />
                <Tab
                  value="2"
                  label={
                    <>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          md={3}
                          xs={5}
                          sx={{
                            display: "block",
                            justifyContent: "end",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              width: { xs: 20, md: 28 },
                              height: { xs: 20, md: 28 },
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              color:
                                communicationTabValue === "2"
                                  ? theme.palette.secondary.main
                                  : "black",
                              backgroundColor: "white",
                              border:
                                communicationTabValue === "2"
                                  ? " "
                                  : "2px solid " +
                                    theme.palette.darkContrast.main,
                              borderRadius: "4px",
                              fontWeight: "bold",
                            }}
                          >
                            <Typography
                              sx={{ typography: { xs: "h6", md: "h5" } }}
                            >
                              {lifeCycleMessages
                                ? lifeCycleMessages.length
                                : "0"}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={9} sx={{ alignContent: "center" }}>
                          <Typography
                            noWrap
                            sx={{
                              typography: { xs: "h6", xl: "h5" },
                              width: "100%",
                              display: { xs: "none", lg: "block" },
                            }}
                          >
                            {"Booking Log".toLowerCase()}
                          </Typography>
                          <ChecklistRtlOutlined
                            sx={{
                              marginLeft: "25px",
                              fontSize: "25px",
                              display: { xs: "block", lg: "none" },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </>
                  }
                  sx={{
                    flex: 1,
                    backgroundColor:
                      communicationTabValue === "2"
                        ? theme.palette.secondary.main
                        : "transparent",
                    color:
                      communicationTabValue === "2"
                        ? "white"
                        : theme.palette.darkContrast.main,
                    borderRight: "1px solid #c4c4c4",
                    borderBottom: "1px solid #c4c4c4",
                    fontSize: "20px",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                    height: "48px",
                  }}
                />
                <Tab
                  value="3"
                  label={
                    <>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                          }}
                        >
                          <Typography
                            noWrap
                            sx={{
                              typography: { xs: "h6", xl: "h5" },
                              width: "100%",
                              display: { xs: "none", lg: "block" },
                            }}
                          >
                            {"Map".toLowerCase()}
                          </Typography>
                          <RouteOutlined
                            sx={{
                              fontSize: "25px",
                              display: { xs: "block", lg: "none" },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </>
                  }
                  sx={{
                    flex: 1,
                    backgroundColor:
                      communicationTabValue === "3"
                        ? theme.palette.secondary.main
                        : "transparent",
                    color:
                      communicationTabValue === "3"
                        ? "white"
                        : theme.palette.darkContrast.main,
                    borderBottom: "1px solid #c4c4c4",
                    borderRight: "1px solid #c4c4c4",
                    fontSize: "20px",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                    height: "48px",
                  }}
                />
                <Tab
                  value="4"
                  label={
                    <>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                          }}
                        >
                          <Typography
                            noWrap
                            sx={{
                              typography: { xs: "h6", xl: "h5" },
                              width: "100%",
                              display: { xs: "none", lg: "block" },
                            }}
                          >
                            {"Attachments".toLowerCase()}
                          </Typography>
                          <FilePresent
                            sx={{
                              fontSize: "25px",
                              display: { xs: "block", lg: "none" },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </>
                  }
                  sx={{
                    flex: 1,
                    backgroundColor:
                      communicationTabValue === "4"
                        ? theme.palette.secondary.main
                        : "transparent",
                    color:
                      communicationTabValue === "4"
                        ? "white"
                        : theme.palette.darkContrast.main,
                    borderBottom: "1px solid #c4c4c4",
                    fontSize: "20px",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                    height: "48px",
                  }}
                />
              </Tabs>

              <TabPanel value="1">
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      marginTop: "-15px",
                      minHeight: { md: "700px", xs: "600px" },
                    }}
                  >
                    <Grid item md={12}>
                      {messages ? (
                        <>
                          <ClientTicketCommunication
                            messages={messages}
                            ticket={data}
                            openUpload={openUpload}
                            handleDownloadMessageAttachment={
                              handleDownloadMessageAttachment
                            }
                          />
                          <Divider sx={{ paddingBottom: "10px" }} />
                        </>
                      ) : (
                        <>
                          <Skeleton height={60} />
                          <Skeleton height={60} />
                          <Skeleton height={60} />
                          <Skeleton height={60} />
                          <Skeleton height={60} />
                        </>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      {messages ? (
                        <ClientTicketNewMessageDialog
                          type={dialogMessageType}
                          addNewMessage={addNewMessage}
                          setNewMessage={setNewMessage}
                          message={newMessage}
                          handleChangeMessageData={handleChangeMessageData}
                          loading={loading}
                          messageTemplates={messageTemplates}
                          files={files}
                          setFiles={setFiles}
                          chatDocument={chatDocument}
                          setChatDocument={setChatDocument}
                          handleUploadDocument={handleUploadDocument}
                          handleOnProcessFile={handleOnProcessFile}
                          openUpload={openUpload}
                          setOpenUpload={setOpenUpload}
                        />
                      ) : (
                        <>
                          <Skeleton height={40} />
                          <Skeleton height={40} />
                          <Skeleton height={40} />
                          <Skeleton height={40} />
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value="2">
                <Grid
                  container
                  spacing={2}
                  sx={{ height: { md: "700px", xs: "600px" } }}
                >
                  <Grid item xs={12}>
                    {lifeCycleMessages ? (
                      <ClientTicketLogContent
                        data={data}
                        lifeCycleMessages={lifeCycleMessages}
                        loadLifeCycleMessages={loadLifeCycleMessages}
                        loading={loading}
                      />
                    ) : (
                      <>
                        <Skeleton height={40} />
                        <Skeleton height={40} />
                        <Skeleton height={40} />
                        <Skeleton height={40} />
                      </>
                    )}
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value="3" sx={{ padding: 0, marginTop: "-31px" }}>
                <Grid
                  container
                  spacing={2}
                  sx={{ height: { md: "765px", xs: "665px" } }}
                >
                  <Grid item xs={12}>
                    <ClientTicketPreviewOpenLayer
                      handleChangeData={handleChangeData}
                      setData={setData}
                      data={data}
                      fromCity={data?.fromCity}
                      toCity={data?.toCity}
                      toCityCallback={toCityCallback}
                      setToCityCallback={(value) => setToCityCallback(value)}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value="4" sx={{ padding: 0, marginTop: "-31px" }}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    height: { md: "765px", xs: "665px" },
                    overflowY: "auto",
                  }}
                >
                  <ClientTicketAttachments
                    data={data}
                    handleDownloadAttachment={handleDownloadAttachment}
                    handleDownloadMessageAttachment={
                      handleDownloadMessageAttachment
                    }
                    downloadAttachments={downloadAttachments}
                    downloadMessageAttachments={downloadMessageAttachments}
                    messageAttachments={messageAttachments}
                  />
                </Grid>
              </TabPanel>
            </TabContext>
          </Paper>
        </Grid>
      </Grid>

      <Outlet> </Outlet>
    </>
  );
}
