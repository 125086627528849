import { Box } from "@mui/material";
import Popup from "ol-popup";
import Feature from "ol/Feature";
import Map from "ol/Map";
import View from "ol/View";
import GeoJSON from "ol/format/GeoJSON";
import LineString from "ol/geom/LineString";
import Point from "ol/geom/Point";
import VectorLayer from "ol/layer/Vector";
import "ol/ol.css";
import { fromLonLat } from "ol/proj";
import VectorSource from "ol/source/Vector";
import { Fill, Icon, Stroke, Style } from "ol/style";
import React, { createRef, useEffect, useRef, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { createRoot } from "react-dom/client";
import ReactDOMServer from "react-dom/server";
import { DestinationsRepository } from "../../repositories/DestinationsRepository";
var _ = require("lodash");

export default function OpenLayerExplore({
  handleChangeData,
  data,
  setData,
  fromCities,
  toCityCallback,
  setToCityCallback,
}) {
  const mapElement = createRef(null);
  const fromCitiesTmp = useRef();
  const fromCity = useRef();
  const toCity = useRef();
  const [maxExtent, setMaxExtent] = useState(null);

  const popupElementRef = useRef(null);
  const vectorLayerRef = useRef(null);
  const rootRef = useRef(null);

  const popupElementToCityRef = useRef(null);
  const vectorLayerToCityRef = useRef(null);
  const rootToCityRef = useRef(null);

  const setFromCitiesPoints = () => {
    let fromPoints = [];
    fromCitiesTmp.current = fromCities;
    fromCities.forEach((city) => {
      if (city.latitude && city.longitude) {
        const cityElement = (
          <Box
            component="div"
            sx={{
              "& > img": { mr: 2, flexShrink: 0 },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            <ReactCountryFlag
              countryCode={city.country.code}
              svg
              style={{
                width: "1.8em",
                borderRadius: "8px",
                height: "1.8em",
                marginBottom: "5px",
                marginRight: "5px",
              }}
              title={city.country.name}
            />
            <b
              style={{
                fontSize: "16px",
                color: "#202020",
              }}
            >
              {city.name}
            </b>
          </Box>
        );

        fromPoints.push(
          new Feature({
            geometry: new Point(fromLonLat([city.longitude, city.latitude])),
            id: city.id,
            cityElement: cityElement,
          })
        );
      }
    });
    var vectorLayer = new VectorLayer({
      name: "fromCities",
      source: new VectorSource({
        features: fromPoints,
      }),
      style: getPinStyle(map.getView().getZoom()),
    });
    map.addLayer(vectorLayer);
  };

  const getPinStyle = (zoomLevel) => {
    const scale = 0.5 + zoomLevel * 0.05;
    return new Style({
      image: new Icon({
        src: "/map/pin.png",
        scale: scale,
      }),
    });
  };

  useEffect(() => {
    if (fromCities.length > 0) {
      setFromCitiesPoints();
    }
  }, [fromCities]);

  useEffect(() => {
    if (map && data.fromCity && data.fromCity.id) {
      addRoute(data.fromCity.id);
    }
  }, [data.fromCity]);

  useEffect(() => {
    if (map && data.fromCity) {
      setFromCityPoint();
    } else {
      if (popupElementRef.current) {
        let overlayContainer = document.querySelector(
          ".ol-overlaycontainer-stopevent"
        );
        if (
          overlayContainer &&
          overlayContainer.contains(popupElementRef.current)
        ) {
          if (rootRef.current) {
            const root = rootRef.current;
            setTimeout(() => {
              root?.unmount();
            }, 500);
          }
          overlayContainer.removeChild(popupElementRef.current);
        }
        popupElementRef.current = null;
      }
      if (vectorLayerRef.current) {
        map.removeLayer(vectorLayerRef.current);
        vectorLayerRef.current = null;
      }
    }
  }, [data?.fromCity]);

  useEffect(() => {
    if (map && data.toCity) {
      setToCityPoint();
    } else {
      if (popupElementToCityRef.current) {
        let overlayContainer = document.querySelector(
          ".ol-overlaycontainer-stopevent"
        );
        if (
          overlayContainer &&
          overlayContainer.contains(popupElementToCityRef.current)
        ) {
          if (rootToCityRef.current) {
            const root = rootToCityRef.current;
            setTimeout(() => {
              root?.unmount();
            }, 500);
          }
          overlayContainer.removeChild(popupElementToCityRef.current);
        }
        popupElementToCityRef.current = null;
      }
      if (vectorLayerToCityRef.current) {
        map.removeLayer(vectorLayerToCityRef.current);
        vectorLayerToCityRef.current = null;
      }
    }
  }, [data?.toCity]);

  let vectorLayer = new VectorLayer({
    format: new GeoJSON(),
  });

  vectorLayer.setSource(
    new VectorSource({
      loader: function (extent, resolution, projection) {
        // /maps/base.geo.json
        var url = `/map/hq.geo.json`;
        fetch(url)
          .then(function (response) {
            return response.json();
          })
          .then(function (json) {
            var features = new GeoJSON().readFeatures(json, {
              featureProjection: projection,
            });

            features.forEach((feature) => {
              feature.setStyle(
                new Style({
                  fill: new Fill({
                    color: "#d5d5d5",
                  }),
                  stroke: new Stroke({
                    color: "#949494",
                    width: 0.5,
                  }),
                })
              );
            });
            vectorLayer.getSource().addFeatures(features);
          });
      },
    })
  );

  const [map] = useState(
    new Map({
      layers: [vectorLayer],
      controls: [],
      target: mapElement.current,
      view: new View({
        center: fromLonLat([-2, 49]),
        zoom: 5,
        minZoom: 1,
        maxZoom: 10,
      }),
    })
  );

  const popup = new Popup({ offset: [10, 10] });
  map.addOverlay(popup);

  useEffect(() => {
    map.setTarget(mapElement.current);
  }, [mapElement.current]);

  map.getView().on("change:resolution", () => {
    const zoomLevel = map.getView().getZoom();
    vectorLayer.setStyle(getPinStyle(zoomLevel));
  });

  useEffect(() => {
    if (map) {
      if (data.fromCity && data.toCity) {
        map.getAllLayers().forEach((layer) => {
          if (layer && layer.get("name") === "routes") {
            layer
              .getSource()
              .getFeatures()
              .forEach((feature) => {
                if (
                  feature.values_.name ===
                  `${data.fromCity.name} (${data.fromCity.country.nameEn}) - ${data.toCity.name} (${data.toCity.country.nameEn})`
                ) {
                  feature.setStyle(
                    new Style({
                      stroke: new Stroke({
                        color: "#40a7b3",
                        width: "3",
                        lineDash: [10, 10],
                      }),
                      zIndex: 1,
                    })
                  );
                } else {
                  feature.setStyle(
                    new Style({
                      stroke: new Stroke({
                        color: "#949494",
                        width: "3",
                        lineDash: [10, 10],
                      }),
                    })
                  );
                }
              });
          }
        });
      } else if (data.fromCity && !data.toCity) {
        fromCity.current = data.fromCity;
        // addRoute(data.fromCity.id);
      } else if (
        (data.toCity && !data.fromCity) ||
        (!data.fromCity && !data.toCity)
      ) {
        handleChangeData("toCity", undefined);
        map
          .getLayers()
          .getArray()
          .filter(
            (layer) =>
              layer.get("name") === "routes" || layer.get("name") === "toCities"
          )
          .forEach((layer) => map.removeLayer(layer));
        map.setView(
          new View({
            center: fromLonLat([-2, 49]),
            zoom: 5,
            minZoom: 1,
            maxZoom: 10,
          })
        );
      }
    }
  }, [data.fromCity, data.toCity, toCityCallback]);

  if (map && !map.hasListener("pointermove"))
    map.on("pointermove", function (evt) {
      if (map.hasFeatureAtPixel(evt.pixel)) {
        map.getViewport().style.cursor = "pointer";
        addEventMove(map, evt);
      } else {
        map.getViewport().style.cursor = "inherit";
        popup.hide();
      }
    });

  var addEventMove = function (map, evt) {
    map.forEachFeatureAtPixel(
      evt.pixel,
      function (feature, layer) {
        if (
          layer &&
          (layer.get("name") === "fromCities" ||
            layer.get("name") === "toCities" ||
            layer.get("name") === "routes")
        ) {
          if (layer.get("name") === "routes") {
            popup.show(
              evt.coordinate,
              `<div style="background-color: white; border-radius: 10px; border: 1px solid #cccccc; padding: 10px;">${ReactDOMServer.renderToStaticMarkup(
                feature.values_.routeElement
              )}</div>`
            );
          } else if (feature.values_.cityElement) {
            popup.show(
              evt.coordinate,
              `<div style="background-color: white; border-radius: 10px; border: 1px solid #cccccc; padding: 10px;">${ReactDOMServer.renderToStaticMarkup(
                feature.values_.cityElement
              )}</div>`
            );
          }
        }
      },
      {
        hitTolerance: 0,
      }
    );
  };

  const animateAfterMaxExtent = (map, vectorLayer, vectorLayerToCities) => {
    const extents = [
      vectorLayer.getSource().getExtent(),
      vectorLayerToCities.getSource().getExtent(),
    ];

    const maxExtent = extents.reduce((acc, extent) => {
      return [
        Math.min(acc[0], extent[0]),
        Math.min(acc[1], extent[1]),
        Math.max(acc[2], extent[2]),
        Math.max(acc[3], extent[3]),
      ];
    }, extents[0]);

    setMaxExtent(maxExtent);

    map
      .getView()
      .fit(maxExtent, { duration: 1000, padding: [100, 100, 100, 100] });
  };

  const addRoute = (cityId) => {
    map
      .getLayers()
      .getArray()
      .filter(
        (layer) =>
          layer.get("name") === "routes" || layer.get("name") === "toCities"
      )
      .forEach((layer) => map.removeLayer(layer));
    DestinationsRepository.getAllDestinationsByAvailableCity({
      searchParams: {
        "fromCity.id": cityId,
      },
    })
      .then((res) => {
        let features = [];
        let fromPoints = [];
        res.data.forEach((destination) => {
          if (destination["coordinates"]) {
            let route = JSON.parse(destination["coordinates"]);
            route = route?.map((coordinate) =>
              fromLonLat([coordinate[0], coordinate[1]])
            );
            const routeElement = (
              <div style={{ display: "inline-flex" }}>
                <Box
                  component="div"
                  sx={{
                    "& > img": { flexShrink: 0 },
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "5px",
                  }}
                >
                  <ReactCountryFlag
                    countryCode={destination.fromCity.country.code}
                    svg
                    style={{
                      width: "1.8em",
                      borderRadius: "8px",
                      height: "1.8em",
                      marginBottom: "5px",
                      marginRight: "5px",
                    }}
                    title={destination.fromCity.country.name}
                  />
                </Box>
                <b
                  style={{
                    fontSize: "16px",

                    color: "#202020",
                  }}
                >
                  {destination.fromCity.name}
                </b>
                <Box
                  component="div"
                  sx={{
                    "& > img": { flexShrink: 0 },
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "5px",
                  }}
                >
                  <b
                    style={{
                      fontSize: "16px",
                      marginLeft: "5px",
                      marginRight: "5px",
                      color: "#202020",
                    }}
                  >
                    -
                  </b>
                </Box>
                <Box
                  component="div"
                  sx={{
                    "& > img": { mr: 2, flexShrink: 0 },
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "5px",
                  }}
                >
                  <ReactCountryFlag
                    countryCode={destination.toCity.country.code}
                    svg
                    style={{
                      width: "1.8em",
                      borderRadius: "8px",
                      height: "1.8em",
                      marginBottom: "5px",
                      marginRight: "5px",
                    }}
                    title={destination.toCity.country.name}
                  />
                </Box>
                <b
                  style={{
                    fontSize: "16px",
                    color: "#202020",
                  }}
                >
                  {destination.toCity.name}
                </b>
              </div>
            );

            features.push(
              new Feature({
                geometry: new LineString(route),
                name: `${destination.fromCity.name} (${destination.fromCity.country.nameEn}) - ${destination.toCity.name} (${destination.toCity.country.nameEn})`,
                id: destination.toCity.id,
                routeElement: routeElement,
              })
            );
            fromPoints.push(
              new Feature({
                geometry: new Point(
                  fromLonLat([
                    destination.toCity.longitude,
                    destination.toCity.latitude,
                  ])
                ),
                name: `${destination.fromCity.name} (${destination.fromCity.country.nameEn}) - ${destination.toCity.name} (${destination.toCity.country.nameEn})`,
                id: destination.toCity.id,
              })
            );
          }
        });
        var vectorLayerToCities = new VectorLayer({
          name: "toCities",
          source: new VectorSource({
            features: fromPoints,
          }),
          style: new Style({
            image: new Icon({
              src: "/map/pin.png",
              scale: 0.5,
            }),
          }),
        });
        let flag = false;
        map
          .getLayers()
          .getArray()
          .filter((layer) => layer.get("name") === "routes")
          .forEach((layer) => {
            layer.set(vectorLayerToCities);
            flag = true;
          });
        if (!flag) map.addLayer(vectorLayerToCities);
        let vectorLayer = new VectorLayer({
          name: "routes",
          source: new VectorSource({
            features: features,
          }),
          style: new Style({
            stroke: new Stroke({
              color: "#949494",
              width: "3",
              lineDash: [10, 10],
            }),
          }),
        });
        flag = false;
        map
          .getLayers()
          .getArray()
          .filter((layer) => layer.get("name") === "routes")
          .forEach((layer) => {
            layer.set(vectorLayer);
            flag = true;
          });
        map.addLayer(vectorLayer);

        animateAfterMaxExtent(map, vectorLayer, vectorLayerToCities);

        if (toCityCallback) {
          handleChangeData("toCity", toCityCallback);
          setToCityCallback(null);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  var addEvent = function (map, evt) {
    map.forEachFeatureAtPixel(
      evt.pixel,
      async function (feature, layer) {
        if (layer && layer.get("name") === "routes") {
          if (feature && feature.values_ && feature.values_.id) {
            toCity.current = fromCitiesTmp.current.filter(
              (item) => item.id === feature.values_.id
            )[0];
            let tmp = { ...data };
            tmp["toCity"] = toCity.current;
            tmp["fromCity"] = fromCity.current;
            setData(tmp);
          }
        } else if (layer && layer.get("name") === "toCities") {
          if (feature && feature.values_ && feature.values_.id) {
            toCity.current = fromCitiesTmp.current.filter(
              (item) => item.id === feature.values_.id
            )[0];
            let tmp = { ...data };
            tmp["toCity"] = toCity.current;
            tmp["fromCity"] = fromCity.current;
            setData(tmp);
          }
        } else if (layer && layer.get("name") === "fromCities") {
          if (feature) {
            fromCity.current = fromCitiesTmp.current.filter(
              (item) => item.id === feature.values_.id
            )[0];
            let tmp = { ...data };
            tmp["toCity"] = undefined;
            tmp["fromCity"] = fromCity.current;
            setData(tmp);
            // addRoute(feature.values_.id);

            if (feature.values_.cityElement) {
              popup.show(
                evt.coordinate,
                `<div style="background-color: white; border-radius: 10px; border: 1px solid #cccccc; padding: 10px;">${ReactDOMServer.renderToStaticMarkup(
                  feature.values_.cityElement
                )}</div>`
              );
            }
          }
        }
      },
      {
        hitTolerance: 0,
      }
    );
  };

  if (map && !map.hasListener("click"))
    map.on("click", function (evt) {
      addEvent(map, evt);
    });

  const setFromCityPoint = () => {
    if (data?.fromCity?.latitude && data?.fromCity?.longitude) {
      const cityElement = (
        <Box
          component="div"
          sx={{
            "& > img": { mr: 2, flexShrink: 0 },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "5px",
          }}
        >
          <ReactCountryFlag
            countryCode={data?.fromCity?.country?.code}
            svg
            style={{
              width: "1.8em",
              borderRadius: "8px",
              height: "1.8em",
              marginBottom: "5px",
              marginRight: "5px",
            }}
            title={data?.fromCity?.country?.name}
          />
          <b
            style={{
              fontSize: "16px",
              color: "#202020",
            }}
          >
            {data?.fromCity?.name}
          </b>
        </Box>
      );

      const fromPoint = new Feature({
        geometry: new Point(
          fromLonLat([data?.fromCity?.longitude, data?.fromCity?.latitude])
        ),
        id: data.fromCity.id,
      });

      if (vectorLayerRef.current) {
        map.removeLayer(vectorLayerRef.current);
      }

      const vectorLayer = new VectorLayer({
        name: "fromCity",
        source: new VectorSource({
          features: [fromPoint],
        }),
        style: getPinStyle(map.getView().getZoom()),
      });

      vectorLayerRef.current = vectorLayer;
      map.addLayer(vectorLayer);

      if (popupElementRef.current) {
        const overlayContainer = document.querySelector(
          ".ol-overlaycontainer-stopevent"
        );

        if (
          overlayContainer &&
          overlayContainer.contains(popupElementRef.current)
        ) {
          if (rootRef.current) {
            const root = rootRef.current;
            setTimeout(() => {
              root?.unmount();
            }, 500);
          }
          overlayContainer.removeChild(popupElementRef.current);
        }
      }

      const newPopupElement = document.createElement("div");
      newPopupElement.className = "popup";
      newPopupElement.style.position = "absolute";
      newPopupElement.style.backgroundColor = "white";
      newPopupElement.style.border = "1px solid #ccc";
      newPopupElement.style.borderRadius = "4px";
      newPopupElement.style.padding = "2px 5px 2px 5px";
      newPopupElement.style.boxShadow = "0 2px 5px rgba(0, 0, 0, 0.3)";
      newPopupElement.style.pointerEvents = "none";

      // ReactDOM.render(cityElement, newPopupElement);
      const root = createRoot(newPopupElement);
      root.render(cityElement);
      rootRef.current = root;
      popupElementRef.current = newPopupElement;

      const updatePopupPosition = () => {
        const coordinate = fromLonLat([
          data?.fromCity?.longitude,
          data?.fromCity?.latitude,
        ]);
        const pixel = map.getPixelFromCoordinate(coordinate);

        if (pixel) {
          newPopupElement.style.top = `${pixel[1] - 50}px`;
          newPopupElement.style.left = `${pixel[0] - 30}px`;
        } else {
          console.error(
            "Pixel conversion returned null for coordinate:",
            coordinate
          );
        }
      };

      const overlayContainer = document.querySelector(
        ".ol-overlaycontainer-stopevent"
      );
      if (overlayContainer) {
        overlayContainer.appendChild(newPopupElement);
        updatePopupPosition();

        map.on("moveend", updatePopupPosition);
      } else {
        console.error("Overlay container not found.");
        setTimeout(setFromCityPoint, 100);
      }

      return () => {
        if (popupElementRef.current) {
          const overlayContainer = document.querySelector(
            ".ol-overlaycontainer-stopevent"
          );
          if (
            overlayContainer &&
            overlayContainer.contains(popupElementRef.current)
          ) {
            root.unmount();
            overlayContainer.removeChild(popupElementRef.current);
          }
          popupElementRef.current = null;
        }
        if (vectorLayerRef.current) {
          map.removeLayer(vectorLayerRef.current);
          vectorLayerRef.current = null;
        }
      };
    }
  };

  const setToCityPoint = () => {
    if (data?.toCity?.latitude && data?.toCity?.longitude) {
      const cityElement = (
        <Box
          component="div"
          sx={{
            "& > img": { mr: 2, flexShrink: 0 },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "5px",
          }}
        >
          <ReactCountryFlag
            countryCode={data?.toCity?.country?.code}
            svg
            style={{
              width: "1.8em",
              borderRadius: "8px",
              height: "1.8em",
              marginBottom: "5px",
              marginRight: "5px",
            }}
            title={data?.toCity?.country?.name}
          />
          <b
            style={{
              fontSize: "16px",
              color: "#202020",
            }}
          >
            {data?.toCity?.name}
          </b>
        </Box>
      );

      const toPoint = new Feature({
        geometry: new Point(
          fromLonLat([data?.toCity?.longitude, data?.toCity?.latitude])
        ),
        id: data.toCity.id,
      });

      if (vectorLayerToCityRef.current) {
        map.removeLayer(vectorLayerToCityRef.current);
      }

      const vectorLayer = new VectorLayer({
        name: "toCity",
        source: new VectorSource({
          features: [toPoint],
        }),
        style: getPinStyle(map.getView().getZoom()),
      });

      vectorLayerToCityRef.current = vectorLayer;
      map.addLayer(vectorLayer);

      if (popupElementToCityRef.current) {
        const overlayContainer = document.querySelector(
          ".ol-overlaycontainer-stopevent"
        );

        if (
          overlayContainer &&
          overlayContainer.contains(popupElementToCityRef.current)
        ) {
          if (rootToCityRef.current) {
            const root = rootToCityRef.current;
            setTimeout(() => {
              root?.unmount();
            }, 500);
          }
          overlayContainer.removeChild(popupElementToCityRef.current);
        }
      }

      const newPopupElement = document.createElement("div");
      newPopupElement.className = "popup";
      newPopupElement.style.position = "absolute";
      newPopupElement.style.backgroundColor = "white";
      newPopupElement.style.border = "1px solid #ccc";
      newPopupElement.style.borderRadius = "4px";
      newPopupElement.style.padding = "2px 5px 2px 5px";
      newPopupElement.style.boxShadow = "0 2px 5px rgba(0, 0, 0, 0.3)";
      newPopupElement.style.pointerEvents = "none";

      // ReactDOM.render(cityElement, newPopupElement);
      const root = createRoot(newPopupElement);
      root.render(cityElement);
      rootToCityRef.current = root;
      popupElementToCityRef.current = newPopupElement;

      const updatePopupPosition = () => {
        const coordinate = fromLonLat([
          data?.toCity?.longitude,
          data?.toCity?.latitude,
        ]);
        const pixel = map.getPixelFromCoordinate(coordinate);

        if (pixel) {
          newPopupElement.style.top = `${pixel[1] - 50}px`;
          newPopupElement.style.left = `${pixel[0] - 30}px`;
        } else {
          console.error(
            "Pixel conversion returned null for coordinate:",
            coordinate
          );
        }
      };

      const overlayContainer = document.querySelector(
        ".ol-overlaycontainer-stopevent"
      );
      if (overlayContainer) {
        overlayContainer.appendChild(newPopupElement);
        updatePopupPosition();

        map.on("moveend", updatePopupPosition);
      } else {
        console.error("Overlay container not found.");
        setTimeout(setFromCityPoint, 100);
      }

      return () => {
        if (popupElementToCityRef.current) {
          const overlayContainer = document.querySelector(
            ".ol-overlaycontainer-stopevent"
          );
          if (
            overlayContainer &&
            overlayContainer.contains(popupElementToCityRef.current)
          ) {
            root.unmount();
            overlayContainer.removeChild(popupElementToCityRef.current);
          }
          popupElementToCityRef.current = null;
        }
        if (vectorLayerToCityRef.current) {
          map.removeLayer(vectorLayerToCityRef.current);
          vectorLayerToCityRef.current = null;
        }
      };
    }
  };

  return (
    <div
      className="mapRow"
      style={{
        textAlign: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <div
        id="map"
        ref={mapElement}
        className="map-container"
        style={{
          height: "95vh",
          overflow: "hidden",
          width: "100%",
          clipPath: "inset(0 round 0.5em)",
        }}
      />
    </div>
  );
}
