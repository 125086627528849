import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  alpha,
  Box,
  Divider,
  Grid,
  Icon,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import ReactCountryFlag from "react-country-flag";
import EastIcon from "@mui/icons-material/East";
import { useTheme } from "@emotion/react";
import moment from "moment";
import { ExpandMore } from "@mui/icons-material";

export default function PricingMobile({
  data,
  selectedDeparture,
  surchargesTotal,
}) {
  const ticketSurchargeList = selectedDeparture?.ticketSurchargeList || [];
  const theme = useTheme();

  return (
    <>
      <Grid
        container
        direction="column"
        spacing={2}
        mb={2}
        sx={{ height: "510px" }}
      >
        <Grid
          container
          spacing={2}
          sx={{ marginLeft: "10px", marginTop: "-60px" }}
        >
          {data?.fromCity && (
            <Grid item xs={5}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                }}
              >
                <ReactCountryFlag
                  countryCode={data.fromCity.country.code}
                  svg
                  style={{
                    width: "1.6em",
                    height: "1.6em",
                    borderRadius: "8px",
                    marginRight: "4px",
                  }}
                  title={data.fromCity.country.name}
                />
                <span style={{ fontSize: "16px" }}>{data.fromCity.name}</span>
              </Box>
            </Grid>
          )}

          {data?.fromCity && data?.toCity && (
            <Grid item xs={1}>
              <EastIcon style={{ marginTop: "3px" }} />
            </Grid>
          )}

          {data?.toCity && (
            <Grid item xs={4}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                }}
              >
                <ReactCountryFlag
                  countryCode={data.toCity.country.code}
                  svg
                  style={{
                    width: "1.6em",
                    height: "1.6em",
                    borderRadius: "8px",
                    marginRight: "4px",
                  }}
                  title={data.toCity.country.name}
                />
                <span style={{ fontSize: "16px" }}>{data.toCity.name}</span>
              </Box>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} sx={{ marginRight: "16px" }}>
          <Box
            sx={{
              backgroundColor: "#88c078",
              color: "white",
              border: "none",
              boxShadow: "none",
              position: "relative",
              padding: "0 10px",
              borderRadius: "6px 6px 0 0",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#88c078",
                color: "white",
                padding: "0 15px",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              <Grid
                container
                style={{ marginBottom: "10px", marginLeft: "-10px" }}
              >
                <Grid item xs={1} style={{ marginTop: "4px" }}>
                  <Tooltip title={selectedDeparture?.level}>
                    {selectedDeparture?.levelValue === "MEDIUM" && (
                      <Icon
                        style={{
                          verticalAlign: "middle",
                          height: "24px",
                          width: "24px",
                        }}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_504_7214)">
                            <rect
                              x="5.50012"
                              y="11"
                              width="3"
                              height="6"
                              rx="1"
                              fill="#E9B145"
                              style={{
                                fill: "#E9B145",
                                fill: "white",
                                fillOpacity: 1,
                              }}
                            />
                            <rect
                              x="10.5001"
                              y="9"
                              width="3"
                              height="8"
                              rx="1"
                              fill="#E9B145"
                              style={{
                                fill: "#E9B145",
                                fill: "white",
                                fillOpacity: 1,
                              }}
                            />
                            <rect
                              x="15.5001"
                              y="7"
                              width="3"
                              height="10"
                              rx="1"
                              fill="#E9B145"
                              fill-opacity="0.25"
                              style={{
                                fill: "#E9B145",
                                fill: "white",
                                fillOpacity: 0.25,
                              }}
                            />
                          </g>
                          <rect
                            x="0.500122"
                            y="0.5"
                            width="23"
                            height="23"
                            rx="3.5"
                            stroke="#E9B145"
                            style={{
                              stroke: "#E9B145",
                              stroke: "white",
                              strokeOpacity: 1,
                            }}
                          />
                          <defs>
                            <clipPath id="clip0_504_7214">
                              <rect
                                x="0.00012207"
                                width="24"
                                height="24"
                                rx="4"
                                fill="white"
                                style={{
                                  fill: "white",
                                  fillOpacity: 1,
                                }}
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </Icon>
                    )}
                  </Tooltip>
                  <Tooltip title={selectedDeparture?.level}>
                    {" "}
                    {selectedDeparture?.levelValue === "HIGH" && (
                      <Icon
                        style={{
                          verticalAlign: "middle",
                          height: "24px",
                          width: "24px",
                        }}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_504_7228)">
                            <rect
                              x="5.50012"
                              y="11"
                              width="3"
                              height="6"
                              rx="1"
                              fill="white"
                              style={{
                                fill: "white",
                                fill: "white",
                                fillOpacity: 1,
                              }}
                            />
                            <rect
                              x="10.5001"
                              y="9"
                              width="3"
                              height="8"
                              rx="1"
                              fill="white"
                              style={{
                                fill: "white",
                                fill: "white",
                                fillOpacity: 1,
                              }}
                            />
                            <rect
                              x="15.5001"
                              y="7"
                              width="3"
                              height="10"
                              rx="1"
                              fill="white"
                              style={{
                                fill: "white",
                                fill: "white",
                                fillOpacity: 1,
                              }}
                            />
                          </g>
                          <rect
                            x="0.500122"
                            y="0.5"
                            width="23"
                            height="23"
                            rx="3.5"
                            stroke="white"
                            style={{
                              stroke: "white",
                              stroke: "white",
                              strokeOpacity: 1,
                            }}
                          />
                          <defs>
                            <clipPath id="clip0_504_7228">
                              <rect
                                x="0.00012207"
                                width="24"
                                height="24"
                                rx="4"
                                fill="white"
                                style={{
                                  fill: "white",
                                  fillOpacity: 1,
                                }}
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </Icon>
                    )}
                  </Tooltip>
                  <Tooltip title={selectedDeparture?.level}>
                    {selectedDeparture?.levelValue === "LOW" && (
                      <Icon
                        style={{
                          verticalAlign: "middle",
                          height: "24px",
                          width: "24px",
                        }}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_504_7314)">
                            <rect
                              x="5.5"
                              y="11"
                              width="3"
                              height="6"
                              rx="1"
                              fill="white"
                              style={{
                                fill: "white",
                                fillOpacity: 1,
                              }}
                            />
                            <rect
                              x="10.5"
                              y="9"
                              width="3"
                              height="8"
                              rx="1"
                              fill="white"
                              fill-opacity="0.25"
                              style={{
                                fill: "white",
                                fillOpacity: 0.25,
                              }}
                            />
                            <rect
                              x="15.5"
                              y="7"
                              width="3"
                              height="10"
                              rx="1"
                              fill="white"
                              fill-opacity="0.25"
                              style={{
                                fill: "white",
                                fillOpacity: 0.25,
                              }}
                            />
                          </g>
                          <rect
                            x="0.5"
                            y="0.5"
                            width="23"
                            height="23"
                            rx="3.5"
                            stroke="white"
                            style={{
                              stroke: "white",
                              strokeOpacity: 1,
                            }}
                          />
                          <defs>
                            <clipPath id="clip0_504_7314">
                              <rect
                                width="24"
                                height="24"
                                rx="4"
                                fill="white"
                                style={{
                                  fill: "white",
                                  fillOpacity: 1,
                                }}
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </Icon>
                    )}
                  </Tooltip>
                </Grid>
                <Grid item xs={11}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                        overflow: "visible",
                        fontSize: "18px",
                        flexShrink: 0,
                        marginLeft: "10px",
                      }}
                    >
                      {selectedDeparture?.operatorName
                        ? selectedDeparture?.operatorName
                        : selectedDeparture?.supplier?.name}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "10px",
                        color: "white",
                        marginTop: "-9px",
                        whiteSpace: "nowrap",
                        marginLeft: "10px",
                      }}
                    >
                      {selectedDeparture?.level}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sx={{ marginBottom: "15px", marginLeft: "-10px" }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Typography variant="h6" sx={{ opacity: "50%" }}>
                        Departure
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          marginTop: "-5px",
                        }}
                      >
                        {selectedDeparture?.departureTime}
                      </Typography>
                      <Typography style={{ marginTop: "-5px" }}>
                        {moment(selectedDeparture?.departureDate).format(
                          "DD.MM.YYYY"
                        )}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sx={{ alignContent: "center", marginLeft: "28px" }}
                    >
                      <Typography variant="h5">
                        <EastIcon
                          style={{ fontSize: "22px", marginLeft: "10px" }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item xs={4} style={{ marginLeft: "20px" }}>
                      <Typography
                        variant="h6"
                        sx={{ opacity: "50%", marginLeft: "45px" }}
                      >
                        Arrival
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          marginTop: "-5px",
                          marginLeft: "45px",
                        }}
                      >
                        {selectedDeparture?.arrivalTime}
                      </Typography>
                      <Typography
                        style={{ marginTop: "-5px", marginLeft: "45px" }}
                      >
                        {selectedDeparture?.arrivalDay == "0"
                          ? moment(selectedDeparture?.departureDate).format(
                              "DD.MM.YYYY"
                            )
                          : selectedDeparture?.arrivalDay == "1"
                          ? moment(selectedDeparture?.departureDate)
                              .add("days", 1)
                              .format("DD.MM.YYYY")
                          : selectedDeparture?.arrivalDay == "2"
                          ? moment(selectedDeparture?.departureDate)
                              .add("days", 2)
                              .format("DD.MM.YYYY")
                          : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ padding: "0 5px 0 20px" }}>
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    backgroundColor: "rgba(160, 205, 147, 0.1)",
                    borderRadius: "5px",
                    minWidth: "270px",
                    overflow: "hidden",
                  }}
                >
                  {ticketSurchargeList.map((surcharge, index) => (
                    <Grid
                      item
                      xs={12}
                      key={index}
                      sx={{
                        height: "60px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",

                        backgroundColor:
                          index % 2 !== 0
                            ? "rgba(160, 205, 147, 0.3)"
                            : "rgba(160, 205, 147, 0.6)",

                        padding: "15px",
                      }}
                    >
                      <Typography sx={{ flex: 1, ...theme.typography.h6 }}>
                        {surcharge.surchargeName}
                      </Typography>
                      {/* {data?.organization?.showPrice && (
                        <Typography
                          sx={{
                            flex: 1,
                            textAlign: "center",
                            ...theme.typography.h6,
                          }}
                        >
                          {surcharge.quantity}
                        </Typography>
                      )}
                      {data?.organization?.showPrice && (
                        <Typography
                          sx={{
                            flex: 1,
                            textAlign: "center",
                            ...theme.typography.h6,
                          }}
                        >
                          x
                        </Typography>
                      )}
                      {data?.organization?.showPrice && (
                        <Typography
                          sx={{
                            flex: 1,
                            textAlign: "center",
                            ...theme.typography.h6,
                          }}
                        >
                          {parseFloat(surcharge.total).toFixed(2)}&euro;
                        </Typography>
                      )}
                      {data?.organization?.showPrice && (
                        <Typography
                          sx={{
                            flex: 1,
                            textAlign: "center",
                            ...theme.typography.h6,
                          }}
                        >
                          =
                        </Typography>
                      )} */}
                      {selectedDeparture &&
                      selectedDeparture?.salesPrice != null &&
                      selectedDeparture?.price != null ? (
                        <Typography
                          sx={{
                            flex: 1,
                            textAlign: "center",
                            ...theme.typography.h6,
                          }}
                        >
                          {parseFloat(surcharge.total).toFixed(2)}
                          &euro;
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Grid item xs={12}>
            <Box
              sx={{
                backgroundColor: "#88c078",
                color: "white",
                border: "none",
                boxShadow: "none",
                padding: "10px 15px",
                borderRadius: " 0 0 6px 6px",
              }}
            >
              {selectedDeparture &&
              selectedDeparture?.salesPrice != null &&
              selectedDeparture?.price != null ? (
                <Grid container spacing={2} justifyContent="center">
                  <Grid item md={4}>
                    <Typography sx={{ opacity: "50%" }} align="left">
                      Total Freight
                    </Typography>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        // marginTop: 10,
                      }}
                      align="left"
                    >
                      {data?.adjustedCalculatedPrice ? (
                        <>
                          {parseFloat(data?.adjustedCalculatedPrice)?.toFixed(
                            2
                          )}{" "}
                          &euro;
                        </>
                      ) : (
                        <>{selectedDeparture?.salesPrice?.toFixed(2)}&euro;</>
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      sx={{
                        opacity: "50%",
                        marginLeft: "20px",
                        marginRight: "20px",
                      }}
                      align="center"
                    >
                      Total Surcharges
                    </Typography>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        // marginTop: 10,
                      }}
                      align="center"
                    >
                      {surchargesTotal ? (
                        <> {surchargesTotal?.toFixed(2)}&euro;</>
                      ) : (
                        "/"
                      )}
                    </Typography>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography sx={{ opacity: "50%" }} align="center">
                      Total Price
                    </Typography>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "14px",
                      }}
                      align="right"
                    >
                      {surchargesTotal ? (
                        data?.adjustedCalculatedPrice ? (
                          <>
                            {(
                              parseFloat(data?.adjustedCalculatedPrice) +
                              surchargesTotal -
                              selectedDeparture?.surchargesIncludedInSalesPrice
                            )?.toFixed(2)}
                            &euro;
                          </>
                        ) : (
                          <>
                            {(
                              selectedDeparture?.salesPrice +
                              surchargesTotal -
                              selectedDeparture?.surchargesIncludedInSalesPrice
                            )?.toFixed(2)}
                            &euro;
                          </>
                        )
                      ) : data?.adjustedCalculatedPrice ? (
                        <>
                          <>
                            {parseFloat(data?.adjustedCalculatedPrice)?.toFixed(
                              2
                            )}
                            &euro;
                          </>
                        </>
                      ) : (
                        <>{selectedDeparture?.salesPrice?.toFixed(2)}&euro;</>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
