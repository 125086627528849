import Axios from "axios";
import { SETTINGS } from "../properties/Settings";
import { get } from "ol/proj";

export const ConsignmentRepository = {
  save: (data) => {
    return Axios({
      url: `${SETTINGS.API_URL}consignments/save`,
      method: "POST",
      data: data,
    });
  },
  getConsignmentById: (id) => {
    return Axios({
      url: `${SETTINGS.API_URL}consignments/${id}`,
      method: "GET",
    });
  },
  addConsignmentToTicket: (data, ticketId) => {
    return Axios({
      url: `${SETTINGS.API_URL}consignments`,
      method: "POST",
      data: data,
      params: {
        ticketId: ticketId,
      },
    });
  },
  deleteConsignment: (id, ticketId) => {
    return Axios({
      url: `${SETTINGS.API_URL}consignments/delete/${id}`,
      params: {
        ticketId: ticketId,
      },
      method: "DELETE",
    });
  },
  getConsignmentStatuses: () => {
    return Axios({
      url: `${SETTINGS.API_URL}consignments/statuses`,
      method: "GET",
    });
  },

  getConsignmentMainTransportDocuments: () => {
    return Axios({
      url: `${SETTINGS.API_URL}consignments/mainTransportDocuments`,
      method: "GET",
    });
  },

  getConsignmentPackageTypes: () => {
    return Axios({
      url: `${SETTINGS.API_URL}consignments/packageTypes`,
      method: "GET",
    });
  },
  getAllConsignments: (
    page,
    size,
    filterParams,
    orderBy = "dateCreated",
    orderDirection = "DESC"
  ) => {
    return Axios({
      url: `${SETTINGS.API_URL}consignments/all`,
      method: "GET",
      params: {
        page: page,
        size: size,
        searchParams: filterParams,
        orderBy: orderBy,
        orderDirection: orderDirection,
      },
    });
  },
  getCustomsOffices: () => {
    return Axios({
      url: `${SETTINGS.API_URL}customsOffice/all`,
      method: "GET",
    });
  },
  //   sendEnsViaApi: (ticketId) => {
  //     return Axios({
  //       url: `${SETTINGS.API_URL}consignments/sendEnsViaApi`,
  //       method: "POST",
  //       params: {
  //         ticketId: ticketId,
  //       },
  //     });
  //   },
};
