import Axios from "axios";
import { SETTINGS } from "../properties/Settings";

export const SuppliersRepository = {
  getBySearchString: (searchString) => {
    return Axios({
      url: `${SETTINGS.API_URL}suppliers/getBySearchString`,
      method: "GET",
      params: {
        searchString: searchString,
      },
    });
  },

  findAllForWeb: () => {
    return Axios({
      url: `${SETTINGS.API_URL}suppliers/findAllForWeb`,
      method: "GET",
    });
  },
};
