import Axios from "axios";
import { SETTINGS } from "../properties/Settings";

export const CountriesV2Repository = {
  fetchAllWithoutPaging: () => {
    return Axios({
      url: SETTINGS.API_URL + "countries/v2/all",
      method: "GET",
    });
  },
  getCitiesByCountry: (code) => {
    return Axios.get(`${SETTINGS.API_URL}cities/v2/country/${code}`);
  },

  get: (id) => {
    return Axios.get(`${SETTINGS.API_URL}countries/v2/${id}`);
  },
};
