import React, { useState, useEffect } from "react";
import {
  Grid,
  List,
  ListItem,
  Typography,
  Box,
  Tooltip,
  Zoom,
  Chip,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import moment from "moment";

export function ClientTicketLogContent({
  data,
  lifeCycleMessages,
  loadLifeCycleMessages,
  loading,
}) {
  const theme = useTheme();
  const statusColors = {
    APPROVED: theme.palette.secondary.main,
    PENDING: theme.palette.extra3.main,
    IN_PROGRESS: theme.palette.in_progress.main,
    REJECTED: theme.palette.rejected.main,
    CANCELLED: theme.palette.canceled.main,
    DONE: theme.palette.done.main,
    CREATED: theme.palette.extra1.main,
    UPDATED: "#f5d96d",
    DELETED: theme.palette.error.main,
    NO_SHOW: theme.palette.mutedRed.main,
  };

  return (
    <List
      sx={{
        padding: { xs: 0, md: "0 10px 0 10px" },
        maxHeight: "670px",
        overflowY: "scroll",
        overflowX: "hidden",
        "&::-webkit-scrollbar": {
          width: "0px",
          background: "transparent",
        },
        scrollbarWidth: "none",
      }}
    >
      {lifeCycleMessages.map((message) => {
        let statusInArray = "";
        let statusLabel = "";
        if (message.message.includes("completed")) {
          statusInArray = "DONE";
          statusLabel = "Completed";
        } else if (message.message.includes("IN PROGRESS")) {
          statusInArray = "IN_PROGRESS";
          statusLabel = "In progress";
        } else if (message.message.includes("rejected")) {
          statusInArray = "REJECTED";
          statusLabel = "Rejected";
        } else if (message.message.includes("approved")) {
          statusInArray = "APPROVED";
          statusLabel = "Approved";
        } else if (message.message.includes("created")) {
          statusInArray = "CREATED";
          statusLabel = "Created";
        } else if (message.message.includes("deleted")) {
          statusInArray = "DELETED";
          statusLabel = "Deleted";
        } else if (message.message.includes("No Show")) {
          statusInArray = "NO_SHOW";
          statusLabel = "No show";
        } else {
          statusInArray = "UPDATED";
          statusLabel = "Updated";
        }

        return (
          <ListItem
            key={message.id}
            sx={{
              height: { xs: "90px", md: "60px" },
              marginRight: "10px",
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={4} md={3.5} xl={2}>
                {statusLabel && (
                  <Chip
                    label={
                      <Typography
                        variant="h6"
                        sx={{
                          textTransform: "capitalize",
                          textAlign: "center",
                          fontSize: { xs: "11px" },
                        }}
                      >
                        {statusLabel.toLowerCase()}
                      </Typography>
                    }
                    sx={{
                      borderRadius: "5px",
                      backgroundColor: statusColors[statusInArray],
                      color: "white",
                      height: { xs: 25, md: 35 },
                      width: { xs: 85, md: 120 },
                      textAlign: "center",
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={6} md={8}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      sx={{
                        color: theme.palette.lightGray.main,
                      }}
                    >
                      {moment(message?.dateCreated).format("DD.MM.YYYY HH:mm")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "bold",
                        fontSize: { xs: "11px" },
                      }}
                    >
                      {message.message}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                md={1}
                xs={2}
                sx={{
                  position: "absolute",
                  right: 0,
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <Tooltip
                  title={
                    <Typography variant="h6" sx={{ fontWeight: "light" }}>
                      {message?.createdBy
                        ? message?.createdBy?.firstName +
                          " " +
                          message?.createdBy?.lastName
                        : "Asterix"}
                    </Typography>
                  }
                  arrow
                  TransitionComponent={Zoom}
                >
                  <Box
                    sx={{
                      width: { xs: 28, md: 32 },
                      height: { xs: 28, md: 32 },
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "white",
                      color: "black",
                      border: "2px solid lightGray",
                      borderRadius: "4px",
                      fontWeight: "bold",
                    }}
                  >
                    <Typography variant="h6" fontWeight="bold">
                      {message?.createdBy
                        ? message?.createdBy?.firstName[0] +
                          message?.createdBy?.lastName[0]
                        : "A"}
                    </Typography>
                  </Box>
                </Tooltip>
              </Grid>
            </Grid>
          </ListItem>
        );
      })}
    </List>
  );
}
