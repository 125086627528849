import { useTheme } from "@emotion/react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  alpha,
  AppBar,
  Box,
  Breadcrumbs,
  Button,
  Chip,
  Grid,
  Icon,
  IconButton,
  Paper,
  Skeleton,
  Snackbar,
  SnackbarContent,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  Divider,
  Alert,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import _ from "lodash";
import React, { act, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { moment } from "../App";
import {
  notifyShowErrorMessage,
  notifyShowSuccessMessage,
} from "../common/CommonActions";
import { LOCALE } from "../properties/Locale";
import { AuthRepository } from "../repositories/AuthRepository";
import { DestinationCitiesRepository } from "../repositories/DestinationCitiesRepository";
import { MaterialsRepository } from "../repositories/MaterialsRepository";
import { ProductRepository } from "../repositories/ProductRepository";
import { TicketRepository } from "../repositories/TicketRepository";
import { VehicleCategoriesRepository } from "../repositories/VehicleCategoriesRepository";
import AgentComplexTicketDetails from "./AgentComplexTicketDetails";
import { AgentNewTicketValidator } from "./AgentNewTicketValidator";
import AgentOneFormTicketDestinationDetails from "./AgentOneFormTicketDestinationDetails";
import AvailableDeparturesCalendar from "./AvailableDeparturesCalendar";
import ChooseComplexDeparture from "./ChooseComplexDeparture";
import ComplexPricing from "./ComplexPricing";
import OpenLayer from "./map/OpenLayer";
import Pricing from "./Pricing";
import AvailableDeparturesCalendarMobile from "./mobile/AvailableDeparturesCalendarMobile";
import PricingMobile from "./mobile/PricingMobile";
import ComplexPricingMobile from "./mobile/ComplexPricingMobile";
import { TabPanel } from "@mui/lab";
import { FilePond } from "react-filepond";
import {
  AttachFile,
  AttachFileOutlined,
  ConfirmationNumber,
  ConfirmationNumberOutlined,
  InfoOutlined,
  LocalAtm,
  LocalAtmOutlined,
  Route,
  RouteOutlined,
  Today,
  TodayOutlined,
} from "@mui/icons-material";
import { SettingsRepository } from "../repositories/SettingsRepository";
export default function CreateTicket() {
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [data, setData] = useState({
    loaded: "2",
    numberOfDrivers: 1,
    departureDay: moment(),
    dangerousGoods: false,
    electricPlugin: false,
    liveAnimals: false,
    cargoWeight: 1,
    vehicleLength: 17,
    vehicleWidth: 2,
    vehicleHeight: 4,
    source: "WEB",
    organization: AuthRepository.getUserDetails()?.organization,
    showPrice: AuthRepository.getUserDetails()?.organization?.showPrice
      ? true
      : false,
    showSupplier: AuthRepository.getUserDetails()?.organization?.showSupplier
      ? true
      : false,
  });
  const [toCityCallback, setToCityCallback] = useState();

  const [fromCities, setFromCities] = useState([]);
  const [fromCitiesSearch, setFromCitiesSearch] = useState("");
  const [toCities, setToCities] = useState([]);
  const [toCitiesSearch, setToCitiesSearch] = useState("");
  const [checked, setChecked] = useState(false);
  const [vehicleCategories, setVehicleCategories] = useState();
  const [formDataErrors, setFormDataErrors] = useState({});
  const [searchParams, setSearchParams] = useState({ date: moment() });
  const [materials, setMaterials] = useState();
  const [availableDepartures, setAvailableDepartures] = useState();
  const [availableComplexDepartures, setAvailableComplexDepartures] =
    useState();
  const [selectedDeparture, setSelectedDeparture] = useState();
  const [surchargesTotal, setSurchargesTotal] = useState();
  const [complexTickets, setComplexTickets] = useState({});
  const [allComplexDepartures, setAllComplexDepartures] = useState();
  const [selectedComplexDepartureKey, setSelectedComplexDepartureKey] =
    useState();
  const [selectedProductDestinations, setSelectedProductDestinations] =
    useState([]);
  const [selectedDepartures, setSelectedDepartures] = useState({});
  const [returnComplexDestinationChecked, setReturnComplexDestinationChecked] =
    useState(false);
  const [showComplexDepartures, setShowComplexDepartures] = useState(false);
  const [loading, setLoading] = useState(false);
  const [departuresLoading, setDeparturesLoading] = useState(false);
  const dispatch = useDispatch();
  const [activeButton, setActiveButton] = useState("");
  const [value, setValue] = React.useState("1");
  const navigate = useNavigate();
  const [departuresCount, setDeparturesCount] = useState(0);
  const [complexDeparturesCount, setComplexDeparturesCount] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [totalSum, setTotalSum] = useState(0);
  const [showTotals, setShowTotals] = useState(false);
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const [dangerousGoodsDocument, setDangerousGoodsDocument] = useState();
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const [additionalDocuments, setAdditionalDocuments] = useState([]);
  const [files, setFiles] = useState([]);
  const [additionalFiles, setAdditionalFiles] = useState([]);
  const [showDeparturesForNextYear, setShowDeparturesForNextYear] =
    useState(false);
  const [openDialogDuplicateTickets, setOpenDialogDuplicateTickets] =
    useState(false);
  const [methodToCall, setMethodToCall] = useState();
  const [duplicateTickets, setDuplicateTickets] = useState([]);
  const [crnMandatory, setCrnMandatory] = useState(false);


  useEffect(() => {
    let tmp = { ...data };
    tmp.fromCity = undefined;
    tmp.toCountry = undefined;
    tmp.toCity = undefined;
    setData(tmp);
    loadAllCities("from");
    getAllVehicleCategories();
    getAllMaterials();
    fetchSettingsProp();
    checkIfCRNisMandatory();
  }, []);

  
  const checkIfCRNisMandatory = () => {
    if (data?.organization?.mandatoryCRN) {
      setCrnMandatory(true);
    } else {
      setCrnMandatory(false);
    }
  };

  useEffect(() => {
    if (data?.loaded == "1") {
      let tmp = { ...data };
      tmp["cargoWeight"] = 0;
      tmp["temperature"] = null;
      setData(tmp);
    } else if (data?.loaded == "2") {
      let tmp = { ...data };
      tmp["cargoWeight"] = 1;
      setData(tmp);
    }
  }, [data?.loaded]);

  useEffect(() => {
    if (data?.electricPlugin === false) {
      let tmp = { ...data };
      tmp["temperature"] = null;
      setData(tmp);
    }
  }, [data?.electricPlugin]);

  useEffect(() => {
    if (data?.fromCity === undefined || data?.toCity === undefined) {
      getAllVehicleCategories();
      setFormDataErrors({});
      if (!isSmallScreen) {
        setActiveButton("map");
      }
      setShowTotals(false);
    } else getAllVehicleCategoriesByProduct();
  }, [data?.toCity, data?.fromCity]);

  useEffect(() => {
    let totalSurcharges = selectedDeparture?.ticketSurchargeList?.reduce(
      (sum, surcharge) => {
        return sum + surcharge.total;
      },
      0
    );
    setSurchargesTotal(totalSurcharges);
    handleChangeData("adjustedCalculatedPrice", undefined);
  }, [selectedDeparture]);

  const handleChangeData = (name, value) => {
    setData(_.set({ ...data }, name, value));
    if (name && value) {
      setFormIsDirty(true);
    }
  };

  const fetchSettingsProp = () => {
    SettingsRepository.fetchPropByKey(
      "SHOW_DEPARTURES_ON_B2B_FOR_NEXT_YEAR_ENABLED"
    )
      .then((res) => {
        if (res.data.value == "true") {
          setShowDeparturesForNextYear(true);
        } else {
          setShowDeparturesForNextYear(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllVehicleCategories = () => {
    VehicleCategoriesRepository.allDefault()
      .then((res) => {
        let filteredVehicleCategories = res.data;
        //sort by minLength
        filteredVehicleCategories.sort((a, b) => a.minLength - b.minLength);
        setVehicleCategories(filteredVehicleCategories);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllVehicleCategoriesByProduct = () => {
    VehicleCategoriesRepository.getAllVehicleCategoriesByMainDestination(
      data?.fromCity?.id,
      data?.toCity?.id
    )
      .then((res) => {
        if (res.data?.length > 0) {
          //sort by minLength
          res.data.sort((a, b) => a.minLength - b.minLength);
          setVehicleCategories(res.data);
        } else getAllVehicleCategories();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangeSearchParams = (name, value) => {
    let tmp = { ...searchParams };
    tmp[name] = value;
    setSearchParams(tmp);
  };

  const handleChangeSwitch = (event) => {
    setChecked(event.target.checked);
  };

  const loadAllCities = (direction) => {
    DestinationCitiesRepository.fetchAllWithoutPaging().then((response) => {
      if (direction == "from") {
        setFromCities(
          response.data.sort((a, b) => a.name.localeCompare(b.name))
        );
      } else {
      }
    });
  };

  const getAllMaterials = () => {
    MaterialsRepository.fetchAllWithoutPaging()
      .then((res) => {
        setMaterials(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setAvailableComplexDepartures(undefined);
    setSelectedProductDestinations([]);
    setSelectedDepartures({});
    setComplexTickets({});
    if (
      data?.toCity != undefined &&
      data?.fromCity != undefined &&
      data?.toCity != undefined &&
      data?.vehicleLength != undefined &&
      data?.vehicleLength != "" &&
      data?.vehicleLength != 0 &&
      data?.vehicleWidth != undefined &&
      data?.vehicleWidth != "" &&
      data?.vehicleWidth != 0 &&
      data?.vehicleHeight != undefined &&
      data?.vehicleHeight != "" &&
      data?.vehicleHeight != 0 &&
      data?.departureDay != undefined &&
      data?.cargoWeight != undefined &&
      data?.loaded != undefined &&
      data?.numberOfDrivers != undefined &&
      data?.dangerousGoods != undefined &&
      data?.electricPlugin != undefined &&
      data?.liveAnimals != undefined &&
      data?.organization?.id != undefined
    ) {
      getAvailableDeparturesOneFormTicket();
      setSelectedDeparture();
    } else {
      setAvailableDepartures();
    }
  }, [
    data?.toCity,
    data?.fromCity,
    data?.vehicleLength,
    data?.vehicleWidth,
    data?.vehicleHeight,
    data?.departureDay,
    data?.cargoWeight,
    data?.loaded,
    data?.numberOfDrivers,
    data?.dangerousGoods,
    data?.electricPlugin,
    data?.liveAnimals,
    data?.organization?.id,
  ]);

  useEffect(() => {
    let sum = 0;

    if (
      availableComplexDepartures &&
      typeof availableComplexDepartures === "object"
    ) {
      for (const key in availableComplexDepartures) {
        if (availableComplexDepartures.hasOwnProperty(key)) {
          const item = availableComplexDepartures[key];
          if (item && Array.isArray(item.productAvailableDeparturesDTOList)) {
            sum += item.productAvailableDeparturesDTOList.length;
          }
        }
      }
    }

    setTotalSum(sum);
  }, [availableComplexDepartures]);

  const getAvailableDeparturesOneFormTicket = () => {
    setAvailableComplexDepartures(undefined);
    setSelectedComplexDepartureKey(undefined);
    setComplexTickets([]);
    setDeparturesLoading(true);
    TicketRepository.getAvailableDeparturesOneFormTicket({
      organizationId: data?.organization?.id,
      fromCityId: data?.fromCity?.id,
      toCityId: data?.toCity?.id,
      vehicleLength: data?.vehicleLength,
      cargoWeight: data?.cargoWeight,
      numberOfDrivers: data?.numberOfDrivers,
      loaded: data?.loaded == "2" ? true : false,
      dangerousGoods: data?.dangerousGoods,
      electricPlugin: data?.electricPlugin,
      liveAnimals: data?.liveAnimals,
      departureMonth: moment(data?.departureDay).utc().month(),
      selectedPriceListId: data?.selectedPriceListId,
      departureDate: moment(data?.departureDay).format("YYYY-MM-DD"),
      vehicleWidth: data?.vehicleWidth,
      vehicleHeight: data?.vehicleHeight,
      material: data?.material?.id,
      numberOfAxles: data?.numberOfAxles,
      isComplexProduct: false,
      complexProductId: null,
      departureDay:
        moment(data?.departureDay).utc().day() == 0
          ? 7
          : moment(data?.departureDay).utc().day(),
      extra: data?.extra,
      commentForCustomer: data?.commentForCustomer,
    })
      .then((res) => {
        setSnackbarOpen(false);
        setDeparturesLoading(false);
        setAvailableDepartures(res.data.body.productAvailableDeparturesDTOs);
        setAllComplexDepartures(
          res.data.body.productAvailableComplexDeparturesDTOs
        );
        setDeparturesCount(
          res.data.body.productAvailableDeparturesDTOs
            ? res.data.body.productAvailableDeparturesDTOs.length
            : 0
        );
        //setActiveButton("timetable");
        if (res.data.body.productAvailableComplexDeparturesDTOs != null) {
          if (
            Object.keys(res.data.body.productAvailableComplexDeparturesDTOs)
              .length == 0
          ) {
            setAvailableComplexDepartures(undefined);
            setSelectedComplexDepartureKey(undefined);
          } else if (
            Object.keys(res.data.body.productAvailableComplexDeparturesDTOs)
              .length == 1
          ) {
            setComplexDeparturesCount(
              Object.keys(res.data.body.productAvailableComplexDeparturesDTOs)
                .length
            );
            setAvailableComplexDepartures(
              res.data.body.productAvailableComplexDeparturesDTOs[
                Object.keys(
                  res.data.body.productAvailableComplexDeparturesDTOs
                )[0]
              ]
            );
            setComplexDeparturesCount(
              Object.keys(res.data.body.productAvailableComplexDeparturesDTOs)
                .length
            );
            setSelectedComplexDepartureKey(
              Object.keys(
                res.data.body.productAvailableComplexDeparturesDTOs
              )[0]
            );
          }
        }
      })
      .catch((err) => {
        setDeparturesLoading(false);
        setAvailableComplexDepartures(undefined);
        setSelectedComplexDepartureKey(undefined);
        setDeparturesCount(0);
        setComplexDeparturesCount(0);
        setAvailableDepartures(undefined);
        console.log(err);
        if (err?.response?.data?.message) {
          setSnackbarMessage(err.response.data.message);
          //dispatch(notifyShowErrorMessage(err.response.data.message));
        }
        setSnackbarOpen(true);
      });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const validateTicket = (data) => {
    if (!AgentNewTicketValidator.isValidSync(data)) {
      var validationErrors = {};
      (async () => {
        await AgentNewTicketValidator.validate(data, {
          abortEarly: false,
        }).catch((err) => {
          err.inner.forEach((validationError) => {
            validationErrors[validationError.path] = {};
            validationErrors[validationError.path] = validationError.message;
          });

          setFormDataErrors(validationErrors);
          setLoading(false);
          return;
        });
      })();
    } else {
      setFormDataErrors({});
    }
  };

  const bookTicketFromProductDepartures = () => {
    setLoading(true);
    let tmp = {
      cargoWeight: data.cargoWeight,
      numberOfDrivers: data.numberOfDrivers,
      loaded: data.loaded == "2" ? true : false,
      priceList: data.priceList,
      fromCity: data.fromCity,
      toCity: data.toCity,
      truckPlateNumber: data.truckPlateNumber,
      trailerPlateNumber: data.trailerPlateNumber,
      dangerousGoods: data.dangerousGoods,
      electricPlugin: data.electricPlugin,
      liveAnimals: data.liveAnimals,
      source: data.source,
      showPrice: data.showPrice,
      showSupplier: data.showSupplier,
      departureDay: moment(data.departureDay).utc(true),
      vehicleLength: data.vehicleLength,
      organization: data.organization,
      vehicleCategory: data.vehicleCategory,
      driverName: data.driverName,
      additionalInfo: data.additionalInfo,
      departureHour: data.departureHour,
      hasAdjustedPrice: data.hasAdjustedPrice,
      adjustedPrice: data.adjustedPrice,
      customerReferenceNumber: data.customerReferenceNumber,
      supplierReferenceNumber: data.supplierReferenceNumber,
      material: data?.material,
      vehicleWidth: data?.vehicleWidth,
      vehicleHeight: data?.vehicleHeight,
      ticketSurcharges: selectedDeparture?.ticketSurchargeList,
      numberOfAxles: data?.numberOfAxles,
      adjustedCalculatedPrice: data?.adjustedCalculatedPrice,
      extra: data?.extra,
      commentForCustomer: data?.commentForCustomer,
      temperature: data?.temperature,
    };
    validateTicket(tmp);
    if (AgentNewTicketValidator.isValidSync(tmp)) {
      setFormIsDirty(false);

      // if (data?.dangerousGoods && dangerousGoodsDocument == null) {
      //   dispatch(
      //     notifyShowErrorMessage(LOCALE.dangerous_goods_document_required)
      //   );
      // }

      // if (
      //   selectedDeparture?.requireAdditionalDocuments &&
      //   additionalDocuments.length === 0
      // ) {
      //   dispatch(notifyShowErrorMessage(LOCALE.additional_documents_required));
      //   return;
      // }

      ProductRepository.createTicket({
        ticket: tmp,
        productDestinationId: selectedDeparture?.productDestinationId,
        priceId: selectedDeparture?.priceId,
        productDestinationDepartureId:
          selectedDeparture?.productDestinationDepartureId,
        dangerousGoodsDocument: dangerousGoodsDocument,
        additionalDocuments: additionalDocuments,
      })
        .then((res) => {
          if (res?.data?.customerReferenceNumber) {
            TicketRepository.notifyEpayForTicket(
              res?.data?.customerReferenceNumber,
              res?.data?.status,
              res?.data?.reservationCode ? res?.data?.reservationCode : null
            )
              .then((res) => {
                console.log(res);
              })
              .catch((err) => {
                console.log(err);
              });
          }
          setLoading(false);
          dispatch(
            notifyShowSuccessMessage(LOCALE.ticket_create_success_message)
          );
          if (checked) {
            bookNewTicket();
          } else {
            declineNewTicket(res.data.id);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          dispatch(notifyShowErrorMessage(LOCALE.ticket_create_error_message));
        });
    } else {
      setLoading(false);
    }
  };

  const bookNewTicket = () => {
    setData({
      loaded: "1",
      numberOfDrivers: 1,
      departureDay: data.departureDay,
      dangerousGoods: false,
      electricPlugin: false,
      liveAnimals: false,
      vehicleLength: 17,
      vehicleWidth: 2,
      vehicleHeight: 4,
      cargoWeight: 1.0,
      source: "PHONE",
      fromCity: data.fromCity,
      toCountry: data.toCountry,
      fromCountry: data.fromCountry,
      toCity: data.toCity,
      showPrice: AuthRepository.getUserDetails()?.organization?.showPrice
        ? true
        : false,
      showSupplier: AuthRepository.getUserDetails()?.organization?.showSupplier
        ? true
        : false,
      organization: data.organization,
    });
    // handleClose();
  };

  const declineNewTicket = (ticketId) => {
    navigate(
      `/client/booking/${0}/${10}/${JSON.stringify({
        status: "ALL_ACTIVE",
      })}/${ticketId}`
    );
  };

  const swapFromCityToCity = () => {
    setSelectedDepartures({});
    setSelectedProductDestinations([]);
    let tmp = { ...data };
    tmp["fromCity"] = data.toCity;
    tmp["toCity"] = data.fromCity;
    setData(tmp);
  };

  const handleButtonClick = (buttonType) => {
    if (buttonType === "booking" && isSmallScreen) {
      setActiveButton("booking");
    } else if (buttonType === "map") {
      setActiveButton("map");
      setShowTotals(false);
    } else if (buttonType === "timetable") {
      // fetchAvailableDepartures();
      setShowTotals(false);
      setActiveButton("timetable");
    } else if (buttonType === "attachments") {
      setActiveButton("attachments");
    } else if (buttonType === "pricing") {
      setActiveButton("pricing");
    }
  };
  const fetchAvailableDepartures = () => {
    if (
      data?.toCity != undefined &&
      data?.fromCity != undefined &&
      data?.vehicleLength != undefined &&
      data?.vehicleWidth != undefined &&
      data?.vehicleHeight != undefined &&
      data?.departureDay != undefined &&
      data?.cargoWeight != undefined &&
      data?.loaded != undefined &&
      data?.numberOfDrivers != undefined &&
      data?.dangerousGoods != undefined &&
      data?.electricPlugin != undefined &&
      data?.liveAnimals != undefined &&
      data?.organization?.id != undefined
    ) {
      getAvailableDeparturesOneFormTicket();
    } else {
      setAvailableDepartures();
    }
  };

  const handleConfirmBooking = () => {
    const errors = {};

    if (!data?.truckPlateNumber) {
      errors.truckPlateNumber = "Truck Plate Number is required";
    }
    if (
      !data?.departureDay ||
      moment(data.departureDay, "DD.MM.YYYY", true).isValid() === false
    ) {
      errors.departureDay = "Departure Date is required";
    }

    if(crnMandatory && !data?.customerReferenceNumber) {
      errors.customerReferenceNumber = "Customer Reference Number is required";
    }

    setFormDataErrors(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }
    setActiveButton("map");
  };

  const handleConfirmTimetable = () => {
    if (data?.dangerousGoods) {
      setActiveButton("attachments");
    } else {
      setShowTotals(true);
      setActiveButton("pricing");
    }
  };

  const handleConfirmAttachments = () => {
    if (selectedDeparture?.adrUploadRequired) {
      // validate if dangerousGoodsDocument is uploaded and if not show error message
      if (!dangerousGoodsDocument) {
        dispatch(
          notifyShowErrorMessage(LOCALE.dangerous_goods_document_required)
        );
        return;
      }
    }
    setShowTotals(true);
    setActiveButton("pricing");
  };

  const handleConfirmRoute = () => {
    // fetchAvailableDepartures();

    const errors = {};

    if (!data?.truckPlateNumber) {
      errors.truckPlateNumber = "Truck Plate Number is required";
    }
    if (
      !data?.departureDay ||
      moment(data.departureDay, "DD.MM.YYYY", true).isValid() === false
    ) {
      errors.departureDay = "Departure Date is required";
    }
    if(crnMandatory && !data?.customerReferenceNumber) {
      errors.customerReferenceNumber = "Customer Reference Number is required";
    }

    setFormDataErrors(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }
    setActiveButton("timetable");
  };

  useEffect(() => {
    handleButtonClick("booking");
    setAvailableDepartures(undefined);
    setAllComplexDepartures(false);
  }, [data?.fromCity, data?.toCity]);

  useEffect(() => {
    if (activeButton == "pricing" || activeButton == "attachments") {
      handleButtonClick("timetable");
    }
  }, [
    data?.vehicleLength,
    data?.numberOfDrivers,
    data?.departureDay,
    data?.loaded,
    data?.dangerousGoods,
    data?.electricPlugin,
    data?.liveAnimals,
  ]);

  const handleCreateComplexTickets = (tmp) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("tickets", JSON.stringify(tmp));

    // if (data?.dangerousGoods && dangerousGoodsDocument == null) {
    //   dispatch(
    //     notifyShowErrorMessage(LOCALE.dangerous_goods_document_required)
    //   );
    //   return;
    // }

    formData.append("dangerousGoodsDocument", dangerousGoodsDocument);

    // if (
    //   (Object.values(selectedDepartures)[0]?.requireAdditionalDocuments ||
    //     Object.values(selectedDepartures)[1]?.requireAdditionalDocuments) &&
    //   additionalDocuments.length === 0
    // ) {
    //   dispatch(notifyShowErrorMessage(LOCALE.additional_documents_required));
    //   return;
    // }

    if (additionalDocuments && additionalDocuments.length > 0) {
      additionalDocuments.forEach((file) => {
        if (file.file instanceof File) {
          formData.append("additionalDocuments", file.file);
        }
      });
    }

    ProductRepository.createComplexTickets(formData)
      .then((res) => {
        setLoading(false);
        navigate(
          `/client/booking/${0}/${10}/${JSON.stringify({
            status: "ALL_ACTIVE",
          })}/${res.data.id}`
        );
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const createComplexTickets = () => {
    let tmpComplexTickets = [];
    Object.keys(complexTickets).map((key) => {
      let selectedDeparture = { ...selectedDepartures[key] };
      let ticket = { ...complexTickets[key] };
      ticket.productDestination = {
        id: selectedDeparture.productDestinationId,
      };
      ticket.productPrice = {
        id: selectedDeparture.priceId,
      };
      ticket.productDestinationDeparture = {
        id: selectedDeparture.productDestinationDepartureId,
      };
      ticket.loaded = data.loaded == "2" ? true : false;
      ticket.fromCity = selectedDeparture.destination.fromCity;
      ticket.toCity = selectedDeparture.destination.toCity;
      ticket.selectedDeparture = selectedDeparture;
      ticket.ticketSurcharges = selectedDeparture.ticketSurchargeList;
      ticket.extra = data.extra;
      // set departureDay hour to 15:00
      ticket.departureDay = moment(ticket.departureDay).set({
        hour: 15,
        minute: 0,
        second: 0,
        millisecond: 0,
      });

      tmpComplexTickets.push(ticket);
    });
    handleCreateComplexTickets(tmpComplexTickets);
  };

  const handleUploadDocument = (file, setFile) => {
    if (file) {
      setFile(file);
    }
  };

  const handleUploadAdditionalDocuments = (files, setFiles) => {
    if (files && files.length > 0) {
      setFiles(files);
    }
  };

  const handleOnProcessFile = (error, file) => {
    if (error) {
      console.log(error);
    }
  };

  const handleRemoveFile = (file) => {
    setAdditionalDocuments((prevDocs) =>
      prevDocs.filter((doc) => doc.file !== file.file)
    );
  };

  const checkDuplicateTickets = async () => {
    let tmp = {
      truckPlateNumber: data?.truckPlateNumber,
      departureDay: moment(data?.departureDay).utc(true),
    };

    try {
      const res = await TicketRepository.checkDuplicateTickets(tmp);
      if (res.data && res.data.length > 0) {
        setDuplicateTickets(res.data);
        return true;
      } else {
        setDuplicateTickets([]);
        return false;
      }
    } catch (err) {
      setDuplicateTickets([]);
      return false;
    }
  };

  const checkDuplicateTicketsComplex = async () => {
    let departureDates = [];
    let truckPlateNumber = "";
    Object.keys(complexTickets).map((key) => {
      let ticket = { ...complexTickets[key] };
      departureDates.push(moment(ticket.departureDay).utc(true));
      truckPlateNumber = ticket.truckPlateNumber;
    });
    try {
      const res = await TicketRepository.checkDuplicateTicketsComplex(
        departureDates,
        truckPlateNumber
      );
      if (res.data && res.data.length > 0) {
        setDuplicateTickets(res.data);
        return true;
      } else {
        setDuplicateTickets([]);
        return false;
      }
    } catch (err) {
      console.error("Error while checking for duplicate tickets:", err);
      setDuplicateTickets([]);
      return false;
    }
  };

  const handleOpenDuplicateTicketsDialog = (value) => {
    setOpenDialogDuplicateTickets(true);
    setMethodToCall(value);
  };

  const handleCloseDuplicateTicketsDialog = () => {
    setMethodToCall(null);
    setDuplicateTickets([]);
    setOpenDialogDuplicateTickets(false);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Box
          sx={{
            display: { md: "none", xs: "flex" },
            flexDirection: "column",
            width: "96%",
            marginLeft: "15px",
          }}
        >
          <Tabs
            value={value}
            onChange={(event, newValue) => setValue(newValue)}
            aria-label="Navigation tabs"
            sx={{
              marginTop: "10px",
              backgroundColor: theme.palette.background.paper,
              //clipPath: "inset(0 round 0.5em)",
              borderRadius: "5px",
            }}
            indicatorColor="none"
            textColor={theme.palette.darkContrast.main}
            // variant="scrollable"
            variant="fullWidth"
            // scrollButtons="auto"
          >
            <Tab
              disabled
              value="1"
              label={
                <>
                  <Grid container>
                    <Grid item xs={12} sx={{ alignContent: "center" }}>
                      {activeButton === "booking" ? (
                        <>
                          <ConfirmationNumber />
                          <Typography
                            sx={{ fontWeight: "light", fontSize: "10px" }}
                          >
                            {"Booking".toLowerCase()}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <ConfirmationNumberOutlined />
                          <Typography
                            sx={{ fontWeight: "light", fontSize: "10px" }}
                          >
                            {"Booking".toLowerCase()}
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </>
              }
              sx={{
                //flex: "0 0 120px",
                backgroundColor: theme.palette.secondary.main,
                color: "white",
                fontWeight: "bold",
                height: "48px",
                borderRight: "2px solid white",
                textTransform: "capitalize",
                minWidth: "80px",
              }}
            />
            <Tab
              disabled
              value="2"
              label={
                <>
                  <Grid container>
                    <Grid item xs={12} sx={{ alignContent: "center" }}>
                      {activeButton === "map" ? (
                        <>
                          <Route />
                          <Typography
                            sx={{ fontWeight: "light", fontSize: "10px" }}
                          >
                            {"Map".toLowerCase()}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <RouteOutlined />
                          <Typography
                            sx={{
                              color:
                                activeButton === "booking" &&
                                theme.palette.lightGray.main,
                              fontWeight: "light",
                              fontSize: "10px",
                            }}
                          >
                            {"Map".toLowerCase()}
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </>
              }
              sx={{
                //flex: "0 0 120px",
                backgroundColor:
                  (!isSmallScreen && data?.toCity && data?.fromCity) ||
                  activeButton === "map" ||
                  activeButton !== "booking"
                    ? theme.palette.secondary.main
                    : "transparent",
                color:
                  (!isSmallScreen && data?.toCity && data?.fromCity) ||
                  activeButton === "map" ||
                  activeButton !== "booking"
                    ? "white"
                    : alpha(theme.palette.lightGray.main, 0.7),
                fontWeight: "bold",
                height: "48px",
                borderRight: "2px solid white",
                textTransform: "capitalize",
                minWidth: "80px",
              }}
            />
            <Tab
              disabled
              value="3"
              label={
                <>
                  <Grid container>
                    <Grid item xs={12} sx={{ alignContent: "center" }}>
                      {activeButton === "timetable" ? (
                        <>
                          <Today />
                          <Typography
                            sx={{ fontWeight: "light", fontSize: "10px" }}
                          >
                            {"Timetable".toLowerCase()}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <TodayOutlined />
                          <Typography
                            sx={{
                              color:
                                activeButton === "booking" ||
                                (activeButton === "map" &&
                                  theme.palette.lightGray.main),
                              fontWeight: "light",
                              fontSize: "10px",
                            }}
                          >
                            {"Timetable".toLowerCase()}
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </>
              }
              sx={{
                // flex: "0 0 120px",
                backgroundColor:
                  activeButton == "timetable" ||
                  activeButton == "attachments" ||
                  activeButton == "pricing"
                    ? theme.palette.secondary.main
                    : "transparent",
                color:
                  activeButton == "timetable" ||
                  activeButton == "attachments" ||
                  activeButton == "pricing"
                    ? "white"
                    : alpha(theme.palette.lightGray.main, 0.7),
                fontWeight: "bold",
                height: "48px",
                borderRight: "2px solid white",
                minWidth: "80px",
                textTransform: "capitalize",
              }}
            />
            <Tab
              disabled
              value="4"
              label={
                <>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {activeButton == "attachments" ? (
                        <>
                          <AttachFile />
                          <Typography
                            sx={{
                              fontWeight: "light",
                              fontSize: "10px",
                              marginLeft: "-5px",
                            }}
                          >
                            {"Attachments".toLowerCase()}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <AttachFileOutlined />
                          <Typography
                            sx={{
                              color:
                                activeButton !== "pricing" &&
                                theme.palette.lightGray.main,
                              fontWeight: "light",
                              fontSize: "10px",
                              marginLeft: "-5px",
                            }}
                          >
                            {"Attachments".toLowerCase()}{" "}
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </>
              }
              sx={{
                //flex: "0 0 120px",
                backgroundColor:
                  (activeButton === "attachments" ||
                    activeButton === "pricing") &&
                  data?.dangerousGoods
                    ? theme.palette.secondary.main
                    : "transparent",
                color:
                  (activeButton === "attachments" ||
                    activeButton === "pricing") &&
                  data?.dangerousGoods
                    ? "white"
                    : alpha(theme.palette.lightGray.main, 0.7),
                fontWeight: "bold",
                height: "48px",
                borderRight: "2px solid white",
                minWidth: "80px",
                textTransform: "capitalize",
              }}
            />
            <Tab
              disabled
              value="5"
              label={
                <>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {activeButton == "pricing" ? (
                        <>
                          <LocalAtm />
                          <Typography
                            sx={{
                              fontWeight: "light",
                              fontSize: "10px",
                              marginLeft: "-5px",
                            }}
                          >
                            {selectedDeparture &&
                            selectedDeparture?.salesPrice != null &&
                            selectedDeparture?.price != null
                              ? "Pricing".toLowerCase()
                              : "Overview".toLowerCase()}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <LocalAtmOutlined />
                          <Typography
                            sx={{
                              color: theme.palette.lightGray.main,
                              fontWeight: "light",
                              fontSize: "10px",
                              marginLeft: "-5px",
                            }}
                          >
                            {(selectedDeparture &&
                              selectedDeparture?.salesPrice != null &&
                              selectedDeparture?.price != null) ||
                            (selectedComplexDepartureKey &&
                              Object.keys(selectedDepartures || {})
                                .map((key) => {
                                  const departure = selectedDepartures[key];
                                  return (
                                    departure?.salesPrice != null &&
                                    departure?.price != null
                                  );
                                })
                                .some((result) => result))
                              ? "Pricing".toLowerCase()
                              : "Overview".toLowerCase()}
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </>
              }
              sx={{
                //flex: "0 0 140px",
                backgroundColor:
                  activeButton === "pricing"
                    ? theme.palette.secondary.main
                    : "transparent",
                color:
                  activeButton === "pricing"
                    ? "white"
                    : alpha(theme.palette.lightGray.main, 0.7),
                fontWeight: "bold",
                height: "48px",
                textTransform: "capitalize",
                minWidth: "80px",
              }}
            />
          </Tabs>
        </Box>
        {(activeButton === "booking" || !isSmallScreen) && (
          <Grid item md={6} xs={12}>
            <Paper
              elevation={1}
              style={{
                padding: "20px",
                height: "88vh",
                overflowY: "auto",
                borderRadius: "5px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Stack spacing={2}>
                        <Breadcrumbs
                          style={{ cursor: "pointer" }}
                          separator={
                            <Icon fontSize="small">navigate_next</Icon>
                          }
                        >
                          <Typography
                            variant="h5"
                            key="2"
                            color="text.primary"
                            style={{ fontWeight: "bold" }}
                          >
                            New Booking
                          </Typography>
                        </Breadcrumbs>
                      </Stack>
                    </Grid>

                    <AgentOneFormTicketDestinationDetails
                      handleChangeData={handleChangeData}
                      setData={setData}
                      data={data}
                      fromCities={fromCities}
                      toCities={toCities}
                      setToCities={setToCities}
                      getAllVehicleCategories={getAllVehicleCategories}
                      vehicleCategories={vehicleCategories}
                      setVehicleCategories={setVehicleCategories}
                      formDataErrors={formDataErrors}
                      fromCitiesSearch={fromCitiesSearch}
                      setFromCitiesSearch={setFromCitiesSearch}
                      toCitiesSearch={toCitiesSearch}
                      setToCitiesSearch={setToCitiesSearch}
                      handleChangeSearchParams={handleChangeSearchParams}
                      handleChangeSwitch={handleChangeSwitch}
                      checked={checked}
                      materials={materials}
                      availableDepartures={availableDepartures}
                      allComplexDepartures={allComplexDepartures}
                      availableComplexDepartures={availableComplexDepartures}
                      swapFromCityToCity={swapFromCityToCity}
                      selectedDeparture={selectedDeparture}
                      setReturnComplexDestinationChecked={
                        setReturnComplexDestinationChecked
                      }
                      returnComplexDestinationChecked={
                        returnComplexDestinationChecked
                      }
                      showComplexDepartures={showComplexDepartures}
                      setShowComplexDepartures={setShowComplexDepartures}
                      setSelectedDeparture={setSelectedDeparture}
                      selectedDepartures={selectedDepartures}
                      dangerousGoodsDocument={dangerousGoodsDocument}
                      setDangerousGoodsDocument={setDangerousGoodsDocument}
                      handleUploadDocument={handleUploadDocument}
                      handleUploadAdditionalDocuments={
                        handleUploadAdditionalDocuments
                      }
                      additionalDocuments={additionalDocuments}
                      setAdditionalDocuments={setAdditionalDocuments}
                      showDeparturesForNextYear={showDeparturesForNextYear}
                    />
                  </Grid>
                </Grid>
                {isSmallScreen && (
                  <Grid item xs={12} md={activeButton == "booking" ? 12 : 11}>
                    <Button
                      variant="contained"
                      style={{
                        height: "50px",
                        backgroundColor:
                          data?.fromCity && data?.toCity
                            ? theme.palette.primary.main
                            : alpha(theme.palette.primary.main, 0.6),
                        color:
                          data?.fromCity && data?.toCity
                            ? theme.palette.dashboardBackground.main
                            : alpha(theme.palette.background.default, 0.7),
                        padding: "10px",
                      }}
                      onClick={handleConfirmBooking}
                      fullWidth
                      disabled={!(data?.fromCity && data?.toCity)}
                    >
                      <Typography
                        style={{
                          textTransform: "none",
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                      >
                        Continue
                      </Typography>
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Grid>
        )}
        {(activeButton !== "booking" || !isSmallScreen) && (
          <Grid item md={6} xs={12}>
            <Paper
              elevation={1}
              style={{
                padding:
                  activeButton === "map"
                    ? "0.1px 0 0 0"
                    : activeButton === "timetable" && isSmallScreen
                    ? "5px"
                    : "20px",
                position: "relative",
                height: "88vh",
                overflowY: activeButton === "map" ? "hidden" : "auto",
                borderRadius: "5px",
              }}
            >
              {isMdUp && (
                <div
                  style={{
                    position: "absolute",
                    top: 35,
                    left: 25,
                    right: 25,
                    zIndex: 2,
                  }}
                >
                  <Grid container spacing={0}>
                    <Grid item xs={3} md={3}>
                      <Button
                        variant="contained"
                        fullWidth
                        style={{
                          height: "45px",
                          borderTopLeftRadius: "5px",
                          borderBottomLeftRadius: "5px",
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          fontWeight: "bold",
                          textTransform: "none",
                          fontSize: "16px",
                          backgroundColor: theme.palette.secondary.main,
                          boxShadow: "none",
                          color:
                            activeButton === "map"
                              ? theme.palette.dashboardBackground.main
                              : alpha(theme.palette.background.default, 0.2),
                        }}
                        disabled
                      >
                        Map
                      </Button>
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <Button
                        variant="contained"
                        fullWidth
                        disabled
                        style={{
                          height: "45px",
                          borderRadius: 0,
                          fontWeight: "bold",
                          textTransform: "none",
                          borderRight: "2px solid",
                          borderLeft: "2px solid",
                          fontSize: "16px",
                          backgroundColor:
                            activeButton == "timetable" ||
                            activeButton == "pricing" ||
                            activeButton == "attachments"
                              ? theme.palette.secondary.main
                              : theme.palette.dashboardBackground.main,
                          color:
                            activeButton === "timetable"
                              ? theme.palette.dashboardBackground.main
                              : activeButton === "pricing" ||
                                activeButton === "attachments"
                              ? alpha(theme.palette.background.default, 0.2)
                              : alpha(theme.palette.lightGray.main, 0.4),

                          boxShadow: activeButton !== "map" && "none",
                          borderColor: "white",
                        }}
                        // onClick={() => handleButtonClick("timetable")}
                      >
                        <Chip
                          style={{
                            color:
                              activeButton === "attachments" ||
                              activeButton === "pricing"
                                ? alpha(theme.palette.darkContrast.main, 0.2)
                                : theme.palette.darkContrast.main,

                            backgroundColor:
                              activeButton === "attachments" ||
                              activeButton === "pricing"
                                ? alpha(theme.palette.background.default, 0.2)
                                : theme.palette.background.default,
                            borderRadius: "5px",
                            fontSize: "12px",
                            marginRight: "5px",
                            padding: 1,
                          }}
                          size="small"
                          label={`${
                            Object.keys(allComplexDepartures || {}).length > 0
                              ? totalSum
                              : ""
                          }${departuresCount > 0 ? departuresCount : ""}`}
                        />
                        Timetable
                      </Button>
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <Button
                        variant="contained"
                        fullWidth
                        disabled
                        style={{
                          height: "45px",
                          borderRadius: 0,
                          fontWeight: "bold",
                          textTransform: "none",
                          borderRight: "2px solid white",
                          fontSize: "16px",
                          backgroundColor:
                            (activeButton == "attachments" ||
                              activeButton == "pricing") &&
                            data?.dangerousGoods
                              ? theme.palette.secondary.main
                              : theme.palette.dashboardBackground.main,
                          color:
                            activeButton === "attachments" &&
                            data?.dangerousGoods
                              ? theme.palette.dashboardBackground.main
                              : activeButton === "pricing" &&
                                data?.dangerousGoods
                              ? alpha(theme.palette.background.default, 0.2)
                              : alpha(theme.palette.lightGray.main, 0.4),
                          boxShadow: activeButton !== "map" && "none",
                          borderColor: "white",
                        }}
                      >
                        Attachments
                      </Button>
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <Button
                        variant="contained"
                        disabled
                        style={{
                          height: "45px",
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                          textTransform: "none",
                          fontSize: "16px",
                          borderTopRightRadius: "5px",
                          borderBottomRightRadius: "5px",
                          fontWeight: "bold",
                          backgroundColor:
                            activeButton === "pricing"
                              ? theme.palette.secondary.main
                              : theme.palette.dashboardBackground.main,
                          borderColor: "white",
                          color:
                            activeButton === "pricing"
                              ? theme.palette.dashboardBackground.main
                              : alpha(theme.palette.lightGray.main, 0.4),
                          boxShadow: activeButton === "pricing" ? "none" : "",
                        }}
                        // onClick={() => handleButtonClick("pricing")}
                        fullWidth
                      >
                        {selectedComplexDepartureKey &&
                          Object.keys(selectedDepartures || {})
                            .map((key) => {
                              const departure = selectedDepartures[key];
                              return (
                                departure?.salesPrice != null &&
                                departure?.price != null
                              );
                            })
                            .some((result) => result) &&
                          activeButton === "pricing" && (
                            <Typography
                              style={{
                                fontWeight: "bold",
                                fontSize: "14px",
                              }}
                              align="center"
                            >
                              <Chip
                                size="small"
                                style={{
                                  color: theme.palette.darkContrast.main,
                                  backgroundColor:
                                    theme.palette.background.default,
                                  borderRadius: "5px",
                                  padding: 1,
                                  marginRight: "3px",
                                  fontSize: "12px",
                                }}
                                label={`€${Object.keys(selectedDepartures)
                                  .reduce((total, key) => {
                                    const adjustedPrice =
                                      complexTickets[key]
                                        ?.adjustedCalculatedPrice;
                                    const ticketPrice = adjustedPrice
                                      ? parseFloat(adjustedPrice)
                                      : selectedDepartures[key]?.salesPrice ||
                                        0;

                                    const surchargeTotal = selectedDepartures[
                                      key
                                    ]?.ticketSurchargeList
                                      ? selectedDepartures[
                                          key
                                        ]?.ticketSurchargeList.reduce(
                                          (surchargeTotal, surcharge) =>
                                            surchargeTotal +
                                            (surcharge?.total || 0),
                                          0
                                        )
                                      : 0;

                                    return total + ticketPrice + surchargeTotal;
                                  }, 0)
                                  .toFixed(2)}`}
                              />
                            </Typography>
                          )}
                        {selectedDeparture &&
                          selectedDeparture?.salesPrice != null &&
                          selectedDeparture?.price != null &&
                          activeButton === "pricing" && (
                            <Chip
                              size="small"
                              label={`€${(
                                +selectedDeparture?.salesPrice +
                                +surchargesTotal
                              ).toFixed(2)}`}
                              style={{
                                color: theme.palette.darkContrast.main,
                                backgroundColor:
                                  theme.palette.background.default,
                                borderRadius: "5px",
                                padding: 1,
                                fontSize: "12px",
                              }}
                            />
                          )}
                        &nbsp;
                        {(selectedDeparture &&
                          selectedDeparture?.salesPrice != null &&
                          selectedDeparture?.price != null) ||
                        (selectedComplexDepartureKey &&
                          Object.keys(selectedDepartures || {})
                            .map((key) => {
                              const departure = selectedDepartures[key];
                              return (
                                departure?.salesPrice != null &&
                                departure?.price != null
                              );
                            })
                            .some((result) => result))
                          ? "Pricing"
                          : "Overview"}
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              )}

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {activeButton === "map" ? (
                    <>
                      <div style={{ position: "relative", zIndex: 1 }}>
                        {activeButton === "map" && (
                          <OpenLayer
                            handleChangeData={handleChangeData}
                            setData={setData}
                            data={data}
                            fromCities={fromCities}
                            toCityCallback={toCityCallback}
                            setToCityCallback={(value) =>
                              setToCityCallback(value)
                            }
                          />
                        )}
                      </div>

                      {activeButton === "map" && (
                        <div
                          style={{
                            position: "absolute",
                            bottom: 35,
                            left: 25,
                            right: 25,
                            zIndex: 2,
                            display: "flex",
                            gap: "10px",
                            justifyContent: "space-between",
                          }}
                        >
                          {activeButton != "map" && (
                            <Grid item xs={12} md={1}>
                              <Button
                                variant="contained"
                                onClick={fetchAvailableDepartures}
                                style={{ backgroundColor: "white" }}
                                fullWidth
                                disabled={activeButton === "map"}
                              >
                                <ArrowBackIosIcon
                                  style={{
                                    color: "gray",
                                    opacity: activeButton === "map" && 0.6,
                                    padding: "1px",
                                  }}
                                />
                              </Button>
                            </Grid>
                          )}
                          {isSmallScreen && (
                            <Grid item xs={2} md={1}>
                              <IconButton
                                variant="contained"
                                style={{
                                  border: "solid 1px ",
                                  borderColor: alpha(
                                    theme.palette.lightGray.main,
                                    0.4
                                  ),
                                  borderRadius: "4px",
                                  backgroundColor:
                                    theme.palette.background.default,
                                  height: "50px",
                                  width: "50px",
                                }}
                                onClick={() => {
                                  handleButtonClick("booking");
                                  // setAvailableDepartures(undefined);
                                  // setAllComplexDepartures(false);
                                }}
                              >
                                <ArrowBackIosIcon
                                  style={{
                                    color: theme.palette.darkContrast.main,

                                    paddingLeft: "3px",
                                    fontSize: "1.3rem",
                                  }}
                                />
                              </IconButton>
                            </Grid>
                          )}
                          <Grid
                            item
                            xs={10}
                            md={activeButton == "map" ? 12 : 11}
                          >
                            <Button
                              variant="contained"
                              style={{
                                height: "50px",
                                backgroundColor:
                                  data?.fromCity && data?.toCity
                                    ? theme.palette.primary.main
                                    : alpha(theme.palette.primary.main, 0.6),
                                color:
                                  data?.fromCity && data?.toCity
                                    ? theme.palette.dashboardBackground.main
                                    : alpha(
                                        theme.palette.background.default,
                                        0.7
                                      ),
                                padding: "10px",
                                marginLeft: activeButton != "map" ? "10px" : "",
                              }}
                              onClick={handleConfirmRoute}
                              fullWidth
                              disabled={!(data?.fromCity && data?.toCity)}
                            >
                              <Typography
                                style={{
                                  textTransform: "none",
                                  fontWeight: "bold",
                                  fontSize: "16px",
                                }}
                              >
                                {" "}
                                {data?.fromCity && data?.toCity
                                  ? "Confirm Route"
                                  : "Select Destination"}
                              </Typography>
                            </Button>
                          </Grid>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {" "}
                      {!showTotals && activeButton == "timetable" ? (
                        <Grid
                          container
                          spacing={2}
                          sx={{ position: "relative" }}
                        >
                          {departuresLoading && (
                            <Grid item xs={12}>
                              <Skeleton
                                height={100}
                                sx={{ marginTop: "-15px" }}
                              />
                              <Skeleton height={50} />
                              <Skeleton height={50} />
                              <Skeleton height={50} />
                              <Skeleton height={50} />
                              <Skeleton height={50} />
                              <Skeleton height={50} />
                              <Skeleton height={50} />
                              <Skeleton height={50} />
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <div
                              style={{
                                marginTop: "70px",
                              }}
                            >
                              {!departuresLoading &&
                                showComplexDepartures &&
                                !availableComplexDepartures &&
                                allComplexDepartures && (
                                  <Grid container spacing={2} wrap="nowrap">
                                    <ChooseComplexDeparture
                                      allComplexDepartures={
                                        allComplexDepartures
                                      }
                                      setAvailableComplexDepartures={
                                        setAvailableComplexDepartures
                                      }
                                      selectedComplexDepartureKey={
                                        selectedComplexDepartureKey
                                      }
                                      setSelectedComplexDepartureKey={
                                        setSelectedComplexDepartureKey
                                      }
                                      availableComplexDepartures={
                                        availableComplexDepartures
                                      }
                                      data={data}
                                      returnComplexDestinationChecked={
                                        returnComplexDestinationChecked
                                      }
                                    ></ChooseComplexDeparture>
                                  </Grid>
                                )}
                            </div>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sx={{
                              height: "700px",
                              overflowY: "auto",
                              marginTop: isSmallScreen && "-60px",
                            }}
                          >
                            {!departuresLoading &&
                              showComplexDepartures &&
                              availableComplexDepartures && (
                                <AgentComplexTicketDetails
                                  availableDepartures={availableDepartures}
                                  data={data}
                                  handleChangeData={handleChangeData}
                                  selectedDeparture={selectedDeparture}
                                  setSelectedDeparture={setSelectedDeparture}
                                  setSurchargesTotal={setSurchargesTotal}
                                  surchargesTotal={surchargesTotal}
                                  availableComplexDepartures={
                                    availableComplexDepartures
                                  }
                                  complexTickets={complexTickets}
                                  setComplexTickets={setComplexTickets}
                                  setAvailableComplexDepartures={
                                    setAvailableComplexDepartures
                                  }
                                  selectedProductDestinations={
                                    selectedProductDestinations
                                  }
                                  setSelectedProductDestinations={
                                    setSelectedProductDestinations
                                  }
                                  selectedDepartures={selectedDepartures}
                                  setSelectedDepartures={setSelectedDepartures}
                                  selectedComplexDepartureKey={
                                    selectedComplexDepartureKey
                                  }
                                  setSelectedComplexDepartureKey={
                                    setSelectedComplexDepartureKey
                                  }
                                  showDeparturesForNextYear={
                                    showDeparturesForNextYear
                                  }
                                />
                              )}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sx={{
                              display: { xs: "block", md: "none" },
                              padding: 0,
                              height: !showComplexDepartures && "650px",
                              marginTop: "-650px",
                            }}
                          >
                            {!departuresLoading &&
                              !showComplexDepartures &&
                              activeButton != "map" && (
                                <AvailableDeparturesCalendarMobile
                                  availableDepartures={availableDepartures}
                                  data={data}
                                  handleChangeData={handleChangeData}
                                  selectedDeparture={selectedDeparture}
                                  handleChangeSearchParams={
                                    handleChangeSearchParams
                                  }
                                  setSelectedDeparture={setSelectedDeparture}
                                  setSurchargesTotal={setSurchargesTotal}
                                  surchargesTotal={surchargesTotal}
                                  availableComplexDepartures={
                                    availableComplexDepartures
                                  }
                                  showDeparturesForNextYear={
                                    showDeparturesForNextYear
                                  }
                                />
                              )}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sx={{
                              display: { xs: "none", md: "block" },
                              height: !showComplexDepartures && "610px",
                              overflowY: !showComplexDepartures && "auto",
                              marginTop: "-700px",
                            }}
                          >
                            {!departuresLoading &&
                              !showComplexDepartures &&
                              activeButton != "map" && (
                                <Grid container>
                                  <AvailableDeparturesCalendar
                                    availableDepartures={availableDepartures}
                                    data={data}
                                    handleChangeData={handleChangeData}
                                    selectedDeparture={selectedDeparture}
                                    handleChangeSearchParams={
                                      handleChangeSearchParams
                                    }
                                    setSelectedDeparture={setSelectedDeparture}
                                    setSurchargesTotal={setSurchargesTotal}
                                    surchargesTotal={surchargesTotal}
                                    availableComplexDepartures={
                                      availableComplexDepartures
                                    }
                                    showDeparturesForNextYear={
                                      showDeparturesForNextYear
                                    }
                                  />
                                </Grid>
                              )}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sx={{
                              position: { md: "sticky" },
                              zIndex: 10,
                              bottom: { xs: 0, md: -20 },
                              right: { md: 0 },
                              left: { md: 0 },
                              background: theme.palette.background.paper,
                            }}
                          >
                            <Grid
                              container
                              spacing={2}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                                marginBottom: "15px",
                              }}
                            >
                              {selectedDeparture?.alertName &&
                                selectedDeparture?.alertContent && (
                                  <Grid item xs={12}>
                                    <Alert
                                      icon={<InfoOutlined fontSize="inherit" />}
                                      severity="info"
                                    >
                                      <Typography variant="h6">
                                        {selectedDeparture?.alertName}
                                      </Typography>
                                      <Typography
                                        variant="h6"
                                        sx={{ fontWeight: "light" }}
                                      >
                                        {selectedDeparture?.alertContent}
                                      </Typography>
                                    </Alert>
                                  </Grid>
                                )}
                              {selectedComplexDepartureKey &&
                                Object.keys(selectedDepartures || {}).some(
                                  (key) => {
                                    const departure = selectedDepartures[key];
                                    return (
                                      departure?.alertName &&
                                      departure?.alertContent
                                    );
                                  }
                                ) && (
                                  <Grid item xs={12}>
                                    <Alert
                                      icon={<InfoOutlined fontSize="inherit" />}
                                      severity="info"
                                    >
                                      <Typography variant="h6">
                                        {
                                          selectedDepartures[
                                            Object.keys(selectedDepartures)[0]
                                          ]?.alertName
                                        }
                                      </Typography>
                                      <Typography
                                        variant="h6"
                                        sx={{ fontWeight: "light" }}
                                      >
                                        {
                                          selectedDepartures[
                                            Object.keys(selectedDepartures)[0]
                                          ]?.alertContent
                                        }
                                      </Typography>
                                    </Alert>
                                  </Grid>
                                )}
                              <Grid item xs={2} md={1}>
                                <IconButton
                                  variant="contained"
                                  style={{
                                    border: "solid 1px ",
                                    borderColor: alpha(
                                      theme.palette.lightGray.main,
                                      0.4
                                    ),
                                    borderRadius: "4px",
                                    backgroundColor:
                                      theme.palette.background.default,
                                    height: "50px",
                                    width: "50px",
                                    marginLeft: "16px",
                                  }}
                                  disabled={activeButton === "map"}
                                  onClick={() => {
                                    handleButtonClick("map");
                                    // setAvailableDepartures(undefined);
                                    // setAllComplexDepartures(false);
                                  }}
                                >
                                  <ArrowBackIosIcon
                                    style={{
                                      color: theme.palette.darkContrast.main,
                                      opacity: activeButton === "map" && 0.6,
                                      paddingLeft: "3px",
                                      fontSize: "1.3rem",
                                    }}
                                  />
                                </IconButton>
                              </Grid>
                              <Grid
                                item
                                xs={10}
                                md={11}
                                sx={{
                                  display: { xs: "none", md: "flex" },
                                }}
                              >
                                <Button
                                  variant="contained"
                                  // style={{
                                  //   backgroundColor:
                                  //     selectedDeparture ||
                                  //     Object.keys(selectedDepartures).length >= 2
                                  //       ? theme.palette.primary.main
                                  //       : alpha(theme.palette.primary.main, 0.6),
                                  //   color:
                                  //     selectedDeparture ||
                                  //     Object.keys(selectedDepartures).length >= 2
                                  //       ? "white"
                                  //       : alpha("#fff", 0.7),
                                  // }}
                                  onClick={handleConfirmTimetable}
                                  fullWidth
                                  disabled={
                                    !selectedDeparture &&
                                    Object.keys(selectedDepartures).length < 2
                                  }
                                  style={{
                                    padding: "10px",
                                    marginLeft: "10px",
                                    height: "50px",
                                    backgroundColor:
                                      !selectedDeparture &&
                                      Object.keys(selectedDepartures).length < 2
                                        ? alpha(theme.palette.primary.main, 0.6)
                                        : theme.palette.primary.main,
                                  }}
                                >
                                  <Typography
                                    style={{
                                      textTransform: "none",
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                      color:
                                        !selectedDeparture &&
                                        Object.keys(selectedDepartures).length <
                                          2
                                          ? alpha(
                                              theme.palette.background.default,
                                              0.7
                                            )
                                          : theme.palette.background.default,
                                    }}
                                  >
                                    {" "}
                                    Confirm Time
                                  </Typography>
                                </Button>
                              </Grid>
                              <Grid
                                item
                                xs={9}
                                sx={{
                                  display: { xs: "block", md: "none" },
                                  marginTop: "auto",
                                  position: "sticky",
                                  bottom: 0,
                                }}
                              >
                                <Button
                                  variant="contained"
                                  // style={{
                                  //   backgroundColor:
                                  //     selectedDeparture ||
                                  //     Object.keys(selectedDepartures).length >= 2
                                  //       ? theme.palette.primary.main
                                  //       : alpha(theme.palette.primary.main, 0.6),
                                  //   color:
                                  //     selectedDeparture ||
                                  //     Object.keys(selectedDepartures).length >= 2
                                  //       ? "white"
                                  //       : alpha("#fff", 0.7),
                                  // }}
                                  onClick={handleConfirmTimetable}
                                  fullWidth
                                  disabled={
                                    !selectedDeparture &&
                                    Object.keys(selectedDepartures).length < 2
                                  }
                                  style={{
                                    height: "50px",
                                    padding: "10px",
                                    marginRight: "16px",
                                    backgroundColor:
                                      !selectedDeparture &&
                                      Object.keys(selectedDepartures).length < 2
                                        ? alpha(theme.palette.primary.main, 0.6)
                                        : theme.palette.primary.main,
                                  }}
                                >
                                  <Typography
                                    style={{
                                      textTransform: "none",
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                      color:
                                        !selectedDeparture &&
                                        Object.keys(selectedDepartures).length <
                                          2
                                          ? alpha(
                                              theme.palette.background.default,
                                              0.7
                                            )
                                          : theme.palette.background.default,
                                    }}
                                  >
                                    {" "}
                                    Confirm Time
                                  </Typography>
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : activeButton === "attachments" ? (
                        <>
                          <Grid
                            container
                            spacing={2}
                            sx={{
                              position: "relative",
                              marginTop: "50px",
                              padding: 0,
                            }}
                          >
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display: "block",
                                height: isSmallScreen ? "550px" : "620px",
                                overflowY: "auto",
                              }}
                            >
                              {data?.dangerousGoods && (
                                <Grid item xs={12}>
                                  <Typography
                                    variant="h6"
                                    sx={{
                                      margin: "3px",
                                      opacity: 0.6,
                                      fontWeight: "light",
                                    }}
                                  >
                                    Dangerous Goods Document
                                  </Typography>
                                  <FilePond
                                    onprocessfile={handleOnProcessFile}
                                    files={files}
                                    onupdatefiles={setFiles}
                                    onremovefile={() => {
                                      setDangerousGoodsDocument(null);
                                    }}
                                    maxFileSize="5MB"
                                    allowFileSizeValidation={true}
                                    allowMultiple={false}
                                    acceptedFileTypes={[
                                      "image/*",
                                      "application/pdf",
                                    ]}
                                    maxFiles={1}
                                    server={{
                                      process: (
                                        fieldName,
                                        file,
                                        metadata,
                                        load,
                                        error,
                                        progress,
                                        abort
                                      ) => {
                                        try {
                                          handleUploadDocument(
                                            file,
                                            setDangerousGoodsDocument
                                          );
                                          load(file);
                                        } catch (err) {
                                          error(
                                            "An error occurred during file processing"
                                          );
                                        }
                                      },
                                      revert: null,
                                      restore: null,
                                      load: null,
                                    }}
                                    name="file"
                                    labelIdle='Drag & Drop your file or <span class="filepond--label-action">Browse</span>'
                                  />
                                </Grid>
                              )}
                              {selectedDeparture?.requireAdditionalDocuments ||
                              Object.values(selectedDepartures)[0]
                                ?.requireAdditionalDocuments ||
                              Object.values(selectedDepartures)[1]
                                ?.requireAdditionalDocuments ? (
                                <>
                                  <Grid item xs={12}>
                                    <Typography
                                      variant="h6"
                                      sx={{
                                        margin: "3px",
                                        opacity: 0.6,
                                        fontWeight: "light",
                                      }}
                                    >
                                      {
                                        selectedDeparture?.additionalDocumentsInfo
                                      }
                                      {Object.values(selectedDepartures)[0]
                                        ?.additionalDocumentsInfo
                                        ? Object.values(selectedDepartures)[0]
                                            ?.additionalDocumentsInfo +
                                          " for First Leg"
                                        : ""}
                                      <br />
                                      {Object.values(selectedDepartures)[1]
                                        ?.additionalDocumentsInfo
                                        ? Object.values(selectedDepartures)[1]
                                            ?.additionalDocumentsInfo +
                                          " for Second Leg"
                                        : ""}
                                    </Typography>
                                    <FilePond
                                      onprocessfile={handleOnProcessFile}
                                      files={additionalFiles}
                                      onupdatefiles={setAdditionalFiles}
                                      onremovefile={(error, file) =>
                                        handleRemoveFile(file)
                                      }
                                      allowMultiple={true}
                                      acceptedFileTypes={[
                                        "image/*",
                                        "application/pdf",
                                      ]}
                                      maxFiles={5}
                                      maxFileSize="5MB"
                                      allowFileSizeValidation={true}
                                      maxTotalFileSize="25MB"
                                      server={{
                                        process: (
                                          fieldName,
                                          file,
                                          metadata,
                                          load,
                                          error,
                                          progress,
                                          abort
                                        ) => {
                                          try {
                                            handleUploadAdditionalDocuments(
                                              additionalFiles,
                                              setAdditionalDocuments
                                            );
                                            load(file);
                                          } catch (err) {
                                            error(
                                              "An error occurred during file processing"
                                            );
                                          }
                                        },
                                        revert: null,
                                        restore: null,
                                        load: null,
                                      }}
                                      name="file"
                                      labelIdle='Drag & Drop your file or <span class="filepond--label-action">Browse</span>'
                                    />
                                  </Grid>
                                </>
                              ) : (
                                <>
                                  <Grid
                                    item
                                    xs={12}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                    mt={3}
                                  >
                                    <Typography
                                      variant="h5"
                                      sx={{
                                        margin: "3px",
                                        opacity: 0.6,
                                        fontWeight: "light",
                                      }}
                                    >
                                      {" "}
                                      {!data?.dangerousGoods &&
                                        "No additional documents required for this route."}
                                    </Typography>
                                  </Grid>
                                </>
                              )}
                              {/* {!data?.dangerousGoods &&
                              !(
                                selectedDeparture?.requireAdditionalDocuments ||
                                Object.values(selectedDepartures)[0]
                                  ?.requireAdditionalDocuments ||
                                Object.values(selectedDepartures)[1]
                                  ?.requireAdditionalDocuments
                              ) && (
                                <>
                                  <Grid
                                    item
                                    xs={12}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                    mt={3}
                                  >
                                    <Typography
                                      variant="h5"
                                      sx={{
                                        margin: "3px",
                                        opacity: 0.6,
                                        fontWeight: "light",
                                      }}
                                    >
                                      No additional documents required for this
                                      route.
                                    </Typography>
                                  </Grid>
                                </>
                              )} */}
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sx={{
                                position: { md: "sticky" },
                                zIndex: 10,
                                bottom: { xs: 0, md: -20 },
                                right: { md: 0 },
                                left: { md: 0 },
                                background: theme.palette.background.paper,
                              }}
                            >
                              <Grid
                                container
                                spacing={2}
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                  marginBottom: "15px",
                                }}
                              >
                                <Grid item xs={2} md={1}>
                                  <IconButton
                                    variant="contained"
                                    style={{
                                      border: "solid 1px ",
                                      borderColor: alpha(
                                        theme.palette.lightGray.main,
                                        0.4
                                      ),
                                      borderRadius: "4px",
                                      backgroundColor:
                                        theme.palette.background.default,
                                      height: "50px",
                                      width: "50px",
                                      marginLeft: "16px",
                                    }}
                                    disabled={activeButton === "map"}
                                    onClick={() => {
                                      handleButtonClick("timetable");
                                      // setAvailableDepartures(undefined);
                                      // setAllComplexDepartures(false);
                                    }}
                                  >
                                    <ArrowBackIosIcon
                                      style={{
                                        color: theme.palette.darkContrast.main,
                                        opacity: activeButton === "map" && 0.6,
                                        paddingLeft: "3px",
                                        fontSize: "1.3rem",
                                      }}
                                    />
                                  </IconButton>
                                </Grid>
                                <Grid
                                  item
                                  xs={10}
                                  md={11}
                                  sx={{
                                    display: { xs: "none", md: "flex" },
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    // style={{
                                    //   backgroundColor:
                                    //     selectedDeparture ||
                                    //     Object.keys(selectedDepartures).length >= 2
                                    //       ? theme.palette.primary.main
                                    //       : alpha(theme.palette.primary.main, 0.6),
                                    //   color:
                                    //     selectedDeparture ||
                                    //     Object.keys(selectedDepartures).length >= 2
                                    //       ? "white"
                                    //       : alpha("#fff", 0.7),
                                    // }}
                                    onClick={handleConfirmAttachments}
                                    fullWidth
                                    disabled={
                                      !selectedDeparture &&
                                      Object.keys(selectedDepartures).length < 2
                                    }
                                    style={{
                                      padding: "10px",
                                      marginLeft: "10px",
                                      height: "50px",
                                      backgroundColor:
                                        !selectedDeparture &&
                                        Object.keys(selectedDepartures).length <
                                          2
                                          ? alpha(
                                              theme.palette.primary.main,
                                              0.6
                                            )
                                          : theme.palette.primary.main,
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        textTransform: "none",
                                        fontWeight: "bold",
                                        fontSize: "16px",
                                        color:
                                          !selectedDeparture &&
                                          Object.keys(selectedDepartures)
                                            .length < 2
                                            ? alpha(
                                                theme.palette.background
                                                  .default,
                                                0.7
                                              )
                                            : theme.palette.background.default,
                                      }}
                                    >
                                      {!data?.dangerousGoods &&
                                      !(
                                        selectedDeparture?.requireAdditionalDocuments ||
                                        Object.values(selectedDepartures)[0]
                                          ?.requireAdditionalDocuments ||
                                        Object.values(selectedDepartures)[1]
                                          ?.requireAdditionalDocuments
                                      )
                                        ? "Continue To Pricing"
                                        : "Confirm Attachments"}
                                    </Typography>
                                  </Button>
                                </Grid>
                                <Grid
                                  item
                                  xs={9}
                                  sx={{
                                    display: { xs: "block", md: "none" },
                                    marginTop: "auto",
                                    position: "sticky",
                                    bottom: 0,
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    onClick={handleConfirmAttachments}
                                    fullWidth
                                    disabled={
                                      !selectedDeparture &&
                                      Object.keys(selectedDepartures).length < 2
                                    }
                                    style={{
                                      height: "50px",
                                      padding: "10px",
                                      marginRight: "16px",
                                      backgroundColor:
                                        !selectedDeparture &&
                                        Object.keys(selectedDepartures).length <
                                          2
                                          ? alpha(
                                              theme.palette.primary.main,
                                              0.6
                                            )
                                          : theme.palette.primary.main,
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        textTransform: "none",
                                        fontWeight: "bold",
                                        fontSize: "16px",
                                        color:
                                          !selectedDeparture &&
                                          Object.keys(selectedDepartures)
                                            .length < 2
                                            ? alpha(
                                                theme.palette.background
                                                  .default,
                                                0.7
                                              )
                                            : theme.palette.background.default,
                                      }}
                                    >
                                      {!data?.dangerousGoods &&
                                      !(
                                        selectedDeparture?.requireAdditionalDocuments ||
                                        Object.values(selectedDepartures)[0]
                                          ?.requireAdditionalDocuments ||
                                        Object.values(selectedDepartures)[1]
                                          ?.requireAdditionalDocuments
                                      )
                                        ? "Continue To Pricing"
                                        : "Confirm Attachments"}
                                    </Typography>
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid
                            container
                            direction="column"
                            spacing={2}
                            sx={{
                              marginTop: "50px",
                              padding: 0,
                            }}
                          >
                            {showTotals &&
                              Object.keys(allComplexDepartures || {}).length ===
                                0 &&
                              !selectedComplexDepartureKey && (
                                <>
                                  <Grid
                                    item
                                    xs={12}
                                    sx={{
                                      display: { xs: "none", md: "block" },
                                    }}
                                  >
                                    <Pricing
                                      data={data}
                                      selectedDeparture={selectedDeparture}
                                      surchargesTotal={surchargesTotal}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sx={{
                                      display: { xs: "flex", md: "none" },
                                    }}
                                  >
                                    <PricingMobile
                                      data={data}
                                      selectedDeparture={selectedDeparture}
                                      surchargesTotal={surchargesTotal}
                                    />
                                  </Grid>
                                </>
                              )}
                            {showTotals &&
                              Object.keys(allComplexDepartures || {}).length >
                                0 &&
                              selectedComplexDepartureKey && (
                                <>
                                  <Grid
                                    item
                                    sx={{
                                      display: { xs: "none", md: "block" },
                                      height: "530px",
                                      overflowY: "auto",
                                    }}
                                  >
                                    <ComplexPricing
                                      availableDepartures={availableDepartures}
                                      data={data}
                                      handleChangeData={handleChangeData}
                                      selectedDeparture={selectedDeparture}
                                      setSelectedDeparture={
                                        setSelectedDeparture
                                      }
                                      setSurchargesTotal={setSurchargesTotal}
                                      surchargesTotal={surchargesTotal}
                                      availableComplexDepartures={
                                        availableComplexDepartures
                                      }
                                      complexTickets={complexTickets}
                                      setComplexTickets={setComplexTickets}
                                      setAvailableComplexDepartures={
                                        setAvailableComplexDepartures
                                      }
                                      selectedProductDestinations={
                                        selectedProductDestinations
                                      }
                                      setSelectedProductDestinations={
                                        setSelectedProductDestinations
                                      }
                                      selectedDepartures={selectedDepartures}
                                      setSelectedDepartures={
                                        setSelectedDepartures
                                      }
                                      selectedComplexDepartureKey={
                                        selectedComplexDepartureKey
                                      }
                                      setSelectedComplexDepartureKey={
                                        setSelectedComplexDepartureKey
                                      }
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    sx={{
                                      display: { xs: "block", md: "none" },
                                      height: "610px",
                                      overflowY: "auto",
                                      marginTop: "-50px",
                                    }}
                                  >
                                    <ComplexPricingMobile
                                      availableDepartures={availableDepartures}
                                      data={data}
                                      handleChangeData={handleChangeData}
                                      selectedDeparture={selectedDeparture}
                                      setSelectedDeparture={
                                        setSelectedDeparture
                                      }
                                      setSurchargesTotal={setSurchargesTotal}
                                      surchargesTotal={surchargesTotal}
                                      availableComplexDepartures={
                                        availableComplexDepartures
                                      }
                                      complexTickets={complexTickets}
                                      setComplexTickets={setComplexTickets}
                                      setAvailableComplexDepartures={
                                        setAvailableComplexDepartures
                                      }
                                      selectedProductDestinations={
                                        selectedProductDestinations
                                      }
                                      setSelectedProductDestinations={
                                        setSelectedProductDestinations
                                      }
                                      selectedDepartures={selectedDepartures}
                                      setSelectedDepartures={
                                        setSelectedDepartures
                                      }
                                      selectedComplexDepartureKey={
                                        selectedComplexDepartureKey
                                      }
                                      setSelectedComplexDepartureKey={
                                        setSelectedComplexDepartureKey
                                      }
                                    />
                                  </Grid>
                                </>
                              )}

                            <Grid
                              item
                              xs={12}
                              sx={{
                                position: "sticky",
                                bottom: 0,
                                marginBottom: "10px",
                                display: "flex",
                                gap: "10px",
                                background: theme.palette.background.paper,
                              }}
                            >
                              <Grid container spacing={2}>
                                {(selectedDeparture &&
                                  selectedDeparture?.salesPrice != null &&
                                  selectedDeparture?.price != null) ||
                                (selectedComplexDepartureKey &&
                                  Object.keys(selectedDepartures || {})
                                    .map((key) => {
                                      const departure = selectedDepartures[key];
                                      return (
                                        departure?.salesPrice != null &&
                                        departure?.price != null
                                      );
                                    })
                                    .some((result) => result)) ? (
                                  <>
                                    {selectedDeparture && (
                                      <Grid item xs={12}>
                                        <Box
                                          sx={{
                                            color: "black",
                                            border: "none",
                                            boxShadow: "none",
                                            padding: "10px 0px 10px 10px",
                                          }}
                                        >
                                          <Grid
                                            container
                                            spacing={2}
                                            justifyContent="center"
                                          >
                                            <Grid
                                              item
                                              xs={4}
                                              sx={{
                                                border: `1px solid ${alpha(
                                                  theme.palette.lightGray.main,
                                                  0.4
                                                )}`,
                                                paddingBottom: "6px",
                                                alignContent: "left",
                                                borderRight: "none",
                                                borderRadius: "5px 0 0 5px",
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  fontWeight: "normal",
                                                  marginLeft: "10px",
                                                  fontSize: "14px",
                                                  color: alpha(
                                                    theme.palette.lightGray
                                                      .main,
                                                    0.8
                                                  ),
                                                }}
                                                align="left"
                                              >
                                                Total Freight
                                              </Typography>
                                              <Typography
                                                style={{
                                                  fontWeight: "bold",
                                                  fontSize: "20px",
                                                  marginLeft: "10px",

                                                  // marginTop: 10,
                                                  color:
                                                    theme.palette.darkContrast
                                                      .main,
                                                }}
                                                align="left"
                                              >
                                                {data?.adjustedCalculatedPrice ? (
                                                  <>
                                                    {parseFloat(
                                                      data?.adjustedCalculatedPrice
                                                    )?.toFixed(2)}{" "}
                                                    &euro;
                                                  </>
                                                ) : (
                                                  <>
                                                    {selectedDeparture?.salesPrice?.toFixed(
                                                      2
                                                    )}
                                                    &euro;
                                                  </>
                                                )}
                                              </Typography>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={4}
                                              style={{
                                                border: "solid 1px ",
                                                borderColor: alpha(
                                                  theme.palette.lightGray.main,
                                                  0.4
                                                ),
                                                paddingBottom: "6px",
                                                alignContent: "center",
                                                borderRight: "none",
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  fontWeight: "normal",
                                                  fontSize: "14px",
                                                  color: alpha(
                                                    theme.palette.lightGray
                                                      .main,
                                                    0.8
                                                  ),
                                                }}
                                                align="center"
                                              >
                                                Total Surcharges
                                              </Typography>
                                              <Typography
                                                style={{
                                                  fontWeight: "bold",
                                                  fontSize: "20px",
                                                  // marginTop: 10,
                                                  color:
                                                    theme.palette.darkContrast
                                                      .main,
                                                }}
                                                align="center"
                                              >
                                                {surchargesTotal ? (
                                                  <>
                                                    {" "}
                                                    {surchargesTotal?.toFixed(
                                                      2
                                                    )}
                                                    &euro;
                                                  </>
                                                ) : (
                                                  "/"
                                                )}
                                              </Typography>
                                            </Grid>

                                            <Grid
                                              item
                                              xs={4}
                                              sx={{
                                                border: "solid 1px ",
                                                borderColor: alpha(
                                                  theme.palette.lightGray.main,
                                                  0.4
                                                ),
                                                paddingBottom: "6px",
                                                alignContent: "right",
                                                borderRadius: "0 5px 5px 0",
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  fontWeight: "normal",
                                                  fontSize: "14px",
                                                  color: alpha(
                                                    theme.palette.lightGray
                                                      .main,
                                                    0.8
                                                  ),
                                                  marginRight: "20px",
                                                }}
                                                align="center"
                                              >
                                                Total Price
                                              </Typography>
                                              <Typography
                                                style={{
                                                  fontWeight: "bold",
                                                  fontSize: "20px",
                                                  color:
                                                    theme.palette.darkContrast
                                                      .main,
                                                  marginRight: "20px",
                                                }}
                                                align="center"
                                              >
                                                {surchargesTotal ? (
                                                  data?.adjustedCalculatedPrice ? (
                                                    <>
                                                      {(
                                                        parseFloat(
                                                          data?.adjustedCalculatedPrice
                                                        ) +
                                                        surchargesTotal -
                                                        selectedDeparture?.surchargesIncludedInSalesPrice
                                                      )?.toFixed(2)}
                                                      &euro;
                                                    </>
                                                  ) : (
                                                    <>
                                                      {(
                                                        selectedDeparture?.salesPrice +
                                                        surchargesTotal -
                                                        selectedDeparture?.surchargesIncludedInSalesPrice
                                                      )?.toFixed(2)}
                                                      &euro;
                                                    </>
                                                  )
                                                ) : data?.adjustedCalculatedPrice ? (
                                                  <>
                                                    <>
                                                      {parseFloat(
                                                        data?.adjustedCalculatedPrice
                                                      )?.toFixed(2)}
                                                      &euro;
                                                    </>
                                                  </>
                                                ) : (
                                                  <>
                                                    {selectedDeparture?.salesPrice?.toFixed(
                                                      2
                                                    )}
                                                    &euro;
                                                  </>
                                                )}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                      </Grid>
                                    )}
                                    {selectedComplexDepartureKey && (
                                      <Grid item xs={12}>
                                        <Box
                                          sx={{
                                            color: "black",
                                            border: "none",
                                            boxShadow: "none",
                                            padding: "10px 15px",
                                            width: "100%",
                                            marginLeft: isSmallScreen && "8px",
                                            marginRight: isSmallScreen && "2px",
                                            boxSizing: "border-box",
                                          }}
                                        >
                                          {selectedDepartures && (
                                            <Grid
                                              container
                                              spacing={2}
                                              justifyContent="center"
                                            >
                                              <Grid
                                                item
                                                xs={4}
                                                style={{
                                                  border: "solid 1px ",
                                                  borderColor: alpha(
                                                    theme.palette.lightGray
                                                      .main,
                                                    0.4
                                                  ),
                                                  paddingBottom: "6px",
                                                  alignContent: "left",
                                                  borderRight: "none",
                                                  borderRadius: "5px 0 0 5px",
                                                }}
                                              >
                                                <Typography
                                                  style={{
                                                    fontWeight: "normal",
                                                    fontSize: "14px",
                                                    color: alpha(
                                                      theme.palette.lightGray
                                                        .main,
                                                      0.8
                                                    ),
                                                  }}
                                                  align="left"
                                                >
                                                  Total freight
                                                </Typography>
                                                <Typography
                                                  style={{
                                                    fontWeight: "bold",
                                                    fontSize: isSmallScreen
                                                      ? "16px"
                                                      : "20px",
                                                    color:
                                                      theme.palette.darkContrast
                                                        .main,
                                                  }}
                                                  align="left"
                                                >
                                                  {
                                                    //sum of all sales prices in selected departures in complex ticket
                                                    Object.keys(
                                                      selectedDepartures
                                                    )
                                                      .map((key) =>
                                                        complexTickets[key]
                                                          ?.adjustedCalculatedPrice
                                                          ? parseFloat(
                                                              complexTickets[
                                                                key
                                                              ]
                                                                ?.adjustedCalculatedPrice
                                                            )
                                                          : selectedDepartures[
                                                              key
                                                            ]?.salesPrice
                                                      )
                                                      .reduce(
                                                        (a, b) => a + b,
                                                        0
                                                      )
                                                      .toFixed(2)
                                                  }
                                                  &euro;
                                                </Typography>
                                              </Grid>
                                              <Grid
                                                item
                                                xs={4}
                                                style={{
                                                  border: "solid 1px ",
                                                  borderColor: alpha(
                                                    theme.palette.lightGray
                                                      .main,
                                                    0.4
                                                  ),
                                                  paddingBottom: "6px",
                                                  alignContent: "center",
                                                  borderRight: "none",
                                                }}
                                              >
                                                <Typography
                                                  style={{
                                                    fontWeight: "normal",
                                                    fontSize: "14px",
                                                    color: alpha(
                                                      theme.palette.lightGray
                                                        .main,
                                                      0.8
                                                    ),
                                                    marginLeft:
                                                      isSmallScreen && "-10px",
                                                  }}
                                                  align="center"
                                                >
                                                  Total surcharges
                                                </Typography>
                                                <Typography
                                                  style={{
                                                    fontWeight: "bold",
                                                    fontSize: isSmallScreen
                                                      ? "16px"
                                                      : "20px",
                                                    color:
                                                      theme.palette.darkContrast
                                                        .main,
                                                  }}
                                                  align="center"
                                                >
                                                  &euro;
                                                  {
                                                    //sum of all surcharges in selected departures in complex ticket
                                                    Object.keys(
                                                      selectedDepartures
                                                    )
                                                      .map((key) =>
                                                        selectedDepartures[key]
                                                          ?.ticketSurchargeList
                                                          ? selectedDepartures[
                                                              key
                                                            ]?.ticketSurchargeList
                                                              ?.map(
                                                                (surcharges) =>
                                                                  surcharges?.total
                                                              )
                                                              ?.reduce(
                                                                (a, b) => a + b,
                                                                0
                                                              )
                                                          : 0
                                                      )
                                                      .reduce(
                                                        (a, b) => a + b,
                                                        0
                                                      )
                                                      .toFixed(2)
                                                  }
                                                  &euro;
                                                </Typography>
                                              </Grid>
                                              <Grid
                                                item
                                                xs={4}
                                                style={{
                                                  border: `1px solid ${alpha(
                                                    theme.palette.lightGray
                                                      .main,
                                                    0.4
                                                  )}`,
                                                  paddingBottom: "6px",
                                                  alignContent: "right",
                                                  borderRadius: "0 5px 5px 0",
                                                }}
                                              >
                                                <Typography
                                                  style={{
                                                    fontWeight: "normal",
                                                    fontSize: "14px",
                                                    color: alpha(
                                                      theme.palette.lightGray
                                                        .main,
                                                      0.8
                                                    ),
                                                    marginRight: "20px",
                                                  }}
                                                  align="right"
                                                >
                                                  Total Price
                                                </Typography>
                                                <Typography
                                                  style={{
                                                    fontWeight: "bold",
                                                    fontSize: isSmallScreen
                                                      ? "16px"
                                                      : "20px",
                                                    color:
                                                      theme.palette.darkContrast
                                                        .main,
                                                    marginRight: "20px",
                                                  }}
                                                  align="right"
                                                >
                                                  {(
                                                    +Object.keys(
                                                      selectedDepartures
                                                    )
                                                      .map((key) =>
                                                        complexTickets[key]
                                                          ?.adjustedCalculatedPrice
                                                          ? parseFloat(
                                                              complexTickets[
                                                                key
                                                              ]
                                                                ?.adjustedCalculatedPrice
                                                            )
                                                          : selectedDepartures[
                                                              key
                                                            ]?.salesPrice
                                                      )
                                                      .reduce(
                                                        (a, b) => a + b,
                                                        0
                                                      ) +
                                                    +Object.keys(
                                                      selectedDepartures
                                                    )
                                                      .map((key) =>
                                                        selectedDepartures[key]
                                                          ?.ticketSurchargeList
                                                          ? selectedDepartures[
                                                              key
                                                            ]?.ticketSurchargeList
                                                              ?.map(
                                                                (surcharges) =>
                                                                  surcharges?.total
                                                              )
                                                              ?.reduce(
                                                                (a, b) => a + b,
                                                                0
                                                              )
                                                          : 0
                                                      )
                                                      .reduce(
                                                        (a, b) => a + b,
                                                        0
                                                      )
                                                      .toFixed(2)
                                                  ).toFixed(2)}
                                                  &euro;
                                                </Typography>
                                              </Grid>
                                            </Grid>
                                          )}
                                        </Box>
                                      </Grid>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                                <Grid
                                  item
                                  xs={2}
                                  md={1}
                                  sx={{
                                    marginBottom: "10px",
                                    display: "flex",
                                  }}
                                >
                                  <IconButton
                                    variant="contained"
                                    onClick={() => {
                                      data?.dangerousGoods
                                        ? handleButtonClick("attachments")
                                        : handleButtonClick("timetable");
                                    }}
                                    style={{
                                      border: "solid 1px ",
                                      borderColor: alpha(
                                        theme.palette.lightGray.main,
                                        0.4
                                      ),
                                      borderRadius: "4px",
                                      backgroundColor:
                                        theme.palette.background.default,
                                      height: "50px",
                                      width: "50px",
                                    }}
                                    disabled={activeButton === "map"}
                                  >
                                    <ArrowBackIosIcon
                                      style={{
                                        color: theme.palette.darkContrast.main,
                                        opacity: activeButton === "map" && 0.6,
                                        paddingLeft: "3px",
                                        fontSize: "1.3rem",
                                      }}
                                    />
                                  </IconButton>
                                </Grid>
                                {!showComplexDepartures &&
                                  !availableComplexDepartures &&
                                  activeButton == "pricing" && (
                                    <>
                                      <Grid
                                        item
                                        md={11}
                                        sx={{
                                          display: {
                                            xs: "none",
                                            md: "block",
                                          },
                                        }}
                                      >
                                        <Button
                                          variant="contained"
                                          fullWidth
                                          disabled={
                                            !data.truckPlateNumber || loading
                                            || (crnMandatory && !data?.customerReferenceNumber)
                                          }
                                          onClick={async () => {
                                            const hasDuplicates =
                                              await checkDuplicateTickets();
                                            if (hasDuplicates) {
                                              handleOpenDuplicateTicketsDialog(
                                                "bookTicketFromProductDepartures"
                                              );
                                            } else {
                                              bookTicketFromProductDepartures();
                                            }
                                          }}
                                          style={{
                                            height: "50px",
                                            padding: "10px",
                                            marginLeft: "10px",
                                            backgroundColor:
                                              !data.truckPlateNumber || (crnMandatory && !data?.customerReferenceNumber)
                                                ? alpha(
                                                    theme.palette.primary.main,
                                                    0.6
                                                  )
                                                : theme.palette.primary.main,
                                          }}
                                        >
                                          <Typography
                                            style={{
                                              textTransform: "none",
                                              fontWeight: "bold",
                                              fontSize: "16px",
                                              color: !data.truckPlateNumber || (crnMandatory && !data?.customerReferenceNumber)
                                                ? alpha(
                                                    theme.palette.background
                                                      .default,
                                                    0.7
                                                  )
                                                : theme.palette.background
                                                    .default,
                                            }}
                                          >
                                            Complete Booking
                                          </Typography>
                                        </Button>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={10}
                                        sx={{
                                          display: {
                                            xs: "block",
                                            md: "none",
                                          },
                                          position: "sticky",
                                          bottom: 0,
                                        }}
                                      >
                                        <Button
                                          variant="contained"
                                          fullWidth
                                          disabled={
                                            !data.truckPlateNumber || loading || (crnMandatory && !data?.customerReferenceNumber)
                                          }
                                          onClick={async () => {
                                            const hasDuplicates =
                                              await checkDuplicateTickets();
                                            if (hasDuplicates) {
                                              handleOpenDuplicateTicketsDialog(
                                                "bookTicketFromProductDepartures"
                                              );
                                            } else {
                                              bookTicketFromProductDepartures();
                                            }
                                          }}
                                          style={{
                                            height: "50px",
                                            backgroundColor:
                                              !data.truckPlateNumber || (crnMandatory && !data?.customerReferenceNumber)
                                                ? alpha(
                                                    theme.palette.primary.main,
                                                    0.6
                                                  )
                                                : theme.palette.primary.main,
                                          }}
                                        >
                                          <Typography
                                            style={{
                                              textTransform: "none",
                                              fontWeight: "bold",
                                              fontSize: "16px",
                                              color: !data.truckPlateNumber || (crnMandatory && !data?.customerReferenceNumber)
                                                ? alpha(
                                                    theme.palette.background
                                                      .default,
                                                    0.7
                                                  )
                                                : theme.palette.background
                                                    .default,
                                            }}
                                          >
                                            Complete Booking
                                          </Typography>
                                        </Button>
                                      </Grid>
                                    </>
                                  )}
                                {showTotals &&
                                  Object.keys(allComplexDepartures || {})
                                    .length > 0 &&
                                  selectedComplexDepartureKey && (
                                    <>
                                      <Grid
                                        item
                                        md={11}
                                        sx={{
                                          display: {
                                            xs: "none",
                                            md: "block",
                                          },
                                        }}
                                      >
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          fullWidth
                                          disabled={!data.truckPlateNumber || (crnMandatory && !data?.customerReferenceNumber)}
                                          style={{
                                            height: "50px",
                                            padding: "10px",
                                            marginLeft: "10px",
                                            backgroundColor:
                                              !data.truckPlateNumber || (crnMandatory && !data?.customerReferenceNumber)
                                                ? alpha(
                                                    theme.palette.primary.main,
                                                    0.6
                                                  )
                                                : theme.palette.primary.main,
                                          }}
                                          onClick={async () => {
                                            const hasDuplicates =
                                              await checkDuplicateTicketsComplex();
                                            if (hasDuplicates) {
                                              handleOpenDuplicateTicketsDialog(
                                                "createComplexTickets"
                                              );
                                            } else {
                                              createComplexTickets();
                                            }
                                          }}
                                        >
                                          <Typography
                                            style={{
                                              textTransform: "none",
                                              fontWeight: "bold",
                                              fontSize: "16px",
                                              color: !data.truckPlateNumber || (crnMandatory && !data?.customerReferenceNumber)
                                                ? alpha(
                                                    theme.palette.background
                                                      .default,
                                                    0.7
                                                  )
                                                : theme.palette.background
                                                    .default,
                                            }}
                                          >
                                            Complete Booking
                                          </Typography>
                                        </Button>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={10}
                                        sx={{
                                          display: {
                                            xs: "block",
                                            md: "none",
                                          },
                                        }}
                                      >
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          fullWidth
                                          disabled={!data.truckPlateNumber || (crnMandatory && !data?.customerReferenceNumber)}
                                          style={{
                                            padding: "10px",
                                            height: "50px",
                                            backgroundColor:
                                              !data.truckPlateNumber|| (crnMandatory && !data?.customerReferenceNumber)
                                                ? alpha(
                                                    theme.palette.primary.main,
                                                    0.6
                                                  )
                                                : theme.palette.primary.main,
                                          }}
                                          onClick={async () => {
                                            const hasDuplicates =
                                              await checkDuplicateTicketsComplex();
                                            if (hasDuplicates) {
                                              handleOpenDuplicateTicketsDialog(
                                                "createComplexTickets"
                                              );
                                            } else {
                                              createComplexTickets();
                                            }
                                          }}
                                        >
                                          <Typography
                                            style={{
                                              textTransform: "none",
                                              fontWeight: "bold",
                                              fontSize: "16px",
                                              color: !data.truckPlateNumber || (crnMandatory && !data?.customerReferenceNumber)
                                                ? alpha(
                                                    theme.palette.background
                                                      .default,
                                                    0.7
                                                  )
                                                : theme.palette.background
                                                    .default,
                                            }}
                                          >
                                            Complete Booking
                                          </Typography>
                                        </Button>
                                      </Grid>
                                    </>
                                  )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      )}{" "}
                    </>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )}
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <SnackbarContent
          style={{
            backgroundColor: "#d93535",
          }}
          message={snackbarMessage}
        />
      </Snackbar>
      <Dialog
        open={openDialogDuplicateTickets}
        onClose={handleCloseDuplicateTicketsDialog}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>Duplicate Bookings Information</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p>
              {Object.keys(complexTickets || {}).length > 0
                ? "We’ve detected that there are existing bookings linked to this plate number for the specified departure dates."
                : "We’ve detected that there are existing bookings linked to this plate number for the specified departure date."}
            </p>
            {duplicateTickets?.length > 0
              ? duplicateTickets?.map((duplicateTicket) => (
                  <p key={duplicateTicket.ticketNumber}>
                    {"Ticket No. "}
                    {duplicateTicket?.ticketNumber}
                    {data?.organization?.id ===
                      duplicateTicket?.organization?.id && (
                      <IconButton
                        component="a"
                        href={`/client/booking/0/15/%7B"status":"ALL_ACTIVE"%7D/${duplicateTicket.id}`}
                        target="_blank"
                        size="small"
                        color="primary"
                      >
                        <Icon>open_in_new</Icon>
                      </IconButton>
                    )}
                  </p>
                ))
              : null}
            <p>
              {
                "No-show fees will be applied to any tickets left unused without timely cancellation."
              }
            </p>
            <p>
              {
                "If you require assistance, please reach out to our support team. Thank you."
              }
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDuplicateTicketsDialog}
            variant="outlined"
            color="inherit"
            fullWidth
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => {
              if (methodToCall === "createComplexTickets") {
                createComplexTickets();
              } else if (methodToCall === "bookTicketFromProductDepartures") {
                bookTicketFromProductDepartures();
              }
              handleCloseDuplicateTicketsDialog();
            }}
          >
            Complete Booking
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
