import { useTheme } from "@emotion/react";
import EastIcon from "@mui/icons-material/East";
import {
  Box,
  Chip,
  Grid,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect } from "react";
import ReactCountryFlag from "react-country-flag";
export default function ComplexPricingMobile({
  data,
  availableComplexDepartures,
  complexTickets,
  setComplexTickets,
  selectedProductDestinations,
  setSelectedProductDestinations,
  selectedDepartures,
}) {
  const theme = useTheme();

  useEffect(() => {
    let tmpComplexTickets = { ...complexTickets };
    let tmp = { ...data };
    Object.keys(tmpComplexTickets).map((key) => {
      tmpComplexTickets[key].cargoWeight = tmp.cargoWeight;
      tmpComplexTickets[key].dangerousGoods = tmp.dangerousGoods;
      tmpComplexTickets[key].electricPlugin = tmp.electricPlugin;
      tmpComplexTickets[key].liveAnimals = tmp.liveAnimals;
      tmpComplexTickets[key].loaded = tmp.loaded;
      tmpComplexTickets[key].numberOfAxles = tmp.numberOfAxles;
      tmpComplexTickets[key].numberOfDrivers = tmp.numberOfDrivers;
      tmpComplexTickets[key].organization = tmp.organization;
      tmpComplexTickets[key].vehicleLength = tmp.vehicleLength;
      tmpComplexTickets[key].vehicleWidth = tmp.vehicleWidth;
      tmpComplexTickets[key].truckPlateNumber = tmp.truckPlateNumber;
      tmpComplexTickets[key].hasAdjustedPrice = tmp.hasAdjustedPrice;
      tmpComplexTickets[key].adjustedCalculatedPrice =
        tmp.adjustedCalculatedPrice;
      tmpComplexTickets[key].adjustedPrice = tmp.adjustedPrice;
    });
    setComplexTickets(tmpComplexTickets);
  }, [data]);

  useEffect(() => {
    if (
      selectedProductDestinations?.length == 0 ||
      selectedProductDestinations == undefined
    ) {
      prepopulateDefaultSelectedProductDestinations("firstLoad");
    }
  }, []);

  const prepopulateDefaultSelectedProductDestinations = (load) => {
    // prepopulate selectedProductDestinations with first legs of all availableComplexDepartures
    let tmp = [];
    let tmpComplexTickets = {};
    let firstLeg = 1;
    let tmpDepartures = { ...selectedDepartures };

    Object.keys(availableComplexDepartures).map((key) => {
      if (
        availableComplexDepartures[key]?.legNumber
          .toString()
          .startsWith(firstLeg.toString())
      ) {
        tmp.push({
          key: key,
          legNumber: availableComplexDepartures[key].legNumber,
        });
        tmpComplexTickets[key] = { ...data };
        tmpComplexTickets[key].departureDay =
          availableComplexDepartures[
            key
          ]?.productAvailableDeparturesDTOList[0]?.departureDate;
        firstLeg = firstLeg + 1;
      }
    });
    if (load === "firstLoad") {
      // setSelectedDepartures(tmpDepartures);
    }
    setComplexTickets(tmpComplexTickets);
    setSelectedProductDestinations(tmp);
  };

  const getLegLabel = (index) => {
    switch (index) {
      case 0:
        return "1";
      case 1:
        return "2";
      case 2:
        return "3";
      default:
        return ` ${index + 1}`;
    }
  };

  return (
    <>
      {selectedDepartures && (
        <Box sx={{ marginLeft: "16px" }}>
          <Grid container spacing={2} justifyContent="center">
            {Object.keys(selectedDepartures).map((key, index) => {
              const departure = selectedDepartures[key];
              const adjustedPrice = complexTickets[key]?.adjustedCalculatedPrice
                ? parseFloat(complexTickets[key]?.adjustedCalculatedPrice)
                : departure?.salesPrice;
              const totalSurcharges = departure?.ticketSurchargeList
                ? departure?.ticketSurchargeList
                    .map((surcharge) => surcharge?.total)
                    .reduce((a, b) => a + b, 0)
                : 0;
              const totalPrice = adjustedPrice + totalSurcharges;

              return (
                <Grid container spacing={2} mb={1}>
                  <Grid item xs={12}>
                    <Typography sx={{ mb: 1 }} variant="h6">
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={10}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              fontWeight: "bold",
                              marginTop: "20px",
                              marginLeft: "10px",
                            }}
                          >
                            <ReactCountryFlag
                              countryCode={
                                availableComplexDepartures[key]?.destination
                                  ?.fromCountry?.code
                              }
                              svg
                              style={{
                                width: "1.4em",
                                height: "1.4em",
                                borderRadius: "5px",
                                marginRight: "4px",
                              }}
                              title={
                                availableComplexDepartures[key]?.destination
                                  ?.fromCountry?.code
                              }
                            />
                            <span style={{ fontSize: "16px" }}>
                              {
                                availableComplexDepartures[key]?.destination
                                  ?.fromCity?.name
                              }
                            </span>

                            <EastIcon
                              style={{ marginLeft: "5px", marginRight: "5px" }}
                            />

                            <ReactCountryFlag
                              countryCode={
                                availableComplexDepartures[key]?.destination
                                  ?.toCountry?.code
                              }
                              svg
                              style={{
                                width: "1.4em",
                                height: "1.4em",
                                borderRadius: "5px",
                                marginRight: "4px",
                              }}
                              title={
                                availableComplexDepartures[key]?.destination
                                  ?.toCountry?.code
                              }
                            />
                            <span style={{ fontSize: "16px" }}>
                              {
                                availableComplexDepartures[key]?.destination
                                  ?.toCity?.name
                              }
                            </span>
                          </Box>
                        </Grid>
                        <Grid item xs={2} container justifyContent="flex-end">
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: "bold",
                              marginBottom: "-20px",
                              marginRight: "7px",
                            }}
                          >
                            <Chip
                              label={getLegLabel(index)}
                              sx={{
                                backgroundColor: "#88c078",
                                color: "white",
                                borderRadius: "4px",
                                fontSize: "14px",
                                paddingRight: "7px",

                                height: "25px",
                                width: "25px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                    <Table
                      elevation={1}
                      style={{
                        borderCollapse: "separate",
                        borderSpacing: " 0 10px",
                        marginTop: "-10px",
                      }}
                    >
                      <TableBody>
                        <TableRow
                          key={departure.productDestinationDepartureId}
                          style={{
                            height: "48px",
                            borderSpacing: "0 10px",
                            borderLeft: "0",
                            borderRight: "0",
                          }}
                        >
                          <TableCell colSpan={10} style={{ border: "none" }}>
                            <Grid
                              container
                              direction="column"
                              spacing={2}
                              mb={-4}
                            >
                              <Grid item xs>
                                <Box
                                  sx={{
                                    backgroundColor: "#88c078",
                                    color: "white",
                                    border: "none",
                                    boxShadow: "none",
                                    position: "relative",
                                    padding: "0 10px",
                                    borderRadius: "6px 6px  0 0",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      backgroundColor: "#88c078",
                                      color: "white",
                                      padding: "0 15px",
                                      paddingTop: "10px",
                                      paddingBottom: "10px",
                                    }}
                                  >
                                    <Grid
                                      container
                                      spacing={2}
                                      sx={{
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={3}
                                        style={{
                                          marginTop: "3px",
                                        }}
                                      >
                                        <Tooltip title={departure?.level}>
                                          {departure?.levelValue ===
                                            "MEDIUM" && (
                                            <Icon
                                              style={{
                                                verticalAlign: "middle",
                                                height: "24px",
                                                width: "24px",
                                              }}
                                            >
                                              <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <rect
                                                  x="5.50012"
                                                  y="11"
                                                  width="3"
                                                  height="6"
                                                  rx="1"
                                                  fill="#E9B145"
                                                  style={{
                                                    fill: "#E9B145",
                                                    fill: "color(display-p3 0.9137 0.6941 0.2706)",
                                                    fillOpacity: 1,
                                                  }}
                                                />
                                                <rect
                                                  x="10.5001"
                                                  y="9"
                                                  width="3"
                                                  height="8"
                                                  rx="1"
                                                  fill="#E9B145"
                                                  style={{
                                                    fill: "#E9B145",
                                                    fill: "color(display-p3 0.9137 0.6941 0.2706)",
                                                    fillOpacity: 1,
                                                  }}
                                                />
                                                <rect
                                                  x="15.5001"
                                                  y="7"
                                                  width="3"
                                                  height="10"
                                                  rx="1"
                                                  fill="#E9B145"
                                                  fillOpacity="0.25"
                                                  style={{
                                                    fill: "#E9B145",
                                                    fill: "color(display-p3 0.9137 0.6941 0.2706)",
                                                    fillOpacity: 0.25,
                                                  }}
                                                />
                                              </svg>
                                            </Icon>
                                          )}
                                        </Tooltip>
                                        <Tooltip title={departure?.level}>
                                          {" "}
                                          {departure?.levelValue === "HIGH" && (
                                            <Icon
                                              style={{
                                                verticalAlign: "middle",
                                                height: "24px",
                                                width: "24px",
                                              }}
                                            >
                                              <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <g clip-path="url(#clip0_504_7228)">
                                                  <rect
                                                    x="5.50012"
                                                    y="11"
                                                    width="3"
                                                    height="6"
                                                    rx="1"
                                                    fill="white"
                                                    style={{
                                                      fill: "white",
                                                      fill: "white",
                                                      fillOpacity: 1,
                                                    }}
                                                  />
                                                  <rect
                                                    x="10.5001"
                                                    y="9"
                                                    width="3"
                                                    height="8"
                                                    rx="1"
                                                    fill="white"
                                                    style={{
                                                      fill: "white",
                                                      fill: "white",
                                                      fillOpacity: 1,
                                                    }}
                                                  />
                                                  <rect
                                                    x="15.5001"
                                                    y="7"
                                                    width="3"
                                                    height="10"
                                                    rx="1"
                                                    fill="white"
                                                    style={{
                                                      fill: "white",
                                                      fill: "white",
                                                      fillOpacity: 1,
                                                    }}
                                                  />
                                                </g>
                                                <rect
                                                  x="0.500122"
                                                  y="0.5"
                                                  width="23"
                                                  height="23"
                                                  rx="3.5"
                                                  stroke="white"
                                                  style={{
                                                    stroke: "white",
                                                    stroke: "white",
                                                    strokeOpacity: 1,
                                                  }}
                                                />
                                                <defs>
                                                  <clipPath id="clip0_504_7228">
                                                    <rect
                                                      x="0.00012207"
                                                      width="24"
                                                      height="24"
                                                      rx="4"
                                                      fill="white"
                                                      style={{
                                                        fill: "white",
                                                        fillOpacity: 1,
                                                      }}
                                                    />
                                                  </clipPath>
                                                </defs>
                                              </svg>
                                            </Icon>
                                          )}
                                        </Tooltip>
                                        <Tooltip title={departure?.level}>
                                          {departure?.levelValue === "LOW" && (
                                            <Icon
                                              style={{
                                                verticalAlign: "middle",
                                                height: "24px",
                                                width: "24px",
                                              }}
                                            >
                                              <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <g clip-path="url(#clip0_504_7314)">
                                                  <rect
                                                    x="5.5"
                                                    y="11"
                                                    width="3"
                                                    height="6"
                                                    rx="1"
                                                    fill="white"
                                                    style={{
                                                      fill: "white",
                                                      fillOpacity: 1,
                                                    }}
                                                  />
                                                  <rect
                                                    x="10.5"
                                                    y="9"
                                                    width="3"
                                                    height="8"
                                                    rx="1"
                                                    fill="white"
                                                    fill-opacity="0.25"
                                                    style={{
                                                      fill: "white",
                                                      fillOpacity: 0.25,
                                                    }}
                                                  />
                                                  <rect
                                                    x="15.5"
                                                    y="7"
                                                    width="3"
                                                    height="10"
                                                    rx="1"
                                                    fill="white"
                                                    fill-opacity="0.25"
                                                    style={{
                                                      fill: "white",
                                                      fillOpacity: 0.25,
                                                    }}
                                                  />
                                                </g>
                                                <rect
                                                  x="0.5"
                                                  y="0.5"
                                                  width="23"
                                                  height="23"
                                                  rx="3.5"
                                                  stroke="white"
                                                  style={{
                                                    stroke: "white",
                                                    strokeOpacity: 1,
                                                  }}
                                                />
                                                <defs>
                                                  <clipPath id="clip0_504_7314">
                                                    <rect
                                                      width="24"
                                                      height="24"
                                                      rx="4"
                                                      fill="white"
                                                      style={{
                                                        fill: "white",
                                                        fillOpacity: 1,
                                                      }}
                                                    />
                                                  </clipPath>
                                                </defs>
                                              </svg>
                                            </Icon>
                                          )}
                                        </Tooltip>
                                      </Grid>
                                      <Grid item xs={9}>
                                        <Typography
                                          sx={{
                                            fontSize: "15px",
                                            fontWeight: "bold",
                                            whiteSpace: "nowrap",
                                            overflow: "visible",
                                            marginLeft: "-45px",
                                            flexShrink: 0,
                                          }}
                                        >
                                          &nbsp;
                                          {departure?.operatorName
                                            ? departure?.operatorName
                                            : departure?.supplier?.name}
                                        </Typography>
                                        <Typography
                                          sx={{
                                            fontSize: "10px",
                                            color: "white",
                                            marginTop: "-7px",
                                            whiteSpace: "nowrap",
                                            marginLeft: "-40px",
                                          }}
                                        >
                                          {departure?.level}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                      <Grid
                                        item
                                        xs={12}
                                        sx={{ marginBottom: "15px" }}
                                      >
                                        <Grid container spacing={2}>
                                          <Grid
                                            item
                                            xs={3}
                                            style={{ marginRight: "10px" }}
                                          >
                                            <Typography
                                              variant="h6"
                                              sx={{ opacity: "50%" }}
                                            >
                                              Departure
                                            </Typography>
                                            <Typography
                                              sx={{
                                                fontWeight: "bold",
                                                fontSize: "14px",
                                                marginTop: "-5px",
                                              }}
                                            >
                                              {departure?.departureTime}
                                            </Typography>
                                            <Typography
                                              style={{ marginTop: "-5px" }}
                                            >
                                              {moment(
                                                departure?.departureDate
                                              ).format("DD.MM.YYYY")}
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={2}
                                            sx={{
                                              alignContent: "center",
                                              marginLeft: "40px",
                                              marginRight: "10px",
                                            }}
                                          >
                                            <Typography>
                                              <EastIcon
                                                style={{ fontSize: "22px" }}
                                              />
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={4}>
                                            <Typography
                                              variant="h6"
                                              sx={{
                                                opacity: "50%",
                                                marginLeft: "40px",
                                              }}
                                            >
                                              Arrival
                                            </Typography>
                                            <Typography
                                              sx={{
                                                fontWeight: "bold",
                                                marginLeft: "40px",
                                                fontSize: "14px",
                                                marginTop: "-5px",
                                              }}
                                            >
                                              {departure?.arrivalTime}
                                            </Typography>
                                            <Typography
                                              style={{
                                                marginLeft: "40px",
                                                marginTop: "-5px",
                                              }}
                                            >
                                              {departure?.arrivalDay == "0"
                                                ? moment(
                                                    departure?.departureDate
                                                  ).format("DD.MM.YYYY")
                                                : departure?.arrivalDay == "1"
                                                ? moment(
                                                    departure?.departureDate
                                                  )
                                                    .add("days", 1)
                                                    .format("DD.MM.YYYY")
                                                : departure?.arrivalDay == "2"
                                                ? moment(
                                                    departure?.departureDate
                                                  )
                                                    .add("days", 2)
                                                    .format("DD.MM.YYYY")
                                                : ""}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                  <Box sx={{ padding: "0 10px 0 20px" }}>
                                    <Grid container spacing={2}>
                                      <Grid item md={12}>
                                        <Grid
                                          container
                                          spacing={2}
                                          sx={{
                                            backgroundColor:
                                              "rgba(160, 205, 147, 0.1)",
                                            borderRadius: "5px",
                                            minWidth: "305px",
                                            overflow: "hidden",
                                          }}
                                        >
                                          {departure?.productDestinationDepartureId ==
                                            selectedDepartures[key]
                                              ?.productDestinationDepartureId &&
                                            departure?.ticketSurchargeList?.map(
                                              (surcharge, index) => (
                                                <Grid
                                                  item
                                                  xs={12}
                                                  key={
                                                    surcharge?.productDestinationDepartureId
                                                  }
                                                  sx={{
                                                    height: "60px",
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                    alignItems: "center",
                                                    backgroundColor:
                                                      index % 2 !== 0
                                                        ? "rgba(160, 205, 147, 0.3)"
                                                        : "rgba(160, 205, 147, 0.6)",
                                                    padding: "10px",
                                                  }}
                                                >
                                                  <Typography
                                                    sx={{
                                                      flex: 1,
                                                      ...theme.typography.h6,
                                                    }}
                                                  >
                                                    {surcharge.surchargeName}
                                                  </Typography>
                                                  {/* {data?.organization
                                                    ?.showPrice && (
                                                    <Typography
                                                      sx={{
                                                        flex: 1,
                                                        textAlign: "center",
                                                        ...theme.typography.h6,
                                                        marginLeft: "30px",
                                                      }}
                                                    >
                                                      <input
                                                        disabled={
                                                          surcharge?.disabled
                                                        }
                                                        value={
                                                          selectedDepartures[
                                                            keyframes
                                                          ]?.ticketSurchargeList?.find(
                                                            (s) =>
                                                              s.productSurcharge
                                                                .id ==
                                                              surcharge
                                                                .productSurcharge
                                                                .id
                                                          )?.quantity
                                                            ? selectedDepartures[
                                                                key
                                                              ]?.ticketSurchargeList?.find(
                                                                (s) =>
                                                                  s
                                                                    .productSurcharge
                                                                    .id ==
                                                                  surcharge
                                                                    .productSurcharge
                                                                    .id
                                                              )?.quantity
                                                            : surcharge?.quantity
                                                        }
                                                        onChange={(e) => {
                                                          let newDeparture = {
                                                            ...selectedDepartures[
                                                              key
                                                            ],
                                                          };
                                                          newDeparture.ticketSurchargeList.find(
                                                            (s) =>
                                                              s.productSurcharge
                                                                .id ==
                                                              surcharge
                                                                .productSurcharge
                                                                .id
                                                          ).quantity =
                                                            e.target.valueAsNumber;
                                                          newDeparture.ticketSurchargeList.find(
                                                            (s) =>
                                                              s.productSurcharge
                                                                .id ==
                                                              surcharge
                                                                .productSurcharge
                                                                .id
                                                          ).total =
                                                            e.target
                                                              .valueAsNumber *
                                                            surcharge.price;
                                                          let tmp = {
                                                            ...selectedDepartures,
                                                          };
                                                          tmp[key] =
                                                            newDeparture;
                                                          setSelectedDepartures(
                                                            tmp
                                                          );
                                                        }}
                                                        size="small"
                                                        type={"number"}
                                                        style={{
                                                          border: "none",
                                                          backgroundColor:
                                                            "transparent",
                                                          width: "30px",
                                                          color: "white",
                                                        }}
                                                      />
                                                    </Typography>
                                                  )}
                                                  {data?.organization
                                                    ?.showPrice && (
                                                    <Typography
                                                      sx={{
                                                        flex: 1,
                                                        textAlign: "center",
                                                        ...theme.typography.h6,
                                                        marginLeft: "-15px",
                                                        marginRight: "5px",
                                                      }}
                                                    >
                                                      x
                                                    </Typography>
                                                  )}
                                                  {data?.organization
                                                    ?.showPrice && (
                                                    <Typography
                                                      sx={{
                                                        flex: 1,
                                                        textAlign: "center",
                                                        ...theme.typography.h6,
                                                      }}
                                                    >
                                                      {surcharge.price?.toFixed(
                                                        2
                                                      )}
                                                      &euro;
                                                    </Typography>
                                                  )}
                                                  {data?.organization
                                                    ?.showPrice && (
                                                    <Typography
                                                      sx={{
                                                        flex: 1,
                                                        textAlign: "center",
                                                        ...theme.typography.h6,
                                                        marginLeft: " 5px",
                                                        marginRight: "5px",
                                                      }}
                                                    >
                                                      =
                                                    </Typography>
                                                  )} */}
                                                  {departure &&
                                                  departure?.salesPrice !=
                                                    null &&
                                                  departure?.price != null ? (
                                                    <Typography
                                                      sx={{
                                                        flex: 1,
                                                        textAlign: "center",
                                                        ...theme.typography.h6,
                                                        marginRight: "10px",
                                                      }}
                                                    >
                                                      {parseFloat(
                                                        surcharge.price *
                                                          surcharge.quantity
                                                      ).toFixed(2)}
                                                      &euro;
                                                    </Typography>
                                                  ) : (
                                                    ""
                                                  )}
                                                </Grid>
                                              )
                                            )}
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </Box>
                                <Grid item>
                                  <Box
                                    sx={{
                                      backgroundColor: "#88c078",
                                      color: "white",
                                      border: "none",
                                      boxShadow: "none",
                                      padding: "10px 15px",
                                      borderRadius: "  0 0 6px 6px",
                                    }}
                                  >
                                    {departure &&
                                    departure?.salesPrice != null &&
                                    departure?.price != null ? (
                                      <Grid
                                        container
                                        spacing={2}
                                        justifyContent="center"
                                      >
                                        <Grid item md={4}>
                                          <Typography
                                            sx={{
                                              opacity: "50%",
                                            }}
                                            align="left"
                                          >
                                            Total Freight
                                          </Typography>
                                          <Typography
                                            style={{
                                              fontWeight: "bold",
                                              fontSize: "14px",
                                              // marginTop: 10,
                                            }}
                                            align="left"
                                          >
                                            {data?.adjustedCalculatedPrice ? (
                                              <>
                                                {parseFloat(
                                                  data?.adjustedCalculatedPrice
                                                )?.toFixed(2)}{" "}
                                                &euro;
                                              </>
                                            ) : (
                                              <>
                                                {departure?.salesPrice?.toFixed(
                                                  2
                                                )}
                                                &euro;
                                              </>
                                            )}
                                          </Typography>
                                        </Grid>
                                        <Grid item md={4}>
                                          <Typography
                                            sx={{
                                              opacity: "50%",
                                              marginLeft: "30px",
                                              marginRight: "30px",
                                            }}
                                            align="center"
                                          >
                                            Total Surcharges
                                          </Typography>
                                          <Typography
                                            style={{
                                              fontWeight: "bold",
                                              fontSize: "14px",
                                              // marginTop: 10,
                                            }}
                                            align="center"
                                          >
                                            <>
                                              {" "}
                                              {parseFloat(
                                                totalSurcharges
                                              ).toFixed(2)}
                                              &euro;
                                            </>
                                          </Typography>
                                        </Grid>

                                        <Grid item md={4}>
                                          <Typography
                                            sx={{ opacity: "50%" }}
                                            align="right"
                                          >
                                            Total Price
                                          </Typography>
                                          <Typography
                                            style={{
                                              fontWeight: "bold",
                                              fontSize: "14px",
                                            }}
                                            align="right"
                                          >
                                            {data?.adjustedCalculatedPrice ? (
                                              <>
                                                {(
                                                  parseFloat(
                                                    data.adjustedCalculatedPrice
                                                  ) +
                                                  totalSurcharges -
                                                  (departure?.surchargesIncludedInSalesPrice ||
                                                    0)
                                                ).toFixed(2)}
                                                &euro;
                                              </>
                                            ) : (
                                              <>
                                                {(
                                                  (departure?.salesPrice || 0) +
                                                  totalSurcharges -
                                                  (departure?.surchargesIncludedInSalesPrice ||
                                                    0)
                                                ).toFixed(2)}
                                                &euro;
                                              </>
                                            )}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    ) : (
                                      ""
                                    )}
                                  </Box>
                                </Grid>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      )}

      {/* {!editTicketFlag && (
        <>
          {data?.organization?.showPrice && (
            <Grid item xs={12} mt={5}>
              <Box
                sx={{
                  color: "black",
                  border: "none",
                  boxShadow: "none",
                  width: "330px",
                }}
              >
                {selectedDepartures && (
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={4}
                      style={{
                        border: "solid 1px ",
                        borderColor: alpha(theme.palette.lightGray.main, 0.5),
                        borderRight: "none",
                        borderRadius: "5px 0 0 5px",
                        paddingRight: "5px",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "normal",
                          fontSize: "12px",
                          color: alpha(theme.palette.lightGray.main, 0.9),
                          textAlign: "left",
                        }}
                        align="left"
                      >
                        Total freight
                      </Typography>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          color: theme.palette.darkContrast.main,
                          textAlign: "left",
                        }}
                        align="left"
                      >
                        {
                          //sum of all sales prices in selected departures in complex ticket
                          Object.keys(selectedDepartures)
                            .map((key) =>
                              complexTickets[key]?.adjustedCalculatedPrice
                                ? parseFloat(
                                    complexTickets[key]?.adjustedCalculatedPrice
                                  )
                                : selectedDepartures[key]?.salesPrice
                            )
                            .reduce((a, b) => a + b, 0)
                            .toFixed(2)
                        }
                        &euro;
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      style={{
                        border: "solid 1px ",
                        borderColor: alpha(theme.palette.lightGray.main, 0.5),
                        paddingBottom: "6px",
                        alignContent: "left",
                        borderRight: "none",
                        paddingRight: "10px",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "normal",
                          fontSize: "12px",
                          color: alpha(theme.palette.lightGray.main, 0.9),
                          textAlign: "left",
                          textWrap: "nowrap",
                        }}
                        align="left"
                      >
                        Total surcharges
                      </Typography>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          color: theme.palette.darkContrast.main,
                          textAlign: "center",
                        }}
                        align="center"
                      >
                        {
                          //sum of all surcharges in selected departures in complex ticket
                          Object.keys(selectedDepartures)
                            .map((key) =>
                              selectedDepartures[key]?.ticketSurchargeList
                                ? selectedDepartures[key]?.ticketSurchargeList
                                    ?.map((surcharges) => surcharges?.total)
                                    ?.reduce((a, b) => a + b, 0)
                                : 0
                            )
                            .reduce((a, b) => a + b, 0)
                            .toFixed(2)
                        }
                        &euro;
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      style={{
                        border: "solid 1px ",
                        borderColor: alpha(theme.palette.lightGray.main, 0.5),
                        paddingBottom: "6px",
                        alignContent: "center",
                        borderRadius: "0 5px 5px 0",
                        paddingRight: "5px",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "normal",
                          fontSize: "12px",
                          color: alpha(theme.palette.lightGray.main, 0.9),
                          textAlign: "right",
                          paddingRight: "5px",
                        }}
                        align="right"
                      >
                        Total Price
                      </Typography>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          color: theme.palette.darkContrast.main,
                          textAlign: "right",
                          paddingRight: "5px",
                        }}
                        align="right"
                      >
                        {(
                          +Object.keys(selectedDepartures)
                            .map((key) =>
                              complexTickets[key]?.adjustedCalculatedPrice
                                ? parseFloat(
                                    complexTickets[key]?.adjustedCalculatedPrice
                                  )
                                : selectedDepartures[key]?.salesPrice
                            )
                            .reduce((a, b) => a + b, 0) +
                          +Object.keys(selectedDepartures)
                            .map((key) =>
                              selectedDepartures[key]?.ticketSurchargeList
                                ? selectedDepartures[key]?.ticketSurchargeList
                                    ?.map((surcharges) => surcharges?.total)
                                    ?.reduce((a, b) => a + b, 0)
                                : 0
                            )
                            .reduce((a, b) => a + b, 0)
                            .toFixed(2)
                        ).toFixed(2)}
                        &euro;
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Grid>
          )}
        </>
      )} */}
    </>
  );
}
